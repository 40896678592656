import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';

import { CompareListItem } from '../../models/compare.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { StatusService } from '../../../gfl-core/gfl-services/status.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'gfl-compare-list-item',
  templateUrl: './compare-list-item.component.html',
  styleUrls: ['./compare-list-item.component.scss'],
})
export class CompareListItemComponent implements OnChanges {
  @Input() compare: CompareListItem;
  @Input() route: any[];
  @Input() acls: Acls;
  public compareFormatted: CompareListItem;
  public insuranceTypeImageDefaultUrl: string;
  public isTerminatedCompare: boolean;

  readonly COMPARE_TERMINATED: number;

  /**
   * @ignore
   */
  constructor(public tools: ToolsService, private statusSrv: StatusService, public router: Router) {
    this.insuranceTypeImageDefaultUrl = environment.STORAGE_URL + '/insurance_types/9/photo.jpg';
    this.COMPARE_TERMINATED = this.statusSrv.getIdFromKey('COMPARE_TERMINATED');
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.compare) {
      this.compareFormatted = _.cloneDeep(this.compare);
      // format  month-year
      this.compareFormatted.compare.created_at_reformat = moment(this.compareFormatted.compare.created_at).format(
        'MMMM YYYY'
      );
      // set flag for terminated compare
      this.isTerminatedCompare = this.compareFormatted.compare.current_status_id === this.COMPARE_TERMINATED;
    }
  }
}
