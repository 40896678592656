import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Observable, of } from 'rxjs';
import { catchError, tap, withLatestFrom } from 'rxjs/operators';

import { ToolsService } from './tools.service';
import { NetworkMonitorService } from './network-monitor.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataMonitorService {
  private lockMaxDuration: number; // ms

  constructor(private tools: ToolsService, private network: NetworkMonitorService) {}

  public setMonitor(
    locksToMonitor: { name: string; lock: () => Observable<number>; cb: () => Observable<any> }[]
  ): void {
    _.forEach(locksToMonitor, (item) => {
      this.monitor(item.name, item.lock, item.cb);
    });
  }

  /**
   * set monitoring of a lock and its process
   *
   * @param name process name
   * @param lock$ lock observable
   * @param cb$ callback observable
   */
  private monitor(name: string, lock$: () => Observable<number>, cb$: () => Observable<any>): void {
    let ref; // setTimeout reference

    lock$()
      .pipe(
        withLatestFrom(this.network.isOnline()),
        tap(([lock, isOnline]) => {
          if (!lock && ref) {
            // process is over so we clear setTimeout
            clearTimeout(ref);
            ref = null;
          }

          if (lock && isOnline) {
            this.setLockMaxDuration(name);

            ref = setTimeout(() => {
              cb$()
                .pipe(
                  catchError((err) => {
                    this.tools.error('dataMonitor service ' + name, err);
                    return of(null);
                  })
                )
                .subscribe();
            }, this.lockMaxDuration * 1000);
          }
        })
      )
      .subscribe();
  }

  /**
   * Set lock max duration according connexion type
   *
   * @param name process name
   */
  private setLockMaxDuration(name: string) {
    const prefix = name === 'documents' ? 'LOCK_MAX_DURATION_DOC' : 'LOCK_MAX_DURATION';

    const { current, connexion } = this.network.getConnexionType();
    switch (current) {
      case connexion.cell3G:
        this.lockMaxDuration = environment[prefix + '_3G'];
        break;
      case connexion.cell4G:
        this.lockMaxDuration = environment[prefix + '_4G'];
        break;
      case connexion.ethernet:
        this.lockMaxDuration = environment[prefix + '_ETHERNET'];
        break;
      case connexion.wifi:
        this.lockMaxDuration = environment[prefix + '_WIFI'];
        break;
      default:
        this.lockMaxDuration = environment[prefix + '_2G'];
    }
  }
}
