import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GflDirectivesModule } from './gfl-directives/gfl-directives.module';
import { GflPipesModule } from './gfl-pipes/gfl-pipes.module';
import { GflComponentsModule } from './gfl-components/gfl-components.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    GflDirectivesModule,
    GflPipesModule,
    GflComponentsModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    GflDirectivesModule,
    GflPipesModule,
    GflComponentsModule,
  ],
})
export class GflCoreModule {}
