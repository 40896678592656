import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';

@Component({
  selector: 'gfl-compare-insurimmo-signed',
  templateUrl: './compare-insurimmo-signed.component.html',
  styleUrls: ['./compare-insurimmo-signed.component.scss'],
})
export class CompareInsurimmoSignedComponent implements OnInit {
  @Input() acls: Acls;
  @Output() title = new EventEmitter<string>();
  @Output() nextButtonLabel = new EventEmitter<string>();
  @Output() openSignedOffer = new EventEmitter<boolean>();
  @Output() sendSignedOffer = new EventEmitter<boolean>();
  @Output() openPolicy = new EventEmitter<boolean>();
  @Output() enableNext = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {
    this.title.emit('COMPARE.DISPLAY.SIGNED_OFFER');
    this.nextButtonLabel.emit('COMPARE.DISPLAY.SEE_POLICY');
    this.enableNext.emit(true);
  }

  public onOpenSignedOffer(): void {
    this.openSignedOffer.emit(true);
  }

  public onSendSignedOffer(): void {
    this.sendSignedOffer.emit(true);
  }

  public onOpenPolicy() {
    this.openPolicy.emit(true);
  }
}
