import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gfl-policy-documents-subheader-tablet',
  templateUrl: './policy-documents-subheader-tablet.component.html',
  styleUrls: ['./policy-documents-subheader-tablet.component.scss'],
})
export class PolicyDocumentsSubheaderTabletComponent {
  constructor(public router: Router) {}

  public goBack() {
    this.router.navigateByUrl('/policies').then();
  }
}
