import { Component, ElementRef, Input, OnChanges, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ToolsService } from '../../gfl-services/tools.service';
import { SpriteService } from '../../gfl-services/sprite.service';

@Component({
  selector: 'gfl-sprite',
  templateUrl: './gfl-sprite.component.html',
  styleUrls: ['./gfl-sprite.component.scss'],
})
export class GflSpriteComponent implements OnChanges, OnDestroy {
  @Input() item: string;
  @Input() flex: boolean;
  public url: string;
  private subscriptions: Subscription[] = [];

  /**
   * @ignore
   */
  constructor(private tools: ToolsService, private spriteSrv: SpriteService, private element: ElementRef) {}

  /**
   * @ignore
   */
  ngOnChanges(value) {
    this.setSpriteItem(this.item, this.flex);
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then();
  }

  /**
   * Fetch item in svg file and add it into DOM element
   *
   * @param item id of sprite element to extract
   * @param flex set to true to add flex: 1
   */
  private setSpriteItem(item: string, flex: boolean): void {
    this.subscriptions.push(
      this.spriteSrv
        .getSprite()
        .pipe(filter((val) => !!val))
        .subscribe((svg) => {
          if (svg) {
            const result = this.tools.extractSymbol(svg, item, flex);
            if (result) {
              this.element.nativeElement.children[0].innerHTML = result;
            }
          }
        })
    );
  }
}
