import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

/**
 * this directive is used to generate a static table header
 * It must be applied on <table> tag and this <table> tag must be wrapped by
 * <ion-scroll scrollY="true" style="flex:1;">...</ion-scroll> components
 *
 * @example
 * <ion-scroll scrollY="true" style="flex:1;">
 *                    <table gflFixTableHead class="board">
 *                      <thead>
 *                        <tr>
 *                          <th>{{ 'INSURANCE.BUDGET.BOARD_POLICY' | translate }}</th>
 *                          <th gflWidthIndicator>{{ 'INSURANCE.BUDGET.BOARD_TYPE' | translate }}</th>
 *                          <th gflWidthIndicator>{{ 'INSURANCE.BUDGET.BOARD_COMPANY' | translate }}</th>
 *                          <th gflWidthIndicator>{{ 'INSURANCE.BUDGET.BOARD_DEADLINE' | translate }}</th>
 *                          <th gflWidthIndicator style="text-align: right">
 *                              {{ 'INSURANCE.BUDGET.BOARD_PREMIUM' | translate }}
 *                           </th>
 *                        </tr>
 *                      </thead>
 *                      <tbody>
 *                        ...
 *                      </tbody>
 *                    </table>
 * </ion-scroll>
 */
@Directive({
  selector: '[gflFixTableHead]',
})
export class GflFixTableHeadDirective implements AfterViewInit {
  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngAfterViewInit() {
    const q = setInterval(() => {
      if (this.el.nativeElement.clientWidth) {
        // copy original table element
        const table = this.el.nativeElement.cloneNode(true);
        // remove fix-table-head attribute
        this.renderer.removeAttribute(table, 'gflFixTableHead');
        // remove tbody element from the cloned table
        this.renderer.removeChild(table, table.lastElementChild);
        // add position and top styles
        this.renderer.setStyle(table, 'position', 'absolute');
        this.renderer.setStyle(table, 'top', '0');
        this.renderer.setStyle(table, 'z-index', '10');
        // insert the new table in the DOM
        const scrollZoomWrapper = this.renderer.parentNode(this.el.nativeElement);
        const scrollContent = this.renderer.parentNode(scrollZoomWrapper);
        const ionScroll = this.renderer.parentNode(scrollContent);

        this.renderer.insertBefore(ionScroll, table, scrollContent);
        clearInterval(q);
      }
    }, 100);
  }
}
