import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthLoginGuard } from './guards/auth-login.guard';
import { AuthLoginAsCustomerGuard } from './guards/auth-login-as-customer.guard';
import { AuthGuard } from './guards/auth.guard';
import { ExternalAuthGuard } from './guards/external-auth.guard';

const routes: Routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'change-customer',
        loadChildren: () =>
          import('./pages/change-customer/change-customer.module').then(m => m.ChangeCustomerPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
        canActivate: [AuthLoginGuard],
      },
      {
        path: 'login-user-as-customer',
        loadChildren: () =>
          import('./pages/login-user-as-customer/login-user-as-customer.module').then(
            m => m.LoginUserAsCustomerPageModule
          ),
        canActivate: [AuthLoginAsCustomerGuard],
      },
      {
        path: 'login-external-customer',
        loadChildren: () =>
          import('../authentication/pages/login-external-customer/login-external-customer.module').then(
            m => m.LoginExternalCustomerPageModule
          ),
        canActivate: [ExternalAuthGuard],
      },
      {
        path: 'reset-password/login-check',
        loadChildren: () => import('./pages/login-check/login-check.module').then(m => m.LoginCheckPageModule),
        canActivate: [AuthLoginGuard],
      },
      {
        path: 'reset-password/code-validation',
        loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule),
        canActivate: [AuthLoginGuard],
      },
      {
        path: 'sign-up',
        loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule),
        canActivate: [AuthLoginGuard],
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
