import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { AddCustomerTypes } from '../../models/customer.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';

@Component({
  selector: 'gfl-customer-add-or-pair-select',
  templateUrl: './customer-add-or-pair-select.component.html',
  styleUrls: ['./customer-add-or-pair-select.component.scss'],
})
export class CustomerAddOrPairSelectComponent implements OnChanges {
  @Input() customerTypeId: number;
  @Input() acls: Acls;

  @Output() typeChoice: EventEmitter<AddCustomerTypes> = new EventEmitter<AddCustomerTypes>();

  public title: string;
  public addLink: string;
  public pairLink: string;

  public addCustomerTypes = AddCustomerTypes;

  readonly CUSTOMER_TYPE_ID_PRIVATE: number;
  readonly CUSTOMER_TYPE_ID_CORPORATE: number;
  readonly CUSTOMER_TYPE_ID_EMPLOYEE: number;

  /**
   * @ignore
   */
  constructor(public tools: ToolsService, private constantSrv: ConstantService) {
    this.CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
    this.CUSTOMER_TYPE_ID_CORPORATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_CORPORATE');
    this.CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerTypeId) {
      switch (this.customerTypeId) {
        case this.CUSTOMER_TYPE_ID_CORPORATE:
          this.title = 'PROFILE.ADD_PRO.TITLE';
          this.addLink = 'PROFILE.ADD_PRO.ADD_NEW';
          this.pairLink = 'PROFILE.ADD_PRO.ADD_EXISTING';
          break;
        case this.CUSTOMER_TYPE_ID_EMPLOYEE:
          this.title = 'PROFILE.ADD_EMPLOYEE.TITLE';
          this.addLink = 'PROFILE.ADD_EMPLOYEE.ADD_NEW';
          this.pairLink = 'PROFILE.ADD_EMPLOYEE.ADD_EXISTING';
          break;
        default:
          this.title = 'PROFILE.ADD_PRIVATE.TITLE';
          this.addLink = 'PROFILE.ADD_PRIVATE.ADD_NEW';
          this.pairLink = 'PROFILE.ADD_PRIVATE.ADD_EXISTING';
          break;
      }
    }
  }

  /**
   * Emit choice type
   *
   * @param type new or existing customer
   */
  public choice(type: AddCustomerTypes): void {
    this.typeChoice.emit(type);
  }
}
