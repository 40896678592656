import { createReducer, on } from '@ngrx/store';

import { Acls } from '../gfl-core/gfl-models/acls.model';
import { PermissionsActions } from './action.types';

export interface PermissionsState {
  acls: { [id: number]: Acls };
}

export const initialPermissionsState: PermissionsState = {
  acls: {},
};

export const permissionsReducer = createReducer(
  initialPermissionsState,

  on(PermissionsActions.initialize, (state, action) => ({ ...action.permissionsState })),

  on(PermissionsActions.setAcls, (state, action) => {
    const obj = { acls: {} };

    if (state.acls && state.acls[0]) {
      obj.acls = {
        0: state.acls[0],
        ...action.acls,
      };
    } else {
      obj.acls = action.acls;
    }

    return obj;
  })
);
