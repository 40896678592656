/**
 * In case of observable error, this operator will retry after a delay of value "delayTime"
 * for "retry" value times. If the observable is still in error after this process,
 * the callback function will be executed.
 *
 * @param scalingDuration delay in ms before retry sequence
 * @param maxRetryAttempts number of attempts
 * @param maxPending max duration before throwing a timeout error
 * @param callback function executed in case of error returning an observable
 */
import { Observable, throwError, timer } from 'rxjs';
import { catchError, finalize, mergeMap, retryWhen } from 'rxjs/operators';

const genericRetryStrategy =
  ({
    maxRetryAttempts,
    scalingDuration,
    excludedStatusCodes = [],
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {}) =>
  (attempts: Observable<any>) =>
    attempts.pipe(
      mergeMap((error, i) => {
        if (error.status >= 500) {
          const retryAttempt = i + 1;
          // if maximum number of retries have been met
          // or response is a status code we don't wish to retry, throw error
          if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find((e) => e === error.status)) {
            return throwError(error);
          }
          console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`);
          // retry after 1s, 2s, etc...
          return timer(scalingDuration);
        } else {
          return throwError(error);
        }
      }),
      finalize(() => {})
    );

export const gflErrorManager =
  (scalingDuration: number = 500, maxRetryAttempts: number = 2, callback, maxPending?: number) =>
  (source: Observable<any>) =>
    source.pipe(
      retryWhen(
        genericRetryStrategy({
          maxRetryAttempts,
          scalingDuration,
        })
      ),
      // timeout(maxPending || environment.REQUEST_TIME_OUT),
      catchError((err) => callback(err))
    );
