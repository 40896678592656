import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AgencyState } from './agency.reducer';

export const selectAgencyState = createFeatureSelector<AgencyState>('agency');

export const agency = createSelector(
  selectAgencyState,
  agencyState => agencyState.agency
);

export const contacts = createSelector(
  selectAgencyState,
  agencyState => agencyState.contacts
);

export const privateLogo = createSelector(
  selectAgencyState,
  agencyState => agencyState.agency.logo_private
);

export const publicLogo = createSelector(
  selectAgencyState,
  agencyState => agencyState.agency.logo_public
);

export const agencyName = createSelector(
  selectAgencyState,
  agencyState => agencyState.agency.social_reason
);

export const agencyAddress = createSelector(
  selectAgencyState,
  agencyState => agencyState.defaultAddress
);

export const privateSliders = createSelector(
  selectAgencyState,
  agencyState => agencyState.sliders.private
);

export const publicSliders = createSelector(
  selectAgencyState,
  agencyState => agencyState.sliders.public
);
