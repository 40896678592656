import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { takeUntil } from 'rxjs/operators';

import { ApiService } from '../gfl-services/api.service';

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
  constructor(private apiSrv: ApiService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(takeUntil(this.apiSrv.onCancelPendingRequests()));
  }
}
