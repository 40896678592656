import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { finalize, first, switchMap, withLatestFrom } from 'rxjs/operators';

import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { GflFormItem, GflModeDisplayType } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form.model';
import { Customer, CustomerFamilyMember } from '../../models/customer.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { GflFormGeneratorService } from '../../../gfl-libraries/gfl-form-generator/services/gfl-form-generator.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { ItemService } from '../../../gfl-core/gfl-services/item.service';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'gfl-member-health',
  templateUrl: './member-health.component.html',
  styleUrls: ['./member-health.component.scss'],
})
export class MemberHealthComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * ApiToken has to be passed to get the selected customer's data
   */
  @Input() selectedMember: Customer | CustomerFamilyMember;
  @Input() acls: Acls;
  @Input() style: FrontTheme;
  @Input() isOffline: boolean;

  public formData: Array<GflFormItem>;
  public isEditMode = false;
  public modeDisplay: GflModeDisplayType;

  public modeDisplayType = GflModeDisplayType;
  public isSubmitted = false;

  public firstName: string;
  private ITEM_TEMPLATE_KEY = 'medical';

  private subscriptions: Subscription[] = [];
  public isLoading: boolean;

  /**
   * @ignore
   */
  constructor(
    private router: Router,
    private customerSrv: CustomerService,
    private constantSrv: ConstantService,
    private itemSrv: ItemService,
    public tools: ToolsService,
    private translate: TranslateService,
    public notificationSrv: NotificationService,
    private store: StoreService,
    private gflFormSrv: GflFormGeneratorService,
    public platform: Platform
  ) {}

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.setModeDisplay();
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedMember && this.acls) {
      this.firstName = this.selectedMember.first_name;
      this.setData();
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then();
  }

  /**
   * set edit mode
   */
  public onEditMode(): void {
    this.isEditMode = true;
    this.isSubmitted = false;
  }

  /**
   * Turns isSubmitted flag to true in order make the form trigger its own submit method
   */
  public onSubmit(): void {
    this.isSubmitted = true;
  }

  /**
   * Disable edit mode
   */
  public onCancel(): void {
    this.isEditMode = false;
  }

  /**
   * Save Medical data to BO
   */
  public submit(formData: Array<GflFormItem>): void {
    this.tools.showLoader();

    this.itemSrv
      .generateSubmissionObservables(formData, this.selectedMember.id)
      .then((observables) => {
        forkJoin(observables)
          .pipe(
            switchMap(() => this.customerSrv.setCustomer({ customerIdLinked: this.selectedMember.id })),
            finalize(() => this.tools.hideLoader())
          )
          .subscribe(
            () => {
              // this.formData = formData;
              this.notificationSrv.showSuccess({ message: 'PROFILE.SAVE' });
              this.isEditMode = false;

              if (this.modeDisplay === GflModeDisplayType.Tablet) {
                return;
              } else {
                this.router.navigateByUrl('/profile').then();
              }
            },
            (err) => {
              this.tools.error('MemberHealthComponent onSubmit()', err);
              this.notificationSrv.showError({ message: 'API.ERROR_MESSAGE' });
            }
          );
      })
      .catch((err) => {
        this.tools.error('MemberHealthComponent onSubmit()', err);
        this.tools.hideLoader();
        this.notificationSrv.showError({ message: 'API.ERROR_MESSAGE' });
      });
  }

  /**
   * Set modeDisplay to mobile or tablet
   */
  private setModeDisplay(): void {
    this.modeDisplay = this.tools.setModeDisplay();
  }

  /**
   * Set SelectedCustomer and formData attributes
   */
  private setData(): void {
    this.subscriptions.push(
      this.customerSrv
        .getCustomerItemsByItemTemplateKey(this.selectedMember.id, this.ITEM_TEMPLATE_KEY)
        .pipe(
          first(),
          withLatestFrom(this.constantSrv.getForeignTableIdByName('CUSTOMER') as Observable<number>),
          switchMap(([customerItems, foreignTableId]) =>
            combineLatest([
              of(customerItems),
              this.itemSrv.getItemTemplateByItemTemplateKey(
                foreignTableId,
                true,
                this.ITEM_TEMPLATE_KEY,
                this.selectedMember.api_token
              ),
            ])
          )
        )
        .subscribe(
          ([customerItems, itemsTemplate]) => {
            this.isLoading = !itemsTemplate || !itemsTemplate.length;
            this.formData = this.gflFormSrv.setFormData(customerItems, itemsTemplate, true);
          },
          (err) => {
            this.tools.error('MemberHealthComponent ngOnInit()', err);
          }
        )
    );
  }
}
