import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { AclsService } from '../../../gfl-core/gfl-services/acls.service';
import { AuthService } from '../../services/auth.service';
import { GflModeDisplayType, GflSelectOption } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form.model';
import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';

import { AuthState } from '../../reducers';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';

@Component({
  selector: 'gfl-change-customer',
  templateUrl: './change-customer.component.html',
  styleUrls: ['./change-customer.component.scss'],
})
export class ChangeCustomerComponent implements OnInit, OnDestroy {
  @Input() logo$: Observable<string>;
  @Input() isOffline: boolean;
  public acls$: Observable<Acls>;
  public swapForm: FormGroup = new FormGroup({});
  public showSendPassword = false;
  public authData: AuthState;
  public options: Array<GflSelectOption>;
  public modeDisplay: GflModeDisplayType;
  public style$: Observable<FrontTheme>;
  private subscriptions: Subscription[] = [];

  constructor(
    private notificationSrv: NotificationService,
    public tools: ToolsService,
    private aclsSrv: AclsService,
    private authSrv: AuthService,
    public platform: Platform,
    public router: Router,
    public modalCtrl: ModalController,
    private store: StoreService
  ) {}

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.initAcls();
    this.style$ = this.store.getStyle();
    this.modeDisplay = this.tools.setModeDisplay();
    this.subscriptions.push(
      this.authSrv.getAuthData().subscribe((authData) => {
        this.options = this.setSelectOptions(authData.agencies, authData.agencyId);
        this.authData = authData;
      })
    );
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then();
  }

  public onSubmit() {
    if (this.isOffline) {
      this.notificationSrv.showOfflineNotAvailable();
      return;
    }

    this.tools.showLoader();

    // tslint:disable-next-line:no-shadowed-variable
    const { login, password, agencyId } = this.swapForm.value;

    this.authSrv
      .login(login, password, this.authData.rememberMe, agencyId, this.authData.agencies)
      .pipe(finalize(() => this.tools.hideLoader()))
      .subscribe(
        (result) => {
          this.onLoginSuccess(result.customer.api_token);
        },
        (errMsgObj) => {
          this.onLoginError(errMsgObj);
        }
      );
  }

  /**
   * Manage login success
   *
   * @param apiToken Api token provided by BO
   */
  private onLoginSuccess(apiToken) {
    // empty login form and hide loader
    this.swapForm.reset();

    if (this.platform.is('desktop')) {
      this.modalCtrl.dismiss(apiToken).then();
    }
    this.router.navigateByUrl('/home').then();
  }

  /**
   * Manage login error
   *
   * @param err string
   */
  private onLoginError(err: string) {
    this.notificationSrv.showError({ message: err as string });
  }

  private setSelectOptions(map: { [id: string]: string }, selected): Array<GflSelectOption> {
    const arr = [];

    _.forEach(map, (item, key) => {
      if (parseInt(key, 10) !== selected) {
        arr.push({
          value: parseInt(key, 10),
          text: item,
        });
      }
    });

    return arr;
  }

  /**
   * Initialize Acls
   */
  private initAcls(): void {
    this.acls$ = this.aclsSrv.getAcls();
  }
}
