import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './index';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const isLoggedIn = createSelector(
  selectAuthState,
  auth => auth.isLoggedIn
);

export const customerId = createSelector(
  selectAuthState,
  auth => auth.customerId
);
