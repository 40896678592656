import { createAction, props } from '@ngrx/store';

import { PoliciesState } from './index';
import { PolicyFO } from '../models/policy.model';
import { Mandate } from '../models/mandate.model';
import { InsuranceType } from '../models/insurance-type.model';
import { Company } from '../../gfl-core/gfl-models/company.model';

export const initialize = createAction(
  '[Store Service setNgrxStore] initialize PoliciesState',
  props<{ policiesState: PoliciesState }>()
);

export const reset = createAction('[Store Service logout()] reset PoliciesState');

export const setPoliciesAndMandates = createAction(
  '[Policy service setPoliciesAndMandates()] load policies and mandates list',
  props<{ policiesList: { [id: number]: { [lang: string]: Array<PolicyFO | Mandate> } } }>()
);

export const setPolicy = createAction(
  '[Policy service getPolicyById()] load a policy from BO',
  props<{ policy: PolicyFO; lang: string }>()
);

export const setInsuranceTypes = createAction(
  '[InsuranceType service setInsuranceTypes()] load insurance types from BO',
  props<{
    insuranceTypes: {
      private: { [lang: string]: { [id: string]: InsuranceType } };
      corporate: { [lang: string]: { [id: string]: InsuranceType } };
    };
  }>()
);

export const resetInsuranceTypes = createAction(
  '[InsuranceType service setInsuranceTypes()] reset all insurance types stored'
);

export const setCompanies = createAction(
  '[Company Service setCompanies()] load companies from BO',
  props<{ companies: { [id: string]: Company } }>()
);

export const setCompaniesByCustomerTypeByInsuranceType = createAction(
  '[Company Service setCompanies()] load companies id by customerType and by insuranceType from BO',
  props<{ companiesByCustomerTypeByInsuranceType: { [id: string]: { [id: string]: number[] } } }>()
);
