export interface ChatItem {
  id: number;
  message: string;
  authorType: number;
  authorName: string;
  createdAt: string;
  done: boolean;
  commentable: {
    type: any;
    id: number;
    deepLink?: {
      label: string;
      path: string;
    };
  };
}

export interface ChatItems {
  new: number;
  items: ChatItem[];
}

export interface ChatItemsMap {
  [customerId: number]: ChatItems;
}

export enum NotificationType {
  CustomerLink,
  Policy,
  PolicyDocument,
  Compare,
  Chat,
  Invoice,
  InvoiceDocument,
}

export interface NotificationItem {
  id?: string;
  type: NotificationType;
  icon?: string;
  sprite?: string;
  text: string;
  mobileLink?: string;
  tabletLink?: string;
  customerId?: number;
  customerName?: string;
  done: boolean;
}
