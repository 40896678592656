import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerRoutingModule } from './customer-routing.module';
import { StoreModule } from '@ngrx/store';
import * as fromCustomer from './reducers';
import { ProfilePageModule } from './pages/profile/profile.module';

@NgModule({
  imports: [
    CommonModule,
    CustomerRoutingModule,
    ProfilePageModule,
    StoreModule.forFeature(fromCustomer.customerFeatureKey, fromCustomer.customerReducer),
  ],
})
export class CustomerModule {}
