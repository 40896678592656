import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  /**
   * @ignore
   */
  constructor(private store: StoreService) {}

  /**
   * Update activated page stream
   *
   * @param page page name
   */
  public set(page: string): void {
    if (page) {
      this.store.setActivatedPage(page);
    }
  }

  /**
   * Return an observable of the activated page's name
   */
  public getActivatedPage(): Observable<string> {
    return this.store.getActivatedPage();
  }
}
