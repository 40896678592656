import { ApplicationRef, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Capacitor } from '@capacitor/core';
import { AlertController, MenuController, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { ToolsService } from './gfl-core/gfl-services/tools.service';
import { StoreService } from './gfl-core/gfl-services/store.service';
import { ConstantService } from './gfl-core/gfl-services/constant.service';
import { StatusService } from './gfl-core/gfl-services/status.service';
import { AgencyService } from './gfl-core/gfl-services/agency.service';
import { CustomerService } from './customer/services/customer.service';
import { SpriteService } from './gfl-core/gfl-services/sprite.service';
import { SecurityService } from './gfl-core/gfl-services/security.service';
import { NotificationService } from './gfl-core/gfl-services/notification.service';
import { NavigationService } from './gfl-core/gfl-services/navigation.service';
import { GoogleAnalyticsEventsService } from './gfl-core/gfl-services/google-analytics-events.service';
import { ApiService } from './gfl-core/gfl-services/api.service';
import { AclsService } from './gfl-core/gfl-services/acls.service';
import { SafeboxService } from './safebox/services/safebox.service';
import { ThemeService } from './gfl-core/gfl-services/theme.service';
import { NetworkMonitorService } from './gfl-core/gfl-services/network-monitor.service';
import { AuthService } from './authentication/services/auth.service';
import { ItemService } from './gfl-core/gfl-services/item.service';
import { InsuranceService } from './policies/services/insurance.service';
import { CompanyService } from './gfl-core/gfl-services/company.service';
import { SliderService } from './gfl-core/gfl-services/slider.service';
import { LanguageService } from './gfl-core/gfl-services/language.service';
import { Acls } from './gfl-core/gfl-models/acls.model';
import { Agency, FrontTheme } from './gfl-core/gfl-models/agency.model';
import { LinkRelationItem } from './gfl-core/gfl-models/api.model';

import { environment } from '../environments/environment';
import { AuthState } from './authentication/reducers';
import { NotificationsDisplayComponent } from './contacts/components/notifications-display/notifications-display.component';
import { ContactComponent } from './contacts/components/contact/contact.component';
import { ChatDisplayComponent } from './contacts/components/chat-display/chat-display.component';

import { SplashScreen } from '@capacitor/splash-screen';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'gfl-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public rootPagePath: string;
  public activePage: string;
  public lang$: Observable<string>;
  public appName: string;
  public version: string;
  public acls: Acls;
  public style$: Observable<FrontTheme>;
  public agency: Agency;
  public isGoForLifeBroker: boolean;
  public isPro$: Observable<boolean>;
  public multipleCustomers$: Observable<boolean>;
  public isOffline: boolean;
  public isLoggedIn: boolean;
  public isRefreshing: boolean;
  public displayNomadLoader: boolean;

  /**
   * @ignore
   */
  constructor(
    private platform: Platform,
    public tools: ToolsService,
    public store: StoreService,
    public menuCtrl: MenuController,
    private constantSrv: ConstantService,
    private statusSrv: StatusService,
    private agencySrv: AgencyService,
    private customerSrv: CustomerService,
    private spriteSrv: SpriteService,
    private securitySrv: SecurityService,
    public notificationSrv: NotificationService,
    private gaSrv: GoogleAnalyticsEventsService,
    private aclsSrv: AclsService,
    private translate: TranslateService,
    private langSrv: LanguageService,
    private navigationSrv: NavigationService,
    private alertCtrl: AlertController,
    private screenOrientation: ScreenOrientation,
    private mobileAccessibility: MobileAccessibility,
    private apiSrv: ApiService,
    private themeSrv: ThemeService,
    private networkSrv: NetworkMonitorService,
    private authSrv: AuthService,
    private itemSrv: ItemService,
    private insuranceSrv: InsuranceService,
    private companySrv: CompanyService,
    public safeboxSrv: SafeboxService,
    private sliderSrv: SliderService,
    private location: Location,
    private modalCtrl: ModalController,
    private appRef: ApplicationRef,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    this.version = environment.APP_VERSION;
    this.appName = environment.APP_NAME;
    this.store.setIsRefreshingData(false);
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.store.setSelectedMember({});
    this.initAcls();
    this.lang$ = this.langSrv.getLang();
    this.tools.manageRefreshingLoader();

    this.store.setIsSignupProcess(false);
    this.store.setDisplayNomadLoader(false);
    this.store.getDisplayNomadLoader().subscribe((res) => {
      this.displayNomadLoader = res;
      this.cd.detectChanges();
    });

    this.store.getIsRefreshingData().subscribe((flag) => {
      this.isRefreshing = flag;
      this.cd.detectChanges();
    });

    const obsMandatory$ = [
      this.sliderSrv.initService().pipe(catchError(() => of(null))),
      this.itemSrv.loadItemTemplates().pipe(catchError(() => of(null))),
    ];

    const obsOptional$ = [
      this.safeboxSrv.loadSafeboxTypes().pipe(catchError(() => of(null))),
      this.insuranceSrv.loadInsuranceTypes().pipe(catchError(() => of(null))),

      this.companySrv.loadCompanies().pipe(catchError(() => of(null))),
    ];

    this.authSrv
      .isLoggedIn()
      .pipe(
        switchMap((isLoggedIn) => {
          this.isLoggedIn = isLoggedIn;

          if (isLoggedIn) {
            return forkJoin([...obsMandatory$, ...obsOptional$]);
          } else {
            return forkJoin(obsMandatory$);
          }
        })
      )
      .subscribe(() => this.checkAppVersion());

    // manage new customer links received in order to display validation link form
    this.store
      .getCustomerCompletedFlag()
      .pipe(
        filter((val) => !!val),
        mergeMap(() => this.store.getAuthData().pipe(first())),
        mergeMap((authState) => {
          // set root page
          this.rootPagePath = this.tools.setRootPage(authState.customerId);

          return this.customerSrv.getCustomerLinkReceived(authState.customerId).pipe(first());
        }),
        tap((linkRelationItems) => {
          if (linkRelationItems && linkRelationItems.length) {
            this.openValidateCustomerLinkModal(linkRelationItems);
          }
        })
      )
      .subscribe();

    this.isPro$ = this.store.getIsProSelected();
    this.multipleCustomers$ = this.store
      .getAuthData()
      .pipe(map((authData) => authData.agencies && !_.isEmpty(authData.agencies)));
    this.networkSrv.isOffline().subscribe((flag) => {
      this.isOffline = flag;
    });

    this.appendGaTrackingCode();

    this.initActivatedPageBehaviour();
    // initialize app
    this.initializeApp().then(() => {
      this.store.setIsAppStateReady(true);
    });
  }

  /**
   * Navigate to notifications page or open modal
   */
  public async displayNotifications(): Promise<void> {
    if (this.tools.isMobile()) {
      this.router.navigateByUrl('/notifications').then();
    } else {
      const modal = await this.modalCtrl.create({
        component: NotificationsDisplayComponent,
        componentProps: {
          acls: this.acls,
        },
      });
      await modal.present();
    }
  }

  /**
   * Navigate to contact page or open modal
   */
  public async displayContact(): Promise<void> {
    if (this.tools.isMobile()) {
      this.router.navigateByUrl('/contact').then();
    } else {
      const modal = await this.modalCtrl.create({
        component: ContactComponent,
        componentProps: {
          acls: this.acls,
          isOffline: this.isOffline,
        },
      });
      await modal.present();
    }
  }

  /**
   * Navigate to chat page or open modal
   */
  public async displayChat(): Promise<void> {
    if (this.tools.isMobile()) {
      this.router.navigateByUrl('/contact').then();
    } else {
      const modal = await this.modalCtrl.create({
        component: ChatDisplayComponent,
        componentProps: {
          acls: this.acls,
          isOffline: this.isOffline,
        },
      });
      await modal.present();
    }
  }

  /**
   * Navigate to the page passed in parameter
   *
   * @param path path to navigate to
   */
  public openPage(path: string) {
    this.router.navigateByUrl(path).catch((err) => {
      this.tools.error('openPage Error ' + path, err);
    });
  }

  /**
   * Method called at init to ask for the code pin in order to enter the page
   *
   * @param successPath Path to navigate to
   */
  public showCodePin(successPath: string): void {
    this.securitySrv.showCodePin(successPath);
  }

  /**
   * Disconnect customer
   */
  public onLogout(): void {
    this.authSrv.logout();
  }

  /**
   * Launched every time route changes
   *
   * @param item this is an ionic page object
   */
  public onActivate(item: object) {
    this.gaSrv.trackView(item.constructor.name);
  }

  /**
   * Email to the director's agency about wishes
   */
  public sendSuggestions(): void {
    this.agencySrv.sendSuggestions().subscribe();
  }

  /**
   * Add Google Analytics script tag.
   */
  private appendGaTrackingCode(): void {
    try {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
      `;
      document.head.appendChild(script);

      this.gaSrv.startTrackerWithId();
    } catch (ex) {
      this.tools.error('Error appending google analytics', ex);
    }
  }

  /**
   * Initialize cordova and Network features
   */
  private async checkPlatform(): Promise<boolean> {
    if (this.tools.isNative()) {
      // Hide keyboard and disable keyboard scroll
      try {
        await Keyboard.setScroll({ isDisabled: false });
      } catch (err) {
        this.tools.error('Keyboard.setScroll', err);
      }

      // set to portrait for mobile
      if (this.tools.isMobile()) {
        try {
          await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        } catch (err) {
          this.tools.error('screenOrientation', err);
        }
      }

      // set textZoom to 100 percents
      this.mobileAccessibility.setTextZoom(100);
    }

    this.networkSrv.initialize();

    return true;
  }

  /**
   * Open modal to accept customer link
   */
  private openValidateCustomerLinkModal(linkRelationItems: LinkRelationItem[]) {
    this.translate
      .get([
        'PROFILE.CUSTOMER_LINK_VALIDATE.TITLE',
        'PROFILE.CUSTOMER_LINK_VALIDATE.MESSAGE',
        'COMMON.BUTTON_CANCEL',
        'COMMON.BUTTON_VALIDATE',
      ])
      .subscribe((result) => {
        const inputs = [];
        const location = this.location.path().split('?')[0];

        if (location === '/authentication/login-user-as-customer') {
          return false;
        }

        _.forEach(linkRelationItems, (item) => {
          inputs.push({
            type: 'checkbox',
            label: item.customer_id_linked_name,
            value: item.customer_id.toString(),
            checked: false,
          });
        });

        this.alertCtrl
          .create({
            header: result['PROFILE.CUSTOMER_LINK_VALIDATE.TITLE'],
            message: result['PROFILE.CUSTOMER_LINK_VALIDATE.MESSAGE'],
            buttons: [
              {
                text: result['COMMON.BUTTON_VALIDATE'],
                cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
                handler: (authorizedIdArr) => {
                  // @ts-ignore
                  const authorizedCustomerLinks: LinkRelationItem[] = _.filter(linkRelationItems, (obj) =>
                    authorizedIdArr.includes(obj.customer_id.toString())
                  );
                  if (authorizedCustomerLinks && authorizedCustomerLinks.length) {
                    this.validateCustomerLinks(authorizedCustomerLinks);
                  }
                },
              },
              {
                text: result['COMMON.BUTTON_CANCEL'],
                cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
                role: 'cancel',
              },
            ],
            inputs,
          })
          .then((alert: HTMLIonAlertElement) => {
            alert.present().catch((err) => this.tools.error('openValidateCustomerLinkModal', err));
          });
      });
  }

  /**
   * Call BO to validate customer links
   *
   * @param authorizedCustomerLinks customer links to validate
   */
  private validateCustomerLinks(authorizedCustomerLinks: LinkRelationItem[]): void {
    const observables = [];

    this.store
      .getAuthData()
      .pipe(
        first(),
        mergeMap(() => {
          _.forEach(authorizedCustomerLinks, (link) => {
            observables.push(this.customerSrv.registerCustomerLink(link.customer_link_type_id, link.customer_id));
          });

          return forkJoin(observables);
        }),
        mergeMap(() => this.store.getAuthData().pipe(first())),
        mergeMap((authData: AuthState) => {
          this.authSrv.logout();
          this.store.login({
            authData: {
              isLoggedIn: true,
              customerToken: authData.customerToken,
              customerLogin: authData.customerLogin,
              customerId: authData.customerId,
              customerTypeId: authData.customerTypeId,
              agencyId: authData.agencyId,
              agencies: authData.agencies,
              rememberMe: true,
              customerRefreshedTimeStamp: Date.now(),
              language: authData.language,
            },
            reloadAll: false,
            reset: false,
          });

          return of(true);
        })
      )
      .subscribe(
        () => {
          this.notificationSrv.showSuccess({
            message: 'PROFILE.CUSTOMER_LINK_VALIDATE.AUTHORIZED_CUSTOMER_LINKS',
          });
          this.router.navigateByUrl(this.rootPagePath).catch((err) => this.tools.error('navigate root', err));
        },
        (err) => {
          this.tools.error('validateCustomerLinks', err);
          this.notificationSrv.showError({
            message: 'API.ERROR_MESSAGE',
          });
        }
      );
  }

  /**
   * Managed the activated link in the sidebar
   */
  private initActivatedPageBehaviour(): void {
    this.navigationSrv
      .getActivatedPage()
      .pipe(
        tap(() => {
          // this is needed in order to refresh style attribute after logged in
          if (!this.style$) {
            this.style$ = this.store.getStyle();
          }
          // if (!this.agency) {
          //   this.agency = this.agencySrv.getAgency();
          // }
        })
      )
      .subscribe((activePage) => {
        this.activePage = activePage;
      });
  }

  /**
   * Initialize Application
   */
  private async initializeApp(): Promise<any> {
    try {
      await this.platform.ready();

      if (Capacitor.isPluginAvailable('SplashScreen')) {
        try {
          await SplashScreen.hide();
        } catch (err) {
          this.tools.error('SplashScreen.hide', err);
        }
      }

      return await this.checkPlatform();
    } catch (err) {
      this.tools.error('initializeApp()', err);
      throw err;
    }
  }

  /**
   * Check if app has been updated and so logout customer and reload static data
   */
  private checkAppVersion() {
    const newAppVersion = environment.APP_VERSION;
    let currentAppVersion;

    if (newAppVersion) {
      this.store
        .get(`${this.appName}_app-version`)
        .pipe(
          first(),
          mergeMap((appVersion) => {
            currentAppVersion = appVersion;
            return this.store.set(`${this.appName}_app-version`, newAppVersion);
          }),
          mergeMap(() =>
            of(currentAppVersion && currentAppVersion !== newAppVersion && !this.isOffline && this.authSrv.logout())
          ),
          tap((result) => {
            if (result) {
              this.store.reloadStaticData();
            }
          })
        )
        .subscribe();
    }
  }

  /**
   * Initialize permissions for sidebar
   */
  private initAcls(): void {
    this.aclsSrv.getAcls().subscribe((p: Acls) => (this.acls = p));
  }
}
