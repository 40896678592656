import { CountableItem, ItemType, ItemTypeParent } from './gfl-form-component.model';

export interface GflFormItem {
  // item_template_insurance_type_id: null,
  item_template_id: number;
  item_template_key: string;
  item_template_key_translate: string;
  is_abstract?: [0, 1];
  is_required: [0, 1];
  is_required_frontend: [0, 1];
  is_visible_frontend: [0, 1];
  item_template_is_visible_frontend: [0, 1];
  is_header: [0, 1];
  is_offer: [0, 1];
  is_offer_compare: [0, 1];
  position: number;
  format?: null;
  unit?: string;
  item_id: number;
  item_type_id: number;
  item_type_name: ItemType;
  item_sub_item_parent_id?: number;
  item_sub_item_parent_key?: ItemTypeParent;
  item_sub_item_parent_label?: string;
  value: string | number;
  value_reformat: string;
  values: { [id: string]: string | number };
  subitems?: Array<GflFormSubItem>;
  countable_values?: { [id: string]: CountableItem };
}

export interface GflFormSubItem {
  // item_template_insurance_type_id: null,
  item_template_id: number;
  item_template_key: string;
  item_template_key_translate: string;
  is_abstract?: [0, 1];
  is_required: [0, 1];
  is_required_frontend: [0, 1];
  is_visible_frontend: [0, 1];
  item_template_is_visible_frontend: [0, 1];
  is_header: [0, 1];
  is_offer: [0, 1];
  is_offer_compare: [0, 1];
  position: number;
  format?: null;
  unit?: string;
  item_id: number;
  item_type_id: number;
  item_type_name: ItemType;
  item_sub_item_parent_id: number;
  item_sub_item_parent_key: ItemTypeParent;
  item_sub_item_parent_label?: string;
  value: string | number;
  value_reformat: string;
  values: { [id: string]: string | number };
  subitems?: Array<GflFormSubItem>;
  countable_values?: { [id: string]: CountableItem };
}

export enum GflModeDisplayType {
  Mobile = 'mobile',
  Tablet = 'tablet',
}

export interface GflSelectOption {
  value: string | number;
  text: string;
  selected: boolean;
  disabled?: boolean;
}
