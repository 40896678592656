import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocksState } from './locks.reducer';

export const selectLocksState = createFeatureSelector<LocksState>('locks');

export const documentCategoriesLock = createSelector(
  selectLocksState,
  locksState => locksState.documentCategoriesLock
);

export const documentsLock = createSelector(
  selectLocksState,
  locksState => locksState.documentsLock
);

export const safeboxTypesLock = createSelector(
  selectLocksState,
  locksState => locksState.safeboxTypesLock
);

export const safeboxesLock = createSelector(
  selectLocksState,
  locksState => locksState.safeboxesLock
);

export const agencyLock = createSelector(
  selectLocksState,
  locksState => locksState.agencyLock
);

export const permissionsLock = createSelector(
  selectLocksState,
  locksState => locksState.permissionsLock
);

export const slidersLock = createSelector(
  selectLocksState,
  locksState => locksState.sliderLock
);

export const companiesLock = createSelector(
  selectLocksState,
  locksState => locksState.companiesLock
);

export const insuranceTypesLock = createSelector(
  selectLocksState,
  locksState => locksState.insuranceTypesLock
);

export const policiesAndMandatesLock = createSelector(
  selectLocksState,
  locksState => locksState.policiesLock
);

export const customerLock = createSelector(
  selectLocksState,
  locksState => locksState.customerLock
);

export const chatItemsLock = createSelector(
  selectLocksState,
  locksState => locksState.chatItemsLock
);

export const comparesLock = createSelector(
  selectLocksState,
  locksState => locksState.comparesLock
);

export const constantsLock = createSelector(
  selectLocksState,
  locksState => locksState.constantsLock
);

export const foreignTablesLock = createSelector(
  selectLocksState,
  locksState => locksState.foreignTablesLock
);

export const spriteLock = createSelector(
  selectLocksState,
  locksState => locksState.spriteLock
);

export const statusesLock = createSelector(
  selectLocksState,
  locksState => locksState.statusesLock
);

export const rolesLock = createSelector(
  selectLocksState,
  locksState => locksState.rolesLock
);

export const itemTemplatesLock = createSelector(
  selectLocksState,
  locksState => locksState.itemTemplatesLock
);

export const translationsLock = createSelector(
  selectLocksState,
  locksState => locksState.translationsLock
);
