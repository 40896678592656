import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { ToolsService } from '../../../../gfl-core/gfl-services/tools.service';
import { CountableItem } from '../../models/gfl-form-component.model';
import { GflThemeOptions } from '../../models/gfl-form-options.model';
import { GflModeDisplayType, GflSelectOption } from '../../models/gfl-form.model';
import { FrontTheme } from '../../../../gfl-core/gfl-models/agency.model';
import { environment } from '../../../../../environments/environment';

interface PhoneItem {
  key: string;
  value: string;
  value_reformat: string;
  prefix: string;
  phone: string;
  deleted: boolean;
}

@Component({
  selector: 'gfl-field-phone-multi',
  templateUrl: './gfl-field-phone-multi.component.html',
  styleUrls: ['./gfl-field-phone-multi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GflFieldPhoneMultiComponent implements OnChanges {
  /**
   * initial values of countable_values attribute
   */
  @Input() countableItems: { [id: string]: CountableItem } | null;

  /**
   * hide value and formatted value in order to display only label
   */
  @Input() hideValue: boolean;

  /**
   * the form implementing this field in two-way binding
   */
  @Input() form: FormGroup;
  @Output() formChange: EventEmitter<FormGroup>;

  /**
   * An array of validators to add to the formControl
   */
  @Input() validators: Array<ValidatorFn> = [];

  /**
   * Set type of displayed template between "mobile" and "tablet" options
   */
  @Input() modeDisplay: GflModeDisplayType;

  /**
   * Front theme style
   */
  @Input() style: FrontTheme;

  /**
   * Text used in ion-label component
   */
  @Input() label: string;

  /**
   * Text used in ion-label component for phone part
   */
  @Input() placeholderPhone: string;

  /**
   * Text used in ion-label component for phone prefix part
   */
  @Input() placeholderPrefix: string;

  /**
   * If isEditMode is false then the field value are read-only
   */
  @Input() isEditMode: boolean;

  /**
   * If isLabel is true then we only display label :)
   */
  @Input() isLabel: boolean;

  /**
   * name attribute for form control
   */
  @Input() name: string;

  /**
   * Color theme to apply to the component
   */
  @Input() theme: GflThemeOptions;

  /**
   * if true then gfl-validation section is not displayed
   * this is used to display custom error message
   */
  @Input() noDisplayErrors: boolean;

  /**
   * readonly flag for HTML attribute field
   */
  @Input() readonly: boolean;

  /**
   * field required flag
   */
  @Input() required: boolean;

  /**
   * true if form parent has een submitted
   */
  @Input() submitted: boolean;

  @ViewChild('select', { static: false }) select: ElementRef;

  public modeDisplays = GflModeDisplayType;
  public phonesFormArray: FormArray;
  public selectOptionsArr: Array<GflSelectOption>;
  public items: Array<object>;

  /**
   * @ignore
   */
  constructor(
    public translate: TranslateService,
    public tools: ToolsService,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef
  ) {
    this.theme = this.theme || {};
    this.formChange = new EventEmitter<FormGroup>();
    this.selectOptionsArr = environment.PREFIX_COUNTRY;
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && changes.form.currentValue !== changes.form.previousValue) {
      this.validators = this.validators || [];
      this.phonesFormArray = new FormArray([], { updateOn: 'blur' });
      // update form locally
      this.form.registerControl(this.name, this.phonesFormArray);
      // update form globally
      this.formChange.emit(this.form);

      if (this.countableItems) {
        // we add all countable items if any
        _.forEach(this.countableItems, (countableItem: CountableItem, key) => {
          const { prefix, phone } = this.separatePrefixAndPhone(countableItem.value);

          this.addItem({
            key,
            value: countableItem.value,
            value_reformat: countableItem.value_reformat,
            prefix,
            phone,
            deleted: false,
          });
        });
      } else {
        // We add an empty object to display an empty phone component
        // allowing us to add a new phone value
        this.addItem();
      }

      // launch angular change detection
      this.ref.markForCheck();
    }

    if (changes.disabled) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      changes.disabled.currentValue === true ? this.phonesFormArray.disable() : this.phonesFormArray.enable();
    }

    if (changes.submitted && changes.submitted.currentValue !== changes.submitted.previousValue) {
      if (this.required) {
        this.phonesFormArray.markAsTouched();
        this.phonesFormArray.markAsDirty();
      }
    }
  }

  /**
   * Return true if the item corresponding to the index param
   * is the first item of phoneFormArray with deleted attribute at false
   *
   * @param index index
   */
  public isFirstVisible(index): boolean {
    const idx = _.findIndex(this.phonesFormArray.controls, (item) => item.value.deleted === false);

    return idx === index;
  }

  /**
   * Update Value attribute
   *
   * @param index index
   * TODO check in template for ion-select event
   */
  public updateFormGroup(index: number): void {
    // get the current item
    // @ts-ignore
    const itemFormGroup: FormGroup = this.phonesFormArray.controls[index];
    // generate and set value attribute by aggregating prefix and phone
    const prefixAndPhone = this.aggregatePrefixAndPhone(itemFormGroup.value.prefix, itemFormGroup.value.phone);
    itemFormGroup.controls.value.setValue(prefixAndPhone);
  }

  /**
   * Return true if emailsFormArray contains only one visible element
   */
  private isOneItemVisible(): boolean {
    const arr = _.filter(this.phonesFormArray.controls, (item) => item.value.deleted === false);

    return arr.length === 1;
  }

  /**
   * Split string param in prefix and phone and return them in an object
   *
   * @param value a string with prefix and phone separated by "--"
   */
  private separatePrefixAndPhone(value: string): { prefix: string; phone: string } {
    const valueArr = value ? value.split('--') : [null, null];

    return {
      prefix: valueArr[0],
      phone: valueArr[1],
    };
  }

  /**
   * Return a well-formatted value of phone number according to BO
   *
   * @param prefix phone prefix
   * @param phone phone number without prefix
   */
  private aggregatePrefixAndPhone(prefix: string, phone: string): string {
    return prefix + '--' + phone;
  }

  /**
   * Return a phone form group
   */
  private generateItem(initValues?: PhoneItem): FormGroup {
    const key = initValues && initValues.key;
    const value = initValues && initValues.value;
    const valueReformat = initValues && initValues.value_reformat;
    const prefix = initValues && initValues.prefix;
    const phone = initValues && initValues.phone;
    const deleted = initValues && initValues.deleted;

    const phoneValidators = [...this.validators, Validators.pattern('0?[0-9]{9,10}')];
    const prefixValidators = [];

    if (this.required) {
      prefixValidators.push(Validators.required);
      phoneValidators.push(Validators.required);
    }

    return this.fb.group({
      key,
      value,
      value_reformat: valueReformat,
      prefix: [prefix, prefixValidators],
      phone: [phone, phoneValidators],
      deleted: !!deleted,
    });
  }

  /**
   * Add a phone item to phonesFormArray and update the form
   *
   * @param initValues phone number
   */
  public addItem(initValues?: PhoneItem): void {
    this.phonesFormArray.push(this.generateItem(initValues));

    this.updateForm();
  }

  /**
   * Remove a phone item in phonesFormArray at index position
   *
   * @param index index
   */
  public removeItem(index: number): void {
    // get the current item
    // @ts-ignore
    const itemFormGroup: FormGroup = this.phonesFormArray.controls[index];

    if (this.isOneItemVisible()) {
      // add a new item if first
      this.addItem();
    }

    if (itemFormGroup.value.key) {
      // this is an existing countable item template object
      // ,so we only check it as deleted
      itemFormGroup.controls.deleted.setValue(true);
    } else {
      // or remove item
      this.phonesFormArray.removeAt(index);
    }

    this.form.markAsDirty();

    this.updateForm();
  }

  /**
   * Update form and emit event in order to update the global form
   */
  private updateForm(): void {
    this.form.setControl(this.name, this.phonesFormArray);
    this.formChange.emit(this.form);
  }
}
