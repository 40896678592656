import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, first, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { StoreService } from '../../gfl-core/gfl-services/store.service';
import { ToolsService } from '../../gfl-core/gfl-services/tools.service';
import { CustomerService } from '../../customer/services/customer.service';
import { ConstantService } from '../../gfl-core/gfl-services/constant.service';
import { WsService } from '../../gfl-core/gfl-services/ws.service';
import { DocumentService } from '../../gfl-core/gfl-services/document.service';
import { InsuranceType } from '../models/insurance-type.model';
import { LanguageService } from '../../gfl-core/gfl-services/language.service';
import { DataMonitorService } from '../../gfl-core/gfl-services/data-monitor.service';

import { environment } from '../../../environments/environment';

export enum InsuranceTypesCategories {
  private = 1,
  corporate = 2,
}

@Injectable({
  providedIn: 'root',
})
export class InsuranceService {
  private TYPES = {
    SICKNESS: [8, 37, 38, 39],
    LIFE: [9, 27, 30, 36, 40, 41, 42],
  };

  /**
   * @ignore
   */
  constructor(
    private wsSrv: WsService,
    public store: StoreService,
    private tools: ToolsService,
    private customerSrv: CustomerService,
    private constantSrv: ConstantService,
    private documentSrv: DocumentService,
    private langSrv: LanguageService,
    private dataMonitorSrv: DataMonitorService
  ) {
    const lang$ = this.store.getLang().pipe(filter((val) => !!val));

    const locksToMonitor = [
      {
        name: 'insuranceTypes',
        lock: () => this.store.getInsuranceTypesLock(),
        cb: () => lang$.pipe(mergeMap((lang) => this.setInsuranceTypes(lang))),
      },
    ];

    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * Check if insurance types list need to be fetched from BO
   */
  public loadInsuranceTypes(): Observable<any> {
    return forkJoin([this.store.getInsuranceTypes().pipe(first()), this.langSrv.getLang().pipe(first())]).pipe(
      mergeMap(([insuranceTypesResult, lang]) => {
        if (_.isEmpty(insuranceTypesResult.private) || _.isEmpty(insuranceTypesResult.private[lang])) {
          return this.setInsuranceTypes(lang);
        } else {
          return of(true);
        }
      }),
      first()
    );
  }

  /**
   * store nested insuranceTypes objects
   *
   * @param lang selected language
   * @param reset if true empty data first
   */
  public setInsuranceTypes(lang: string, reset?: boolean): Observable<any> {
    const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
    const CUSTOMER_TYPE_ID_CORPORATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_CORPORATE');
    const requestsObs$ = [
      this.wsSrv.requestInsuranceTypes(CUSTOMER_TYPE_ID_PRIVATE),
      this.wsSrv.requestInsuranceTypes(CUSTOMER_TYPE_ID_CORPORATE),
      this.store.getInsuranceTypes().pipe(first()),
    ];
    let insuranceTypesObj;
    let insuranceTypesStored = {
      private: {},
      corporate: {},
    };

    return this.store.setInsuranceTypesLock(Date.now()).pipe(
      mergeMap(() => forkJoin(requestsObs$)),
      mergeMap(
        ([insuranceTypesPrivate, insuranceTypesCorporate, insuranceTypesFromStore]: [
          { [id: string]: InsuranceType },
          { [id: string]: InsuranceType },
          {
            private: { [lang: string]: { [id: string]: InsuranceType } };
            corporate: { [lang: string]: { [id: string]: InsuranceType } };
          }
        ]) => {
          insuranceTypesStored = _.cloneDeep(insuranceTypesFromStore);
          return of({
            private: insuranceTypesPrivate,
            corporate: insuranceTypesCorporate,
          });
        }
      ),
      mergeMap((res) => {
        insuranceTypesObj = res;
        const obs$: Observable<string>[] = [];

        _.forEach(insuranceTypesObj, (insuranceTypesCat, category) => {
          _.forEach(insuranceTypesCat, (item, idx) => {
            if (!item.is_visible_fronted || !item.is_visible_frontend) {
              delete insuranceTypesObj[category][idx];
            } else {
              if (item.svg) {
                if (this.tools.isNative()) {
                  obs$.push(
                    this.tools
                      .fetchSVGImage(item.svg)
                      .pipe(
                        mergeMap((data) =>
                          this.documentSrv.storeSVGFile(
                            data,
                            item.name + '-' + category + '.svg',
                            environment.SVG_FOLDERS.INSURANCE_TYPES
                          )
                        )
                      )
                  );
                } else {
                  obs$.push(of(item.svg));
                }
              } else {
                obs$.push(of(item.svg));
              }

              _.forEach(item.children, (subItem) => {
                if (this.tools.isNative()) {
                  if (subItem.svg) {
                    obs$.push(
                      this.tools
                        .fetchSVGImage(subItem.svg)
                        .pipe(
                          mergeMap((data) =>
                            this.documentSrv.storeSVGFile(
                              data,
                              subItem.name + '-' + category + '.svg',
                              environment.SVG_FOLDERS.INSURANCE_TYPES
                            )
                          )
                        )
                    );
                  } else {
                    obs$.push(of(subItem.svg));
                  }
                } else {
                  obs$.push(of(subItem.svg));
                }
              });
            }
          });
        });

        if (!obs$.length) {
          obs$.push(of(null));
        }

        return forkJoin(obs$);
      }),
      mergeMap((result) => {
        _.forEach(insuranceTypesObj, (insuranceTypesCat) => {
          _.forEach(insuranceTypesCat, (insuranceType) => {
            insuranceType.svg = result.shift() as string;

            _.forEach(insuranceType.children, (child) => {
              child.svg = result.shift() as string;
            });
          });
        });

        insuranceTypesStored.private[lang] = insuranceTypesObj.private;
        insuranceTypesStored.corporate[lang] = insuranceTypesObj.corporate;

        this.store.setInsuranceTypes(insuranceTypesStored);

        return forkJoin([of(insuranceTypesObj), this.store.setInsuranceTypesLock(null)]);
      }),
      catchError((err) => {
        this.tools.error('setInsuranceTypes', err);
        throw err;
      })
    );
  }

  /**
   * Return an observable of all insuranceTypes
   *
   * @param category insurance type category
   * @param lang selected language
   */
  public getInsuranceTypes(
    category: InsuranceTypesCategories,
    lang?: string
  ): Observable<{ [id: string]: InsuranceType }> {
    let obs$;

    switch (category) {
      case InsuranceTypesCategories.private:
        if (lang) {
          obs$ = this.store.getInsuranceTypesPrivate(lang);
        } else {
          obs$ = this.langSrv.getLang().pipe(mergeMap((langRes) => this.store.getInsuranceTypesPrivate(langRes)));
        }
        break;
      case InsuranceTypesCategories.corporate:
        if (lang) {
          obs$ = this.store.getInsuranceTypesCorporate(lang);
        } else {
          obs$ = this.langSrv.getLang().pipe(mergeMap((langRes) => this.store.getInsuranceTypesCorporate(langRes)));
        }
        break;
    }

    return obs$.pipe(
      filter((val) => !_.isEmpty(val) || (_.isObject(val) && Object.keys(val).length === 0)),
      first(),
      withLatestFrom(this.store.getInsuranceTypeIdByCustomerType(category)),
      switchMap(([insuranceTypes, ids]) => {
        const result = {};
        _.forEach(ids, (id) => {
          if (insuranceTypes[id]) {
            result[id] = insuranceTypes[id];
          }
        });

        return of(result);
      })
    );
  }

  /**
   * Return an observable of all insuranceTypes objects
   *
   * @param category insurance type category
   */
  public getFlattenInsuranceTypes(category: InsuranceTypesCategories): Observable<{ [id: string]: InsuranceType }> {
    return this.getInsuranceTypes(category).pipe(
      first(),
      mergeMap((insuranceTypesRes) => {
        // get children if any
        _.map(insuranceTypesRes, (insuranceType) => {
          if (insuranceType.children) {
            insuranceTypesRes = { ...insuranceTypesRes, ...insuranceType.children };
          }
        });

        return of(insuranceTypesRes);
      })
    );
  }

  /**
   * Check if insurance type id is corresponding to a sickness insurance
   *
   * @param id  - insurance type id
   */
  public isSicknessType(id: number): boolean {
    return this.TYPES.SICKNESS.indexOf(id) > -1;
  }

  /**
   * Check if insurance type id is corresponding to a life insurance
   *
   * @param id  - insurance type id
   */
  public isLifeType(id: number): boolean {
    return this.TYPES.LIFE.indexOf(id) > -1;
  }
}
