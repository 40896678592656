import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { PolicyFO } from '../../models/policy.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';

@Component({
  selector: 'gfl-policy-refund',
  templateUrl: './policy-refund.component.html',
  styleUrls: ['./policy-refund.component.scss'],
})
export class PolicyRefundComponent implements OnInit {
  public refundAdd = true;
  public refundHistory = false;
  @Input() policy: PolicyFO;
  @Input() acls: Acls;
  @Input() isRefundPage: boolean;
  @Input() style: FrontTheme;

  /**
   * @ignore
   */
  constructor(public platform: Platform, public tools: ToolsService) {}

  /**
   * @ignore
   */
  ngOnInit() {
    this.displayRefundAdd();
  }

  /**
   * set flags to display refund add component
   */
  public displayRefundAdd(): void {
    this.refundAdd = true;
    this.refundHistory = false;
  }

  /**
   * set flags to display refund history component
   */
  public displayRefundHistory(): void {
    this.refundAdd = false;
    this.refundHistory = true;
  }
}
