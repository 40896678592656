import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { StoreService } from './store.service';
import { ToolsService } from './tools.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SpriteService {
  readonly appName: string;
  private sprite$: BehaviorSubject<string> = new BehaviorSubject(null);

  /**
   * @ignore
   */
  constructor(private http: HttpClient, private store: StoreService, private tools: ToolsService) {
    this.appName = environment.APP_NAME;
  }

  /**
   * Store sprite text in Store
   *
   * @param spriteUrl url of sprite file
   * TODO - move http request to ws service
   */
  public initService(spriteUrl: string): void {
    // first we fetch from localstorage
    this.store
      .get(`${this.appName}_sprite`)
      .pipe(
        mergeMap((sprite) => {
          if (!sprite) {
            return this.setSprite(spriteUrl);
          } else {
            return of(sprite);
          }
        })
      )
      .subscribe(
        (sprite) => this.sprite$.next(sprite),
        (err) => {
          this.tools.error('Sprite initService ERROR', err);
        }
      );
  }

  /**
   * fetch and store sprite svg
   *
   * @param spriteUrl string
   */
  public setSprite(spriteUrl: string): Observable<any> {
    let sprite;

    return this.store.setSpriteLock(Date.now()).pipe(
      mergeMap(() => this.http.get(spriteUrl, { responseType: 'text' })),
      mergeMap((spriteRes: string) => {
        sprite = spriteRes;
        this.sprite$.next(sprite);
        return this.store.set(`${this.appName}_sprite`, sprite);
      }),
      mergeMap(() => this.store.setSpriteLock(null)),
      mergeMap(() => of(sprite)),
      catchError((err) => {
        this.tools.error('SpriteService setSprite()', err);
        throw err;
      })
    );
  }

  /**
   * Return an observable of the agency sprite text
   */
  public getSprite(): Observable<string> {
    return this.sprite$.asObservable();
  }
}
