import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'gflSanitizeUrl',
})
export class GflSanitizeUrlPipe implements PipeTransform {
  /**
   * @ignore
   */
  constructor(private _sanitizer: DomSanitizer) {}

  transform(v: string): SafeUrl {
    return this._sanitizer.bypassSecurityTrustUrl(v);
  }
}
