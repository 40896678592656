import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';

import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { LinkRelationItem } from '../../../gfl-core/gfl-models/api.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { Customer, CustomerFamilyMember } from '../../models/customer.model';
import { GflModeDisplayType } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form.model';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { CustomerService } from '../../services/customer.service';
import { AuthService } from '../../../authentication/services/auth.service';
import { RolesService } from '../../../gfl-core/gfl-services/roles.service';

import { AuthState } from '../../../authentication/reducers';

@Component({
  selector: 'gfl-customer-links-edit',
  templateUrl: './customer-links-edit.component.html',
  styleUrls: ['./customer-links-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerLinksEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedMember: Customer | CustomerFamilyMember;
  @Input() isPro: boolean;
  @Input() isOffline: boolean;
  @Input() style: FrontTheme;
  @Input() acls: Acls;
  private customer: Customer;

  public modeDisplay: GflModeDisplayType;
  public modeDisplaysTypes = GflModeDisplayType;
  public available$: Observable<LinkRelationItem[]>;
  public issuedOnly$: Observable<LinkRelationItem[]>;
  public receivedOnly$: Observable<LinkRelationItem[]>;
  public CUSTOMER_TYPE_ID_PRIVATE: number;
  public CUSTOMER_TYPE_ID_CORPORATE: number;
  public CUSTOMER_TYPE_ID_EMPLOYEE: number;
  public noDataDisplay: string;
  public errorDisplay: false;

  /**
   * @ignore
   */
  constructor(
    public tools: ToolsService,
    public customerSrv: CustomerService,
    public store: StoreService,
    public translate: TranslateService,
    private alertCtrl: AlertController,
    public notificationSrv: NotificationService,
    private constantSrv: ConstantService,
    private authSrv: AuthService,
    private rolesSrv: RolesService,
    private cd: ChangeDetectorRef
  ) {
    this.CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
    this.CUSTOMER_TYPE_ID_CORPORATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_CORPORATE');
    this.CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.setModeDisplay();
    this.customerSrv.getCustomer().subscribe((customer) => (this.customer = customer));
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedMember = this.selectedMember || this.customer;
    if (changes.selectedMember && this.acls && this.selectedMember) {
      this.setData();
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.available$ = null;
    this.issuedOnly$ = null;
    this.receivedOnly$ = null;
  }

  /**
   * Display delete alert
   *
   * @param link link relation item
   */
  public onDeleteLink(link: LinkRelationItem): void {
    this.translate
      .get([
        'PROFILE.CUSTOMER_LINKS.DELETE.MESSAGE',
        'PROFILE.CUSTOMER_LINKS.DELETE.BI_DIRECTIONAL',
        'PROFILE.CUSTOMER_LINKS.DELETE.MONO_DIRECTIONAL',
        'COMMON.BUTTON_CANCEL',
        'COMMON.BUTTON_VALIDATE',
      ])
      .subscribe(async (result) => {
        const alert = await this.alertCtrl.create({
          subHeader: result['PROFILE.CUSTOMER_LINKS.DELETE.MESSAGE'],
          buttons: [
            {
              text: result['COMMON.BUTTON_VALIDATE'],
              cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
              handler: (value) => {
                this.deleteLink(link, !!parseInt(value, 10), 'PROFILE.CUSTOMER_LINKS.DELETE.SUCCESS');
              },
            },
            {
              role: 'cancel',
              text: result['COMMON.BUTTON_CANCEL'],
              cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
            },
          ],
        });
        await alert.present();
      });
  }

  /**
   * Display delete alert
   *
   * @param link link relation item
   */
  public onDeleteAvailableLink(link: LinkRelationItem): void {
    this.translate
      .get([
        'PROFILE.CUSTOMER_LINKS.DELETE.MESSAGE_OPTIONS',
        'PROFILE.CUSTOMER_LINKS.DELETE.BI_DIRECTIONAL',
        'PROFILE.CUSTOMER_LINKS.DELETE.MONO_DIRECTIONAL',
        'COMMON.BUTTON_CANCEL',
        'COMMON.BUTTON_UPDATE',
      ])
      .subscribe(async (result) => {
        const alert = await this.alertCtrl.create({
          message: result['PROFILE.CUSTOMER_LINKS.DELETE.MESSAGE_OPTIONS'],
          inputs: [
            {
              type: 'radio',
              label: result['PROFILE.CUSTOMER_LINKS.DELETE.BI_DIRECTIONAL'],
              value: '1',
              checked: true,
            },
            {
              type: 'radio',
              label: result['PROFILE.CUSTOMER_LINKS.DELETE.MONO_DIRECTIONAL'],
              value: '0',
            },
          ],
          buttons: [
            {
              text: result['COMMON.BUTTON_UPDATE'],
              cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
              handler: (value) => {
                this.deleteLink(link, !!parseInt(value, 10), 'PROFILE.CUSTOMER_LINKS.DELETE.SUCCESS_OPTIONS');
              },
            },
            {
              role: 'cancel',
              text: result['COMMON.BUTTON_CANCEL'],
              cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
            },
          ],
        });
        await alert.present();
      });
  }

  /**
   * Display edit alert
   *
   * @param link link relation item
   */
  public onEditLink(link: LinkRelationItem): void {
    forkJoin([
      this.translate.get(['PROFILE.CUSTOMER_LINKS.ROLE_EDIT', 'COMMON.BUTTON_CANCEL', 'COMMON.BUTTON_UPDATE']),
      this.rolesSrv.getRolesByCustomerTypeId(link.customer_type_id),
    ]).subscribe(async ([result, roles]) => {
      const inputs = [];

      _.forEach(roles, (role) => {
        inputs.push({
          type: 'radio',
          label: role.name,
          value: role.id,
          checked: link.role_id === role.id,
        });
      });

      const alert = await this.alertCtrl.create({
        subHeader: result['PROFILE.CUSTOMER_LINKS.ROLE_EDIT'],
        inputs,
        buttons: [
          {
            text: result['COMMON.BUTTON_UPDATE'],
            cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
            handler: (roleId) => {
              this.updateCustomerLink(link.id, roleId);
            },
          },
          {
            role: 'cancel',
            text: result['COMMON.BUTTON_CANCEL'],
            cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
          },
        ],
      });
      await alert.present();
    });
  }

  // /**
  //  * Display edit alert
  //  * @param link link relation item
  //  */
  // public onEditLink(link: LinkRelationItem): void {
  //   this.translate
  //     .get([
  //       'PROFILE.CUSTOMER_LINKS.RIGHTS.MESSAGE',
  //       'PROFILE.CUSTOMER_LINKS.RIGHTS.READ',
  //       'PROFILE.CUSTOMER_LINKS.RIGHTS.WRITE',
  //       'PROFILE.CUSTOMER_LINKS.RIGHTS.DELETE',
  //       'COMMON.BUTTON_CANCEL',
  //       'COMMON.BUTTON_UPDATE',
  //     ])
  //     .subscribe(async result => {
  //       const alert = await this.alertCtrl.create({
  //         subHeader: result['PROFILE.CUSTOMER_LINKS.RIGHTS.MESSAGE'],
  //         inputs: [
  //           {
  //             type: 'checkbox',
  //             label: result['PROFILE.CUSTOMER_LINKS.RIGHTS.READ'],
  //             value: 'read',
  //             checked: !!link.read,
  //             name: 'read',
  //           },
  //           {
  //             type: 'checkbox',
  //             label: result['PROFILE.CUSTOMER_LINKS.RIGHTS.WRITE'],
  //             value: 'write',
  //             checked: !!link.write,
  //             name: 'write',
  //           },
  //           {
  //             type: 'checkbox',
  //             label: result['PROFILE.CUSTOMER_LINKS.RIGHTS.DELETE'],
  //             value: 'delete',
  //             checked: !!link.delete,
  //             name: 'delete',
  //           },
  //         ],
  //         buttons: [
  //           {
  //             role: 'cancel',
  //             text: result['COMMON.BUTTON_CANCEL'],
  //             cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
  //           },
  //           {
  //             text: result['COMMON.BUTTON_UPDATE'],
  //             cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
  //             handler: values => {
  //               const rights = {
  //                 read: values.includes('read') ? 1 : 0,
  //                 write: values.includes('write') ? 1 : 0,
  //                 delete: values.includes('delete') ? 1 : 0,
  //               };
  //
  //               this.updateRights(link.id, rights);
  //             },
  //           },
  //         ],
  //       });
  //       await alert.present();
  //     });
  // }

  /**
   * Set modeDisplay to mobile or tablet
   */
  private setModeDisplay(): void {
    this.modeDisplay = this.tools.setModeDisplay();
  }

  /**
   * Declare observables
   */
  private setData(): void {
    this.noDataDisplay = null;

    const customerLinks$ = this.customerSrv
      .getCustomerLinks(this.selectedMember.id)
      .pipe(filter((val) => !_.isEmpty(val)));

    this.available$ = customerLinks$.pipe(
      map((links) => links.available),
      tap(() => {
        this.cd.detectChanges();
      })
    );
    this.issuedOnly$ = customerLinks$.pipe(map((links) => (links.issued_only.length ? links.issued_only : [])));
    this.receivedOnly$ = customerLinks$.pipe(map((links) => (links.received_only.length ? links.received_only : [])));
  }

  /**
   * Update customer link
   *
   * @param customerLinkId customer link id
   * @param roleId customer link role's id
   * @param rights read, write and delete rights
   */
  private updateCustomerLink(
    customerLinkId: number,
    roleId: number,
    rights?: { read: number; write: number; delete: number }
  ): void {
    this.customerSrv
      .updateCustomerLink(customerLinkId, this.selectedMember.id, roleId, rights)
      .pipe(
        switchMap(() => {
          this.notificationSrv.showSuccess({
            message: 'PROFILE.CUSTOMER_LINKS.RIGHTS.SUCCESS',
          });
          return this.store.getAuthData().pipe(
            first(),
            switchMap((authData) =>
              this.customerSrv.setCustomer({
                customerToken: authData.customerToken,
                customerIdLinked: null,
                noUpdateMode: true,
                noAskForValidationCustomerLinks: false,
                fetchCustomerLinks: true,
                noContract: false,
              })
            )
          );
        }),
        catchError((err) => {
          this.notificationSrv.showError({ message: err });
          return throwError(err);
        })
      )
      .subscribe();
  }

  /**
   * Delete customer link from BO
   *
   * @param link link relation item
   * @param isBiDirectional true if both direction link must be deleted
   * @param successMessage message for success action
   */
  private deleteLink(link: LinkRelationItem, isBiDirectional: boolean, successMessage: string) {
    this.customerSrv
      .deleteCustomerLink(link.customer_id, link.customer_id_linked, isBiDirectional)
      .pipe(
        switchMap(() => {
          this.notificationSrv.showSuccess({
            message: successMessage,
          });
          return this.store.getAuthData().pipe(
            switchMap((authData) =>
              this.customerSrv.setCustomer({
                customerToken: authData.customerToken,
                customerIdLinked: null,
                noUpdateMode: true,
                noAskForValidationCustomerLinks: false,
                fetchCustomerLinks: true,
                noContract: false,
                reset: true,
              })
            )
          );
        }),
        tap(() => this.cd.detectChanges()),
        catchError((err) => {
          this.notificationSrv.showError({ message: err });
          return throwError(err);
        })
      )
      .subscribe();
  }

  /**
   * Open modal to accept customer link
   */
  public openValidateCustomerLinkModal(linkRelationItems: LinkRelationItem[]) {
    this.translate
      .get([
        'PROFILE.CUSTOMER_LINK_VALIDATE.TITLE',
        'PROFILE.CUSTOMER_LINK_VALIDATE.MESSAGE',
        'COMMON.BUTTON_CANCEL',
        'COMMON.BUTTON_VALIDATE',
      ])
      .subscribe((result) => {
        const inputs = [];

        _.forEach(linkRelationItems, (item) => {
          inputs.push({
            type: 'checkbox',
            label: item.customer_id_linked_name,
            value: item.customer_id.toString(),
            checked: false,
          });
        });

        this.alertCtrl
          .create({
            header: result['PROFILE.CUSTOMER_LINK_VALIDATE.TITLE'],
            message: result['PROFILE.CUSTOMER_LINK_VALIDATE.MESSAGE'],
            buttons: [
              {
                text: result['COMMON.BUTTON_VALIDATE'],
                cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
                handler: (authorizedIdArr) => {
                  // @ts-ignore
                  const authorizedCustomerLinks: LinkRelationItem[] = _.filter(linkRelationItems, (obj) =>
                    authorizedIdArr.includes(obj.customer_id.toString())
                  );
                  if (authorizedCustomerLinks && authorizedCustomerLinks.length) {
                    this.validateCustomerLinks(authorizedCustomerLinks);
                  }
                },
              },
              {
                text: result['COMMON.BUTTON_CANCEL'],
                cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
                role: 'cancel',
              },
            ],
            inputs,
          })
          .then((alert: HTMLIonAlertElement) => {
            alert.present().catch((err) => this.tools.error('openValidateCustomerLinkModal', err));
          });
      });
  }

  /**
   * Call BO to validate customer links
   *
   * @param authorizedCustomerLinks customer links to validate
   */
  private validateCustomerLinks(authorizedCustomerLinks: LinkRelationItem[]): void {
    const observables = [];
    _.forEach(authorizedCustomerLinks, (link) => {
      observables.push(this.customerSrv.registerCustomerLink(link.customer_link_type_id, link.customer_id));
    });

    forkJoin(observables)
      .pipe(
        switchMap(() => this.store.getAuthData().pipe(first())),
        switchMap((authData: AuthState) => {
          this.authSrv.logout();
          this.store.login({
            authData: {
              isLoggedIn: true,
              customerToken: authData.customerToken,
              customerLogin: authData.customerLogin,
              customerId: authData.customerId,
              agencyId: authData.agencyId,
              agencies: authData.agencies,
              customerTypeId: authData.customerTypeId,
              rememberMe: true,
              customerRefreshedTimeStamp: Date.now(),
              language: authData.language,
            },
            reloadAll: false,
            noNotifications: true,
            reset: false,
          });

          return of(true);
        })
      )
      .subscribe(
        () => {
          this.notificationSrv.showSuccess({
            message: 'PROFILE.CUSTOMER_LINK_VALIDATE.AUTHORIZED_CUSTOMER_LINKS',
          });
        },
        (err) => {
          this.tools.error('validateCustomerLinks', err);
          this.notificationSrv.showError({
            message: 'API.ERROR_MESSAGE',
          });
        }
      );
  }
}
