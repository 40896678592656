export interface Slider {
  id?: number;
  title?: string;
  description?: string;
  small?: string;
  bgcolor?: string;
  txtcolor?: string;
  redirect?: string;
  photo?: string;
}

export enum SliderType {
  Public = 1,
  Private,
}
