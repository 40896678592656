import { createReducer, on } from '@ngrx/store';
import { Document, DocumentCategoryItem } from '../gfl-core/gfl-models/document.model';
import { DocumentsActions } from './action.types';

export interface DocumentsState {
  documents: { [id: string]: Document };
  categoriesByInsuranceType: {
    items: DocumentCategoryItem[];
  };
}

export const initialDocumentsState: DocumentsState = {
  documents: {},
  categoriesByInsuranceType: {
    items: [],
  },
};

export const documentsReducer = createReducer(
  initialDocumentsState,

  on(DocumentsActions.reset, () => initialDocumentsState),

  on(DocumentsActions.initialize, (state, action) => ({ ...action.documentsState })),

  on(DocumentsActions.setDocumentCategories, (state, action) => {
    const categoriesByInsuranceType = {
      items: action.categoriesByInsuranceType,
    };

    return {
      ...state,
      categoriesByInsuranceType,
    };
  }),

  on(DocumentsActions.setDocuments, (state, action) => ({
    ...state,
    documents: action.documents,
  }))
);
