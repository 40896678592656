import { createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';

import { FrontTheme } from '../gfl-core/gfl-models/agency.model';
import { Customer, CustomerFamilyMember } from '../customer/models/customer.model';
import { NetWorkStatus } from '../gfl-core/gfl-services/network-monitor.service';

import { CustomerActions } from '../customer/reducers/action-types';
import { UiActions } from './action.types';

export interface UiState {
  isCustomerCompleted: boolean;
  lang: string;
  style: FrontTheme;
  adminLogo: string;
  isProSelected: boolean;
  isProSelectorDisplayed: boolean;
  isAppStateReady: boolean;
  isSignupProcess: boolean;
  selectedMember: CustomerFamilyMember | Customer;
  isLoading: boolean;
  activatedPage: string;
  networkStatus: NetWorkStatus;
  refreshState: boolean;
  displayNomadLoader: boolean;
}

export const initialUiState: UiState = {
  isCustomerCompleted: undefined,
  lang: undefined,
  style: undefined,
  adminLogo: undefined,
  isProSelected: undefined,
  isProSelectorDisplayed: undefined,
  isAppStateReady: undefined,
  isSignupProcess: undefined,
  selectedMember: undefined,
  isLoading: undefined,
  activatedPage: undefined,
  networkStatus: NetWorkStatus.Online,
  refreshState: false,
  displayNomadLoader: false,
};

export const uiReducer = createReducer(
  initialUiState,

  on(UiActions.initialize, (state, action) => ({ ...action.uiState })),

  on(UiActions.setLang, (state, action) => ({
    ...state,
    lang: action.lang,
  })),

  on(UiActions.setAdminLogo, (state, action) => ({
    ...state,
    adminLogo: action.logo,
  })),

  on(UiActions.setRefreshState, (state, action) => ({
    ...state,
    refreshState: action.refreshState,
  })),

  on(UiActions.setIsCustomerCompleted, (state, action) => ({
    ...state,
    isCustomerCompleted: action.isCustomerCompleted,
  })),

  on(UiActions.updateLang, (state, action) => ({
    ...state,
    lang: action.lang,
  })),

  on(UiActions.setIsProSelected, (state, action) => ({
    ...state,
    isProSelected: action.isPro,
  })),

  on(UiActions.setIsSignupProcess, (state, action) => ({
    ...state,
    isSignupProcess: action.isSignupProcess,
  })),

  on(UiActions.setIsProSelectorDisplayed, (state, action) => ({
    ...state,
    isProSelectorDisplayed: action.isProSelectorDisplayed,
  })),

  on(UiActions.setStyle, (state, action) => ({
    ...state,
    style: action.style,
  })),

  on(UiActions.setSelectedMember, (state, action) => ({
    ...state,
    selectedMember: action.selectedMember,
  })),

  on(UiActions.setLoaderState, (state, action) => ({
    ...state,
    isLoading: action.display,
  })),

  on(UiActions.setNomadLoaderState, (state, action) => ({
    ...state,
    displayNomadLoader: action.display,
  })),

  on(UiActions.setActivatedPage, (state, action) => ({
    ...state,
    activatedPage: action.activatedPage,
  })),

  on(UiActions.setNetworkStatus, (state, action) => ({
    ...state,
    networkStatus: action.networkStatus,
  })),

  on(CustomerActions.setPairingCode, (state, action) => {
    const newState = _.cloneDeep(state);

    if (newState.selectedMember.id === action.id) {
      newState.selectedMember.pairing_code = action.pairingCode;
    }

    return newState;
  }),

  on(UiActions.setIsAppStateReady, (state, action) => ({
    ...state,
    isAppStateReady: action.isReady,
  }))
);
