import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

import { Observable } from 'rxjs';

import { AgencyService } from '../../gfl-services/agency.service';
import { AclsService } from '../../gfl-services/acls.service';
import { NetworkMonitorService } from '../../gfl-services/network-monitor.service';
import { Acls } from '../../gfl-models/acls.model';

import { environment } from '../../../../environments/environment';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'gfl-header-mobile',
  templateUrl: './gfl-header-mobile.component.html',
  styleUrls: ['./gfl-header-mobile.component.scss'],
})
export class GflHeaderMobileComponent implements OnInit {
  @Input() displayBackButton: boolean;
  @Input() hideMenuButton: boolean;
  @Input() hideNetworkStatus: boolean;
  public logo$: Observable<string>;
  public acls$: Observable<Acls>;
  public isOffLine$: Observable<boolean>;
  public isDev: boolean;

  /**
   * @ignore
   */
  constructor(
    private agencySrv: AgencyService,
    public platform: Platform,
    private aclsSrv: AclsService,
    private network: NetworkMonitorService
  ) {
    this.isDev = environment.mode === 'Development' || environment.mode === 'preproduction';
    // this.isDev = false;
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.initAcls();
    this.logo$ = this.agencySrv.getPrivateLogo();
    this.isOffLine$ = this.network.isOffline();
  }

  /**
   * Initialize permissions
   */
  private initAcls(): void {
    this.acls$ = this.aclsSrv.getAcls().pipe(shareReplay());
  }
}
