export enum ComponentType {
  INPUT_TEXT = 'input_text',
  INPUT_NUM = 'input_num',
  INPUT_NUM_INT = 'input_num_int',
  INPUT_DATE = 'input_date',
  // TEXTAREA = 'textarea',
  SELECT = 'select',
  TOGGLE = 'toggle',
  PHONE = 'phone',
  PHONE_MULTI = 'phone_multi',
  EMAIL = 'email',
  EMAIL_MULTI = 'email_multi',
  ZIPCODE_CITY = 'zipcode_city',
}

export enum ItemTypeParent {
  PHONE = 'phone',
  EMAIL = 'email',
}

export enum ItemType {
  INT = 'int',
  DOUBLE = 'double',
  BOOLEAN = 'boolean',
  VARCHAR = 'varchar',
  TEXT = 'text',
  DATE = 'date',
}

export interface CountableItem {
  value: string;
  value_reformat: string;
}
