import { createAction, props } from '@ngrx/store';

import { SafeboxState } from './safebox.reducer';
import { SafeboxType } from '../models/safebox-type.model';
import { Safebox } from '../models/safebox.model';

export const initialize = createAction(
  '[Store Service setNgrxStore] initialize SafeboxState',
  props<{ safeboxState: SafeboxState }>()
);

export const reset = createAction('[Store Service refreshAllData()] reset all stored data');

export const resetSafeboxTypes = createAction('[Store Service resetSafeboxTypes()] reset safeboxTypes data');

export const setSafeboxTypes = createAction(
  '[Store Service setSafeboxTypes()] load safebox types from BO',
  props<{ safeboxTypes: SafeboxType[]; lang: string }>()
);

export const setSafeboxes = createAction(
  '[Store Service setSafeboxes()] load safeboxes from BO',
  props<{ safeboxes: { [id: string]: Safebox } }>()
);

export const addSafebox = createAction(
  '[Store Service addSafebox()] load a safebox from BO',
  props<{ safebox: Safebox }>()
);

export const removeSafebox = createAction(
  '[Store Service removeSafebox()] remove a safebox from store',
  props<{ safeboxId: number }>()
);
