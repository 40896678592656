import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, shareReplay } from 'rxjs/operators';

import { ChatItemsMap } from '../../models/contacts.model';
import { Customer, CustomerFamilyMember } from '../../../customer/models/customer.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { ContactsService } from '../../services/contacts.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';

@Component({
  selector: 'gfl-chat-display',
  templateUrl: './chat-display.component.html',
  styleUrls: ['./chat-display.component.scss'],
})
export class ChatDisplayComponent implements OnInit, OnDestroy {
  @ViewChild('contentRef', { static: false }) contentRef: ElementRef;
  @Input() acls: Acls;

  public members$: Observable<CustomerFamilyMember[]>;
  public selectedMember: CustomerFamilyMember | Customer;
  public chatItemsMap$: Observable<ChatItemsMap>;
  public chatItemsCount$: Observable<{ [customerId: number]: { count: number } }>;
  public COMMENT_COMMENTABLE_AUTHOR_USER: number;
  public COMMENT_COMMENTABLE_AUTHOR_CUSTOMER: number;
  public message: string;
  public isLoading: boolean;
  public errorDisplay: boolean;
  public noDataDisplay: string = null;
  private subscriptions: Subscription[] = [];

  constructor(
    private contactsSrv: ContactsService,
    private notificationSrv: NotificationService,
    public tools: ToolsService,
    private modalCtrl: ModalController,
    private constantSrv: ConstantService,
    public translation: TranslateService,
    private customerSrv: CustomerService,
    private store: StoreService,
    private ngZone: NgZone
  ) {
    this.COMMENT_COMMENTABLE_AUTHOR_USER = this.constantSrv.getValueFromKey('COMMENT_COMMENTABLE_AUTHOR_USER');
    this.COMMENT_COMMENTABLE_AUTHOR_CUSTOMER = this.constantSrv.getValueFromKey('COMMENT_COMMENTABLE_AUTHOR_CUSTOMER');
  }

  ngOnInit(): void {
    this.members$ = this.customerSrv.getMembers().pipe(
      filter((val) => !_.isEmpty(val)),
      delay(0), // avoid "ExpressionChangedAfterItHasBeenCheckedError" error
      map((members) => {
        if (members && members.length) {
          if (members.length > 1) {
            members.shift();
          }
          this.customerSrv.selectMember(members[0]);
        }

        return members;
      }),
      catchError((err) => {
        this.tools.error('ChatDisplayComponent initialize members$', err);
        // this.errorDisplay = true;
        return of([]);
      })
    );

    this.chatItemsMap$ = this.getChatItemsMap();

    this.chatItemsCount$ = this.chatItemsMap$.pipe(
      map((chatItemsMap) => {
        const res = {};

        _.forEach(chatItemsMap, (o, key) => {
          res[key] = { count: o.new };
        });

        return res;
      })
    );
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then();
  }

  /**
   * Manage Chat items display
   */
  public getChatItemsMap(): Observable<ChatItemsMap> {
    return combineLatest([
      this.contactsSrv.getChatItems(),
      this.getSelectedMember(),
      this.store.getChatItemsLock(),
    ]).pipe(
      mergeMap(([chatItemsMap, selectedMember, lock]) => {
        this.selectedMember = selectedMember;

        if (!chatItemsMap || !selectedMember || selectedMember.id === 0) {
          this.isLoading = true;
        } else {
          this.noDataDisplay = lock
            ? ''
            : !selectedMember.issued_only &&
              (!chatItemsMap[selectedMember.id] || !chatItemsMap[selectedMember.id].items.length)
            ? 'CONTACT.CHAT.NO_ITEM'
            : null;
          this.errorDisplay = false;
          this.isLoading = !!lock;

          if (!NgZone.isInAngularZone()) {
            this.ngZone.run(() => {
              this.noDataDisplay = lock
                ? ''
                : !selectedMember.issued_only &&
                  (!chatItemsMap[selectedMember.id] || !chatItemsMap[selectedMember.id].items.length)
                ? 'CONTACT.CHAT.NO_ITEM'
                : null;
              this.errorDisplay = false;
              this.isLoading = !!lock;
            });
          }
        }

        return of(chatItemsMap);
      }),
      catchError((err) => {
        this.tools.error('ChatDisplayComponent getChatItemsMap', err);
        this.isLoading = false;
        this.errorDisplay = true;
        return of(null);
      })
    );
  }

  /**
   * Send customer message to BO
   */
  public send(): void {
    this.contactsSrv.sendChatItem(this.message, this.selectedMember.id).subscribe(
      () => {
        this.message = null;
        this.contactsSrv.markChatItemsAsSeen(this.selectedMember.id);
      },
      (err) => {
        this.tools.error('send() err', err);
        this.notificationSrv.showError({ message: err });
      }
    );
  }

  /**
   * Close current view
   */
  public close(): void {
    this.modalCtrl.dismiss().then();
  }

  /**
   * return selected member and update chat items state
   */
  private getSelectedMember() {
    let selectedMember;

    return this.customerSrv.getSelectedMember().pipe(
      mergeMap((res) => {
        selectedMember = res;
        if (this.contentRef && selectedMember) {
          this.contentRef.nativeElement.scrollTop = this.contentRef.nativeElement.scrollHeight;
          return this.contactsSrv.markChatItemsAsSeen(selectedMember.id);
        }
        return of(true);
      }),
      mergeMap(() => of(selectedMember)),
      shareReplay()
    );
  }
}
