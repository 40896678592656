import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActionSheetController, AlertController, Platform } from '@ionic/angular';
import { Camera, ImageOptions, Photo, CameraResultType, CameraSource } from '@capacitor/camera';
import { TranslateService } from '@ngx-translate/core';

import * as _ from 'lodash';

import { UploadDocument } from '../../gfl-models/document.model';
import { AcceptedExtension } from '../../gfl-models/picture.model';
import { NotificationService } from '../../gfl-services/notification.service';
import { ImageService } from '../../gfl-services/image.service';
import { DocumentService } from '../../gfl-services/document.service';
import { ToolsService } from '../../gfl-services/tools.service';

@Component({
  selector: 'gfl-upload-files-to-pdf',
  templateUrl: './gfl-upload-files-to-pdf.component.html',
  styleUrls: ['./gfl-upload-files-to-pdf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GflUploadFilesToPdfComponent implements OnChanges {
  @Input() documents: Array<UploadDocument>;
  @Output() documentsChange = new EventEmitter<Array<UploadDocument>>();

  @Input() typeId: number;
  @Input() cameraOptions: ImageOptions;
  @Input() limitToOneImage: boolean;
  @Input() displayMultipleImages: boolean;

  @Output() removeDocument = new EventEmitter<any>();

  @ViewChild('inputFileCamera', { static: false }) inputFileCamera: ElementRef;

  public acceptedExtension: AcceptedExtension;

  public canAddDocument: boolean;

  /**
   * @ignore
   */
  constructor(
    private tools: ToolsService,
    private translate: TranslateService,
    private actionSheetCtrl: ActionSheetController,
    private alertCtrl: AlertController,
    private platform: Platform,
    private imageSrv: ImageService,
    private notificationSrv: NotificationService,
    private documentSrv: DocumentService,
    private ref: ChangeDetectorRef
  ) {
    this.canAddDocument = true;
    this.acceptedExtension = this.limitToOneImage ? AcceptedExtension.IMAGE : AcceptedExtension.ALL;
    this.cameraOptions = {
      quality: 50,
      resultType: CameraResultType.DataUrl,
      // encodingType: this.camera.EncodingType.JPEG,
      // mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      height: 1800,
      width: 1350,
    };
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documents && changes.documents.previousValue !== changes.documents.currentValue) {
      this.updateDocumentTypeAuthorized();
    }
  }

  /**
   * Remove document from documents array
   */
  public onRemoveDocument(document?: UploadDocument): void {
    // we reassign a clone of documents to the documents attribute
    // in order to force the angular change detection event
    if (document) {
      this.documents = _.reject(this.documents, { data: document.data });
    } else {
      this.documents = [];
    }

    this.documentsChange.emit(this.documents);
    this.updateDocumentTypeAuthorized();
  }

  /**
   * Get document
   */
  public onTakeDocument(): void {
    if (this.tools.isNative()) {
      // accept all type of media
      this.takeNativePicture();
    } else {
      this.inputFileCamera.nativeElement.click();
    }
  }

  public onTakeNewPage(): void {
    this.translate
      .get(['COMMON.UPLOAD.ADD_NEW_PAGE', 'COMMON.BUTTON_CONFIRM', 'COMMON.BUTTON_CANCEL'])
      .subscribe(async (result) => {
        if (!this.tools.isMobile()) {
          const alert = await this.alertCtrl.create({
            header: result['COMMON.UPLOAD.ADD_NEW_PAGE'],
            buttons: [
              {
                text: result['COMMON.BUTTON_CONFIRM'],
                cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
                handler: () => {
                  this.onTakeDocument();
                },
              },
              {
                text: result['COMMON.BUTTON_CANCEL'],
                cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
                role: 'cancel', // will always sort to be on the bottom
                handler: () => {},
              },
            ],
          });
          await alert.present();
        } else {
          const actionSheet = await this.actionSheetCtrl.create({
            header: result['COMMON.UPLOAD.ADD_NEW_PAGE'],
            buttons: [
              {
                text: result['COMMON.BUTTON_CONFIRM'],
                cssClass: 'gfl-alert-validate-btn',
                icon: !this.platform.is('ios') ? 'image' : null,
                handler: () => {
                  this.onTakeDocument();
                },
              },
              {
                text: result['COMMON.BUTTON_CANCEL'],
                role: 'cancel', // will always sort to be on the bottom
                icon: !this.platform.is('ios') ? 'close' : null,
                handler: () => {},
              },
            ],
          });
          await actionSheet.present();
        }
      });
  }

  /**
   * Open the document
   *
   * @param document document object to upload
   */
  public onOpenDocument(document: UploadDocument): void {
    if (document.document_id) {
      return this.documentSrv.openDocumentFile(document.document_id);
    }
  }

  /**
   * Add a document to documents array using the input field
   *
   * @param event DOM event
   */
  public onAddDocument(event: Event): void {
    // check if a file has been selected...
    if (!(event.target as HTMLInputElement).files.length) {
      return;
    }

    this.documentSrv.handleSelectedDocument(event).subscribe(
      (document: UploadDocument) => {
        // Check if documents of typeId is set as an array
        this.documents = _.isArray(this.documents) ? this.documents : [];

        document.category_id = this.typeId;
        this.documents.push(document);

        this.documentsChange.emit(this.documents);
        this.updateDocumentTypeAuthorized();
      },
      (err) => {
        this.tools.error('GflUploadFilesToPdfComponent addDocument', err);
        this.notificationSrv.showError({
          message: 'API.ERROR_MESSAGE',
        });
      }
    );
  }

  /**
   * Select Source type in order to deliver the picture
   */
  private takeNativePicture() {
    this.translate
      .get(['COMMON.PHOTO_FROM_CAMERA', 'COMMON.PHOTO_FROM_LIBRARY', 'COMMON.BUTTON_CANCEL'])
      .subscribe(async (result) => {
        const actionSheet = await this.actionSheetCtrl.create({
          header: result['COMMON.CONTACT_TITLE'],
          buttons: [
            {
              text: result['COMMON.PHOTO_FROM_CAMERA'],
              cssClass: 'gfl-alert-validate-btn',
              icon: !this.platform.is('ios') ? 'camera' : null,
              handler: () => {
                this.cameraOptions.source = CameraSource.Camera;
                this.getPicture();
              },
            },
            {
              text: result['COMMON.PHOTO_FROM_LIBRARY'],
              cssClass: 'gfl-alert-info-btn',
              icon: !this.platform.is('ios') ? 'images' : null,
              handler: () => {
                this.cameraOptions.source = CameraSource.Photos;
                this.getPicture();
              },
            },
            {
              text: result['COMMON.BUTTON_CANCEL'],
              role: 'cancel', // will always sort to be on the bottom
              icon: !this.platform.is('ios') ? 'close' : null,
              handler: () => {},
            },
          ],
        });
        await actionSheet.present();
      });
  }

  /**
   * Use Native Camera or Photo Library to get the picture
   */
  private getPicture(): void {
    let base64Image: string;

    Camera.getPhoto(this.cameraOptions)
      .then((imageData: Photo) => {
        // imageData is either a base64 encoded string or a file URI
        // If it's base64 (DATA_URL):
        base64Image = imageData.dataUrl;

        return this.imageSrv.getUploadedImageDimensions({ file: null, base64: base64Image });
      })
      .then((dimension) => {
        // Check if documents of typeId is set as an array
        this.documents = _.isArray(this.documents) ? this.documents : [];

        this.documents.push({
          category_id: this.typeId,
          title: null,
          spriteItem: null,
          thumbnail: base64Image,
          data: base64Image,
          ext: 'jpg',
          width: dimension.width,
          height: dimension.height,
        });

        this.documentsChange.emit(this.documents);
        this.updateDocumentTypeAuthorized();
      })
      .catch((err) => {
        this.tools.error('GflUploadFilesToPdfComponent getPicture', err);
        if (typeof err !== 'string') {
          this.notificationSrv.showError({
            message: 'API.ERROR_MESSAGE',
          });
        }
      });
  }

  /**
   * Set canAddDocument flag value and update accepted documents types.
   *
   * If there's no documents uploaded then we accept .png,.jpg,.jpeg,.svg,.doc,.docx,.pdf,.xls,.xlsx types
   * else if there's one document of type image then we limit further documents to image type
   * finally we limit to only one document of other types
   */
  private updateDocumentTypeAuthorized(): void {
    if (this.documents && this.documents.length > 1) {
      // document type authorized has already been updated
      return;
    } else if (!this.documents || !this.documents.length) {
      // All documents have been removed
      this.acceptedExtension = this.limitToOneImage ? AcceptedExtension.IMAGE : AcceptedExtension.ALL;
      this.canAddDocument = true;
    } else {
      const document = this.documents[0];
      const regExp = /data:image/;
      const isPicture = regExp.test(document.data);

      if (isPicture) {
        this.acceptedExtension = AcceptedExtension.IMAGE;
        this.canAddDocument = !this.limitToOneImage;
      } else {
        this.canAddDocument = false;
      }
    }

    this.ref.markForCheck();
    this.ref.detectChanges();
  }
}
