import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GflObjectLoopPipe } from './gfl-object-loop.pipe';
import { GflOrderByDatePipe } from './gfl-order-by-date.pipe';
import { GflSanitizeHtmlPipe } from './gfl-sanitize-html.pipe';
import { GflSanitizeUrlPipe } from './gfl-sanitize-url.pipe';

@NgModule({
  declarations: [GflOrderByDatePipe, GflSanitizeHtmlPipe, GflObjectLoopPipe, GflSanitizeUrlPipe],
  imports: [CommonModule],
  exports: [GflObjectLoopPipe, GflOrderByDatePipe, GflSanitizeHtmlPipe, GflSanitizeUrlPipe],
})
export class GflPipesModule {}
