import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { GflCoreModule } from '../../../gfl-core/gfl-core.module';
import { CustomerComponentsModule } from '../../components/customer-components.module';
import { ContactComponentsModule } from '../../../contacts/components/contact-components.module';

import { ProfilePage } from './profile.page';
import { AuthentificationComponentsModule } from '../../../authentication/components/authentification-components.module';

@NgModule({
  imports: [
    GflCoreModule,
    CustomerComponentsModule,
    ContactComponentsModule,
    AuthentificationComponentsModule,
    IonicModule,
  ],
  declarations: [ProfilePage],
})
export class ProfilePageModule {}
