import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { Customer, CustomerFamilyMember } from '../../models/customer.model';

@Component({
  selector: 'gfl-customer-type-select',
  templateUrl: './customer-type-select.component.html',
  styleUrls: ['./customer-type-select.component.scss'],
})
export class CustomerTypeSelectComponent {
  @Input() acls: Acls;
  @Input() CUSTOMER_TYPE_ID_PRIVATE: number;
  @Input() CUSTOMER_TYPE_ID_CORPORATE: number;
  @Input() CUSTOMER_TYPE_ID_EMPLOYEE: number;
  @Input() selectedMember: CustomerFamilyMember | Customer;

  @Output() customerTypeChoice: EventEmitter<number> = new EventEmitter<number>();

  /**
   * @ignore
   */
  constructor() {}

  public choice(customerType: number): void {
    this.customerTypeChoice.emit(customerType);
  }
}
