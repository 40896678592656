import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ToolsService } from '../../gfl-core/gfl-services/tools.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthLoginGuard implements CanActivate, CanActivateChild {
  /**
   * @ignore
   */
  constructor(private tools: ToolsService, private authSrv: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.fn();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.fn();
  }

  private fn(): Observable<boolean> {
    this.tools.setCookie(); // refresh cookie for PWA

    return this.authSrv.isLoggedIn().pipe(
      map((loggedIn) => {
        if (loggedIn) {
          // already logged in... redirect to home page
          if (!this.tools.isMobile()) {
            this.router.navigateByUrl('/policies').then();
          } else {
            this.router.navigateByUrl('/home').then();
          }
        }
        return !loggedIn;
      })
    );
  }
}
