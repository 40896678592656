import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './authentication/guards/auth.guard';
import { AuthLoginGuard } from './authentication/guards/auth-login.guard';
import { MobileGuard } from './mobile.guard';

const fallbackRoute: Route = {
  path: '**',
  redirectTo: '/home',
};

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomePageModule),
    canActivate: [AuthLoginGuard],
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard, MobileGuard],
  },
  {
    path: 'safebox',
    loadChildren: () => import('./safebox/safebox.module').then(m => m.SafeboxPageModule),
    canActivate: [AuthGuard],
  },
  fallbackRoute,
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
