import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromContacts from './contacts.reducer';

export const selectContactsState = createFeatureSelector<fromContacts.ContactsState>(fromContacts.contactsFeatureKey);

export const chatItemsMap = createSelector(
  selectContactsState,
  contactsState => contactsState.chat
);

export const notifications = createSelector(
  selectContactsState,
  contactsState => contactsState.notifications
);
