import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

/**
 * this directive adds width attribute to the element to which it is applied
 * it is used to generate a static table header in order to keep width of
 * every th cells when in position absolute
 *
 * @example
 * <th gflWidthIndicator>{{ 'INSURANCE.BUDGET.BOARD_POLICY' | translate }}</th>
 * ends with : <th gflWidthIndicator width="113">N° de police</th>
 */
@Directive({
  selector: '[gflWidthIndicator]',
})
export class GflWidthIndicatorDirective implements AfterViewInit {
  /**
   * @ignore
   */
  constructor(private renderer: Renderer2, private el: ElementRef) {}

  /**
   * @ignore
   */
  ngAfterViewInit() {
    const q = setInterval(() => {
      if (this.el.nativeElement.clientWidth) {
        this.renderer.setAttribute(this.el.nativeElement, 'width', this.el.nativeElement.clientWidth);
        clearInterval(q);
      }
    }, 100);
  }
}
