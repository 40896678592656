import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';

import { ProgressSignupConfig } from '../progress-signup/progress-signup.component';

@Component({
  selector: 'gfl-sms-code-validation',
  templateUrl: './sms-code-validation.component.html',
  styleUrls: ['./sms-code-validation.component.scss'],
})
export class SmsCodeValidationComponent implements OnInit {
  @Input() state: ProgressSignupConfig;
  @Input() customerToken: string;
  @Output() validate: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  public validationForm: FormGroup;

  constructor(
    public tools: ToolsService,
    private fb: FormBuilder,
    private customerSrv: CustomerService,
    private notificationSrv: NotificationService
  ) {
    this.initForm();
  }

  ngOnInit() {}

  public onSubmit() {
    this.validate.emit(this.validationForm.value.code);
  }

  public onCancel() {
    this.cancel.emit();
  }

  /**
   * Ask for an SMS to be sent for authentication
   */
  public sendSmsAuthentication(): void {
    this.tools.showLoader();

    this.customerSrv
      .sendSmsAuthentication(this.customerToken)
      .pipe(finalize(() => this.tools.hideLoader()))
      .subscribe(
        () => {
          this.notificationSrv.showSuccess({ message: 'SIGNUP.AUTH_CODE' });
        },
        (err) => {
          this.notificationSrv.showError({ message: err });
        }
      );
  }

  /**
   * Initialize the validation form
   */
  private initForm(): void {
    // form definition
    this.validationForm = this.fb.group({
      code: ['', [Validators.required, Validators.maxLength(4), Validators.minLength(4)]],
    });
  }
}
