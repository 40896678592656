import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSafebox from './safebox.reducer';

export const selectSafeboxState = createFeatureSelector<fromSafebox.SafeboxState>(fromSafebox.safeboxFeatureKey);

export const safeboxTypes = createSelector(
  selectSafeboxState,
  safeboxState => safeboxState.safeboxTypes
);

export const safeboxes = createSelector(
  selectSafeboxState,
  safeboxState => safeboxState.safeboxes
);
