import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Storage } from '@ionic/storage';
import * as _ from 'lodash';

import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { AuthActions } from '../../authentication/reducers/action-types';
import {
  AgencyActions,
  DocumentsActions,
  LocksActions,
  PermissionsActions,
  UiActions,
} from '../../reducers/action.types';
import { CustomerActions } from '../../customer/reducers/action-types';
import { PoliciesActions } from '../../policies/reducers/action-types';
import { ComparesActions } from '../../compares/reducers/action-types';
import { ContactsActions } from '../../contacts/reducers/action-types';

import { customerId, isLoggedIn, selectAuthState } from '../../authentication/reducers/auth.selector';
import {
  agency,
  agencyAddress,
  agencyName,
  contacts,
  privateLogo,
  privateSliders,
  publicLogo,
  publicSliders,
} from '../../reducers/agency.selector';
import {
  activatedPage,
  adminLogo,
  displayNomadLoader,
  isAppStateReady,
  isCustomerCompleted,
  isLoading,
  isProSelected,
  isProSelectorDisplayed,
  isRefreshingData,
  isSignupProcess,
  languageSelected,
  networkStatus,
  selectedMember,
  style,
} from '../../reducers/ui.selector';
import { customers, members } from '../../customer/reducers/customer.selector';
import {
  companies,
  companiesByCustomerTypeByInsuranceType,
  insuranceTypes,
  insuranceTypesCorporate,
  insuranceTypesPrivate,
  policiesAndMandates,
  policiesItems,
} from '../../policies/reducers/policies.selector';
import { compares, comparesItems } from '../../compares/reducers/compares.selector';
import { documentCategories, documents } from '../../reducers/documents.selector';
import { safeboxes, safeboxTypes } from '../../safebox/reducers/safebox.selectors';
import { chatItemsMap, notifications } from '../../contacts/reducers/contacts.selectors';
import { acls } from '../../reducers/permissions.selector';
import {
  agencyLock,
  documentsLock,
  safeboxesLock,
  safeboxTypesLock,
  slidersLock,
  documentCategoriesLock,
  companiesLock,
  insuranceTypesLock,
  policiesAndMandatesLock,
  customerLock,
  chatItemsLock,
  comparesLock,
  constantsLock,
  foreignTablesLock,
  spriteLock,
  statusesLock,
  itemTemplatesLock,
  translationsLock,
  rolesLock,
  permissionsLock,
} from '../../reducers/locks.selector';

import { Agency, DefaultAddress, FrontTheme } from '../gfl-models/agency.model';
import { Acls } from '../gfl-models/acls.model';
import { Contact } from '../gfl-models/contact.model';
import { Customer, CustomerBank, CustomerFamilyMember, StoredCustomer } from '../../customer/models/customer.model';
import { ItemTemplateFO } from '../gfl-models/item-template.model';
import { Slider, SliderType } from '../gfl-models/slider.model';
import { PolicyFO } from '../../policies/models/policy.model';
import { Mandate } from '../../policies/models/mandate.model';
import { InsuranceType } from '../../policies/models/insurance-type.model';
import { CompareFO, CompareListItem } from '../../compares/models/compare.model';
import { Company } from '../gfl-models/company.model';
import { NetWorkStatus } from './network-monitor.service';
import { DocumentCategoryItem, Document } from '../gfl-models/document.model';
import { SafeboxActions } from '../../safebox/reducers/action-types';
import { SafeboxType } from '../../safebox/models/safebox-type.model';
import { Safebox } from '../../safebox/models/safebox.model';
import { ChatItem, NotificationItem } from '../../contacts/models/contacts.model';

import { GflState } from '../../reducers';
import { AuthState } from '../../authentication/reducers';
import { UiState } from '../../reducers/ui.reducer';
import { DocumentsState } from '../../reducers/documents.reducer';
import { AgencyState } from '../../reducers/agency.reducer';
import { ComparesState } from '../../compares/reducers';
import { CustomerState } from '../../customer/reducers';
import { PoliciesState } from '../../policies/reducers';
import { SafeboxState } from '../../safebox/reducers/safebox.reducer';
import { ContactsState } from '../../contacts/reducers/contacts.reducer';
import { PermissionsState } from '../../reducers/permissions.reducer';

import { environment } from '../../../environments/environment';

/**
 * This service take storage promise and return an observable and set NgRx Store data from storage
 */
@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private appName = environment.APP_NAME;

  /**
   * @ignore
   */
  constructor(private ngrxStore: Store<GflState>, private storage: Storage) {}

  /**
   * Initialize NgRx store with persistent data on App Launch
   *
   * @param appName name of the app
   */
  public loadStore(appName: string) {
    return this.setNgrxStore(appName).toPromise();
  }

  /**
   * Record current NgRx store Documents state in persistent layer
   *
   * @param appName application name
   * @param data DocumentsState data
   */
  public setPersistentStoreDocuments(appName: string, data: DocumentsState): Observable<any> {
    return this.set(appName + '_documents', data);
  }

  /**
   * Remove Documents state from persistent layer
   *
   * @param appName application name
   */
  public removePersistentStoreDocuments(appName: string): Observable<any> {
    return this.remove(appName + '_documents');
  }

  /**
   * Record current NgRx store agency state in persistent layer
   *
   * @param appName application name
   * @param data AgencyState data
   */
  public setPersistentStoreAgency(appName: string, data: AgencyState): Observable<any> {
    return this.set(appName + '_agency', data);
  }

  /**
   * Remove Agency state from persistent layer
   *
   * @param appName application name
   */
  public removePersistentStoreAgency(appName: string): Observable<any> {
    return this.remove(appName + '_agency');
  }

  /**
   * Record current NgRx store compare state in persistent layer
   *
   * @param appName application name
   * @param data CompareState data
   */
  public setPersistentStoreCompare(appName: string, data: ComparesState): Observable<any> {
    return this.set(appName + '_compares', data);
  }

  /**
   * Remove Compare state from persistent layer
   *
   * @param appName application name
   */
  public removePersistentStoreCompare(appName: string): Observable<any> {
    return this.remove(appName + '_compares');
  }

  /**
   * Record current NgRx store UI state in persistent layer
   *
   * @param appName application name
   * @param data UiState data
   */
  public setPersistentStoreUi(appName: string, data: UiState): Observable<any> {
    return this.set(appName + '_ui', data);
  }

  /**
   * Remove UI state from persistent layer
   *
   * @param appName application name
   */
  public removePersistentStoreUi(appName: string): Observable<any> {
    return this.remove(appName + '_ui');
  }

  /**
   * Record current NgRx store Permissions state in persistent layer
   *
   * @param appName application name
   * @param data PermissionsState data
   */
  public setPersistentStorePermissions(appName: string, data: PermissionsState): Observable<any> {
    return this.set(appName + '_permissions', data);
  }

  /**
   * Remove permissions state from persistent layer
   *
   * @param appName application name
   */
  public removePersistentStorePermissions(appName: string): Observable<any> {
    return this.remove(appName + '_permissions');
  }

  /**
   * Record current NgRx store customer state in persistent layer
   *
   * @param appName application name
   * @param data CustomerState data
   */
  public setPersistentStoreCustomer(appName: string, data: CustomerState): Observable<any> {
    return this.set(appName + '_customer', data);
  }

  /**
   * Remove Customer state from persistent layer
   *
   * @param appName application name
   */
  public removePersistentStoreCustomer(appName: string): Observable<any> {
    return this.remove(appName + '_customer');
  }

  /**
   * Record current NgRx store auth state in persistent layer
   *
   * @param appName application name
   * @param data AuthState data
   */
  public setPersistentStoreAuth(appName: string, data: AuthState): Observable<any> {
    return this.set(appName + '_auth', data);
  }

  /**
   * Remove auth state from persistent layer
   *
   * @param appName application name
   */
  public removePersistentStoreAuth(appName: string): Observable<any> {
    return this.remove(appName + '_auth');
  }

  /**
   * Record current NgRx store policies state in persistent layer
   *
   * @param appName application name
   * @param data PoliciesState data
   */
  public setPersistentStorePolicies(appName: string, data: PoliciesState): Observable<any> {
    return this.set(appName + '_policies', data);
  }

  /**
   * Remove policies state from persistent layer
   *
   * @param appName application name
   */
  public removePersistentStorePolicies(appName: string): Observable<any> {
    return this.remove(appName + '_policies');
  }

  /**
   * Record current NgRx store safebox state in persistent layer
   *
   * @param appName application name
   * @param data SafeboxState data
   */
  public setPersistentStoreSafebox(appName: string, data: SafeboxState): Observable<any> {
    return this.set(appName + '_safebox', data);
  }

  /**
   * Remove safebox state from persistent layer
   *
   * @param appName application name
   */
  public removePersistentStoreSafebox(appName: string): Observable<any> {
    return this.remove(appName + '_safebox');
  }

  /**
   * Record current NgRx store contacts state in persistent layer
   *
   * @param appName application name
   * @param data ContactsState data
   */
  public setPersistentStoreContacts(appName: string, data: ContactsState): Observable<any> {
    return this.set(appName + '_contacts', data);
  }

  /**
   * Remove contacts state from persistent layer
   *
   * @param appName application name
   */
  public removePersistentStoreContacts(appName: string): Observable<any> {
    return this.remove(appName + '_contacts');
  }

  /**
   * Remove safebox state from persistent layer
   *
   * @param appName application name
   */
  public removePersistentStoreSprite(appName: string): Observable<any> {
    return this.remove(appName + '_sprite');
  }

  /**
   * Initialize NgRx store with localstorage data
   */
  private setNgrxStore(appName: string): Observable<any> {
    return forkJoin([
      this.get(appName + '_agency').pipe(
        tap((agencyState) => {
          if (agencyState && agencyState.agency.name) {
            this.ngrxStore.dispatch(AgencyActions.initialize({ agencyState }));
          }
        })
      ),
      this.get(appName + '_permissions').pipe(
        tap((permissionsState) => {
          if (permissionsState && permissionsState.acls) {
            this.ngrxStore.dispatch(PermissionsActions.initialize({ permissionsState }));
          }
        })
      ),
      this.get(appName + '_ui').pipe(
        tap((uiState) => {
          if (uiState) {
            uiState.isLoading = false;
            this.ngrxStore.dispatch(UiActions.initialize({ uiState }));
          }
        })
      ),
      this.get(appName + '_auth').pipe(
        tap((authState) => {
          if (authState) {
            this.ngrxStore.dispatch(AuthActions.initialize({ authState }));
          }
        })
      ),
      this.get(appName + '_customer').pipe(
        tap((customerState) => {
          if (customerState) {
            this.ngrxStore.dispatch(CustomerActions.initialize({ customerState }));
          }
        })
      ),
      this.get(appName + '_policies').pipe(
        tap((policiesState) => {
          if (policiesState) {
            this.ngrxStore.dispatch(PoliciesActions.initialize({ policiesState }));
          }
        })
      ),
      this.get(appName + '_compares').pipe(
        tap((comparesState) => {
          if (comparesState) {
            this.ngrxStore.dispatch(ComparesActions.initialize({ comparesState }));
          }
        })
      ),
      this.get(appName + '_documents').pipe(
        tap((documentsState) => {
          if (documentsState) {
            this.ngrxStore.dispatch(DocumentsActions.initialize({ documentsState }));
          }
        })
      ),
      this.get(appName + '_safebox').pipe(
        tap((safeboxState) => {
          if (safeboxState) {
            this.ngrxStore.dispatch(SafeboxActions.initialize({ safeboxState }));
          }
        })
      ),
      this.get(appName + '_contacts').pipe(
        tap((contactsState) => {
          if (contactsState) {
            this.ngrxStore.dispatch(ContactsActions.initialize({ contactsState }));
          }
        })
      ),
    ]);
  }

  /**
   * Fetch data in localStorage
   *
   * @param selector key to fetch
   */
  public get(selector: string): Observable<any> {
    return from(this.storage.get(selector)).pipe(map((obj) => obj && JSON.parse(obj)));
  }

  /**
   * Set data in localStorage
   *
   * @param selector defined key
   * @param value data to store
   */
  public set(selector: string, value: any): Observable<any> {
    return of(this.storage.set(selector, JSON.stringify(value)));
  }

  /**
   * Remove data in localStorage
   *
   * @param selector key to select
   */
  public remove(selector: string): Observable<any> {
    return from(this.storage.remove(selector));
  }

  /**
   * Remove all data from localStorage
   */
  public clear(): Observable<void> {
    return from(this.storage.clear());
  }

  /**
   * Update common data in store
   */
  public reloadStaticData(): void {
    this.ngrxStore.dispatch(UiActions.reload());
  }

  /**
   * Update all application data
   *
   * @param authStateObj auth state
   */
  public refreshAllData(authStateObj: AuthState): void {
    this.ngrxStore.dispatch(AuthActions.reloadAll({ authState: authStateObj }));
    this.setIsRefreshingData(true);
  }

  public removeAllPersistentStores() {
    this.storage.clear().then();
  }

  /* ============================================================
      ## AclsService Actions
     ============================================================*/

  /**
   * Return an observable of the agency's acls
   *
   * @param id customer's id - optional
   */
  public getAcls(id?: number): Observable<Acls> {
    const acls$ = this.ngrxStore.pipe(select(acls));

    if (id) {
      return acls$.pipe(map((aclsMap) => aclsMap[id]));
    } else {
      return this.getIsSignupProcess().pipe(
        switchMap((flag) => {
          if (flag) {
            return this.getCustomersIdList([]).pipe(map((ids) => ids[0]));
          } else {
            return this.getAuthData().pipe(map((authState) => authState.customerId));
          }
        }),
        switchMap((resId) =>
          resId ? acls$.pipe(map((aclsMap) => aclsMap[resId])) : acls$.pipe(map((aclsMap) => aclsMap[0]))
        )
      );
    }
  }

  /**
   * Set acls in ngrxStore
   *
   * @param aclsObj the agency's acls object
   */
  public setAcls(aclsObj: { [id: number]: Acls }): void {
    this.ngrxStore.dispatch(PermissionsActions.setAcls({ acls: aclsObj }));
  }

  /**
   * Return an observable of agency's lock property
   */
  public getPermissionsLock(): Observable<number> {
    return this.ngrxStore.pipe(select(permissionsLock));
  }

  /**
   * Set permissions
   *
   * @param date time stamp
   */
  public setPermissionsLock(date: number | null): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setPermissionsLock({ lock: date }));
    return of(true);
  }

  /* ============================================================
      ## AuthService Actions
     ============================================================*/

  /**
   * Store auth data
   *
   * @param data Auth State data
   */
  public login(data: {
    authData: AuthState;
    reloadAll: boolean;
    noNotifications?: boolean;
    reset?: boolean;
    cb?: () => void;
  }): void {
    this.set(this.appName + '_setNotifications', !data.noNotifications);
    this.setDisplayNomadLoader(true);
    this.setIsLoggedIn(true);

    this.getIsLoadingTranslation()
      .pipe(
        filter((val) => !val),
        first(),
        tap(() => {
          this.setIsRefreshingData(true);
        }),
        delay(300),
        tap(() => this.ngrxStore.dispatch(AuthActions.login(data)))
      )
      .subscribe();
  }

  /**
   * Store auth data on switch mode pro
   *
   * @param data Auth State data
   */
  public loginOnSwitchMode(data: { authData: AuthState }): void {
    this.ngrxStore.dispatch(AuthActions.loginOnSwitchMode(data));
  }

  /**
   * Store auth data on switch mode pro
   *
   * @param userToken BO user's token
   * @param customerId customer's id
   * @param agencyId agency's id
   */
  public loginUserAsCustomer(userToken: string, customerId: number, agencyId: number): void {
    this.logout(true);
    this.ngrxStore.dispatch(AuthActions.loginUserAsCustomer({ userToken, customerId, agencyId }));
  }

  /**
   * Remove auth data but rememberMe and email, reset customer, policies and compares stores
   *
   * @param noRedirection if true no redirection to welcome page (use fo login user as customer feature
   */
  public logout(noRedirection?: boolean): void {
    this.setDisplayNomadLoader(true);
    this.resetGlobalCustomerData();
    this.ngrxStore.dispatch(AuthActions.logout({ noRedirection }));
  }

  /**
   * Reset Customer, Policies and Compares stored data
   */
  public resetGlobalCustomerData(): void {
    this.resetCustomerData();
    this.resetPoliciesData();
    this.resetComparesData();
    this.resetDocumentsData();
    this.resetContactsData();
    this.resetSafeboxData();
  }

  /**
   * Reset Customer stored data
   */
  public resetContactsData() {
    this.ngrxStore.dispatch(ContactsActions.reset());
  }

  /**
   * Reset Customer stored data
   */
  public resetDocumentsData() {
    this.ngrxStore.dispatch(DocumentsActions.reset());
  }

  /**
   * Reset Customer stored data
   */
  public resetCustomerData() {
    this.ngrxStore.dispatch(CustomerActions.reset());
  }

  /**
   * Reset Policies stored data
   */
  public resetPoliciesData() {
    this.ngrxStore.dispatch(PoliciesActions.reset());
  }

  /**
   * Reset Compares stored data
   */
  public resetComparesData() {
    this.ngrxStore.dispatch(ComparesActions.reset());
  }

  /**
   * Return an observable of true value if customer is logged in
   */
  public getIsLoggedIn(): Observable<boolean> {
    return this.ngrxStore.pipe(select(isLoggedIn));
  }

  /**
   * set login state flag
   *
   * @param flag true if logged in
   */
  public setIsLoggedIn(flag: boolean) {
    this.ngrxStore.dispatch(AuthActions.setIsLoggedIn({ isLoggedIn: flag }));
  }

  /**
   * Return an observable of the auth state
   */
  public getAuthData(): Observable<AuthState> {
    return this.ngrxStore.pipe(select(selectAuthState));
  }

  /**
   * Return an observable of customerId
   */
  public getCustomerId(): Observable<number> {
    return this.ngrxStore.pipe(select(customerId));
  }

  public setRememberMe(rememberMeFlag: boolean): void {
    this.ngrxStore.dispatch(AuthActions.setRememberMe({ rememberMe: rememberMeFlag }));
  }

  /* ============================================================
      ## NetworkMonitorService Actions
    ============================================================*/

  /**
   * Return an observable of network status
   */
  public getNetworkStatus(): Observable<NetWorkStatus> {
    return this.ngrxStore.pipe(select(networkStatus));
  }

  /**
   * Set current network status
   *
   * @param state network state
   */
  public setNetworkStatus(state: NetWorkStatus): void {
    this.ngrxStore.dispatch(UiActions.setNetworkStatus({ networkStatus: state }));
  }

  /* ============================================================
      ## UI Actions
    ============================================================*/

  /**
   * Return an observable of the "set customer process completed" flag
   */
  public getCustomerCompletedFlag(): Observable<boolean> {
    return this.ngrxStore.pipe(select(isCustomerCompleted));
  }

  /**
   * Set current language to use
   *
   * @param flag true if set customer process is completed
   */
  public setCustomerCompletedFlag(flag: boolean): void {
    this.ngrxStore.dispatch(UiActions.setIsCustomerCompleted({ isCustomerCompleted: flag }));
  }

  /**
   * Return an observable of the current selected language
   */
  public getLang(): Observable<string> {
    return this.ngrxStore.pipe(select(languageSelected));
  }

  /**
   * Set current language to use
   *
   * @param lang language to set as current
   */
  public setLang(lang: string): void {
    this.ngrxStore.dispatch(UiActions.setLang({ lang }));
  }

  /**
   * Return an observable of the current state of translation service
   */
  public getIsLoadingTranslation(): Observable<boolean> {
    return this.getTranslationsLock().pipe(map((res) => !!res));
  }

  /**
   * Set current language to use
   *
   * @param lang language to set as current
   * @param noPoliciesUpdate if true avoid to launch policies update process
   * @param noUpdateLoaderIndicator if true don't update isRefreshing state
   */
  public updateLang(lang: string, noPoliciesUpdate?: boolean, noUpdateLoaderIndicator?: boolean): void {
    this.ngrxStore.dispatch(UiActions.updateLang({ lang, noPoliciesUpdate, noUpdateLoaderIndicator }));
  }

  /**
   * Return an observable of activated page
   */
  public getActivatedPage(): Observable<string> {
    return this.ngrxStore.pipe(select(activatedPage));
  }

  /**
   * Set activated page in store
   *
   * @param page activated page's name
   */
  public setActivatedPage(page: string): void {
    this.ngrxStore.dispatch(UiActions.setActivatedPage({ activatedPage: page }));
  }

  /**
   * Return an observable of the isSignupProcess flag
   */
  public getIsSignupProcess(): Observable<boolean> {
    return this.ngrxStore.pipe(select(isSignupProcess));
  }

  /**
   * Set isSignupProcess flag
   *
   * @param isSignupProcess true if processing a new signup
   */
  // tslint:disable-next-line:no-shadowed-variable
  public setIsSignupProcess(isSignupProcess: boolean): void {
    this.getIsSignupProcess()
      .pipe(first())
      .subscribe((result) => {
        if (result !== isSignupProcess) {
          this.ngrxStore.dispatch(UiActions.setIsSignupProcess({ isSignupProcess }));
        }
      });
  }

  /**
   * Return an observable of the isProSelected flag
   */
  public getIsProSelected(): Observable<boolean> {
    return this.ngrxStore.pipe(select(isProSelected));
  }

  /**
   * Set isPro flag
   *
   * @param isPro true if customer is of type employee
   */
  public setIsProSelected(isPro: boolean): void {
    this.getIsProSelected()
      .pipe(first())
      .subscribe((result) => {
        if (result !== isPro) {
          this.ngrxStore.dispatch(UiActions.setIsProSelected({ isPro }));
        }
      });
  }

  /**
   * Set isPro flag
   *
   * @param isDisplayed true if Pro selector is displayed
   */
  public setIsProSelectorDisplayed(isDisplayed: boolean): void {
    this.ngrxStore.dispatch(UiActions.setIsProSelectorDisplayed({ isProSelectorDisplayed: isDisplayed }));
  }

  /**
   * Return an observable of the isProSelected flag
   */
  public getIsProSelectorDisplayed(): Observable<boolean> {
    return this.ngrxStore.pipe(select(isProSelectorDisplayed));
  }

  /**
   * Return an observable of the AppStateReady flag
   */
  public getIsAppStateReady(): Observable<boolean> {
    return this.ngrxStore.pipe(select(isAppStateReady));
  }

  /**
   * Set AppStateReady flag
   *
   * @param isReady true if App fully initialize
   */
  public setIsAppStateReady(isReady: boolean): void {
    this.ngrxStore.dispatch(UiActions.setIsAppStateReady({ isReady }));
  }

  /**
   * Return an observable of the isRefreshingData flag
   */
  public getIsRefreshingData(): Observable<boolean> {
    return this.ngrxStore.pipe(select(isRefreshingData));
  }

  /**
   * Set AppStateReady flag
   *
   * @param refreshState true if refresh process running
   */
  public setIsRefreshingData(refreshState: boolean): void {
    this.ngrxStore.dispatch(UiActions.setRefreshState({ refreshState }));
  }

  /**
   * Return an observable of global loader state
   */
  public getIsLoading(): Observable<boolean> {
    return this.ngrxStore.pipe(select(isLoading));
  }

  /**
   * Set loading flag in ngrxStore
   *
   * @param loading true if loader displayed
   */
  public setIsLoading(loading: boolean): void {
    this.ngrxStore.dispatch(UiActions.setLoaderState({ display: loading }));
  }

  /**
   * Return an observable of nomad loader state
   */
  public getDisplayNomadLoader(): Observable<boolean> {
    return this.ngrxStore.pipe(select(displayNomadLoader));
  }

  /**
   * Set loading flag in ngrxStore
   *
   * @param state true if loader displayed
   */
  public setDisplayNomadLoader(state: boolean): void {
    this.ngrxStore.dispatch(UiActions.setNomadLoaderState({ display: state }));
  }

  /* ============================================================
      ## AgencyService related Actions
    ============================================================*/

  /**
   * Return an observable of agency's lock property
   */
  public getAgencyLock(): Observable<number> {
    return this.ngrxStore.pipe(select(agencyLock));
  }

  /**
   * Set style
   *
   * @param date time stamp
   */
  public setAgencyLock(date: number | null): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setAgencyLock({ lock: date }));
    return of(true);
  }

  /**
   * Return an observable of agency's lock property
   */
  public getSlidersLock(): Observable<number> {
    return this.ngrxStore.pipe(select(slidersLock));
  }

  /**
   * Set style
   *
   * @param date time stamp
   */
  public setSlidersLock(date: number | null): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setSlidersLock({ lock: date }));
    return of(true);
  }

  /**
   * Return an observable of style
   */
  public getStyle(): Observable<FrontTheme> {
    return this.ngrxStore.pipe(select(style));
  }

  /**
   * Set style
   *
   * @param theme agency's style
   */
  public setStyle(theme: FrontTheme): void {
    this.ngrxStore.dispatch(UiActions.setStyle({ style: theme }));
  }

  /**
   * Return an observable of the agency
   */
  public getAgency(): Observable<Agency> {
    return this.ngrxStore.pipe(select(agency));
  }

  /**
   * Return an observable of the agency's name
   */
  public getAgencyName(): Observable<string> {
    return this.ngrxStore.pipe(select(agencyName));
  }

  /**
   * Set agency in ngrxStore
   *
   * @param agencyObj the agency object
   */
  public setAgency(agencyObj: Agency): void {
    this.ngrxStore.dispatch(AgencyActions.setAgency({ agency: agencyObj }));
  }

  /**
   * Set agency's address in ngrxStore
   *
   * @param address the agency's address object
   */
  public setAgencyAddress(address: DefaultAddress): void {
    this.ngrxStore.dispatch(AgencyActions.setDefaultAddress({ defaultAddress: address }));
  }

  /**
   * Return an observable of the agency's address
   */
  public getAgencyAddress(): Observable<DefaultAddress> {
    return this.ngrxStore.pipe(select(agencyAddress));
  }

  /**
   * Return an observable of the agency's contacts
   */
  public getContacts(): Observable<Contact[]> {
    return this.ngrxStore.pipe(select(contacts));
  }

  /**
   * Set contacts of agency
   *
   * @param contactsObj an array of agency's contacts
   */
  public setContacts(contactsObj: Contact[]): void {
    this.ngrxStore.dispatch(AgencyActions.setContacts({ contacts: contactsObj }));
  }

  /**
   * Return an observable of isAgencyAdmin flag
   */
  public getIsAgencyAdmin(): Observable<boolean> {
    return this.ngrxStore.pipe(
      select(agency),
      map((agencyRep) => agencyRep.is_admin === 1)
    );
  }

  /**
   * Return an observable of isAgencyMestria flag
   */
  public getIsAgencyMestria(): Observable<boolean> {
    return this.ngrxStore.pipe(
      select(agency),
      map((agencyRep) => agencyRep.id === 4)
    );
  }

  /**
   * Return an observable of the private agency's logo
   */
  public getPrivateLogo(): Observable<string> {
    return this.ngrxStore.pipe(select(privateLogo));
  }

  /**
   * Return an observable of the public agency's logo
   */
  public getPublicLogo(): Observable<string> {
    return this.ngrxStore.pipe(select(publicLogo));
  }

  /**
   * set admin logo
   *
   * @param logo admin svg log
   */
  public setAdminLogo(logo: string): void {
    this.ngrxStore.dispatch(UiActions.setAdminLogo({ logo }));
  }

  /**
   * Return an observable of the private agency's logo
   */
  public getAdminLogo(): Observable<string> {
    return this.ngrxStore.pipe(select(adminLogo));
  }

  /**
   * Return an observable of the sliders
   *
   * @param slidersType public or private
   * @param lang selected language
   */
  public getSliders(slidersType: SliderType, lang: string): Observable<Slider[]> {
    return this.ngrxStore.pipe(
      select(slidersType === SliderType.Public ? publicSliders : privateSliders),
      map((sliders) => sliders[lang])
    );
  }

  /**
   * Set public sliders
   *
   * @param sliders public sliders array
   * @param slidersType public or private
   * @param lang sliders language
   */
  public setSliders(sliders: Slider[], slidersType: SliderType, lang: string): void {
    switch (slidersType) {
      case SliderType.Public:
        this.ngrxStore.dispatch(AgencyActions.setPublicSliders({ lang, sliders }));
        break;
      case SliderType.Private:
        this.ngrxStore.dispatch(AgencyActions.setPrivateSliders({ lang, sliders }));
        break;
    }
  }

  public resetSliders(type?: SliderType): void {
    this.ngrxStore.dispatch(AgencyActions.resetSliders({ sliderType: type }));
  }

  /* ============================================================
      ## CustomerService Actions
    ============================================================*/

  /**
   * Return an observable of selected member
   */
  public getSelectedMember(): Observable<CustomerFamilyMember | Customer> {
    return this.ngrxStore.pipe(select(selectedMember));
  }

  /**
   * Set selected member in store
   *
   * @param member selected member
   */
  public setSelectedMember(member: CustomerFamilyMember | Customer): void {
    this.ngrxStore.dispatch(UiActions.setSelectedMember({ selectedMember: member }));
  }

  /**
   * Return an observable of customers
   */
  public getCustomers(): Observable<{ [id: number]: StoredCustomer }> {
    return this.ngrxStore.pipe(select(customers));
  }

  /**
   * Return an observable of a customer
   *
   * @param id customer's id
   */
  public getCustomer(id: number): Observable<StoredCustomer> {
    return this.ngrxStore.pipe(
      select(customers),
      mergeMap((customersObj: { [id: string]: StoredCustomer }) => of(customersObj[id]))
    );
  }

  /**
   * Returns an array of all stored customers ids but employee customer type
   *
   * @param except an array of customerTypeId that we don't want to retrieve
   */
  public getCustomersIdList(except: number[]): Observable<number[]> {
    return this.ngrxStore.pipe(
      select(customers),
      mergeMap((customersObj) => {
        const ids = [];
        _.forEach(customersObj, (customer) => {
          if (!except.includes(customer.customer.customer_type_id)) {
            ids.push(customer.customer.id);
          }
        });
        return of(ids);
      })
    );
  }

  /**
   * Returns an array of all stored customers token
   *
   * @param except an array of customerTypeId that we don't want to retrieve
   */
  public getCustomersList(except: number[]): Observable<{ [id: number]: Customer }> {
    return this.ngrxStore.pipe(
      select(customers),
      mergeMap((customersObj) => {
        const customersRep = {};
        _.forEach(customersObj, (customer) => {
          if (!except.includes(customer.customer.customer_type_id)) {
            customersRep[customer.customer.id] = customer.customer;
          }
        });
        return of(customersRep);
      })
    );
  }

  /**
   * Set a customer in customer store
   *
   * @param customerData a customer for store object
   */
  public setCustomer(customerData: StoredCustomer): void {
    this.ngrxStore.dispatch(CustomerActions.setCustomer({ storedCustomer: customerData }));
  }

  /**
   * Return an observable of all family members and employee
   */
  public getMembers(): Observable<CustomerFamilyMember[]> {
    return this.ngrxStore.pipe(select(members));
  }

  /**
   * Set Api token of selected member
   *
   * @param membersObj a customer for store object
   */
  public setMembers(membersObj: CustomerFamilyMember[]): void {
    this.ngrxStore.dispatch(CustomerActions.setMembers({ members: membersObj }));
  }

  /**
   * Set pairing code of customer in store
   *
   * @param id customer ID
   * @param pairingCode paring code
   */
  public setCustomerPairingCode(id: number, pairingCode: string) {
    this.ngrxStore.dispatch(CustomerActions.setPairingCode({ id, pairingCode }));
  }

  /**
   * Return an observable of the customer's bank data
   *
   * @param id customer ID
   */
  public getCustomerBank(id: number): Observable<CustomerBank> {
    return this.ngrxStore.pipe(
      select(customers),
      filter((val) => !_.isEmpty(val)),
      mergeMap((customersObj: { [id: number]: StoredCustomer }) =>
        id !== 0 ? of(customersObj[id].customer.bank_account) : of(null)
      )
    );
  }

  /**
   * Return an observable of the customer's default address
   *
   * @param id customer ID
   */
  public getCustomerDefaultAddress(id: number): Observable<DefaultAddress> {
    return this.ngrxStore.pipe(
      select(customers),
      mergeMap((customersObj: { [id: number]: StoredCustomer }) => of(customersObj[id].customer.default_address))
    );
  }

  /**
   * Return an observable of the customer's items
   *
   * @param id customer ID
   */
  public getCustomerItems(id: number): Observable<{ [id: string]: ItemTemplateFO }> {
    return this.ngrxStore.pipe(
      select(customers),
      filter((val) => !_.isEmpty(val)),
      mergeMap((customersObj: { [id: number]: StoredCustomer }) =>
        id !== 0 ? of(customersObj[id].customer.items) : of(null)
      )
    );
  }

  /**
   * Return an observable of customer lock
   */
  public getCustomerLock(): Observable<number> {
    return this.ngrxStore.pipe(select(customerLock));
  }

  /**
   * Set companies lock in Customer store
   *
   * @param date a time stamp
   */
  public setCustomerLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setCustomerLock({ lock: date }));
    return of(true);
  }

  /* ============================================================
      ## PolicyService And CompareService Actions
    ============================================================*/

  /**
   * Return an observable of a map of compares by token
   *
   * @param id customer ID
   */
  public getCompares(
    id?: number
  ): Observable<{ [lang: string]: CompareListItem[] } | { [id: number]: { [lang: string]: CompareListItem[] } }> {
    return this.ngrxStore.pipe(
      select(compares),
      mergeMap((comparesList: { [id: number]: { [lang: string]: CompareListItem[] } }) =>
        id ? of(comparesList[id]) : of(comparesList)
      )
    );
  }

  /**
   * Set Compares in store
   *
   * @param comparesData a map of compares
   */
  public setCompares(comparesData: { [id: number]: { [lang: string]: CompareListItem[] } }): void {
    this.ngrxStore.dispatch(ComparesActions.setCompares({ comparesList: comparesData }));
  }

  /**
   * Return an observable of a compare
   *
   * @param compareId compare ID
   */
  public getCompare(compareId: number): Observable<CompareFO> {
    return combineLatest([this.getLang(), this.ngrxStore.pipe(select(comparesItems))]).pipe(
      mergeMap(([lang, comparesItemsResp]) => of(comparesItemsResp[lang] && comparesItemsResp[lang][compareId]))
    );
  }

  /**
   * Set compare in Compares Store
   *
   * @param compareObj compare object
   * @param lang selected language
   */
  public setCompare(compareObj: CompareFO, lang: string) {
    this.ngrxStore.dispatch(ComparesActions.setCompare({ compare: compareObj, lang }));
  }

  /**
   * Return an observable of a map of policies and mandates by customer id
   */
  public getPoliciesAndMandates(): Observable<{ [id: string]: { [lang: string]: Array<PolicyFO | Mandate> } }> {
    return this.ngrxStore.pipe(select(policiesAndMandates), distinctUntilChanged());
  }

  /**
   * Return an observable of mandates array
   *
   * @param id customer ID
   */
  public getMandates(id: number): Observable<Mandate[]> {
    return combineLatest([this.getLang(), this.getPoliciesAndMandates()]).pipe(
      mergeMap(([lang, policiesAndMandatesObj]) => {
        const mandates = _.filter(policiesAndMandatesObj[id] && policiesAndMandatesObj[id][lang], {
          is_mandate: true,
        }) as Mandate[];

        return of(mandates);
      })
    );
  }

  /**
   * Set policies and mandates in Policies store
   *
   * @param policiesData a map of policies and mandates
   */
  public setPoliciesAndMandates(policiesData: { [id: number]: { [lang: string]: Array<PolicyFO | Mandate> } }): void {
    this.ngrxStore.dispatch(PoliciesActions.setPoliciesAndMandates({ policiesList: policiesData }));
  }

  /**
   * Return an observable of policy according to policyId
   *
   * @param policyId policy's id
   */
  public getPolicy(policyId: number): Observable<PolicyFO> {
    return combineLatest([this.getLang(), this.ngrxStore.pipe(select(policiesItems))]).pipe(
      mergeMap(([lang, policies]) => of(policies[lang] && policies[lang][policyId]))
    );
  }

  /**
   * set a policy in Store
   *
   * @param policyObj policy object
   * @param lang selected language
   */
  public setPolicy(policyObj: PolicyFO, lang: string): void {
    this.ngrxStore.dispatch(PoliciesActions.setPolicy({ policy: policyObj, lang }));
  }

  /**
   * Return an observable of a map of private insuranceTypes by language
   *
   * @param lang selected language
   */
  public getInsuranceTypesPrivate(lang: string): Observable<{ [id: string]: InsuranceType }> {
    return this.ngrxStore.pipe(
      select(insuranceTypesPrivate),
      map((res) => res[lang])
    );
  }

  /**
   * Return an observable of a map of corporate insuranceTypes by language
   *
   * @param lang selected language
   */
  public getInsuranceTypesCorporate(lang: string): Observable<{ [id: string]: InsuranceType }> {
    return this.ngrxStore.pipe(
      select(insuranceTypesCorporate),
      map((res) => res[lang])
    );
  }

  /**
   * Return an observable of a map of insuranceTypes
   */
  public getInsuranceTypes(): Observable<{
    private: { [lang: string]: { [id: string]: InsuranceType } };
    corporate: { [lang: string]: { [id: string]: InsuranceType } };
  }> {
    return this.ngrxStore.pipe(select(insuranceTypes));
  }

  /**
   * Set insuranceTypes in Policies store
   *
   * @param insuranceTypesObj a map of all insurance types by language
   */
  public setInsuranceTypes(insuranceTypesObj: {
    private: { [lang: string]: { [id: string]: InsuranceType } };
    corporate: { [lang: string]: { [id: string]: InsuranceType } };
  }): void {
    this.ngrxStore.dispatch(PoliciesActions.setInsuranceTypes({ insuranceTypes: insuranceTypesObj }));
  }

  /**
   * Return an observable of insurance types lock property
   */
  public getInsuranceTypesLock(): Observable<number> {
    return this.ngrxStore.pipe(select(insuranceTypesLock));
  }

  /**
   * Set insurance types lock
   *
   * @param date time stamp
   */
  public setInsuranceTypesLock(date: number | null): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setInsuranceTypesLock({ lock: date }));
    return of(true);
  }

  /**
   * Return an observable of an array of company objects
   */
  public getCompanies(): Observable<{ [id: string]: Company }> {
    return this.ngrxStore.pipe(select(companies));
  }

  /**
   * Set companies in Policies store
   *
   * @param companiesObj an array of company objects
   */
  public setCompanies(companiesObj: { [id: string]: Company }): void {
    this.ngrxStore.dispatch(PoliciesActions.setCompanies({ companies: companiesObj }));
  }

  /**
   * Return an observable of a map of insurance types for a customer type
   *
   * @param customerTypeId customer type id
   */
  public getInsuranceTypeIdByCustomerType(customerTypeId: number): Observable<any> {
    return this.ngrxStore.pipe(
      select(companiesByCustomerTypeByInsuranceType),
      map((resp) => Object.keys(resp[customerTypeId.toString()]))
    );
  }

  /**
   * Return an observable of an array of company objects
   *
   * @param customerTypeId customer type id
   * @param insuranceTypeId insurance type id
   */
  public getCompaniesByCustomerTypeByInsuranceType(
    customerTypeId: number,
    insuranceTypeId: number
  ): Observable<number[]> {
    return this.ngrxStore.pipe(
      select(companiesByCustomerTypeByInsuranceType),
      map((resp) => resp[customerTypeId.toString()][insuranceTypeId.toString()])
    );
  }

  /**
   * Set CompaniesByCustomerTypeByInsuranceType in Policies store
   *
   * @param obj a map of companies id by customerType and by insuranceType
   */
  public setCompaniesByCustomerTypeByInsuranceType(obj: { [id: string]: { [id: string]: number[] } }): void {
    this.ngrxStore.dispatch(
      PoliciesActions.setCompaniesByCustomerTypeByInsuranceType({ companiesByCustomerTypeByInsuranceType: obj })
    );
  }

  /**
   * Return an observable of companies lock
   */
  public getCompaniesLock(): Observable<number> {
    return this.ngrxStore.pipe(select(companiesLock));
  }

  /**
   * Set companies lock in Policies store
   *
   * @param date a time stamp
   */
  public setCompaniesLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setCompaniesLock({ lock: date }));
    return of(true);
  }

  /**
   * Return an observable of policies lock
   */
  public getPoliciesAndMandatesLock(): Observable<number> {
    return this.ngrxStore.pipe(select(policiesAndMandatesLock));
  }

  /**
   * Set policies lock in Policies store
   *
   * @param date a time stamp
   */
  public setPoliciesAndMandatesLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setPoliciesAndMandatesLock({ lock: date }));
    return of(true);
  }

  /**
   * Return an observable of compares lock
   */
  public getComparesLock(): Observable<number> {
    return this.ngrxStore.pipe(select(comparesLock));
  }

  /**
   * Set compares lock in Policies store
   *
   * @param date a time stamp
   */
  public setComparesLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setComparesLock({ lock: date }));
    return of(true);
  }

  /* ============================================================
    ## DocumentsService Actions
  ============================================================*/

  /**
   * Return an observable of a map of DocumentCategoryItem objects
   */
  public getDocuments(): Observable<{ [id: string]: Document }> {
    return this.ngrxStore.pipe(select(documents));
  }

  /**
   * Set DocumentCategories in Policies store
   *
   * @param documentsMap an map of documents
   */
  public setDocuments(documentsMap: { [id: string]: Document }): void {
    this.ngrxStore.dispatch(DocumentsActions.setDocuments({ documents: documentsMap }));
  }

  /**
   * Return an observable of a map of DocumentCategoryItem objects
   */
  public getDocumentCategories(): Observable<DocumentCategoryItem[]> {
    return this.ngrxStore.pipe(select(documentCategories));
  }

  /**
   * Set DocumentCategories in Policies store
   *
   * @param documentCategoriesObj a map of DocumentCategoryItem objects
   */
  public setDocumentCategories(documentCategoriesObj: DocumentCategoryItem[]): void {
    this.ngrxStore.dispatch(
      DocumentsActions.setDocumentCategories({ categoriesByInsuranceType: documentCategoriesObj })
    );
  }

  /**
   * Return an observable of DocumentCategoryItem lock
   */
  public getDocumentCategoriesLock(): Observable<number> {
    return this.ngrxStore.pipe(select(documentCategoriesLock));
  }

  /**
   * Set DocumentCategories lock in Policies store
   *
   * @param date a time stamp
   */
  public setDocumentCategoriesLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setDocumentCategoriesLock({ lock: date }));
    return of(true);
  }

  /**
   * Return an observable of Documents lock
   */
  public getDocumentsLock(): Observable<number> {
    return this.ngrxStore.pipe(select(documentsLock));
  }

  /**
   * Set Documents lock in Documents store
   *
   * @param date a time stamp
   */
  public setDocumentsLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setDocumentsLock({ lock: date }));
    return of(true);
  }

  /* ============================================================
  ## SafeboxService Actions
  ============================================================*/

  /**
   * Return an observable of an array of safebox types
   */
  public getSafeboxTypes(): Observable<{ [lang: string]: SafeboxType[] }> {
    return this.ngrxStore.pipe(select(safeboxTypes));
  }

  /**
   * Set safebox types in Safebox store
   *
   * @param safeboxTypesArr a map of all insurance types
   * @param lang selected language
   */
  public setSafeboxTypes(safeboxTypesArr: SafeboxType[], lang: string): void {
    this.ngrxStore.dispatch(SafeboxActions.setSafeboxTypes({ safeboxTypes: safeboxTypesArr, lang }));
  }

  public resetSafeboxTypes(): void {}

  /**
   * Return an observable of safebox types lock
   */
  public getSafeboxTypesLock(): Observable<number> {
    return this.ngrxStore.pipe(select(safeboxTypesLock));
  }

  /**
   * Set safebox types lock
   *
   * @param date a time stamp
   */
  public setSafeboxTypesLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setSafeboxTypesLock({ lock: date }));
    return of(true);
  }

  /**
   * Return an observable of an array of safebox types
   */
  public getSafeboxes(): Observable<{ [id: string]: Safebox }> {
    return this.ngrxStore.pipe(select(safeboxes));
  }

  /**
   * Set safeboxes in Safebox store
   *
   * @param safeboxesMap a map of all safeboxes
   */
  public setSafeboxes(safeboxesMap: { [id: string]: Safebox }): void {
    this.ngrxStore.dispatch(SafeboxActions.setSafeboxes({ safeboxes: safeboxesMap }));
  }

  /**
   * Return an observable of safebox lock
   */
  public getSafeboxesLock(): Observable<number> {
    return this.ngrxStore.pipe(select(safeboxesLock));
  }

  /**
   * Set safebox lock
   *
   * @param date a time stamp
   */
  public setSafeboxesLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setSafeboxesLock({ lock: date }));
    return of(true);
  }

  /**
   * Add or update a safebox in Safebox store
   *
   * @param safeboxObj safebox object
   */
  public addSafebox(safeboxObj: Safebox): void {
    this.ngrxStore.dispatch(SafeboxActions.addSafebox({ safebox: safeboxObj }));
  }

  /**
   * Remove a safebox from Safebox store
   *
   * @param id safebox id
   */
  public removeSafebox(id: number): void {
    this.ngrxStore.dispatch(SafeboxActions.removeSafebox({ safeboxId: id }));
  }

  /**
   * Reset safebox stored data
   */
  public resetSafeboxData() {
    this.ngrxStore.dispatch(SafeboxActions.reset());
  }

  /* ============================================================
  ## ContactsService Actions
  ============================================================*/

  /**
   * Return an observable of a map of chatItems Array
   */
  public getChatItems(): Observable<{ [customerId: number]: { new: number; items: ChatItem[] } }> {
    return this.ngrxStore.pipe(select(chatItemsMap));
  }

  /**
   * Return an observable of an array of chatItems
   *
   * @param id customer's id
   */
  public getChatItemsByCustomer(id: number): Observable<{ new: number; items: ChatItem[] }> {
    return this.getChatItems().pipe(map((res) => res[id] || { new: 0, items: [] }));
  }

  /**
   * Set safeboxes in Safebox store
   *
   * @param chatItems an map of chat items bby customerId
   */
  public setChatItems(chatItems: { [customerId: number]: { new: number; items: ChatItem[] } }): any {
    return this.ngrxStore.dispatch(ContactsActions.setChatItems({ chatItemsMap: chatItems }));
  }

  /**
   * Return an observable of an array of notifications
   */
  public getNotifications(): Observable<NotificationItem[]> {
    return this.ngrxStore.pipe(select(notifications));
  }

  /**
   * Set safeboxes in Safebox store
   *
   * @param notificationsArr an array of notifications
   */
  public setNotifications(notificationsArr: NotificationItem[]): any {
    return this.ngrxStore.dispatch(ContactsActions.setNotifications({ notifications: notificationsArr }));
  }

  /**
   * Add or update notifications in Contacts store
   *
   * @param notificationsArr an array of notification objects to add
   */
  public addNotifications(notificationsArr: NotificationItem[]): void {
    this.ngrxStore.dispatch(ContactsActions.addNotifications({ notifications: notificationsArr }));
  }

  /**
   * Return an observable of chat items lock
   */
  public getChatItemsLock(): Observable<number> {
    return this.ngrxStore.pipe(select(chatItemsLock));
  }

  /**
   * Set chat items types lock
   *
   * @param date a time stamp
   */
  public setChatItemsLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setChatItemsLock({ lock: date }));
    return of(true);
  }

  /* ============================================================
  ## Locks Actions
  ============================================================*/

  /**
   * Return an observable of constants lock
   */
  public getConstantsLock(): Observable<number> {
    return this.ngrxStore.pipe(select(constantsLock));
  }

  /**
   * Set constants lock
   *
   * @param date a time stamp
   */
  public setConstantsLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setConstantsLock({ lock: date }));
    return of(true);
  }

  /**
   * Return an observable of foreign tables lock
   */
  public getForeignTablesLock(): Observable<number> {
    return this.ngrxStore.pipe(select(foreignTablesLock));
  }

  /**
   * Set foreign tables lock
   *
   * @param date a time stamp
   */
  public setForeignTablesLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setForeignTablesLock({ lock: date }));
    return of(true);
  }

  /**
   * Return an observable of sprite lock
   */
  public getSpriteLock(): Observable<number> {
    return this.ngrxStore.pipe(select(spriteLock));
  }

  /**
   * Set sprite lock
   *
   * @param date a time stamp
   */
  public setSpriteLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setSpriteLock({ lock: date }));
    return of(true);
  }

  /**
   * Return an observable of statuses lock
   */
  public getStatusesLock(): Observable<number> {
    return this.ngrxStore.pipe(select(statusesLock));
  }

  /**
   * Set statuses lock
   *
   * @param date a time stamp
   */
  public setStatusesLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setStatusesLock({ lock: date }));
    return of(true);
  }

  /**
   * Return an observable of roles lock
   */
  public getRolesLock(): Observable<number> {
    return this.ngrxStore.pipe(select(rolesLock));
  }

  /**
   * Set roles lock
   *
   * @param date a time stamp
   */
  public setRolesLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setRolesLock({ lock: date }));
    return of(true);
  }

  /**
   * Return an observable of itemTemplates lock
   */
  public getItemTemplatesLock(): Observable<number> {
    return this.ngrxStore.pipe(select(itemTemplatesLock));
  }

  /**
   * Set itemTemplates lock
   *
   * @param date a time stamp
   */
  public setItemTemplatesLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setItemTemplatesLock({ lock: date }));
    return of(true);
  }

  /**
   * Return an observable of translations lock
   */
  public getTranslationsLock(): Observable<number> {
    return this.ngrxStore.pipe(select(translationsLock));
  }

  /**
   * Set translations lock
   *
   * @param date a time stamp
   */
  public setTranslationsLock(date: number): Observable<boolean> {
    this.ngrxStore.dispatch(LocksActions.setTranslationLock({ lock: date }));
    return of(true);
  }
}
