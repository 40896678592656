import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';

import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { Customer, CustomerFamilyMember } from '../../models/customer.model';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';

@Component({
  selector: 'gfl-member-connexion-data',
  templateUrl: './member-connexion-data.component.html',
  styleUrls: ['./member-connexion-data.component.scss'],
})
export class MemberConnexionDataComponent implements OnInit, OnDestroy {
  @Output() openResetPasswordModal: EventEmitter<any> = new EventEmitter();
  @Output() getPairingCode: EventEmitter<any> = new EventEmitter();
  @Input() acls: Acls;
  @Input() style: FrontTheme;
  @Input() isOffline: boolean;
  @Input() selectedMember: CustomerFamilyMember | Customer;
  public rememberMe: boolean;
  private customerEmail: string;
  private subscriptions: Subscription[] = [];
  readonly CUSTOMER_TYPE_ID_CORPORATE: number;

  /**
   * @ignore
   */
  constructor(
    public tools: ToolsService,
    private store: StoreService,
    public platform: Platform,
    public notificationSrv: NotificationService,
    public constantSrv: ConstantService
  ) {
    this.CUSTOMER_TYPE_ID_CORPORATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_CORPORATE');
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.store
      .getAuthData()
      .pipe(first())
      .subscribe((authState) => {
        this.rememberMe = authState.rememberMe;
        this.customerEmail = authState.customerLogin;
      });
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then();
  }

  /**
   * Emit actionOpenResetPasswordModal event
   */
  public actionOpenResetPasswordModal(): void {
    this.openResetPasswordModal.emit();
  }

  /**
   * Emit get pairing code event
   */
  public actionGetPairingCode(): void {
    this.getPairingCode.emit();
  }

  /**
   * Store rememberMe and customerEmail
   */
  public toggleRememberMe(): void {
    this.store.setRememberMe(this.rememberMe);
  }
}
