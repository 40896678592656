import { NgModule } from '@angular/core';
import { GflCoreModule } from '../gfl-core/gfl-core.module';
import { IonicModule } from '@ionic/angular';
import { StoreModule } from '@ngrx/store';

import { ComparesRoutingModule } from './compares-routing.module';
import { CompareComponentsModule } from './components/compare-components.module';
import { ComparesPage } from './pages/compares/compares.page';
import * as fromCompares from './reducers';

@NgModule({
  imports: [
    GflCoreModule,
    CompareComponentsModule,
    IonicModule,
    ComparesRoutingModule,
    StoreModule.forFeature(fromCompares.comparesFeatureKey, fromCompares.comparesReducer, {
      metaReducers: fromCompares.metaReducers,
    }),
  ],
  declarations: [ComparesPage],
})
export class ComparesPageModule {}
