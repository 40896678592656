import { Pipe, PipeTransform } from '@angular/core';

/**
 * usage: <div *ngFor="let obj{ key, value } of object | objectLoop">
 */
@Pipe({
  name: 'gflObjectLoop',
})
export class GflObjectLoopPipe implements PipeTransform {
  transform(object: any, ...args: any[]): any {
    const keys = Object.keys(object);
    const result = [];

    keys.forEach(key => {
      result.push({
        key,
        value: object[key],
      });
    });

    return result;
  }
}
