import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpCancelInterceptor } from './gfl-http-cancel.interceptor';
import { BoVersionInterceptor } from './gfl-bo-version.interceptor';
import { AppVersionInterceptor } from './gfl-app-version.interceptor';

/* Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: BoVersionInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AppVersionInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpCancelInterceptor, multi: true },
];
