import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ComparesState } from './index';

export const selectComparesState = createFeatureSelector<ComparesState>('compares');

export const compares = createSelector(
  selectComparesState,
  comparesState => comparesState.comparesList
);

export const comparesItems = createSelector(
  selectComparesState,
  comparesState => comparesState.comparesItems
);
