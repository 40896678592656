import { createAction, props } from '@ngrx/store';

import { CustomerFamilyMember, StoredCustomer } from '../models/customer.model';

import { CustomerState } from './index';

export const initialize = createAction(
  '[Store Service setNgrxStore] initialize CustomerState',
  props<{ customerState: CustomerState }>()
);

export const reset = createAction('[Store Service logout()] reset CustomerState');

export const setCustomer = createAction(
  '[Customer Service setCustomer()] set customer',
  props<{ storedCustomer: StoredCustomer }>()
);

export const setMembers = createAction(
  '[Customer Service setMembers()] set Family members',
  props<{ members: CustomerFamilyMember[] }>()
);

export const setPairingCode = createAction(
  '[Customer Service setCustomerPairingCode()] set pairing code',
  props<{ id: number; pairingCode: string }>()
);
