import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as _ from 'lodash';

import { forkJoin, Observable, Subscription } from 'rxjs';
import { finalize, first, map, switchMap } from 'rxjs/operators';

import { RequestCallService } from '../../../gfl-core/gfl-services/request-call.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { SelectInterfaceType, ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { AgencyService } from '../../../gfl-core/gfl-services/agency.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { NetworkMonitorService } from '../../../gfl-core/gfl-services/network-monitor.service';
import { Contact } from '../../../gfl-core/gfl-models/contact.model';
import { ItemTemplateFO } from '../../../gfl-core/gfl-models/item-template.model';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'gfl-request-call',
  templateUrl: './request-call.component.html',
  styleUrls: ['./request-call.component.scss'],
})
export class RequestCallComponent implements OnInit, OnDestroy {
  private agencyName$: Observable<string>;
  public contact$: Observable<Contact>;

  public interface: string;
  public countryPrefixOptions: Array<{ value: string; text: string; selected: boolean }>;
  public submitted = false;
  public timeSlots;
  public countryPrefix: string;
  public phone: string;
  public reservedTimeSlot: any;
  public isRequestSent = false;
  public isOffline: boolean;
  readonly ITEM_TEMPLATE_KEY_PHONE: string;
  public sendMail: boolean;
  private subscriptions: Subscription[] = [];

  /**
   * @ignore
   */
  constructor(
    private customerSrv: CustomerService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private notificationSrv: NotificationService,
    private requestCallSrv: RequestCallService,
    public tools: ToolsService,
    private store: StoreService,
    private modalCtrl: ModalController,
    private agencySrv: AgencyService,
    public platform: Platform,
    private network: NetworkMonitorService
  ) {
    this.ITEM_TEMPLATE_KEY_PHONE = 'phone';
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.agencyName$ = this.agencySrv.getAgencyName();
    this.contact$ = this.agencySrv.getMyContact();

    this.subscriptions.push(
      this.network.isOffline().subscribe((flag) => {
        this.isOffline = flag;
      })
    );

    this.interface = this.platform.is('iphone') ? SelectInterfaceType.ActionSheet : SelectInterfaceType.Popover;

    this.initialize();
  }

  /**
   * @ignore
   */
  ngOnDestroy() {
    this.tools.unsubscribeAll(this.subscriptions).then();
  }

  /**
   * Call GOFORLIFE
   */
  public confirmCall() {
    let phone;

    forkJoin([this.agencyName$.pipe(first()), this.contact$.pipe(first())])
      .pipe(
        switchMap(([agencyName, contact]) => {
          phone = contact.phone;

          return this.translate.get(['REQUEST_CALL.CALL', 'COMMON.BUTTON_CANCEL', 'COMMON.BUTTON_CALL'], {
            agency: agencyName,
            // eslint-disable-next-line id-blacklist
            number: phone,
          });
        })
      )
      .subscribe(async (result) => {
        const modal = await this.alertCtrl.create({
          message: result['REQUEST_CALL.CALL'],
          buttons: [
            {
              text: result['COMMON.BUTTON_CALL'],
              cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
              handler: () => {
                this.callAction(phone);
              },
            },
            {
              text: result['COMMON.BUTTON_CANCEL'],
              cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
              handler: () => {},
            },
          ],
        });
        await modal.present();
      });
  }

  /**
   * Save Call request data to BO
   */
  public saveRequestCall() {
    if (this.isOffline) {
      this.notificationSrv.showOfflineNotAvailable();
      return;
    }

    const phone = !this.phone || this.phone[0] !== '0' ? this.phone : this.phone.slice(1);

    // Run verifications
    if (!phone || phone.length !== 9) {
      return this.notificationSrv.showError({ message: 'SIGNUP.ERRORS.PHONE' });
    }
    if (!this.reservedTimeSlot) {
      return this.notificationSrv.showError({ message: 'REQUEST_CALL.ERRORS.TIMESLOT' });
    }

    this.tools.showLoader();

    const reservedTimeSlot = this.reservedTimeSlot;
    const dateRecall =
      reservedTimeSlot !== 'anytime' ? reservedTimeSlot.dateRecall.format('YYYY-MM-DD HH:mm:ss') : null;

    this.requestCallSrv
      .saveRequestCall(dateRecall, this.phone, this.countryPrefix)
      .pipe(finalize(() => this.tools.hideLoader()))
      .subscribe(
        () => {
          this.isRequestSent = true;
          this.notificationSrv.showSuccess({ message: 'REQUEST_CALL.SAVED' });

          if (this.sendMail) {
            this.sendMessageToAdvisor();
          }

          setTimeout(() => {
            this.modalCtrl.dismiss().then();
          }, 2000);
        },
        (err) => {
          this.tools.error('RequestCallComponent saveRequestCall()', err);
          this.notificationSrv.showError({ message: 'REQUEST_CALL.ERRORS.SAVE' });
        }
      );
  }

  /**
   * Close Modal
   */
  public closeModal() {
    this.modalCtrl.dismiss().then();
  }

  /**
   * Initialize properties
   */
  private initialize() {
    this.countryPrefixOptions = environment.PREFIX_COUNTRY;

    this.store
      .getAuthData()
      .pipe(
        first(),
        map((authState) => authState.customerId),
        switchMap((customerId) =>
          this.customerSrv.getCustomerItemsByItemTemplateKey(customerId, this.ITEM_TEMPLATE_KEY_PHONE)
        )
      )
      .subscribe((phoneItems) => {
        this.setPhoneAttributes(phoneItems);
      });

    this.store
      .getLang()
      .pipe(first())
      .subscribe((lang) => {
        moment.locale(lang);
        this.timeSlots = this.requestCallSrv.setTimeSlots();
      });
  }

  /**
   * Set countryPrefix and phone values
   *
   * @param phoneItems phone item templates
   */
  private setPhoneAttributes(phoneItems: ItemTemplateFO[]): void {
    let phoneStr;

    _.forEach(phoneItems[0].subitems, (subItem) => {
      _.forEach(subItem.countable_values, (obj) => {
        if (obj.value) {
          phoneStr = obj.value;
          return false;
        }
      });

      if (phoneStr) {
        return false;
      }
    });

    const phoneArr = phoneStr ? phoneStr.split('--') : [null, null];

    this.countryPrefix = phoneArr[0] || '+41';
    this.phone = phoneArr[1];
    this.reservedTimeSlot = null;
  }

  /**
   * open a web window (works well with native device)
   *
   * @param phoneNumber phone number
   */
  private async callAction(phoneNumber: string): Promise<void> {
    window.open('tel:' + phoneNumber, '_system');
  }

  /**
   * Email to the customer's advisor
   */
  private sendMessageToAdvisor(): void {
    this.agencySrv.sendMessageToAdvisor().subscribe();
  }
}
