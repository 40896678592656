export interface Picture {
  safebox_id?: number;
  document_id?: number;
  category_id: number;
  picture: string;
}

export enum AcceptedExtension {
  IMAGE = '.png,.jpg,.jpeg,.svg',
  ALL = '.png,.jpg,.jpeg,.svg,.doc,.docx,.pdf,.xls,.xlsx',
}
