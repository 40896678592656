import { Component, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';

import { PolicyFO } from '../../models/policy.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';

@Component({
  selector: 'gfl-policy-refund-simple',
  templateUrl: './policy-refund-simple.component.html',
  styleUrls: ['./policy-refund-simple.component.scss'],
})
export class PolicyRefundSimpleComponent {
  @Input() policy: PolicyFO;
  @Input() acls$: Observable<Acls>;

  constructor(
    public platform: Platform,
    public tools: ToolsService,
    private modalCtrl: ModalController,
    public translate: TranslateService
  ) {}

  /**
   * Close current modal
   */
  public closeModal() {
    this.modalCtrl.dismiss().then();
  }
}
