import { createAction, props } from '@ngrx/store';
import { ContactsState } from './contacts.reducer';
import { ChatItem, NotificationItem } from '../models/contacts.model';

export const initialize = createAction(
  '[Store Service setNgrxStore] initialize ContactsState',
  props<{ contactsState: ContactsState }>()
);

export const reset = createAction('[Store Service logout()] reset ContactsState');

export const setChatItems = createAction(
  '[Store Service setChatItems()] load ChatItems from BO',
  props<{ chatItemsMap: { [customerId: number]: { new: number; items: ChatItem[] } } }>()
);

export const setNotifications = createAction(
  '[Store Service setNotifications()] set Notifications',
  props<{ notifications: NotificationItem[] }>()
);

export const addNotifications = createAction(
  '[Store Service addNotification()] add or update a notification',
  props<{ notifications: NotificationItem[] }>()
);
