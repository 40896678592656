import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { filter, first, map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private store: StoreService, private translate: TranslateService) {}

  /**
   * initialize service
   */
  initService(): Promise<void> {
    // listen to language selected in store and set accordingly translation and moment local conf
    // as a listener,this is updated every time language is changed
    this.getLang()
      .pipe(
        filter((lang) => !!lang),
        map((lang) => {
          moment.locale(lang);
          return lang;
        }),
        mergeMap((lang) => this.translate.use(lang))
      )
      .subscribe();

    // this promise is resolved during this app initialization
    // and set language in store if none and set accordingly translation and moment local conf
    return new Promise((resolve) => {
      this.getLang()
        .pipe(
          first(),
          mergeMap((lang) => {
            if (!lang) {
              // Set the default language for translation strings, and the current language.
              lang = this.translate.getBrowserLang() || 'fr';
              this.store.setLang(lang);
            }
            moment.locale(lang);
            return this.translate.use(lang);
          })
        )
        .subscribe(() => {
          resolve();
        });
    });
  }

  /**
   * return an observable of selected language
   */
  public getLang(): Observable<string> {
    return this.store.getLang();
  }
}
