import { createReducer, on } from '@ngrx/store';

import * as _ from 'lodash';

import { CustomerFamilyMember, StoredCustomer } from '../models/customer.model';

import { CustomerActions } from './action-types';

export const customerFeatureKey = 'customer';

export interface CustomerState {
  customers: {
    [id: number]: StoredCustomer;
  };
  members: CustomerFamilyMember[];
}

export const initialCustomerState: CustomerState = {
  customers: {},
  members: [],
};

export const customerReducer = createReducer(
  initialCustomerState,

  on(CustomerActions.reset, () => initialCustomerState),

  on(CustomerActions.initialize, (state, action) => ({
    customers: action.customerState.customers,
    members: action.customerState.members,
  })),

  on(CustomerActions.setCustomer, (state, action) => {
    const newState = {
      customers: { ...state.customers },
      members: [...state.members],
    };

    const storedCustomer = action.storedCustomer;

    // newState.customers[storedCustomer.customer.id] = {};
    newState.customers[storedCustomer.customer.id] = storedCustomer;

    return newState;
  }),

  on(CustomerActions.setMembers, (state, action) => ({
    ...state,
    members: action.members,
  })),

  on(CustomerActions.setPairingCode, (state, action) => {
    const newState = _.cloneDeep(state);

    if (newState.customers[action.id]) {
      newState.customers[action.id].customer.pairing_code = action.pairingCode;
    }

    const memberIdx = _.findIndex(newState.members, { id: action.id });

    if (memberIdx) {
      newState.members[memberIdx].pairing_code = action.pairingCode;
    }

    return newState;
  })
);
