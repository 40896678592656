import { createReducer, on } from '@ngrx/store';

import { AgencyActions } from './action.types';
import { Agency, DefaultAddress } from '../gfl-core/gfl-models/agency.model';
import { Contact } from '../gfl-core/gfl-models/contact.model';
import { Slider, SliderType } from '../gfl-core/gfl-models/slider.model';

export interface AgencyState {
  agency: Agency;
  contacts: Array<Contact>;
  defaultAddress: DefaultAddress;
  sliders: {
    public: { [lang: string]: Slider[] };
    private: { [lang: string]: Slider[] };
  };
}

export const initialAgencyState: AgencyState = {
  agency: {},
  defaultAddress: {},
  contacts: [],
  sliders: { public: {}, private: {} },
};

export const agencyReducer = createReducer(
  initialAgencyState,

  on(AgencyActions.initialize, (state, action) => ({ ...action.agencyState })),

  on(AgencyActions.setAgency, (state, action) => ({
    ...state,
    agency: action.agency,
  })),

  on(AgencyActions.setDefaultAddress, (state, action) => ({
    ...state,
    defaultAddress: action.defaultAddress,
  })),

  on(AgencyActions.setContacts, (state, action) => ({
    ...state,
    contacts: action.contacts,
  })),

  on(AgencyActions.setPrivateSliders, (state, action) => {
    const sliders = {
      public: { ...state.sliders.public },
      private: { ...state.sliders.private },
    };

    sliders.private[action.lang] = action.sliders;

    return {
      ...state,
      sliders,
    };
  }),

  on(AgencyActions.setPublicSliders, (state, action) => {
    const sliders = {
      public: { ...state.sliders.public },
      private: { ...state.sliders.private },
    };

    sliders.public[action.lang] = action.sliders;

    return {
      ...state,
      sliders,
    };
  }),

  on(AgencyActions.resetSliders, (state, action) => {
    let sliders;

    if (action.sliderType) {
      sliders = {
        public: action.sliderType === SliderType.Public ? {} : { ...state.sliders.public },
        private: action.sliderType === SliderType.Private ? {} : { ...state.sliders.private },
      };
    } else {
      sliders = {
        public: {},
        private: {},
      };
    }

    return {
      ...state,
      sliders,
    };
  })
);
