import { PolicyFO } from './policy.model';

export interface Chart {
  name?: string;
  id?: number;
  totalPrime?: number;
  totalReduction?: number;
  averageReduction?: number;
  totalPrimeReduct?: number;
  selectedPolicy?: ChartPolicy;
  policies?: ChartPolicy[];
}

export interface ChartPolicy {
  prime?: number;
  reduction?: number;
  basePrime?: number;
  label?: string;
  company?: string;
  insurance?: number;
  value?: number;
  color?: any;
  colorIndex?: number;
  baseStyle?: {
    'background-color'?: string;
    color?: string;
    border?: string;
    padding?: string;
  };
  colorStyle?: {
    'background-color'?: string;
    color?: string;
    padding?: string;
  };
  optimizedColorStyle?: {
    'background-color'?: string;
    color?: string;
    padding?: string;
  };
  borderStyle?: {
    border?: string;
  };
  heightStyle?: any;
  height?: number;
  expiration?: string;
  expired?: boolean;
  policy?: PolicyFO;
}

export enum PeriodicityTypes {
  Month = 'month',
  Year = 'year',
}
