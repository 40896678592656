import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UiState } from './ui.reducer';

export const selectUiState = createFeatureSelector<UiState>('ui');

export const languageSelected = createSelector(
  selectUiState,
  ui => ui.lang
);

export const isSignupProcess = createSelector(
  selectUiState,
  ui => ui.isSignupProcess
);

export const isProSelected = createSelector(
  selectUiState,
  ui => ui.isProSelected
);

export const isCustomerCompleted = createSelector(
  selectUiState,
  ui => ui.isCustomerCompleted
);

export const isProSelectorDisplayed = createSelector(
  selectUiState,
  ui => ui.isProSelectorDisplayed
);

export const isAppStateReady = createSelector(
  selectUiState,
  ui => ui.isAppStateReady
);

export const isRefreshingData = createSelector(
  selectUiState,
  ui => ui.refreshState
);

export const networkStatus = createSelector(
  selectUiState,
  ui => ui.networkStatus
);

export const style = createSelector(
  selectUiState,
  ui => ui.style
);

export const adminLogo = createSelector(
  selectUiState,
  ui => ui.adminLogo
);

export const selectedMember = createSelector(
  selectUiState,
  ui => ui.selectedMember
);

export const isLoading = createSelector(
  selectUiState,
  ui => ui.isLoading
);

export const displayNomadLoader = createSelector(
  selectUiState,
  ui => ui.displayNomadLoader
);

export const activatedPage = createSelector(
  selectUiState,
  ui => ui.activatedPage
);
