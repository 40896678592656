import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { GflCoreModule } from '../../gfl-core/gfl-core.module';
import { GflFormGeneratorModule } from '../../gfl-libraries/gfl-form-generator/gfl-form-generator.module';
import { PoliciesComponentsModule } from '../../policies/components/policies-components.module';
import { AuthentificationComponentsModule } from '../../authentication/components/authentification-components.module';

import { CompareDisplayComponent } from './compare-display/compare-display.component';
import { CompareListComponent } from './compare-list/compare-list.component';
import { CompareListItemComponent } from './compare-list-item/compare-list-item.component';
import { CompareListSelectComponent } from './compare-list-select/compare-list-select.component';
import { CompareInsurimmoProcessComponent } from './compare-insurimmo-process/compare-insurimmo-process.component';
import { CompareInsurimmoConditionsComponent } from './compare-insurimmo-conditions/compare-insurimmo-conditions.component';
import { CompareInsurimmoOfferComponent } from './compare-insurimmo-offer/compare-insurimmo-offer.component';
import { CompareInsurimmoSignedComponent } from './compare-insurimmo-signed/compare-insurimmo-signed.component';

@NgModule({
  declarations: [
    CompareDisplayComponent,
    CompareListComponent,
    CompareListItemComponent,
    CompareListSelectComponent,
    CompareInsurimmoProcessComponent,
    CompareInsurimmoConditionsComponent,
    CompareInsurimmoOfferComponent,
    CompareInsurimmoSignedComponent,
  ],
  imports: [
    GflCoreModule,
    IonicModule,
    GflFormGeneratorModule.forRoot(),
    PoliciesComponentsModule,
    RouterModule,
    AuthentificationComponentsModule,
  ],
  exports: [
    CompareDisplayComponent,
    CompareListComponent,
    CompareListItemComponent,
    CompareListSelectComponent,
    CompareInsurimmoProcessComponent,
    CompareInsurimmoConditionsComponent,
    CompareInsurimmoOfferComponent,
    CompareInsurimmoSignedComponent,
  ],
})
export class CompareComponentsModule {}
