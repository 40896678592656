import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { GflLoaderComponent } from './gfl-loader/gfl-loader.component';
import { GflMembersSelectComponent } from './gfl-members-select/gfl-members-select.component';
import { GflModeProSelectorComponent } from './gfl-mode-pro-selector/gfl-mode-pro-selector.component';
import { GflPoweredByComponent } from './gfl-powered-by/gfl-powered-by.component';
import { GflSpriteComponent } from './gfl-sprite/gfl-sprite.component';
import { GflUploadFilesToPdfComponent } from './gfl-upload-files-to-pdf/gfl-upload-files-to-pdf.component';
import { GflFooterNavComponent } from './gfl-footer-nav/gfl-footer-nav.component';
import { GflHeaderMobileComponent } from './gfl-header-mobile/gfl-header-mobile.component';
import { GflHeaderTabletComponent } from './gfl-header-tablet/gfl-header-tablet.component';
import { GflSidebarLinkComponent } from './gfl-sidebar-link/gfl-sidebar-link.component';
import { GflDirectivesModule } from '../gfl-directives/gfl-directives.module';
import { GflHeaderLinkComponent } from './gfl-header-link/gfl-header-link.component';
import { GflImgComponent } from './gfl-img/gfl-img.component';
import { GflPipesModule } from '../gfl-pipes/gfl-pipes.module';
import { GflNomadLoaderComponent } from './gfl-nomad-loader/gfl-nomad-loader.component';
import { GflRefresherBtnComponent } from './gfl-refresher-btn/gfl-refresher-btn.component';
import { GflUpdateAppComponent } from './gfl-update-app/gfl-update-app.component';

@NgModule({
  declarations: [
    GflLoaderComponent,
    GflMembersSelectComponent,
    GflModeProSelectorComponent,
    GflPoweredByComponent,
    GflSidebarLinkComponent,
    GflSpriteComponent,
    GflUploadFilesToPdfComponent,
    GflFooterNavComponent,
    GflHeaderMobileComponent,
    GflHeaderTabletComponent,
    GflHeaderLinkComponent,
    GflImgComponent,
    GflNomadLoaderComponent,
    GflRefresherBtnComponent,
    GflUpdateAppComponent,
  ],
  imports: [CommonModule, IonicModule, GflDirectivesModule, TranslateModule, RouterModule, FormsModule, GflPipesModule],
  exports: [
    GflLoaderComponent,
    GflMembersSelectComponent,
    GflModeProSelectorComponent,
    GflPoweredByComponent,
    GflSidebarLinkComponent,
    GflSpriteComponent,
    GflUploadFilesToPdfComponent,
    GflFooterNavComponent,
    GflHeaderMobileComponent,
    GflHeaderTabletComponent,
    GflHeaderLinkComponent,
    GflImgComponent,
    GflNomadLoaderComponent,
    GflRefresherBtnComponent,
    GflUpdateAppComponent,
  ],
})
export class GflComponentsModule {}
