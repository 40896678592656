import { createReducer, on } from '@ngrx/store';
import * as ContactsActions from './contacts.actions';
import { ChatItem, NotificationItem } from '../models/contacts.model';
import * as _ from 'lodash';

export const contactsFeatureKey = 'contacts';

export interface ContactsState {
  chat: { [customerId: number]: { new: number; items: ChatItem[] } };
  notifications: NotificationItem[];
}

export const initialContactsState: ContactsState = {
  chat: {},
  notifications: [],
};

export const contactsReducer = createReducer(
  initialContactsState,

  on(ContactsActions.initialize, (state, action) => ({
    ...action.contactsState,
  })),

  on(ContactsActions.reset, () => initialContactsState),

  on(ContactsActions.setChatItems, (state, action) => ({
    ...state,
    chat: action.chatItemsMap,
  })),

  on(ContactsActions.setNotifications, (state, action) => ({
    ...state,
    notifications: action.notifications,
  })),

  on(ContactsActions.addNotifications, (state, action) => {
    const notifications = _.cloneDeep(state.notifications);

    _.forEach(action.notifications, (notif) => {
      const item = _.find(notifications, { id: notif.id });

      if (item) {
        const idx = _.indexOf(notifications, item);
        notifications[idx] = item;
      } else {
        notifications.push(notif);
      }
    });

    return {
      ...state,
      notifications,
    };
  })
);
