import { AbstractControl } from '@angular/forms';

export class DateValidation {
  static isMajor(AC: AbstractControl) {
    const birthDate = AC.value;
    const majorAge = 18;

    if (!birthDate) {
      return { isMajor: true };
    }

    const temp = birthDate.split('-');
    const majorDate = new Date(parseInt(temp[0], 10) + majorAge + '-' + temp[1] + '-' + temp[2]);
    const today = new Date();

    if (majorDate > today) {
      return { isMajor: true };
    } else {
      return null;
    }
  }
}
