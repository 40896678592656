import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { ModalController } from '@ionic/angular';

import { tap } from 'rxjs/operators';

import { StoreService } from '../gfl-services/store.service';
import { NetworkMonitorService } from '../gfl-services/network-monitor.service';
import { ToolsService } from '../gfl-services/tools.service';

import { GflUpdateAppComponent } from '../gfl-components/gfl-update-app/gfl-update-app.component';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  readonly appVersion: string;
  private isOnline: boolean;
  private inProcess: boolean;

  constructor(
    private store: StoreService,
    private network: NetworkMonitorService,
    private modalCtrl: ModalController,
    private tools: ToolsService
  ) {
    this.appVersion = environment.APP_VERSION;
    this.network.isOnline().subscribe((isOnline) => (this.isOnline = isOnline));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && this.tools.isNative()) {
          const newAppVersion = event.headers.get('app-version');

          if (newAppVersion && !this.inProcess) {
            if (this.appVersion !== newAppVersion) {
              this.inProcess = true;
              this.displayModal().then();
            }
          }
        }
      })
    );
  }

  async displayModal() {
    const modal = await this.modalCtrl.create({
      component: GflUpdateAppComponent,
      backdropDismiss: false,
    });
    await modal.present();
  }
}
