import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { GflThemeOptions } from '../../models/gfl-form-options.model';
import { GflModeDisplayType, GflSelectOption } from '../../models/gfl-form.model';
import { FrontTheme } from '../../../../gfl-core/gfl-models/agency.model';
import { ToolsService } from '../../../../gfl-core/gfl-services/tools.service';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'gfl-field-phone',
  templateUrl: './gfl-field-phone.component.html',
  styleUrls: ['./gfl-field-phone.component.scss'],
})
export class GflFieldPhoneComponent implements OnChanges {
  /**
   * initial value
   */
  @Input() value: {
    phone: string;
    prefix: string;
  };

  /**
   * hide value and formatted value in order to display only label
   */
  @Input() hideValue: boolean;

  /**
   * the form implementing this field in two-way binding
   */
  @Input() form: FormGroup;
  @Output() formChange: EventEmitter<FormGroup>;

  /**
   * An array of validators to add to the formControl
   */
  @Input() validators: Array<ValidatorFn> = [];

  /**
   * Set type of displayed template between "mobile" and "tablet" options
   */
  @Input() modeDisplay: GflModeDisplayType;

  /**
   * Front theme style
   */
  @Input() style: FrontTheme;

  /**
   * Text used in ion-label component
   */
  @Input() label: string;

  /**
   * Text used in ion-label component for phone part
   */
  @Input() placeholderPhone: string;

  /**
   * Text used in ion-label component for phone prefix part
   */
  @Input() placeholderPrefix: string;

  /**
   * If isEditMode is false then the field value are read-only
   */
  @Input() isEditMode: boolean;

  /**
   * name attribute for form control
   */
  @Input() name: string;

  /**
   * If isLabel is true then we only display label :)
   */
  @Input() isLabel: boolean;

  /**
   * This is the value_reformat attribute provided by the BO
   * for objects using item_templates
   */
  @Input() formattedValue: string;

  /**
   * Color theme to apply to the component
   */
  @Input() theme: GflThemeOptions;

  /**
   * if true then gfl-validation section is not displayed
   * this is used to display custom error message
   */
  @Input() noDisplayErrors: boolean;

  /**
   * readonly flag for HTML attribute field
   */
  @Input() readonly: boolean;

  /**
   * field required flag
   */
  @Input() required: boolean;

  /**
   * true if form parent has een submitted
   */
  @Input() submitted: boolean;

  @ViewChild('select', { static: false }) select: ElementRef;

  public modeDisplays = GflModeDisplayType;
  public phone: string;
  public prefix: string;
  public phoneWithPrefix: string;
  public selectOptionsArr: Array<GflSelectOption>;
  public isIcon: boolean;
  private phoneValidatorsApplied: Array<ValidatorFn>;
  private prefixValidatorsApplied: Array<ValidatorFn>;
  private formGroup: FormGroup;

  /**
   * @ignore
   */
  constructor(public translate: TranslateService, public tools: ToolsService) {
    this.selectOptionsArr = environment.PREFIX_COUNTRY;
    this.theme = this.theme || {};
    this.formChange = new EventEmitter<FormGroup>();
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && changes.form.currentValue !== changes.form.previousValue) {
      if (!this.form.contains(this.name)) {
        this.validators = this.validators || [];
        // set validators
        this.phoneValidatorsApplied = [
          ...this.validators,
          Validators.minLength(9),
          Validators.maxLength(10),
          Validators.pattern('[0-9]{0,10}'),
        ];
        this.prefixValidatorsApplied = [Validators.required];
        if (this.required) {
          this.phoneValidatorsApplied.push(Validators.required);
          this.prefixValidatorsApplied.push(Validators.required);
        }
        // set local attributes
        this.phone = this.value && this.value.phone;
        this.prefix = this.value && this.value.prefix;
        this.phoneWithPrefix = this.prefix && this.phone ? '(' + this.prefix + ') ' + this.phone : '';

        // generate formGroup
        this.formGroup = new FormGroup({
          prefix: new FormControl(this.prefix, this.prefixValidatorsApplied),
          phone: new FormControl(this.phone, this.phoneValidatorsApplied),
          phoneWithPrefix: new FormControl(this.phoneWithPrefix, []),
        });
        // update form locally
        this.form.registerControl(this.name, this.formGroup);
        // update form globally
        this.formChange.emit(this.form);
      }
    }
    if (changes.formattedValue && changes.formattedValue.currentValue !== changes.formattedValue.previousValue) {
      this.isIcon = !!RegExp('class="icons"').test(changes.formattedValue.currentValue);
    }

    if (changes.value && changes.value.currentValue !== changes.value.previousValue) {
      this.formGroup.get('phone').patchValue(this.value.phone);
      this.formGroup.get('prefix').patchValue(this.value.prefix);
    }

    if (changes.disabled) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (changes.disabled.currentValue === true) {
        this.formGroup.get('phone').disable();
        this.formGroup.get('prefix').disable();
      } else {
        this.formGroup.get('phone').enable();
        this.formGroup.get('prefix').enable();
      }
    }

    if (changes.submitted && changes.submitted.currentValue !== changes.submitted.previousValue) {
      if (this.phoneValidatorsApplied.length) {
        this.formGroup.get('phone').markAsTouched();
        this.formGroup.get('phone').markAsDirty();
      }
      if (this.prefixValidatorsApplied.length) {
        this.formGroup.get('prefix').markAsTouched();
        this.formGroup.get('prefix').markAsDirty();
      }
    }
  }
}
