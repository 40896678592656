import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

import { from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Refund } from '../../models/refund.model';
import { UploadDocument } from '../../../gfl-core/gfl-models/document.model';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { DocumentService } from '../../../gfl-core/gfl-services/document.service';

@Component({
  selector: 'gfl-policy-refund-edit',
  templateUrl: './policy-refund-edit.component.html',
  styleUrls: ['./policy-refund-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyRefundEditComponent implements OnInit {
  public refund: Refund;
  public documents: Array<UploadDocument> = [];
  public dateValue: string;

  /**
   * @ignore
   */
  constructor(
    public platform: Platform,
    private documentSrv: DocumentService,
    public tools: ToolsService,
    private modalCtrl: ModalController,
    private cd: ChangeDetectorRef
  ) {}

  /**
   * @ignore
   */
  ngOnInit() {
    if (this.refund && this.refund.documents) {
      // collect all document's observable in observables array
      Object.keys(this.refund.documents).forEach((documentKey) => {
        // Build the refund's documents property
        // with base64 encoded documents
        this.documentSrv
          .getDocumentByChecksum(
            this.refund.documents[documentKey].document_checksum,
            this.refund.documents[documentKey].document_customer_id
          )
          .pipe(
            switchMap((blob) => this.tools.readFileObs(blob)),
            switchMap((readerResult: string) =>
              from(this.documentSrv.generateUploadedDocument(this.refund.documents[documentKey], readerResult))
            ),
            tap((document) => {
              this.documents.push(document);
              this.cd.detectChanges();
            })
          )
          .subscribe();
      });
    }
  }

  /**
   * Open the document
   *
   * @param document uploaded document
   */
  public openRefundDocument(document: UploadDocument): void {
    return this.documentSrv.openDocumentFile(document.document_id);
  }

  /**
   * Close current modal
   */
  public closeModal() {
    this.modalCtrl.dismiss().then();
  }
}
