import { createAction, props } from '@ngrx/store';
import { AuthState } from './index';

export const initialize = createAction(
  '[Store Service setNgrxStore] initialize AuthState',
  props<{ authState: AuthState }>()
);

export const login = createAction(
  '[Login Component] Login',
  props<{
    authData: AuthState;
    reloadAll: boolean;
    reset?: boolean;
  }>()
);

export const setIsLoggedIn = createAction('[Store service] set login state', props<{ isLoggedIn: boolean }>());

export const loginUserAsCustomer = createAction(
  '[LoginUserAsCustomer page] LoginUserAsCustomer',
  props<{
    userToken: string;
    customerId: number;
    agencyId: number;
  }>()
);

export const loginOnSwitchMode = createAction(
  '[Gfl-mode-pro-selector Component]  switch to private/employee',
  props<{
    authData: AuthState;
  }>()
);

export const credential = createAction('[Reset-password Component] Set RememberMe', props<{ rememberMe: boolean }>());

export const logout = createAction('[Logout] Logout', props<{ noRedirection: boolean }>());

export const updateCustomerRefreshedTimeStamp = createAction(
  '[Auth Service] update customer data TimeStamp',
  props<{ customerRefreshedTimeStamp: number }>()
);

export const reloadAll = createAction(
  '[Sidebar refreshAllData()] update constants, statuses, agency data and all customers data',
  props<{ authState: AuthState }>()
);

export const setRememberMe = createAction(
  '[MemberConnexionData component toggleRememberMe()] set rememberMe flag',
  props<{ rememberMe: boolean }>()
);
