import { createAction, props } from '@ngrx/store';
import { ComparesState } from './index';
import { CompareFO, CompareListItem } from '../models/compare.model';

export const initialize = createAction(
  '[Store Service setNgrxStore] initialize ComparesState',
  props<{ comparesState: ComparesState }>()
);

export const reset = createAction('[Store Service logout()] reset ComparesState');

export const setCompares = createAction(
  '[Compare service setCompares()] load compares list',
  props<{ comparesList: { [id: number]: { [lang: string]: CompareListItem[] } } }>()
);

export const setCompare = createAction(
  '[Compare service setCompare()] load a compare from BO',
  props<{ compare: CompareFO; lang: string }>()
);
