import { ModuleWithProviders, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { GflCoreModule } from '../../gfl-core/gfl-core.module';

import { GflFormGeneratorService } from './services/gfl-form-generator.service';

import { GflFieldEmailComponent } from './components/gfl-field-email/gfl-field-email.component';
import { GflFieldEmailMultiComponent } from './components/gfl-field-email-multi/gfl-field-email-multi.component';
import { GflFieldGeneratorComponent } from './components/gfl-field-generator/gfl-field-generator.component';
import { GflFieldInputComponent } from './components/gfl-field-input/gfl-field-input.component';
import { GflFieldInputDateComponent } from './components/gfl-field-input-date/gfl-field-input-date.component';
import { GflFieldInputIntegerComponent } from './components/gfl-field-input-integer/gfl-field-input-integer.component';
import { GflFieldInputNumberComponent } from './components/gfl-field-input-number/gfl-field-input-number.component';
import { GflFieldPasswordComponent } from './components/gfl-field-password/gfl-field-password.component';
import { GflFieldPhoneComponent } from './components/gfl-field-phone/gfl-field-phone.component';
import { GflFieldPasswordWithConfirmComponent } from './components/gfl-field-password-with-confirm/gfl-field-password-with-confirm.component';
import { GflFieldPhoneMultiComponent } from './components/gfl-field-phone-multi/gfl-field-phone-multi.component';
import { GflFieldSelectComponent } from './components/gfl-field-select/gfl-field-select.component';
import { GflFieldToggleBtnComponent } from './components/gfl-field-toggle-btn/gfl-field-toggle-btn.component';
import { GflFieldZipcodeCityComponent } from './components/gfl-field-zipcode-city/gfl-field-zipcode-city.component';
import { GflFormGeneratorComponent } from './components/gfl-form-generator/gfl-form-generator.component';
import { IonicSelectableModule } from 'ionic-selectable';

/**
 * This module can be used to generate:
 * - an entire ionic form with `gfl-form-generator` component,
 * - a form item field with `gfl-field-generator` component,
 * - a specific form item field with one of the `gfl-field-...` component
 */
@NgModule({
  declarations: [
    GflFieldEmailComponent,
    GflFieldEmailMultiComponent,
    GflFieldGeneratorComponent,
    GflFieldInputComponent,
    GflFieldInputDateComponent,
    GflFieldInputIntegerComponent,
    GflFieldInputNumberComponent,
    GflFieldPasswordComponent,
    GflFieldPasswordWithConfirmComponent,
    GflFieldPhoneComponent,
    GflFieldPhoneMultiComponent,
    GflFieldSelectComponent,
    GflFieldToggleBtnComponent,
    GflFieldZipcodeCityComponent,
    GflFormGeneratorComponent,
  ],
  imports: [GflCoreModule, IonicModule, IonicSelectableModule],
  exports: [
    GflFieldEmailComponent,
    GflFieldEmailMultiComponent,
    GflFieldGeneratorComponent,
    GflFieldInputComponent,
    GflFieldInputDateComponent,
    GflFieldInputIntegerComponent,
    GflFieldInputNumberComponent,
    GflFieldPasswordComponent,
    GflFieldPasswordWithConfirmComponent,
    GflFieldPhoneComponent,
    GflFieldPhoneMultiComponent,
    GflFieldSelectComponent,
    GflFieldToggleBtnComponent,
    GflFieldZipcodeCityComponent,
    GflFormGeneratorComponent,
  ],
})
export class GflFormGeneratorModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: GflFormGeneratorModule,
      providers: [GflFormGeneratorService],
    };
  }
}
