import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { CustomerFamilyMember } from '../../models/customer.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';

import { ContactComponent } from '../../../contacts/components/contact/contact.component';

@Component({
  selector: 'gfl-sub-header-profile-tablet',
  templateUrl: './sub-header-profile-tablet.component.html',
  styleUrls: ['./sub-header-profile-tablet.component.scss'],
})
export class SubHeaderProfileTabletComponent {
  @Input() acls: Acls;
  @Input() members: Array<CustomerFamilyMember>;
  @Input() isOffline: boolean;
  @Output() openContract: EventEmitter<any> = new EventEmitter<any>();
  @Output() openCustomerAddModal: EventEmitter<any> = new EventEmitter<any>();

  /**
   * @ignore
   */
  constructor(private modalCtrl: ModalController, public notificationSrv: NotificationService) {}

  public openContractEmit(): void {
    this.openContract.emit();
  }

  public openCustomerAddModalEmit(): void {
    this.openCustomerAddModal.emit();
  }

  public async openContactModal() {
    const modal = await this.modalCtrl.create({
      component: ContactComponent,
      componentProps: {
        acls: this.acls,
        isOffline: this.isOffline,
      },
    });
    await modal.present();
  }
}
