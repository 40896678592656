import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, first, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import { Company } from '../gfl-models/company.model';
import { Contact } from '../gfl-models/contact.model';
import { StoreService } from './store.service';
import { ToolsService } from './tools.service';
import { WsService } from './ws.service';
import { DocumentService } from './document.service';
import { DataMonitorService } from './data-monitor.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private wsSrv: WsService,
    private store: StoreService,
    private tools: ToolsService,
    private documentSrv: DocumentService,
    private dataMonitorSrv: DataMonitorService
  ) {
    const locksToMonitor = [
      {
        name: 'companies',
        lock: () => this.store.getCompaniesLock(),
        cb: () => this.setCompanies(),
      },
    ];

    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * Check if companies list needs to be fetched from BO
   */
  public loadCompanies(): Observable<any> {
    return this.store.getCompanies().pipe(
      mergeMap((companies) => {
        if (_.isEmpty(companies)) {
          return this.setCompanies();
        } else {
          return of(true);
        }
      }),
      first()
    );
  }

  /**
   * Store a map of company objects
   */
  public setCompanies(): Observable<any> {
    let companies;

    return this.store.setCompaniesLock(Date.now()).pipe(
      mergeMap(() => this.wsSrv.requestCompaniesByCustomerTypeByInsuranceType()),
      tap((resp) => {
        const respFormatted = this.sanitizeCompaniesByCustomerTypeByInsuranceType(resp);
        this.store.setCompaniesByCustomerTypeByInsuranceType(respFormatted);
      }),
      mergeMap(() => this.wsSrv.requestCompanies()),
      mergeMap((res) => {
        companies = res;
        const obs$: Observable<string>[] = [];

        _.forEach(companies, (item) => {
          if (item.svg) {
            if (this.tools.isNative()) {
              obs$.push(
                this.tools
                  .fetchSVGImage(item.svg)
                  .pipe(
                    mergeMap((data) =>
                      this.documentSrv.storeSVGFile(data, item.name + '.svg', environment.SVG_FOLDERS.COMPANIES)
                    )
                  )
              );
            } else {
              obs$.push(of(item.svg));
            }
          }
        });

        if (!obs$.length) {
          obs$.push(of(null));
        }
        return forkJoin(obs$);
      }),
      mergeMap((result) => {
        _.forEach(companies, (company: Company) => {
          if (company.svg) {
            company.svg = result.shift() as string;
          }
        });

        this.store.setCompanies(companies);

        return of(this.store.setCompaniesLock(null));
      }),
      catchError((err) => {
        this.tools.error('CompanyService setCompanies()', err);
        return of(null);
      })
    );
  }

  /**
   * Return an observable of companies map
   */
  public getCompanies(): Observable<{ [id: number]: Company }> {
    return this.store.getCompanies();
  }

  // Get one company giving its ID
  /**
   * Return an observable of the company for the given id
   *
   * @param companyId Company ID
   */
  public getCompanyById(companyId: number): Observable<{ company: Company; contact: Contact }> {
    return this.wsSrv.requestCompany(companyId);
  }

  /**
   * Return an observable of array of all companies for given type
   *
   * @param insuranceTypeId insurance type id
   * @param customerTypeId customer type id
   */
  public getCompaniesByInsuranceType(insuranceTypeId: number, customerTypeId: number): Observable<Company[]> {
    return this.store.getCompaniesByCustomerTypeByInsuranceType(customerTypeId, insuranceTypeId).pipe(
      withLatestFrom(this.getCompanies()),
      mergeMap(([arrIds, companiesList]: [number[], { [id: number]: Company }]) => {
        const companies = [];
        _.forEach(arrIds, (id) => {
          if (companiesList[id]) {
            companies.push(companiesList[id]);
          }
        });
        return of(companies);
      })
    );
  }

  private sanitizeCompaniesByCustomerTypeByInsuranceType(obj: { [id: string]: { [id: string]: number[] } }): {
    [id: string]: { [id: string]: number[] };
  } {
    _.forEach(obj, (customerTypeObj, customerTypeId) => {
      _.forEach(customerTypeObj, (insuranceTypeObj, insuranceTypeId) => {
        if (!insuranceTypeObj.length) {
          delete obj[customerTypeId][insuranceTypeId];
        }
      });
    });

    return obj;
  }
}
