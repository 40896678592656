import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { GflFormGeneratorService } from '../../services/gfl-form-generator.service';
import { GflThemeOptions } from '../../models/gfl-form-options.model';
import { GflFormItem, GflModeDisplayType } from '../../models/gfl-form.model';
import { FrontTheme } from '../../../../gfl-core/gfl-models/agency.model';

/**
 * This component is used to display an entire ionic form.
 *
 * All form's field are generated from the itemsData attribute.
 *
 * @example
 * <gfl-form-generator
 *                  [style]="style"
 *                  [itemsData]="formData"
 *                  [modeDisplay]="ModeDisplayType.Tablet"
 *                  [itemCol]="6"
 *                  [theme]="theme"
 *                  [(isEditMode)]="isEditMode"
 *                  [isSubmitted]="isSubmitted"
 *                  (onSubmit)="submit($event)"></gfl-form-generator>
 *
 * TODO Check for col-6 on gfl-field-generator tag
 */
@Component({
  selector: 'gfl-form-generator',
  templateUrl: './gfl-form-generator.component.html',
  styleUrls: ['./gfl-form-generator.component.scss'],
})
export class GflFormGeneratorComponent implements OnChanges {
  // TODO - fix case of Email, Phone and ZipCodeCity components

  /**
   * Set type of displayed template between "mobile" and "tablet" options
   */
  @Input() modeDisplay: GflModeDisplayType;

  /**
   * If isEditMode is false then the form's items value are read-only
   */
  @Input() isEditMode = false;

  /**
   * if this flag turns to true, the form is externally asked to be submitted.
   */
  @Input() isSubmitted = false;

  /**
   * itemsData contains fields definition and initial values
   */
  @Input() itemsData: Array<GflFormItem>;

  /**
   * the agency's style data
   */
  @Input() style: FrontTheme;

  /**
   * itemCol is used in tablet/desktop display mode to indicate the width of items
   */
  @Input() itemCol: number;

  /**
   * Color theme to apply to the component
   */
  @Input() theme: GflThemeOptions;

  /**
   * Event Emitter used to indicate that the form is on edit mode
   */
  @Output() isEditModeChange = new EventEmitter<boolean>();

  /**
   * Event Emitter used on form submission
   */
  @Output() actionSubmit = new EventEmitter<Array<GflFormItem>>();

  /**
   * The form handled by this component
   */
  public form: FormGroup;

  /**
   * Initial values of formControls
   */
  private initialFormValues: { [id: string]: any };

  /**
   * formData is used to generate the HTML form
   *
   * @ignore
   */
  public formData: Array<GflFormItem>;

  /**
   * This mode display flag is true if the device is a mobile
   */
  public isMobile = false;

  /**
   * This mode display flag is true if the device is a tablet or a desktop
   */
  public isTablet = false;

  /**
   * @ignore
   */
  constructor(public translate: TranslateService, private gflFormGenSrv: GflFormGeneratorService) {
    this.theme = this.theme || {};
  }

  /**
   * On changes, regenerate form data and set display mode
   *
   * @param value changed value
   */
  ngOnChanges(value: SimpleChanges) {
    if (value.itemsData && this.itemsData) {
      this.setFormData();
      // we declare an empty formGroup that will register every formControls
      // generated by every gfl-field-components
      this.form = new FormGroup({});
    }
    if (value.modeDisplay) {
      this.isMobile = this.modeDisplay === GflModeDisplayType.Mobile;
      this.isTablet = this.modeDisplay === GflModeDisplayType.Tablet;
    }
    // if (value.isEditMode) {
    //   this.setFormData();
    // }
    if (value.isSubmitted && value.isSubmitted.currentValue) {
      this.submit();
    }
  }

  /**
   * On Submission, onSubmit event is emitted with the actual formData attribute
   */
  public submit(): void {
    if (this.form.dirty && this.form.valid) {
      const formData = this.gflFormGenSrv.updateFormData(this.formData, this.form.value);

      this.actionSubmit.emit(formData);
    }
  }

  /**
   * Reset form with initial values
   */
  public onCancel(): void {
    this.isEditModeChange.emit(false);
    this.form.reset(this.initialFormValues);
  }

  /**
   * Generate formData attribute with itemsData Input
   */
  private setFormData(): void {
    this.formData = this.gflFormGenSrv.formatItemsTemplate(_.cloneDeep(this.itemsData));
    // we store initial values to be used in case of cancellation
    this.initialFormValues = this.gflFormGenSrv.getFormInitialValues(this.formData);
  }
}
