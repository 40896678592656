import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationService } from '../../gfl-services/navigation.service';

@Component({
  selector: 'gfl-sidebar-link',
  templateUrl: './gfl-sidebar-link.component.html',
  styleUrls: ['./gfl-sidebar-link.component.scss'],
})
export class GflSidebarLinkComponent implements OnInit {
  public isActive: boolean;
  public url: string;
  public item: string;
  @Input() ionIcon: string;
  @Input() iconDefault: string;
  @Input() iconActivated: string;
  @Input() txtLink: string;
  @Input() onClickParam: string;
  @Input() activeParam: string;
  @Output() change: EventEmitter<string> = new EventEmitter<string>();

  constructor(private navigationSrv: NavigationService) {}

  ngOnInit() {
    this.navigationSrv.getActivatedPage().subscribe(activePage => {
      this.isActive = activePage === this.activeParam;
      this.item = this.isActive ? this.iconActivated : this.iconDefault;
    });
  }

  /**
   *  Emit "change" param's new value
   */
  public action(): void {
    this.change.emit('clicked');
  }
}
