import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { InsuranceService } from '../../services/insurance.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { PolicyFO } from '../../models/policy.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { Mandate } from '../../models/mandate.model';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'gfl-policy-list-item',
  templateUrl: './policy-list-item.component.html',
  styleUrls: ['./policy-list-item.component.scss'],
})
export class PolicyListItemComponent implements OnInit {
  @Input() policy: PolicyFO | Mandate;
  @Input() style: FrontTheme;
  @Input() action: () => void;
  @Input() acls: Acls;
  @Input() isOffline: boolean;
  @Input() displayName: boolean;
  @Input() isSignupProcess: boolean;
  @Output() openPolicyResumeModal: EventEmitter<any> = new EventEmitter();
  @Output() openInvoicesModal: EventEmitter<any> = new EventEmitter();
  @Output() openCgModal: EventEmitter<any> = new EventEmitter();
  @Output() openPolicyDetail: EventEmitter<any> = new EventEmitter();
  @Output() openPolicyPdf: EventEmitter<any> = new EventEmitter();
  @Output() openInsuranceClaimModal: EventEmitter<any> = new EventEmitter();
  @Output() openInsuranceRefundModal: EventEmitter<any> = new EventEmitter();
  @Output() deleteMandate: EventEmitter<any> = new EventEmitter();
  @Output() openDocumentsPage: EventEmitter<any> = new EventEmitter();
  public isRefund: boolean;
  public insuranceTypeImageDefaultUrl: string;

  /**
   * @ignore
   */
  constructor(
    public tools: ToolsService,
    private insuranceSrv: InsuranceService,
    public notificationSrv: NotificationService
  ) {
    this.insuranceTypeImageDefaultUrl = environment.STORAGE_URL + '/insurance_types/9/photo.jpg';
  }

  /**
   * @ignore
   */
  ngOnInit() {
    const isSickness = this.insuranceSrv.isSicknessType(this.policy.insurance_type_id);
    const isLife = this.insuranceSrv.isLifeType(this.policy.insurance_type_id);

    this.isRefund = isSickness && !isLife;
  }

  public actionResume() {
    this.openPolicyResumeModal.emit();
  }

  public actionDisplayMandate() {
    this.openPolicyPdf.emit();
  }

  public actionRequestClaim() {
    this.openInsuranceClaimModal.emit();
  }

  public actionRequestRefund() {
    this.openInsuranceRefundModal.emit();
  }

  public actionDeleteMandate() {
    this.deleteMandate.emit();
  }

  public actionDisplayDocuments() {
    this.openDocumentsPage.next();
  }

  public actionDisplayInvoices() {
    this.openInvoicesModal.next();
  }

  public actionDisplayCg() {
    this.openCgModal.next();
  }

  public actionOpenPolicyDetail() {
    this.openPolicyDetail.next();
  }
}
