import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ToolsService } from '../../gfl-services/tools.service';

@Component({
  selector: 'gfl-loader',
  templateUrl: './gfl-loader.component.html',
  styleUrls: ['./gfl-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GflLoaderComponent {
  @Input() errorDisplay: boolean;
  @Input() noDataDisplay: string;

  constructor(public tools: ToolsService, public translate: TranslateService) {}
}
