import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsRoutingModule } from './contacts-routing.module';
import { StoreModule } from '@ngrx/store';
import * as fromContacts from './reducers/contacts.reducer';

@NgModule({
  imports: [
    CommonModule,
    ContactsRoutingModule,
    StoreModule.forFeature(fromContacts.contactsFeatureKey, fromContacts.contactsReducer),
  ],
})
export class ContactsModule {}
