import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { catchError, filter, first, map, mergeMap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';

import { Status } from '../gfl-models/status.model';
import { ToolsService } from './tools.service';
import { StoreService } from './store.service';
import { WsService } from './ws.service';
import { LanguageService } from './language.service';
import { DataMonitorService } from './data-monitor.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  private statusesCurrentLanguage: Status[] = [];
  private statuses: { [lang: string]: Status[] } = {};

  private readonly appName = environment.APP_NAME;

  constructor(
    private wsSrv: WsService,
    private tools: ToolsService,
    private store: StoreService,
    private langSrv: LanguageService,
    private dataMonitorSrv: DataMonitorService
  ) {
    const lang$ = this.store.getLang().pipe(filter((val) => !!val));

    const locksToMonitor = [
      {
        name: 'statuses',
        lock: () => this.store.getStatusesLock(),
        cb: () => lang$.pipe(mergeMap((lang) => this.setStatuses(lang))),
      },
    ];

    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * Fetch statuses on app initialization
   */
  public loadStatuses(): Promise<void> {
    return new Promise((resolve) => {
      this.manageStatuses().subscribe(() => {
        resolve();
      });
    });
  }

  /**
   * update statuses
   */
  public manageStatuses(): Observable<any> {
    // first we fetch from localstorage
    return forkJoin([this.store.get(`${this.appName}_statuses`), this.langSrv.getLang().pipe(first())]).pipe(
      mergeMap(([statuses, lang]) => {
        if (statuses && statuses[lang]) {
          this.statuses = statuses;
          this.statusesCurrentLanguage = statuses[lang];
          return of(statuses);
        } else {
          // if no local data then we fetch via http request
          return this.setStatuses(lang);
        }
      })
    );
  }

  /**
   * Set Statuses from BO
   *
   * @param lang selected language
   * @param reset if true empty data first
   */
  public setStatuses(lang: string, reset?: boolean): Observable<any> {
    return this.store.setStatusesLock(Date.now()).pipe(
      mergeMap(() => this.wsSrv.requestStatuses()),
      map((statuses: Status[]) => {
        this.statusesCurrentLanguage = statuses;
        this.statuses = reset ? {} : this.statuses;

        this.statuses[lang] = statuses;

        return this.store.set(`${this.appName}_statuses`, this.statuses);
      }),
      mergeMap(() => this.store.setStatusesLock(null)),
      catchError((err) => {
        this.tools.error('StatusService setStatuses()', err);
        return of(true);
      })
    );
  }

  /**
   * Get statuses from BO
   */
  public getStatuses(): Array<Status> {
    return _.cloneDeep(this.statusesCurrentLanguage);
  }

  /**
   * Check if statusId is corresponding to the key constant
   *
   * @param statusId  status id
   * @param key status key
   */
  public is(statusId: string, key: string): boolean {
    const status = this.getStatuses().find((item) => item.id === parseInt(statusId, 10));

    return status && status.key === key;
  }

  /**
   * Return status ID corresponding to the key
   *
   * @param key status key
   */
  public getIdFromKey(key: string): number {
    const result = _.find(this.getStatuses(), { key });

    return result && result.id;
  }

  /**
   * Return translated key status
   *
   * @param id status id
   */
  public getKeyTranslateFromId(id: number) {
    const result = _.find(this.getStatuses(), { id });

    return result && result.key_translate;
  }
}
