import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { FrontTheme } from '../gfl-models/agency.model';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  readonly appName: string;

  /**
   * @ignore
   */
  constructor(private store: StoreService) {}

  /**
   * Override all css variables with agency's style from BO
   */
  setTheme() {
    this.store.getStyle().subscribe((style: FrontTheme) => {
      const el: HTMLElement = document.querySelector(':root');

      _.forEach(style, (value, key) => {
        el.style.setProperty('--gfl-' + key, '' + value);
      });
    });
  }
}
