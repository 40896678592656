import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { Observable, Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { AclsService } from '../../../gfl-core/gfl-services/acls.service';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { LoginApiResponse } from '../../models/auth.model';

@Component({
  selector: 'gfl-login-check',
  templateUrl: './login-check.component.html',
  styleUrls: ['./login-check.component.scss'],
})
export class LoginCheckComponent implements OnInit, OnDestroy {
  @Input() logo$: Observable<string>;
  @Input() isOffline: boolean;
  public login: string;
  public formErrors = {
    login: undefined,
  };
  private acls: Acls;
  private subscriptions: Subscription[] = [];

  constructor(
    public tools: ToolsService,
    private customerSrv: CustomerService,
    private notificationSrv: NotificationService,
    private router: Router,
    private modalCtrl: ModalController,
    private aclsSrv: AclsService,
    private translate: TranslateService,
    private alertCtrl: AlertController
  ) {}

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.subscriptions.push(this.initAcls().subscribe((acls) => (this.acls = acls)));
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then();
  }

  /**
   * Launch login check process
   * agencyId customer agency id
   */
  public onSendLogin(agencyId?: number): void {
    this.tools.showLoader();

    this.customerSrv
      .resetPasswordLoginValidation(this.login, agencyId)
      .pipe(finalize(() => this.tools.hideLoader()))
      .subscribe(
        (result) => {
          if (result && result.message) {
            this.notificationSrv.showSuccess({ message: result.message });
          }

          const navigationExtras: NavigationExtras = {
            state: { login: this.login, agencyId },
          };
          this.router.navigateByUrl('/authentication/reset-password/code-validation', navigationExtras).then();
        },
        (err) => {
          this.onError(err);
        }
      );
  }

  /**
   * Cancel password modification
   */
  public onCancel() {
    this.router.navigateByUrl('/authentication/login').then();
  }

  /**
   * Manage login check error
   *
   * @param err error object
   */
  private onError(err: string | HttpErrorResponse) {
    if (err instanceof HttpErrorResponse && err.status === 409) {
      if (this.acls.LOGIN_MULTIPLE_CUSTOMERS) {
        return this.onMultipleCustomersResponse(err.error);
      } else {
        this.notificationSrv.showError({ message: err.error.message as string });
      }
    } else {
      this.notificationSrv.showError({ message: err as string });
    }
  }

  /**
   * display an alert to choose the agency in case of multiple agencies
   *
   * @param error response from login ws
   */
  private onMultipleCustomersResponse(error: LoginApiResponse): void {
    const inputs = [];
    // @ts-ignore
    const agencies = error.fields.agency_id;

    _.forEach(agencies, (agency, id) => {
      inputs.push({
        type: 'radio',
        label: agency,
        value: parseInt(id, 10),
        checked: false,
      });
    });

    this.translate
      .get(['LOGIN.MULTIPLE_CUSTOMER', 'LOGIN.CHOOSE_AGENCY', 'COMMON.BUTTON_VALIDATE'])
      .pipe(
        switchMap(async (translation) => {
          const alert = await this.alertCtrl.create({
            header: translation['LOGIN.CHOOSE_AGENCY'],
            subHeader: error.message,
            inputs,
            buttons: [
              {
                text: translation['COMMON.BUTTON_VALIDATE'],
                handler: (agencyId) => {
                  this.onSendLogin(agencyId);
                },
              },
            ],
          });
          await alert.present();
        })
      )
      .subscribe();
  }

  /**
   * Initialize Acls
   */
  private initAcls(): Observable<Acls> {
    return this.aclsSrv.getAcls();
  }
}
