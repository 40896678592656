import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterDirection } from '@ionic/core';

import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { NavigationService } from '../../gfl-services/navigation.service';

@Component({
  selector: 'gfl-header-link',
  templateUrl: './gfl-header-link.component.html',
  styleUrls: ['./gfl-header-link.component.scss'],
})
export class GflHeaderLinkComponent implements OnInit {
  private isActive: boolean;
  public isActive$: Observable<boolean>;
  public item$: Observable<string>;
  @Input() ionIcon: string;
  @Input() nb: number;
  @Input() iconDefault: string;
  @Input() iconActivated: string;
  @Input() txtLink: string;
  @Input() onClickParam: string;
  @Input() activeParam: string;
  @Input() link: string;
  @Input() linkDirection: RouterDirection;
  @Output() change: EventEmitter<string> = new EventEmitter<string>();

  constructor(private navigationSrv: NavigationService) {}

  /**
   * @ignore
   */
  ngOnInit(): void {
    const activatedPage$ = this.navigationSrv.getActivatedPage();

    this.isActive$ = activatedPage$.pipe(switchMap(activePage => of(activePage === this.activeParam)));
    this.item$ = this.isActive$.pipe(
      switchMap(isActive => {
        this.isActive = isActive;
        return of(isActive ? this.iconActivated : this.iconDefault);
      })
    );
  }

  /**
   *  Emit "change" param's new value
   */
  public action(): void {
    if (!this.isActive) {
      this.change.emit('clicked');
    }
  }
}
