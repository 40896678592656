import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { NetworkMonitorService } from './gfl-core/gfl-services/network-monitor.service';

@Injectable({
  providedIn: 'root',
})
export class OnlineGuard implements CanActivate {
  /**
   * @ignore
   */
  constructor(private network: NetworkMonitorService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.network.isOnline();
  }
}
