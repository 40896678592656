import { AbstractControl } from '@angular/forms';
import * as ibantools from 'ibantools';

export class IbanValidation {
  static isValidIBAN(AC: AbstractControl) {
    const value = AC.value;

    if (value) {
      const iban = ibantools.electronicFormatIBAN(value);

      if (ibantools.isValidIBAN(iban)) {
        return null;
      } else {
        return { notValidIBAN: true };
      }
    } else {
      return null;
    }
  }

  static isValidBIC(AC: AbstractControl) {
    const value = AC.value;

    if (value) {
      if (ibantools.isValidBIC(value)) {
        return null;
      } else {
        return { notValidBIC: true };
      }
    } else {
      return null;
    }
  }
}
