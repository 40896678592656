import { ApplicationRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@capacitor/clipboard';
import * as _ from 'lodash';

import { forkJoin, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Customer, CustomerFamilyMember, CustomerFormType } from '../../models/customer.model';
import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { MandateService } from '../../../policies/services/mandate.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { StatusService } from '../../../gfl-core/gfl-services/status.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { DocumentService } from '../../../gfl-core/gfl-services/document.service';
import { NavigationService } from '../../../gfl-core/gfl-services/navigation.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { ApiService } from '../../../gfl-core/gfl-services/api.service';
import { CustomerService } from '../../services/customer.service';
import { AclsService } from '../../../gfl-core/gfl-services/acls.service';
import { NetworkMonitorService } from '../../../gfl-core/gfl-services/network-monitor.service';

import { ChangePasswordComponent } from '../../components/change-password/change-password.component';
import { SignatureFullScreenComponent } from '../../../authentication/components/signature-full-screen/signature-full-screen.component';
import { CustomerCreateComponent } from '../../components/customer-create/customer-create.component';
import { SignupBrokerComponent } from '../../../authentication/components/signup-broker/signup-broker.component';
import { CustomerLinksPairComponent } from '../../components/customer-links-pair/customer-links-pair.component';
import { ChatDisplayComponent } from '../../../contacts/components/chat-display/chat-display.component';

@Component({
  selector: 'gfl-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage {
  public members$: Observable<Array<CustomerFamilyMember>>;
  public selectedMember$: Observable<CustomerFamilyMember | Customer>;
  public acls$: Observable<Acls>;
  public style$: Observable<FrontTheme>;
  public customer$: Observable<Customer>;

  public navParam: string;
  private subscriptions: Subscription[] = [];
  public isPro: boolean;
  public isEmployee: boolean;
  public isOffline: boolean;
  private hasAcceptedCg = false;

  private customerTypeId: number;
  readonly action: string;

  readonly CUSTOMER_TYPE_ID_PRIVATE: number;
  readonly CUSTOMER_TYPE_ID_CORPORATE: number;
  readonly CUSTOMER_TYPE_ID_EMPLOYEE: number;
  public FOREIGN_KEY_CUSTOMER$: Observable<number>;

  public expanded: boolean;

  /**
   * @ignore
   */
  constructor(
    private router: Router,
    private translate: TranslateService,
    private customerSrv: CustomerService,
    public store: StoreService,
    public tools: ToolsService,
    private documentSrv: DocumentService,
    private mandateSrv: MandateService,
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    public notificationSrv: NotificationService,
    private navigationSrv: NavigationService,
    private aclsSrv: AclsService,
    private constantSrv: ConstantService,
    private apiSrv: ApiService,
    private alertCtrl: AlertController,
    private statusSrv: StatusService,
    public platform: Platform,
    private route: ActivatedRoute,
    private network: NetworkMonitorService,
    private appRef: ApplicationRef
  ) {
    this.CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
    this.CUSTOMER_TYPE_ID_CORPORATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_CORPORATE');
    this.CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
    this.FOREIGN_KEY_CUSTOMER$ = this.constantSrv.getForeignTableIdByName('CUSTOMER') as Observable<number>;
    this.action = this.route.snapshot.queryParamMap.get('action');
  }

  /**
   * @ignore
   */
  ionViewWillEnter(): void {
    // we want to get Mode state, but we don't want screen to be
    // refreshed before new screen is displayed when user switches mode private/pro
    this.subscriptions.push(
      this.store.getIsProSelected().subscribe((isPro) => {
        this.isPro = isPro;
      })
    );

    this.subscriptions.push(
      this.network.isOffline().subscribe((flag) => {
        this.isOffline = flag;
        this.appRef.tick();
      })
    );

    this.selectedMember$ = this.customerSrv.getSelectedMember();

    this.members$ = this.initMembers();
    this.style$ = this.store.getStyle();
    this.customer$ = this.customerSrv.getCustomer();

    // check for param in order to open the right modal
    if (this.navParam === 'add') {
      this.openAddCustomerChoiceAlert();
    } else if (this.navParam === 'password') {
      this.customer$.pipe(first()).subscribe((customer) => this.openResetPasswordModal(customer.id).then());
    }

    this.initAcls();

    this.navigationSrv.set('ProfilePage');
  }

  /**
   * @ignore
   */
  ionViewDidEnter(): void {
    switch (this.action) {
      case 'password':
        this.customer$.pipe(first()).subscribe((customer) => this.openResetPasswordModal(customer.id).then());
        break;
      case 'add':
        if (this.tools.isMobile()) {
          this.openAddCustomerChoicePage();
        } else {
          this.openAddCustomerChoiceAlert();
        }
        break;
      case 'chat':
        if (!this.tools.isMobile()) {
          this.openChatModal().then();
        }
    }
  }

  /**
   * @ignore
   */
  ionViewDidLeave(): void {
    this.tools.unsubscribeAll(this.subscriptions).then();
  }

  /**
   * Set member selected from IHM
   *
   * @param member a family member or an employee
   */
  private selectMember(member: CustomerFamilyMember): void {
    this.isEmployee = member.customer_type_id === this.CUSTOMER_TYPE_ID_EMPLOYEE;
    this.customerSrv.selectMember(member);
  }

  /**
   *  Open reset password modal
   *
   *  @param customerId customer id
   */
  public async openResetPasswordModal(customerId: number): Promise<void> {
    if (this.isOffline) {
      this.notificationSrv.showOfflineNotAvailable();
      return;
    }

    const resetPasswordModal = await this.modalCtrl.create({
      component: ChangePasswordComponent,
      componentProps: {
        customerId,
      },
    });

    await resetPasswordModal.present();
  }

  /**
   *  Navigate to profile-customer-links mobile page
   */
  public openCustomerLinksPage(): void {
    this.router.navigateByUrl('/profile/customer-links').then();
  }

  /**
   * Navigate to profile-add-customer-choice mobile page
   */
  public openAddCustomerChoicePage(): void {
    this.router.navigateByUrl('/profile/customer-choice').then();
  }

  /**
   * Display alert to choose the type of customer to link
   */
  public openAddCustomerChoiceAlert(): void {
    if (this.isOffline) {
      this.notificationSrv.showOfflineNotAvailable();
      return;
    }

    this.customerTypeId = null;

    this.translate
      .get([
        'PROFILE.LINK_ADD_CUSTOMER',
        'PROFILE.LINK_ADD_PRIVATE',
        'PROFILE.LINK_ADD_EMPLOYEE',
        'PROFILE.LINK_ADD_PRO',
        'COMMON.BUTTON_NEXT',
        'COMMON.BUTTON_CANCEL',
      ])
      .pipe(withLatestFrom(this.acls$, this.selectedMember$), first())
      .subscribe(async ([result, acls, selectedMember]: [Array<any>, Acls, CustomerFamilyMember | Customer]) => {
        const inputs = [];

        if (acls.PROFILE_CUSTOMER_ADD_PRIVATE) {
          inputs.push({
            type: 'radio',
            label: result['PROFILE.LINK_ADD_PRIVATE'],
            checked: true,
            value: this.CUSTOMER_TYPE_ID_PRIVATE.toString(),
          });
        }

        if (acls.PROFILE_CUSTOMER_ADD_EMPLOYEE && selectedMember.customer_type_id === this.CUSTOMER_TYPE_ID_CORPORATE) {
          inputs.push({
            type: 'radio',
            label: result['PROFILE.LINK_ADD_EMPLOYEE'],
            checked: false,
            value: this.CUSTOMER_TYPE_ID_EMPLOYEE.toString(),
          });
        }

        if (acls.PROFILE_CUSTOMER_ADD_PRO && selectedMember.customer_type_id === this.CUSTOMER_TYPE_ID_EMPLOYEE) {
          inputs.push({
            type: 'radio',
            label: result['PROFILE.LINK_ADD_PRO'],
            checked: false,
            value: this.CUSTOMER_TYPE_ID_CORPORATE.toString(),
          });
        }

        const alert = await this.alertCtrl.create({
          header: result['PROFILE.LINK_ADD_CUSTOMER'],
          buttons: [
            {
              text: result['COMMON.BUTTON_NEXT'],
              cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
              handler: (customerTypeId) => {
                if (customerTypeId) {
                  this.customerTypeId = parseInt(customerTypeId, 10);

                  this.openAddTypeChoiceAlert();
                }
              },
            },
            {
              text: result['COMMON.BUTTON_CANCEL'],
              cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
              role: 'cancel',
            },
          ],
          inputs,
        });

        await alert.present();
      });
  }

  /**
   * Display alert to choose the customer to link will be created or paired
   */
  public openAddTypeChoiceAlert(): void {
    let keys;

    switch (this.customerTypeId) {
      case this.CUSTOMER_TYPE_ID_CORPORATE:
        keys = ['PROFILE.ADD_PRO.TITLE', 'PROFILE.ADD_PRO.ADD_NEW', 'PROFILE.ADD_PRO.ADD_EXISTING'];
        break;
      case this.CUSTOMER_TYPE_ID_EMPLOYEE:
        keys = ['PROFILE.ADD_EMPLOYEE.TITLE', 'PROFILE.ADD_EMPLOYEE.ADD_NEW', 'PROFILE.ADD_EMPLOYEE.ADD_EXISTING'];
        break;
      default:
        keys = ['PROFILE.ADD_PRIVATE.TITLE', 'PROFILE.ADD_PRIVATE.ADD_NEW', 'PROFILE.ADD_PRIVATE.ADD_EXISTING'];
        break;
    }

    keys = [...keys, 'COMMON.BUTTON_PREVIOUS', 'COMMON.BUTTON_NEXT'];

    forkJoin([
      this.translate.get(keys),
      this.acls$.pipe(first()),
      this.customer$.pipe(first()),
      this.selectedMember$.pipe(
        switchMap((selectedMember) => this.customerSrv.getCustomer(selectedMember.id)),
        first()
      ),
    ]).subscribe(async ([result, acls, customer, selectedMember]) => {
      result = _.values(result);

      const inputs = [];

      if (this.customerTypeId === this.CUSTOMER_TYPE_ID_EMPLOYEE) {
        if (acls.PROFILE_CUSTOMER_ADD_CREATE_EMPLOYEE) {
          inputs.push({
            type: 'radio',
            label: result[1],
            checked: true,
            value: 'create',
          });
        }
      } else if (acls.PROFILE_CUSTOMER_ADD_CREATE) {
        inputs.push({
          type: 'radio',
          label: result[1],
          checked: true,
          value: 'create',
        });
      }

      if (acls.PROFILE_CUSTOMER_ADD_PAIR) {
        inputs.push({
          type: 'radio',
          label: result[2],
          checked: false,
          value: 'pair',
        });
      }

      const alert = await this.alertCtrl.create({
        header: result[0],
        buttons: [
          {
            text: result[3],
            handler: () => {
              this.openAddCustomerChoiceAlert();
            },
          },
          {
            text: result[4],
            handler: async (data) => {
              if (data === 'create') {
                const modal = await this.modalCtrl.create({
                  component: CustomerCreateComponent,
                  componentProps: {
                    selectedMember$: this.selectedMember$.pipe(
                      switchMap((selected) => this.customerSrv.getCustomer(selected.id))
                    ),
                    customerFormType: CustomerFormType.Create,
                    customerTypeId: this.customerTypeId,
                  },
                });
                await modal.present();
              } else if (data === 'pair') {
                const modal = await this.modalCtrl.create({
                  component: CustomerLinksPairComponent,
                  componentProps: {
                    customer:
                      this.isPro && this.customerTypeId === this.CUSTOMER_TYPE_ID_EMPLOYEE ? selectedMember : customer,
                    selectedMember,
                    customerTypeId: this.customerTypeId,
                  },
                });
                await modal.present();
              }
            },
          },
        ],
        inputs,
      });

      await alert.present();
    });
  }

  /**
   *  Open contract of a member
   */
  public openContract(): void {
    this.acls$
      .pipe(
        withLatestFrom(this.selectedMember$),
        first(),
        switchMap(([acls, selectedMember]) => {
          let obs$ = of(null);
          if (acls.PROFILE_MANDATE_SHOW_DOCUMENT) {
            obs$ = this.customerSrv.openCustomerContract(selectedMember.id);
          }
          return obs$;
        })
      )
      .subscribe();
  }

  /**
   * Open ChatDisplayComponent in a modal
   */
  public async openChatModal() {
    const modal = await this.modalCtrl.create({
      component: ChatDisplayComponent,
    });
    await modal.present();
  }

  /**
   * Check if customer has a pairing code,
   * if not then we generate one, and display data
   */
  public getPairingCode(): void {
    let customerTypeId: number;
    let customerId: number;

    // the customer from which we want to get the pairing code
    this.tools
      .showLoaderObs()
      .pipe(
        switchMap(() => this.selectedMember$),
        first(),
        switchMap((selectedMember) => {
          // store customerTypeId to display custom message
          customerTypeId = selectedMember.customer_type_id;
          customerId = selectedMember.id;

          return this.customerSrv.getPairingCode(customerId);
        }),
        switchMap((pairingCode) => {
          // return customer pairing code or generate one
          if (pairingCode) {
            return of(pairingCode);
          } else {
            return this.customerSrv.generatePairingCode(customerId);
          }
        })
      )
      .subscribe(
        (pairingCode: string) => {
          this.displayPairingCode(pairingCode, customerTypeId);
        },
        (err) => this.notificationSrv.showError({ message: err })
      );
  }

  /**
   * Display an alert with pairing data
   *
   * @param pairingCode pairing code
   * @param customerTypeId customer type id
   */
  private displayPairingCode(pairingCode: string, customerTypeId: number): void {
    let customMessage: string;

    switch (customerTypeId) {
      case this.CUSTOMER_TYPE_ID_PRIVATE:
        customMessage = 'PROFILE.PAIRING_WORDING_PRIVATE';
        break;
      case this.CUSTOMER_TYPE_ID_EMPLOYEE:
        customMessage = 'PROFILE.PAIRING_WORDING_EMPLOYEE';
        break;
      case this.CUSTOMER_TYPE_ID_CORPORATE:
        customMessage = 'PROFILE.PAIRING_WORDING_CORPORATE';
        break;
    }

    this.translate
      .get(['PROFILE.LINK_PAIRING_CODE', 'PROFILE.PAIRING_CODE_MESSAGE', customMessage])
      .subscribe(async (result) => {
        const alert = await this.alertCtrl.create({
          header: result['PROFILE.LINK_PAIRING_CODE'],
          cssClass: 'pairing-code-alert',
          message:
            '<div class="pairing-code-wrapper">' +
            `<span class="pairing-code">${pairingCode}</span>` +
            '<span class="clipboard"><ion-icon name="clipboard"></ion-icon></span>' +
            '</div><br>' +
            result['PROFILE.PAIRING_CODE_MESSAGE'] +
            '<br>' +
            result[customMessage],
          buttons: ['OK'],
        });
        await alert.present().then(() => {
          this.tools.hideLoader();
          document.querySelector('.pairing-code-wrapper').addEventListener('click', () => {
            this.copyToClipBoard(pairingCode);
          });
        });
      });
  }

  /**
   * Copy pairing code to clipboard
   *
   * @param pairingCode Pairing code
   */
  private async copyToClipBoard(pairingCode: string): Promise<void> {
    if (this.tools.isNative()) {
      try {
        // eslint-disable-next-line id-blacklist
        await Clipboard.write({ string: pairingCode });
        this.notificationSrv.showSuccess({ message: 'COMMON.COPY_TO_CLIPBOARD' });
      } catch (err) {
        this.notificationSrv.showError({ message: 'ERROR.COPY_TO_CLIPBOARD' });
      }
    } else {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.value = pairingCode;
      document.body.appendChild(selBox);
      selBox.select();

      try {
        // TODO - use Clipboard API - https://developer.mozilla.org/fr/docs/Web/API/Clipboard/writeText
        // but we need to use https protocol to use this API
        const successful = document.execCommand('copy');
        document.body.removeChild(selBox);

        if (successful) {
          this.notificationSrv.showSuccess({ message: 'COMMON.COPY_TO_CLIPBOARD' });
        } else {
          this.notificationSrv.showError({ message: 'ERROR.COPY_TO_CLIPBOARD' });
        }
      } catch (err) {
        this.notificationSrv.showError({ message: 'ERROR.COPY_TO_CLIPBOARD' });
      }
    }
  }

  /**
   * open profile-identity page
   */
  public openProfileIdentity(): void {
    this.router.navigateByUrl('/profile/identity').then();
  }

  /**
   *  open profile-bank page
   */
  public openProfileBank(): void {
    this.router.navigateByUrl('/profile/bank').then();
  }

  /**
   *  open profile-health page
   */
  public openProfileHealth(): void {
    this.router.navigateByUrl('/profile/health').then();
  }

  /**
   * Open modal to accept broker contract
   * if contract is accepted then launch signature process
   */
  public async openBrokerModal(): Promise<void> {
    const subscription = this.selectedMember$.subscribe(async (selectedMember) => {
      const modal = await this.modalCtrl.create({
        component: SignupBrokerComponent,
        componentProps: {
          customerId: selectedMember.id,
        },
      });
      modal.onDidDismiss().then((data) => {
        // @ts-ignore
        this.hasAcceptedCg = data && data.hasAcceptedCg;
        if (!this.hasAcceptedCg) {
          this.notificationSrv.showError({ message: 'SIGNATURE.MANDATE_ACCEPT' });
        } else {
          this.openSignatureModal();
        }
      });
      await modal.present();
    });

    this.subscriptions.push(subscription);
  }

  /**
   * Open Modal to add Signature
   */
  private async openSignatureModal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: SignatureFullScreenComponent,
    });
    await modal.onDidDismiss().then((rep) => {
      // @ts-ignore
      if (rep && rep.data && rep.data.signatureImg) {
        // @ts-ignore
        this.saveSignature(rep.data.signatureImg);
      }
    });
    await modal.present();
  }

  /**
   * Save Signature document to BO
   */
  private saveSignature(image): void {
    // TODO - generate a signature service !
    this.tools.showLoader();

    this.customerSrv
      .getSelectedMember()
      .pipe(
        switchMap((selectedMember) => {
          const DOCUMENT_CATEGORY_ID_SIGNATURE = this.constantSrv.getValueFromKey('DOCUMENT_CATEGORY_ID_SIGNATURE');
          image = image.replace('data:image/png;base64,', '');

          const document = {
            path: image,
            tmp: image,
            name: selectedMember.api_token + '_signature.png',
            ext: 'png',
          };

          return this.documentSrv.saveDocument(document, selectedMember.id, DOCUMENT_CATEGORY_ID_SIGNATURE);
        }),
        finalize(() => this.tools.hideLoader())
      )
      .subscribe(
        () => {},
        () => {
          this.notificationSrv.showError({ message: 'SIGNATURE.MANDATE_ERROR' });
        }
      );
  }

  /**
   * Set members observable,
   * Remove family member and set selected member data
   */
  private initMembers(memberAdded?: boolean): Observable<CustomerFamilyMember[]> {
    return this.customerSrv.getMembers().pipe(
      filter((val) => !_.isEmpty(val)),
      delay(0), // avoid "ExpressionChangedAfterItHasBeenCheckedError" error
      map((members) => {
        if (!members[0].customer_link_type_id) {
          // remove family member
          members.shift();
        }
        return members;
      }),
      tap((members) => {
        let selectedMember: CustomerFamilyMember;

        if (!memberAdded) {
          selectedMember = members[0];
          this.isEmployee = selectedMember.customer_type_id === this.CUSTOMER_TYPE_ID_EMPLOYEE;
        } else {
          const idx = members.length - 1;
          selectedMember = members[idx];
          this.isEmployee = selectedMember.customer_type_id === this.CUSTOMER_TYPE_ID_EMPLOYEE;
        }

        this.selectMember(selectedMember);
      }),
      catchError((err) => {
        this.tools.error('ProfilePage initialize members$', err);
        return of([]);
      })
    );
  }

  /**
   * Initialize Acls
   */
  private initAcls(): void {
    this.acls$ = this.customerSrv.getCustomerLinks().pipe(
      mergeMap((customerLinks) => this.aclsSrv.getAclsForSelectedMember(customerLinks)),
      shareReplay()
    );
  }
}
