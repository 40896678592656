import { Component, OnInit } from '@angular/core';
// import { Market } from '@ionic-native/market/ngx';
import { Observable } from 'rxjs';
import { StoreService } from '../../gfl-services/store.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'gfl-gfl-update-app',
  templateUrl: './gfl-update-app.component.html',
  styleUrls: ['./gfl-update-app.component.scss'],
})
export class GflUpdateAppComponent implements OnInit {
  public logo$: Observable<string>;
  public appId: string;

  constructor(
    // private market: Market,
    private store: StoreService,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.logo$ = this.store.getPublicLogo();
    this.store.getAgency().subscribe((agency) => {
      this.appId = this.platform.is('ios') ? agency.apple_store_id : agency.play_store_id;
    });
  }

  openMarket() {
    // this.market.open(this.appId).then();
  }
}
