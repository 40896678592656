import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Refund } from '../models/refund.model';
import { PolicyFO } from '../models/policy.model';
import { WsService } from '../../gfl-core/gfl-services/ws.service';

@Injectable({
  providedIn: 'root',
})
export class RefundService {
  /**
   * @ignore
   */
  constructor(private wsSrv: WsService) {}

  /**
   * Save new refund to BO
   *
   * @param refund refund object
   * @param customerId customer's id
   */
  public putRefund(refund: Refund, customerId: number): Observable<any> {
    const params = { customer_id_linked: customerId };
    return this.wsSrv.postRefund(refund, params);
  }

  /**
   * Return an observable of refunds array corresponding to the policyId
   *
   * @param policy policy object
   */
  public getRefundsByPolicy(policy: PolicyFO): Observable<Array<Refund>> {
    return this.wsSrv.requestRefunds(policy.policy_id, { customer_id_linked: policy.user_id }).pipe(
      map((master) => {
        const refunds: Array<Refund> = Object.keys(master).map((key) => master[key]);

        return refunds;
      })
    );
  }
}
