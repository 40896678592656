import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import * as _ from 'lodash';

import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

import { Contract, DefaultAddress, FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Customer } from '../../../customer/models/customer.model';
import { CustomerService } from '../../../customer/services/customer.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { AgencyService } from '../../../gfl-core/gfl-services/agency.service';

@Component({
  selector: 'gfl-signup-broker',
  templateUrl: './signup-broker.component.html',
  styleUrls: ['./signup-broker.component.scss'],
})
export class SignupBrokerComponent implements OnInit {
  @Input() customer: Customer;
  @Input() signupProcess: boolean;
  public customer$: Observable<Customer>;
  public contract$: Observable<Contract>;
  public language = 'fr';
  public logo$: Observable<string>;
  public style$: Observable<FrontTheme>;
  public agencyName$: Observable<string>;
  public agencyAddress$: Observable<DefaultAddress>;
  public errorDisplay: boolean;

  /**
   * @ignore
   */
  constructor(
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private store: StoreService,
    private agencySrv: AgencyService,
    private customerSrv: CustomerService,
    public tools: ToolsService
  ) {}

  /**
   * @ignore
   */
  ngOnInit() {
    if (['fr', 'de', 'en'].indexOf(this.translate.currentLang) > -1) {
      this.language = this.translate.currentLang;
    }

    this.style$ = this.store.getStyle();
    this.logo$ = this.agencySrv.getPublicLogo();
    this.agencyAddress$ = this.agencySrv.getAgencyAddress();
    this.agencyName$ = this.agencySrv.getAgencyName();

    const customerApiToken = this.signupProcess && this.customer.api_token;
    const customerLinkedId = !this.signupProcess && this.customer.id;
    this.contract$ = this.agencySrv.getContractTemplate(customerLinkedId, customerApiToken).pipe(
      catchError((err) => {
        this.tools.error('SignupBrokerComponent', err);
        this.errorDisplay = true;
        return of(undefined);
      })
    );

    this.customer$ = this.setCustomerData().pipe(
      catchError((err) => {
        this.tools.error('SignupBrokerComponent', err);
        this.errorDisplay = true;
        return of(undefined);
      })
    );
  }

  /**
   * Shut current modal with hasAcceptedCg attribute
   */
  public acceptBroker(): void {
    this.modalCtrl.dismiss({ hasAcceptedCg: true }).then();
  }

  /**
   * Set customer attribute
   */
  private setCustomerData(): Observable<Customer> {
    let obs$;

    if (this.customer.id) {
      obs$ = this.customerSrv.getCustomer(this.customer.id);
    } else {
      obs$ = of(this.customer);
    }
    return obs$.pipe(
      map((customer: Customer) => {
        const customerCloned = _.cloneDeep(customer) as Customer;

        customerCloned.dob = customerCloned.dob ? moment(customerCloned.dob).format('DD/MM/YYYY') : null;

        return customerCloned;
      }),
      shareReplay()
    );
  }
}
