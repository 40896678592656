import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import * as _ from 'lodash';

import { first, map, mergeMap } from 'rxjs/operators';
import { forkJoin, Observable, Subject, throwError, TimeoutError } from 'rxjs';

import { StoreService } from './store.service';
import { ToolsService } from './tools.service';
import { NetworkMonitorService } from './network-monitor.service';
import { ErrorType } from '../gfl-models/error-type.enum';

import { environment } from '../../../environments/environment';

import { gflErrorManager } from '../gfl-operators/gfl-error-manager';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private cancelPendingRequests$: Subject<void> = new Subject<void>();

  /**
   * @ignore
   */
  constructor(
    private http: HttpClient,
    private store: StoreService,
    private platform: Platform,
    private tools: ToolsService,
    private network: NetworkMonitorService
  ) {}

  /**
   * A GET Http request
   *
   * @param path (must begin with /)
   * @param params HTTP params
   * @param responseType HTTP response type
   * @param getHeaders response will contain headers if true
   * @param noAgency flag to remove agency_id from params
   */
  public httpGet(
    path: string,
    params?: any,
    responseType?: string,
    getHeaders?: boolean,
    noAgency?: boolean
  ): Observable<any> {
    const url = environment.API_URL + path;

    return this.getDefaultParams(path, params, noAgency).pipe(
      mergeMap((httpOptions) => {
        if (responseType) {
          httpOptions = Object.assign(httpOptions, { responseType });
        }

        if (getHeaders) {
          httpOptions.observe = 'response';
        }

        return this.http.get(url, httpOptions).pipe(gflErrorManager(500, 2, this.handleError));
      })
    );
  }

  /**
   * A POST Http request
   *
   * @param path (must begin with /)
   * @param params HTTP params
   * @param data data transmitted
   * @param noAgency flag to remove agency_id from params
   * @param noApiToken flag to remove api_token from params
   */
  public httpPost(path: string, params?: any, data?: any, noAgency?: boolean, noApiToken?: boolean): Observable<any> {
    const url = environment.API_URL + path;

    return this.getDefaultParams(path, params, noAgency, noApiToken).pipe(
      mergeMap((httpOptions) => this.http.post(url, data, httpOptions).pipe(gflErrorManager(2000, 3, this.handleError)))
    );
  }

  /**
   * A PUT Http request
   *
   * @param path (must begin with /)
   * @param params HTTP params
   * @param data data transmitted
   * @param noAgency flag to remove agency_id from params
   * @param noApiToken flag to remove api_token from params
   */
  public httpPut(path: string, params?: any, data?: object, noAgency?: boolean, noApiToken?: boolean): Observable<any> {
    const url = environment.API_URL + path;

    return this.getDefaultParams(path, params, noAgency, noApiToken).pipe(
      mergeMap((httpOptions) => this.http.put(url, data, httpOptions).pipe(gflErrorManager(2000, 3, this.handleError)))
    );
  }

  /**
   * A DELETE Http request
   *
   * @param path (must begin with /)
   * @param params HTTP params
   */
  public httpDelete(path: string, params?: object): Observable<any> {
    const url = environment.API_URL + path;

    return this.getDefaultParams(path, params).pipe(
      mergeMap((httpOptions) => this.http.delete(url, httpOptions).pipe(gflErrorManager(500, 3, this.handleError)))
    );
  }

  /**
   * Cancels all pending Http requests.
   */
  public cancelPendingRequests(): void {
    this.cancelPendingRequests$.next();
  }

  /**
   * Return cancelPendingRequests$ as an Observable
   */
  public onCancelPendingRequests(): Observable<any> {
    return this.cancelPendingRequests$.asObservable();
  }

  /**
   * Http Error Handling
   *
   * @param error the HTTP error response
   */
  // TODO fix pb with dismissOnPageChange option
  //  TODO Don't display timeout error for file upload
  private handleError = (error: HttpErrorResponse): Observable<never> => {
    if (error instanceof TimeoutError) {
      // this.notificationSrv.showError({
      //   message: ErrorType.REQUEST_TIME_OUT,
      //   // dismissOnPageChange: true,
      //   showCloseButton: true,
      // });
    }
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this.tools.error('An error occurred:', error);
    }

    if (error.status >= 400) {
      if (error.error.msgs) {
        if (typeof error.error.msgs === 'string') {
          throw error.error.msgs;
        } else {
          const key = Object.keys(error.error.msgs)[0];
          throw _.isArray(error.error.msgs[key]) ? error.error.msgs[key][0] : error.error.msgs[key];
        }
      } else if (error.error.errors) {
        if (typeof error.error.errors === 'string') {
          throw error.error.errors;
        } else {
          const key = Object.keys(error.error.errors)[0];
          throw _.isArray(error.error.errors[key]) ? error.error.errors[key][0] : error.error.errors[key];
        }
      } else {
        switch (error.status) {
          case 401:
            throw ErrorType.NOT_AUTHORISED;
          case 404:
            throw ErrorType.DOCUMENT_NOT_FOUND;
          case 409:
            return throwError(error);
          default:
            throw ErrorType.HTTP_RESPONSE;
        }
      }
    }

    if (!error.status) {
      throw ErrorType.HTTP_RESPONSE;
    }
  };

  /**
   * Returns an observable of default http params
   *
   * @param path API url to reach
   * @param httpParams HTTP params
   * @param noAgency flag to remove agency_id from params
   * @param noApiToken flag to remove api_token from params
   */
  private getDefaultParams(path: string, httpParams?: any, noAgency?: boolean, noApiToken?: boolean): Observable<any> {
    const language$ = this.store.getLang().pipe(first());
    const headers = { Authorization: environment.API_AUTH };

    if (path.indexOf('/v2') > -1) {
      headers['Accept'] = 'application/x.BO.v2+json';
    }

    return this.network.isOffline().pipe(
      first(),
      mergeMap((isOffline) => {
        if (isOffline) {
          return throwError({
            errors: {
              unknown: ['NETWORK.NOT_AVAILABLE'],
            },
          });
        } else {
          return forkJoin([language$, this.store.getAuthData().pipe(first())]).pipe(
            map(([languageIso, authData]) => {
              let params = {
                language_iso: languageIso,
              };

              if (authData.customerToken) {
                // @ts-ignore
                params.agency_id = authData.agencyId;
              } else if (!noAgency && environment.AGENCY_ID) {
                // @ts-ignore
                params.agency_id = environment.AGENCY_ID;
              }

              if (authData.customerToken && !noApiToken) {
                // @ts-ignore
                params.api_token = authData.customerToken;
              }

              // remove customer_id_linked if same as customer logged in
              if (
                httpParams &&
                ((httpParams.customer_id_linked && httpParams.customer_id_linked === authData.customerId) ||
                  httpParams.customer_id_linked === undefined)
              ) {
                delete httpParams.customer_id_linked;
              }

              params = Object.assign(params, httpParams);

              return {
                headers: new HttpHeaders(headers),
                params,
              };
            })
          );
        }
      })
    );
  }
}
