import { Component, Input, OnDestroy } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { forkJoin, Observable, Subscription } from 'rxjs';
import { first, switchMap, withLatestFrom } from 'rxjs/operators';

import { PolicyFO } from '../../models/policy.model';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { CompanyService } from '../../../gfl-core/gfl-services/company.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { AgencyService } from '../../../gfl-core/gfl-services/agency.service';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'gfl-policy-claim',
  templateUrl: './policy-claim.component.html',
  styleUrls: ['./policy-claim.component.scss'],
})
export class PolicyClaimComponent implements OnDestroy {
  @Input() policy: PolicyFO;
  public lang: string;
  @Input() acls$: Observable<{
    INSURANCE_DETAIL_CLAIM_REQUEST: boolean;
    INSURANCE_DETAIL_CLAIM_REQUEST_CALL_ASSISTANCE: boolean;
    INSURANCE_DETAIL_CLAIM_REQUEST_CALL_EMERGENCY: boolean;
    INSURANCE_CLAIM_REQUEST: boolean;
  }>;
  private subscriptions: Subscription[] = [];
  private appName: string;

  /**
   * @ignore
   */
  constructor(
    private translate: TranslateService,
    private companySrv: CompanyService,
    private agencySrv: AgencyService,
    public tools: ToolsService,
    private alertCtrl: AlertController,
    private store: StoreService,
    private modalCtrl: ModalController,
    public platform: Platform
  ) {
    this.appName = environment.APP_NAME;
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then();
  }

  /**
   * Call the company contact
   */
  public callCompany(): void {
    let phoneNumber: string;

    this.companySrv
      .getCompanyById(this.policy.company.id)
      .pipe(
        switchMap((respCompany) => {
          phoneNumber = respCompany.contact ? respCompany.contact.phone : '';

          return this.translate.get(['INSURANCE.CLAIM.CALL_ASSISTANCE', 'COMMON.BUTTON_CANCEL', 'COMMON.BUTTON_CALL'], {
            company: respCompany.company.name,
            phone: phoneNumber,
          });
        }),
        withLatestFrom(this.agencySrv.getAgency())
      )
      .subscribe(
        ([result, broker]) => {
          this.alertCtrl
            .create({
              header: broker.name,
              message: result['INSURANCE.CLAIM.CALL_ASSISTANCE'],
              buttons: [
                {
                  text: result['COMMON.BUTTON_CALL'],
                  cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
                  handler: () => {
                    this.callAction(phoneNumber).then();
                  },
                },
                {
                  text: result['COMMON.BUTTON_CANCEL'],
                  cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
                  handler: () => {},
                },
              ],
            })
            .then((alert) => alert.present());
        },
        (err) => this.tools.error('callCompany getCompanyById Error', err)
      );
  }

  /**
   * Confirm call before launching phone
   */
  public call(phoneNumber: string): void {
    forkJoin([
      this.translate.get(['INSURANCE.CLAIM.CALL_EMERGENCY', 'COMMON.BUTTON_CANCEL', 'COMMON.BUTTON_CALL']),
      this.agencySrv.getAgencyName().pipe(first()),
    ]).subscribe(async ([result, brokerName]) => {
      const alert = await this.alertCtrl.create({
        header: brokerName,
        message: result['INSURANCE.CLAIM.CALL_EMERGENCY'],
        buttons: [
          {
            text: result['COMMON.BUTTON_CALL'],
            cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
            handler: () => {
              this.callAction(phoneNumber);
            },
          },
          {
            text: result['COMMON.BUTTON_CANCEL'],
            cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
            handler: () => {},
          },
        ],
      });
      await alert.present();
    });
  }

  /**
   * Close Current modal
   */
  public closeModal(): void {
    this.modalCtrl.dismiss().then();
  }

  /**
   * open a web window (works well with native device)
   *
   * @param phoneNumber phone number
   */
  private async callAction(phoneNumber: string): Promise<void> {
    window.open('tel:' + phoneNumber, '_system');
  }
}
