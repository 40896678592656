import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';

import { uiReducer, UiState } from './ui.reducer';
import { agencyReducer, AgencyState } from './agency.reducer';
import { comparesReducer, ComparesState } from '../compares/reducers';
import { documentsReducer, DocumentsState } from './documents.reducer';
import { safeboxReducer, SafeboxState } from '../safebox/reducers/safebox.reducer';
import { locksReducer, LocksState } from './locks.reducer';
import { permissionsReducer, PermissionsState } from './permissions.reducer';

import { environment } from '../../environments/environment';

export interface GflState {
  [x: string]: any;
  router: any;
  ui: UiState;
  agency: AgencyState;
  documents: DocumentsState;
  compares: ComparesState;
  safebox: SafeboxState;
  locks: LocksState;
  permissions: PermissionsState;
}

export const reducers: ActionReducerMap<GflState> = {
  router: routerReducer,
  ui: uiReducer,
  agency: agencyReducer,
  documents: documentsReducer,
  compares: comparesReducer,
  safebox: safeboxReducer,
  locks: locksReducer,
  permissions: permissionsReducer,
};

// const logger = (reducer: ActionReducer<any>): ActionReducer<any> => (state, action) => {
//   console.log('state before: ', state);
//   console.log('action', action);
//
//   return reducer(state, action);
// };

export const metaReducers: MetaReducer<GflState>[] = !environment.production ? [] : [];
