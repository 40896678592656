import { createReducer, MetaReducer, on } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { AuthActions } from './action-types';

export const authFeatureKey = 'auth';

export interface AuthState {
  isLoggedIn: boolean;
  customerToken: string;
  customerLogin: string;
  customerId: number;
  agencyId: number;
  agencies: { [id: string]: string };
  customerTypeId: number;
  language: string;
  rememberMe: boolean;
  customerRefreshedTimeStamp: number;
}

const initialAuthState: AuthState = {
  isLoggedIn: false,
  customerToken: undefined,
  customerLogin: undefined,
  customerId: undefined,
  agencyId: undefined,
  agencies: undefined,
  customerTypeId: undefined,
  language: undefined,
  rememberMe: false,
  customerRefreshedTimeStamp: undefined,
};

export const authReducer = createReducer(
  initialAuthState,

  on(AuthActions.initialize, (state, action) => ({
    ...action.authState,
  })),

  on(AuthActions.login, (state, action) => ({
    isLoggedIn: action.authData.isLoggedIn,
    customerToken: action.authData.customerToken,
    customerLogin: action.authData.customerLogin,
    customerTypeId: action.authData.customerTypeId,
    customerId: action.authData.customerId,
    agencyId: action.authData.agencyId,
    agencies: action.authData.agencies,
    language: action.authData.language,
    rememberMe: action.authData.rememberMe,
    customerRefreshedTimeStamp: action.authData.customerRefreshedTimeStamp,
  })),

  on(AuthActions.loginOnSwitchMode, (state, action) => ({
    isLoggedIn: action.authData.isLoggedIn,
    customerToken: action.authData.customerToken,
    customerLogin: action.authData.customerLogin,
    customerTypeId: action.authData.customerTypeId,
    customerId: action.authData.customerId,
    agencyId: action.authData.agencyId,
    agencies: action.authData.agencies,
    language: action.authData.language,
    rememberMe: action.authData.rememberMe,
    customerRefreshedTimeStamp: action.authData.customerRefreshedTimeStamp,
  })),

  on(AuthActions.logout, (state) => ({
    isLoggedIn: false,
    customerToken: undefined,
    customerLogin: state.rememberMe ? state.customerLogin : undefined,
    customerTypeId: undefined,
    customerId: undefined,
    agencyId: undefined,
    agencies: undefined,
    rememberMe: state.rememberMe,
    language: undefined,
    customerRefreshedTimeStamp: undefined,
  })),

  on(AuthActions.credential, (state, action) => ({
    ...state,
    rememberMe: action.rememberMe,
  })),

  on(AuthActions.updateCustomerRefreshedTimeStamp, (state, action) => ({
    ...state,
    customerRefreshedTimeStamp: action.customerRefreshedTimeStamp,
  })),

  on(AuthActions.setRememberMe, (state, action) => ({
    ...state,
    rememberMe: action.rememberMe,
  })),

  on(AuthActions.setIsLoggedIn, (state, action) => ({
    ...state,
    isLoggedIn: action.isLoggedIn,
  }))
);

export const metaReducers: MetaReducer<AuthState>[] = !environment.production ? [] : [];
