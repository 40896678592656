import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { SignaturePadComponent } from '@almothafar/angular-signature-pad';

import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';

@Component({
  selector: 'gfl-signature-full-screen',
  templateUrl: './signature-full-screen.component.html',
  styleUrls: ['./signature-full-screen.component.scss'],
})
export class SignatureFullScreenComponent implements AfterViewInit {
  @ViewChild('signature') signaturePad: SignaturePadComponent;

  /**
   * @ignore
   */
  constructor(
    private modalCtrl: ModalController,
    private notificationSrv: NotificationService,
    public platform: Platform
  ) {}

  /**
   * @ignore
   */
  ngAfterViewInit() {
    setTimeout(() => {
      // Init canvas
      const footerHeight = document.querySelector('gfl-signature-full-screen ion-footer').clientHeight;
      const headerHeight = document.querySelector('gfl-signature-full-screen ion-header').clientHeight;
      const helperHeight = document.querySelector('.helper-section').clientHeight;
      const modalHeight = document.querySelector('gfl-signature-full-screen').clientHeight;
      const modalWidth = document.querySelector('gfl-signature-full-screen').clientWidth;

      this.signaturePad.set('canvasWidth', modalWidth - 40);
      this.signaturePad.set('canvasHeight', modalHeight - (footerHeight + headerHeight + helperHeight + 50));
      this.signaturePad.clear();
    }, 300);
  }

  /**
   * Remove data from canvas
   */
  public clearCanvas() {
    this.signaturePad.clear();
  }

  public validateSignature() {
    if (this.signaturePad.isEmpty()) {
      return this.notificationSrv.showError({ message: 'SIGNATURE.MANDATORY' });
    }

    this.modalCtrl
      .dismiss({
        signatureImg: this.signaturePad.toDataURL(),
      })
      .then();
  }

  public closeModal() {
    this.modalCtrl.dismiss().then();
  }
}
