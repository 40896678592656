import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';
import { Camera, ImageOptions, Photo, CameraResultType, CameraSource } from '@capacitor/camera';
import { ActionSheetController, LoadingController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import * as _ from 'lodash';

import { forkJoin, of, Subscription } from 'rxjs';
import { filter, finalize, first, mergeMap } from 'rxjs/operators';

import { CustomerService } from '../../services/customer.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { GflFormGeneratorService } from '../../../gfl-libraries/gfl-form-generator/services/gfl-form-generator.service';
import { ImageService } from '../../../gfl-core/gfl-services/image.service';
import { ItemService } from '../../../gfl-core/gfl-services/item.service';
import { Customer, CustomerFamilyMember } from '../../models/customer.model';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { DocumentService } from '../../../gfl-core/gfl-services/document.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { AgencyService } from '../../../gfl-core/gfl-services/agency.service';
import { GflFormItem, GflModeDisplayType } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form.model';
import { Agency, FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Contact } from '../../../gfl-core/gfl-models/contact.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'gfl-member-identity',
  templateUrl: './member-identity.component.html',
  styleUrls: ['./member-identity.component.scss'],
})
export class MemberIdentityComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedMember: Customer | CustomerFamilyMember;
  @Input() selectedMemberName: string;
  @Input() FOREIGN_KEY_CUSTOMER: number;

  @Input() isPro: boolean;
  @Input() style: FrontTheme;
  @Input() acls: Acls;
  @Input() isOffline: boolean;

  public customer: Customer;
  public customerPhone: { phone: string; prefix: string };
  public customerAddress: {
    street: string;
    street2: string;
    postcode: string;
    cityId: number;
    countryName: string;
  };
  public idCard: string;
  public countryPrefixOptions: Array<object>;
  public isEditMode = false;
  public agency: Agency;
  public loader: HTMLIonLoadingElement;
  private cameraOptions: ImageOptions;
  readonly isNative: boolean;

  private contact: Contact;
  public customerFormData: Array<GflFormItem>;
  public phoneFormData: Array<GflFormItem>;
  public emailFormData: Array<GflFormItem>;
  public modeDisplay: GflModeDisplayType;
  private subscriptions: Subscription[] = [];

  public identityForm: FormGroup;
  public validatorsForm: { [id: string]: Array<ValidatorFn> } = {};
  public initialFormValues: { [id: string]: any };
  private initialPhoneFormValues: { [id: string]: any };
  private initialEmailFormValues: { [id: string]: any };

  readonly DOCUMENT_CATEGORY_ID_ID_CARD: number;

  private ITEM_TEMPLATE_KEY_CUSTOMER: string;
  readonly ITEM_TEMPLATE_KEY_EMAIL: string;
  readonly ITEM_TEMPLATE_KEY_PHONE: string;

  @ViewChild('inputFileCamera', { static: false }) inputFileCamera: ElementRef;

  /**
   * @ignore
   */
  constructor(
    private customerSrv: CustomerService,
    private documentSrv: DocumentService,
    private translate: TranslateService,
    private actionSheetCtrl: ActionSheetController,
    private socialSharing: SocialSharing,
    public notificationSrv: NotificationService,
    public tools: ToolsService,
    private imageSrv: ImageService,
    private loadingCtrl: LoadingController,
    private gflFormSrv: GflFormGeneratorService,
    private constantSrv: ConstantService,
    private itemSrv: ItemService,
    private agencySrv: AgencyService,
    public platform: Platform
  ) {
    this.isNative = this.tools.isNative();
    this.cameraOptions = {
      quality: 50,
      resultType: CameraResultType.DataUrl,
      // encodingType: this.camera.EncodingType.JPEG,
      // mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      height: 1800,
      width: 1350,
    };
    this.ITEM_TEMPLATE_KEY_EMAIL = 'email';
    this.ITEM_TEMPLATE_KEY_PHONE = 'phone';
    this.DOCUMENT_CATEGORY_ID_ID_CARD = this.constantSrv.getValueFromKey('DOCUMENT_CATEGORY_ID_ID_CARD');
    this.countryPrefixOptions = environment.PREFIX_COUNTRY;
  }

  /**
   * @ignore
   */
  ngOnInit() {
    this.setModeDisplay();

    this.subscriptions.push(this.agencySrv.getMyContact().subscribe((contact) => (this.contact = contact)));
    this.subscriptions.push(this.agencySrv.getAgency().subscribe((agency) => (this.agency = agency)));
  }

  /**
   * @ignore
   * @param changes changed values
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedMember && this.FOREIGN_KEY_CUSTOMER) {
      const CUSTOMER_TYPE_ID_CORPORATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_CORPORATE');

      this.ITEM_TEMPLATE_KEY_CUSTOMER =
        this.selectedMember.customer_type_id === CUSTOMER_TYPE_ID_CORPORATE
          ? 'item_templates_pro'
          : 'item_templates_private';

      // set IdCard picture if any...
      this.setDocuments();
      // set current customer
      this.setData();
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then();
  }

  /**
   * ...
   *
   * @ignore
   */
  public submit(): void {
    // To be implemented if needed...
  }

  /**
   * Get picture
   */
  public takePicture(): void {
    if (this.isNative) {
      this.takeNativePicture();
    } else {
      this.inputFileCamera.nativeElement.click();
    }
  }

  /**
   * Launch actionSheet action on edit mode
   */
  public onEditMode(): void {
    this.translate
      .get(
        ['COMMON.CONTACT_TITLE', 'COMMON.CALL_US', 'COMMON.BUTTON_CANCEL', 'COMMON.SEND_US_EMAIL', 'MAIL_DATA_CHANGED'],
        { agency_name: this.agency.name }
      )
      .subscribe(async (result) => {
        const actionSheet = await this.actionSheetCtrl.create({
          header: result['COMMON.CONTACT_TITLE'],
          buttons: [
            {
              text: result['COMMON.CALL_US'],
              cssClass: 'gfl-alert-validate-btn',
              icon: !this.platform.is('ios') ? 'call' : null,
              handler: () => {
                const phoneNumber = this.contact.phone;
                this.callAction(phoneNumber);
              },
            },
            {
              text: result['COMMON.SEND_US_EMAIL'],
              cssClass: 'gfl-alert-validate-btn',
              icon: !this.platform.is('ios') ? 'mail' : null,
              handler: () => {
                // Check if sharing via email is supported
                this.socialSharing
                  .canShareViaEmail()
                  .then(() => {
                    // Sharing via email is possible
                    this.sendEmailAction(result['COMMON.SEND_US_EMAIL']);
                  })
                  .catch(() => {
                    // Sharing via email is not possible
                    this.notificationSrv.showError({ message: 'API.ERROR_MESSAGE' });
                  });
              },
            },
            {
              text: result['COMMON.BUTTON_CANCEL'],
              role: 'cancel', // will always sort to be on the bottom
              icon: !this.platform.is('ios') ? 'close' : null,
              handler: () => {},
            },
          ],
        });
        await actionSheet.present();
      });
  }

  /**
   * Add a picture ID_Card
   *
   * @param event DOM event
   */
  public addPicture(event: Event): void {
    this.imageSrv.handleSelectedImage(event).subscribe(
      (picture: string) => {
        this.idCard = picture;
        this.saveIdCard(picture);
      },
      (err) => {
        this.tools.error('SafeboxEditComponent addPicture', err);
        this.notificationSrv.showError({
          message: 'API.ERROR_MESSAGE',
        });
      }
    );
  }

  /**
   * Set modeDisplay to mobile or tablet
   */
  private setModeDisplay(): void {
    this.modeDisplay = this.tools.setModeDisplay();
  }

  /**
   * Set SelectedCustomer and formData attributes
   */
  private setData(): void {
    forkJoin([
      this.customerSrv.getCustomer(this.selectedMember.id).pipe(
        filter((val) => !_.isEmpty(val)),
        first()
      ),
      this.customerSrv
        .getCustomerItemsByItemTemplateKey(this.selectedMember.id, this.ITEM_TEMPLATE_KEY_CUSTOMER)
        .pipe(first()),
      this.customerSrv
        .getCustomerItemsByItemTemplateKey(this.selectedMember.id, this.ITEM_TEMPLATE_KEY_PHONE)
        .pipe(first()),
      this.customerSrv
        .getCustomerItemsByItemTemplateKey(this.selectedMember.id, this.ITEM_TEMPLATE_KEY_EMAIL)
        .pipe(first()),
      this.itemSrv
        .getItemTemplateByItemTemplateKey(
          this.FOREIGN_KEY_CUSTOMER,
          true,
          this.ITEM_TEMPLATE_KEY_CUSTOMER,
          this.selectedMember.api_token
        )
        .pipe(first()),
      this.itemSrv
        .getItemTemplateByItemTemplateKey(
          this.FOREIGN_KEY_CUSTOMER,
          true,
          this.ITEM_TEMPLATE_KEY_PHONE,
          this.selectedMember.api_token
        )
        .pipe(first()),
      this.itemSrv
        .getItemTemplateByItemTemplateKey(
          this.FOREIGN_KEY_CUSTOMER,
          true,
          this.ITEM_TEMPLATE_KEY_EMAIL,
          this.selectedMember.api_token
        )
        .pipe(first()),
    ]).subscribe(
      ([
        customer,
        customerItems,
        phoneItems,
        emailItems,
        itemsTemplateCustomer,
        itemsTemplatePhone,
        itemsTemplateEmail,
      ]) => {
        // @ts-ignore
        this.customer = customer;

        this.customerAddress = {
          street:
            this.customer &&
            this.customer.default_address &&
            this.customer.default_address.address &&
            this.customer.default_address.address.street,
          street2:
            this.customer &&
            this.customer.default_address &&
            this.customer.default_address.address &&
            this.customer.default_address.address.street2,
          postcode:
            this.customer &&
            this.customer.default_address &&
            this.customer.default_address.address &&
            this.customer.default_address.address.postcode,
          cityId:
            this.customer &&
            this.customer.default_address &&
            this.customer.default_address.address &&
            this.customer.default_address.address.city_id,
          countryName:
            this.customer &&
            this.customer.default_address &&
            this.customer.default_address.country &&
            this.customer.default_address.country.name,
        };
        // @ts-ignore
        const itemsData = this.gflFormSrv.setFormData(customerItems, itemsTemplateCustomer);
        // @ts-ignore
        const itemsPhoneData = this.gflFormSrv.setFormData(phoneItems, itemsTemplatePhone, true);
        // @ts-ignore
        const itemsEmailData = this.gflFormSrv.setFormData(emailItems, itemsTemplateEmail, true);

        // this step is needed because we won't use the GflFormGenerator but directly GflFieldGenerator...
        this.customerFormData = this.gflFormSrv.formatItemsTemplate(itemsData);
        this.phoneFormData = this.gflFormSrv.formatItemsTemplate(itemsPhoneData);
        this.emailFormData = this.gflFormSrv.formatItemsTemplate(itemsEmailData);
        // we store initial values to be used in case of cancellation
        this.initialFormValues = this.gflFormSrv.getFormInitialValues(this.customerFormData);
        this.initialPhoneFormValues = this.gflFormSrv.getFormInitialValues(this.phoneFormData);
        this.initialEmailFormValues = this.gflFormSrv.getFormInitialValues(this.emailFormData);

        this.initForm();
      },
      (err) => {
        this.tools.error('MemberHealthComponent ngOnInit()', err);
      }
    );
  }

  /**
   * Generate dynamic form
   */
  private initForm(): void {
    this.identityForm = new FormGroup({});

    this.validatorsForm = {
      email: [],
      address1: [],
      address2: [],
      zipCodeCity: [],
      phone: [],
    };

    this.initialFormValues = {
      ...this.initialFormValues,
      ...this.initialPhoneFormValues,
      ...this.initialEmailFormValues,
      email: this.customer.email,
      address1: this.customerAddress.street,
      address2: this.customerAddress.street2,
      zipCodeCity: this.customerAddress,
      countryName: this.customerAddress.countryName,
      phone: this.customerPhone,
    };
  }

  /**
   * Set documents related to customer
   */
  private setDocuments() {
    this.idCard = null;

    this.documentSrv
      .getDocumentByTypeAndCategory(this.DOCUMENT_CATEGORY_ID_ID_CARD, this.selectedMember.id)
      .pipe(
        mergeMap((document) => {
          if (this.tools.isNative() && document.device_uri) {
            return this.documentSrv.getFileData(document.id, document.file_type, document.device_uri);
          } else if (document.checksum) {
            return this.documentSrv.getDocumentByChecksum(document.checksum, document.customer_id);
          } else {
            return of(null);
          }
        })
      )
      .subscribe(
        (document) => {
          if (!document) {
            return (this.idCard = 'none');
          } else if (document instanceof Blob) {
            const reader = this.tools.getFileReader();
            reader.onloadend = () => {
              // @ts-ignore
              this.idCard = reader.result;
            };
            reader.readAsDataURL(document);
          } else {
            this.idCard = document;
          }
        },
        () => (this.idCard = 'none')
      );
  }

  /**
   * Select Source type in order to deliver the picture
   */
  private takeNativePicture() {
    this.translate
      .get(['COMMON.PHOTO_FROM_CAMERA', 'COMMON.PHOTO_FROM_LIBRARY', 'COMMON.BUTTON_CANCEL'])
      .subscribe(async (result) => {
        const actionSheet = await this.actionSheetCtrl.create({
          header: result['COMMON.CONTACT_TITLE'],
          buttons: [
            {
              text: result['COMMON.PHOTO_FROM_CAMERA'],
              cssClass: 'gfl-alert-validate-btn',
              icon: !this.platform.is('ios') ? 'camera' : null,
              handler: () => {
                this.cameraOptions.source = CameraSource.Camera;
                this.getPicture();
              },
            },
            {
              text: result['COMMON.PHOTO_FROM_LIBRARY'],
              cssClass: 'gfl-alert-info-btn',
              icon: !this.platform.is('ios') ? 'images' : null,
              handler: () => {
                this.cameraOptions.source = CameraSource.Photos;
                this.getPicture();
              },
            },
            {
              text: result['COMMON.BUTTON_CANCEL'],
              role: 'cancel', // will always sort to be on the bottom
              icon: !this.platform.is('ios') ? 'close' : null,
              handler: () => {},
            },
          ],
        });
        await actionSheet.present();
      });
  }

  /**
   * Use Native Camera or Photo Library to get the picture
   */
  private getPicture(): void {
    Camera.getPhoto(this.cameraOptions).then(
      (imageData: Photo) => {
        this.idCard = imageData.dataUrl;
        this.saveIdCard(imageData.base64String);
      },
      (err) => {
        this.tools.error('MemberIdentityComponent getPicture', err);
        if (typeof err !== 'string') {
          this.notificationSrv.showError({
            message: 'API.ERROR_MESSAGE',
          });
        }
      }
    );
  }

  /**
   * Save IdCard of the current member displayed
   *
   * @param picture base64 encoded string
   */
  private saveIdCard(picture: string) {
    picture = picture.replace(/data:image\/.{3,4};base64,/, '');

    this.tools.showLoader();

    const document = {
      path: picture,
      tmp: picture,
      name: this.selectedMember.id + '_id_card.jpg',
      ext: 'jpg',
    };

    return this.documentSrv
      .saveDocument(document, this.selectedMember.id, this.DOCUMENT_CATEGORY_ID_ID_CARD)
      .pipe(finalize(() => this.tools.hideLoader()))
      .subscribe(
        () => {},
        () => {
          this.notificationSrv.showError({ message: 'SIGNATURE.MANDATE_ERROR' });
        }
      );
  }

  /**
   * open a web window (works well with native device)
   *
   * @param phoneNumber phone number
   */
  private async callAction(phoneNumber: string): Promise<void> {
    window.open('tel:' + phoneNumber, '_system');
  }

  /**
   * Send Email
   *
   * @param body the body email
   */
  private sendEmailAction(body: string) {
    const email = {
      to: [this.contact.email],
      subject: 'Profile change : ' + this.customer.lastname + ' ' + this.customer.firstname,
      body,
      isHtml: true,
    };

    this.socialSharing
      .shareViaEmail(email.body, email.subject, email.to)
      .then(() => {
        this.notificationSrv.showSuccess({ message: 'COMMON.MESSAGE_SENT' });
      })
      .catch((err) => {
        this.tools.error('shareViaEmail result', err);
        this.notificationSrv.showError({ message: 'API.ERROR_MESSAGE' });
      });
  }
}
