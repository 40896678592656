import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import * as moment from 'moment';
import * as _ from 'lodash';

import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { PolicyFO } from '../../models/policy.model';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { RefundService } from '../../services/refund.service';
import { Refund } from '../../models/refund.model';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';

import { PolicyRefundEditComponent } from '../policy-refund-edit/policy-refund-edit.component';

import { Acls } from '../../../gfl-core/gfl-models/acls.model';

@Component({
  selector: 'gfl-policy-refund-history',
  templateUrl: './policy-refund-history.component.html',
  styleUrls: ['./policy-refund-history.component.scss'],
})
export class PolicyRefundHistoryComponent implements OnInit {
  public refunds$: Observable<Array<Refund>>;
  @Input() policy: PolicyFO;
  @Input() style: FrontTheme;
  @Input() acls: Acls;
  public errorDisplay: boolean;
  public noDataDisplay: string;

  /**
   * @ignore
   */
  constructor(
    private store: StoreService,
    public tools: ToolsService,
    private modalCtrl: ModalController,
    private refundSrv: RefundService,
    public platform: Platform
  ) {}

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.refunds$ = this.refundSrv.getRefundsByPolicy(this.policy).pipe(
      map((refunds) => {
        this.errorDisplay = false;
        refunds = _.sortBy(refunds, (o: Refund) => moment(o.refund_invoice_date)).reverse();

        if (!refunds || !refunds.length) {
          this.noDataDisplay = 'COMMON.NO_DATA';
        }

        return refunds;
      }),
      catchError(() => {
        this.errorDisplay = true;
        return of(null);
      }),
      shareReplay()
    );
  }

  /**
   *  Display edit modal
   *
   * @param refund refund object
   */
  public async openRefundEditModal(refund: Refund): Promise<void> {
    const refundEditModal = await this.modalCtrl.create({
      component: PolicyRefundEditComponent,
      componentProps: {
        refund,
        style: this.style,
        acls: this.acls,
      },
    });

    await refundEditModal.present();
  }
}
