import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

import { Acls } from '../../gfl-models/acls.model';
import { NavigationService } from '../../gfl-services/navigation.service';
import { NotificationService } from '../../gfl-services/notification.service';
import { StoreService } from '../../gfl-services/store.service';
import { AclsService } from '../../gfl-services/acls.service';
import { SecurityService } from '../../gfl-services/security.service';
import { AgencyService } from '../../gfl-services/agency.service';
import { ToolsService } from '../../gfl-services/tools.service';
import { AuthService } from '../../../authentication/services/auth.service';
import { NetworkMonitorService } from '../../gfl-services/network-monitor.service';
import { map, tap } from 'rxjs/operators';
import { ContactsService } from '../../../contacts/services/contacts.service';
import { NotificationsDisplayComponent } from '../../../contacts/components/notifications-display/notifications-display.component';
import { ContactComponent } from '../../../contacts/components/contact/contact.component';

@Component({
  selector: 'gfl-header-tablet',
  templateUrl: './gfl-header-tablet.component.html',
  styleUrls: ['./gfl-header-tablet.component.scss'],
})
export class GflHeaderTabletComponent implements OnInit, OnDestroy {
  public activePage$: Observable<string>;
  public acls$: Observable<Acls>;
  private acls: Acls;
  public logo$: Observable<string>;
  public isPro$: Observable<boolean>;
  public isOffline: boolean;
  public notificationsNb$: Observable<number>;
  private subscriptions: Subscription[] = [];

  /**
   * @ignore
   */
  constructor(
    private router: Router,
    private store: StoreService,
    private aclsSrv: AclsService,
    private navigationSrv: NavigationService,
    private agencySrv: AgencyService,
    private tools: ToolsService,
    public translate: TranslateService,
    private authSrv: AuthService,
    private securitySrv: SecurityService,
    public notificationSrv: NotificationService,
    private network: NetworkMonitorService,
    private contactsSrv: ContactsService,
    private modalCtrl: ModalController
  ) {}

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.initAcls();
    this.logo$ = this.agencySrv.getPrivateLogo();
    this.isPro$ = this.store.getIsProSelected();
    this.activePage$ = this.navigationSrv.getActivatedPage();
    this.notificationsNb$ = this.contactsSrv
      .getNotificationsToDisplay()
      .pipe(map((notifications) => notifications.length));

    this.subscriptions.push(
      this.network.isOffline().subscribe((flag) => {
        this.isOffline = flag;
      })
    );
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then();
  }

  /**
   * Navigate to the page passed in parameter
   *
   * @param page page
   */
  public openPage(page: string) {
    this.router.navigateByUrl(page).then();
  }

  /**
   * Method called at init to ask for the code pin in order to enter the page
   *
   * @param successPath path
   */
  public showCodePin(successPath: string): void {
    this.securitySrv.showCodePin(successPath);
  }

  /**
   * Disconnect customer
   */
  public onLogout() {
    this.authSrv.logout();
  }

  public async openNotificationsModal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: NotificationsDisplayComponent,
      componentProps: {
        acls: this.acls,
      },
    });
    await modal.present();
  }

  public async openContactModal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ContactComponent,
      componentProps: {
        acls: this.acls,
        isOffline: this.isOffline,
      },
    });
    await modal.present();
  }

  /**
   * Initialize permissions for sidebar
   */
  private initAcls(): void {
    this.acls$ = this.aclsSrv.getAcls().pipe(tap((acls) => (this.acls = acls)));
  }
}
