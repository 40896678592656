import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RowPremiumFO } from '../../models/compare.model';

@Component({
  selector: 'gfl-compare-insurimmo-conditions',
  templateUrl: './compare-insurimmo-conditions.component.html',
  styleUrls: ['./compare-insurimmo-conditions.component.scss'],
})
export class CompareInsurimmoConditionsComponent implements OnInit {
  @Input() policy: RowPremiumFO;
  @Input() cgvFlag: boolean;
  @Input() cgaFlag: boolean;
  @Output() title = new EventEmitter<string>();
  @Output() nextButtonLabel = new EventEmitter<string>();
  @Output() enableNext = new EventEmitter<boolean>();
  @Output() openCgv = new EventEmitter<boolean>();
  @Output() openCga = new EventEmitter<boolean>();
  public cga: boolean;
  public cgv: boolean;

  constructor() {}

  ngOnInit() {
    this.title.emit('COMPARE.DISPLAY.CG');
    this.nextButtonLabel.emit('COMPARE.DISPLAY.SEE_OFFER_DOC');
  }

  public checkAgreement(): void {
    let enable: boolean;
    if (this.cgvFlag && this.cgaFlag) {
      enable = this.cga && this.cgv;
    } else if (this.cgv) {
      enable = this.cgv;
    } else if (this.cga) {
      enable = this.cga;
    }
    this.enableNext.emit(enable);
  }

  public onOpenCga(): void {
    this.openCga.emit(true);
  }

  public onOpenCgv(): void {
    this.openCgv.emit(true);
  }
}
