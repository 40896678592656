import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { format, parseISO } from 'date-fns';

import { ToolsService } from '../../../../gfl-core/gfl-services/tools.service';
import { GflThemeOptions } from '../../models/gfl-form-options.model';
import { GflModeDisplayType } from '../../models/gfl-form.model';
import { FrontTheme } from '../../../../gfl-core/gfl-models/agency.model';

@Component({
  selector: 'gfl-field-input-date',
  templateUrl: './gfl-field-input-date.component.html',
  styleUrls: ['./gfl-field-input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GflFieldInputDateComponent implements OnChanges {
  /**
   * initial value
   */
  @Input() value: string;

  /**
   * hide value and formatted value in order to display only label
   */
  @Input() hideValue: boolean;

  /**
   * the form implementing this field in two-way binding
   */
  @Input() form: FormGroup;
  @Output() formChange: EventEmitter<FormGroup>;

  /**
   * An array of validators to add to the formControl
   */
  @Input() validators: Array<ValidatorFn> = [];

  /**
   * Set type of displayed template between "mobile" and "tablet" options
   */
  @Input() modeDisplay: GflModeDisplayType;

  /**
   * Front theme style
   */
  @Input() style: FrontTheme;

  /**
   * Text used in ion-label component
   */
  @Input() label: string;

  /**
   * Text used in ion-label component
   */
  @Input() placeholder: string;

  /**
   * If isEditMode is false then the field value are read-only
   */
  @Input() isEditMode: boolean;

  /**
   * If isLabel is true then we only display label :)
   */
  @Input() isLabel: boolean;

  /**
   * name attribute for form control
   */
  @Input() name: any;

  /**
   * This is the value_reformat attribute provided by the BO
   * for objects using item_templates
   */
  @Input() formattedValue: string;

  /**
   * Color theme to apply to the component
   */
  @Input() theme: GflThemeOptions;

  /**
   * Date format used on display, by default 'DD/MM/YYYY'
   */
  @Input() displayFormat: string;

  /**
   * Picker format used on display, by default 'DD MM YYYY'
   */
  @Input() pickerFormat: string;

  /**
   * Text to display in the cancel button label
   */
  @Input() cancelText: string;

  /**
   * Text to display in the done button label
   */
  @Input() doneText: string;

  /**
   * if true then gfl-validation section is not displayed
   * this is used to display custom error message
   */
  @Input() noDisplayErrors: boolean;

  /**
   * readonly flag for HTML attribute field
   */
  @Input() readonly: boolean;

  /**
   * field required flag
   */
  @Input() required: boolean;

  /**
   * true if form parent has been submitted
   */
  @Input() submitted: boolean;

  public isTouched = false;
  public isValid: boolean;
  public modeDisplays = GflModeDisplayType;
  public isIcon: boolean;
  public id = 'open-date-input-' + Math.floor(Math.random() * 100);
  private validatorsApplied: Array<ValidatorFn>;
  private formControl: FormControl;
  public inputFormControl: FormControl;

  /**
   * @ignore
   */
  constructor(public tools: ToolsService, public translate: TranslateService, private ref: ChangeDetectorRef) {
    this.formChange = new EventEmitter<FormGroup>();
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && changes.form.currentValue !== changes.form.previousValue) {
      if (!this.form.contains(this.name)) {
        this.theme = this.theme || {};
        this.displayFormat = this.displayFormat || 'DD/MM/YYYY';
        this.pickerFormat = this.pickerFormat || 'DD MM YYYY';
        this.cancelText = this.cancelText || 'COMMON.BUTTON_CANCEL';
        this.doneText = this.doneText || 'COMMON.BUTTON_VALIDATE';
        this.validators = this.validators || [];

        // set validators
        this.validatorsApplied = [...this.validators];
        if (this.required) {
          this.validatorsApplied.push(Validators.required);
        }

        // Remove unwanted time data
        const value = this.value && this.value.split(' ')[0];

        // generate formControl
        this.formControl = new FormControl(
          { value, disabled: !this.isEditMode },
          { validators: this.validatorsApplied }
        );
        this.inputFormControl = new FormControl(this.formatDate(value), this.validatorsApplied);
        this.formControl.valueChanges.subscribe((value) => this.setItemState(value));

        // update form locally
        this.form.registerControl(this.name, this.formControl);

        // update form globally
        this.formChange.emit(this.form);

        // launch angular change detection
        this.ref.markForCheck();
      }
    }

    if (changes.formattedValue && changes.formattedValue.currentValue !== changes.formattedValue.previousValue) {
      this.isIcon = !!RegExp('class="icons"').test(changes.formattedValue.currentValue);
    }

    if (changes.value && changes.value.currentValue !== changes.value.previousValue) {
      this.formControl.patchValue(this.value);
    }

    if (this.isEditMode === true) {
      this.formControl.enable();
    } else {
      this.formControl.disable();
    }

    if (changes.disabled) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      changes.disabled.currentValue === true ? this.formControl.disable() : this.formControl.enable();
    }

    if (changes.submitted && changes.submitted.currentValue !== changes.submitted.previousValue) {
      if (this.validatorsApplied.length) {
        this.formControl.markAsTouched();
        this.formControl.markAsDirty();
        this.inputFormControl.markAsTouched();
      }
    }
  }

  /**
   * Format ISO date
   *
   * @param value ISO date
   */
  formatDate(value: string) {
    try {
      return value ? format(parseISO(value), 'dd/MM/yyyy') : '';
    } catch (e) {
      return value;
    }
  }

  setItemState(value: string) {
    if (value) {
      this.inputFormControl.patchValue(this.formatDate(value));
      this.inputFormControl.markAsTouched();
    }

    const validity = this.formControl.status;
    const errors = this.formControl.errors;

    switch (validity) {
      case 'VALID':
        this.inputFormControl.setErrors(null);
        break;
      case 'INVALID':
        this.inputFormControl.setErrors(errors);
        break;
    }
  }
}
