import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { Observable, of, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { NotificationService } from '../../../../gfl-core/gfl-services/notification.service';
import { ToolsService } from '../../../../gfl-core/gfl-services/tools.service';
import { CustomerService } from '../../../../customer/services/customer.service';
import { GflThemeOptions } from '../../models/gfl-form-options.model';
import { GflModeDisplayType } from '../../models/gfl-form.model';
import { FrontTheme } from '../../../../gfl-core/gfl-models/agency.model';
import { City } from '../../../../gfl-core/gfl-models/city.model';

@Component({
  selector: 'gfl-field-zipcode-city',
  templateUrl: './gfl-field-zipcode-city.component.html',
  styleUrls: ['./gfl-field-zipcode-city.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GflFieldZipcodeCityComponent implements OnChanges, OnDestroy {
  /**
   * initial value
   */
  @Input() value: City;

  /**
   * hide value and formatted value in order to display only label
   */
  @Input() hideValue: boolean;

  /**
   * the form implementing this field in two-way binding
   */
  @Input() form: FormGroup;
  @Output() formChange: EventEmitter<FormGroup>;

  /**
   * An array of validators to add to the formControl
   */
  @Input() validators: Array<ValidatorFn> = [];

  /**
   * Set type of displayed template between "mobile" and "tablet" options
   */
  @Input() modeDisplay: GflModeDisplayType;

  /**
   * Front theme style
   */
  @Input() gflStyle: FrontTheme;

  /**
   * Text used in ion-label zipCode component
   */
  @Input() labelZipCode: string;

  /**
   * Text used in ion-label city component
   */
  @Input() labelCity: string;

  /**
   * Text used in ion-label zipCode component
   */
  @Input() placeholderZipCode: string;

  /**
   * Text used in ion-label city component
   */
  @Input() placeholderCity: string;

  /**
   * If isEditMode is false then the field value are read-only
   */
  @Input() isEditMode: boolean;

  /**
   * If isLabel is true then we only display label :)
   */
  @Input() isLabel: boolean;

  /**
   * name attribute for form control
   */
  @Input() name: string;

  /**
   * Color theme to apply to the component
   */
  @Input() theme: GflThemeOptions;

  /**
   * if true then gfl-validation section is not displayed
   * this is used to display custom error message
   */
  @Input() noDisplayErrors: boolean;

  /**
   * readonly flag for HTML attribute field
   */
  @Input() readonly: boolean;

  /**
   * field required flag
   */
  @Input() required: boolean;

  /**
   * true if form parent has een submitted
   */
  @Input() submitted: boolean;

  public modeDisplays = GflModeDisplayType;
  public postcode: string;
  public city: City;
  public cities$: Observable<Array<City>>;
  public isCities = false;
  private subscriptions: Subscription[] = [];
  private postcodeValidatorsApplied: Array<ValidatorFn>;
  private cityValidatorsApplied: Array<ValidatorFn>;
  private formGroup: FormGroup;

  /**
   * @ignore
   */
  constructor(
    public translate: TranslateService,
    public tools: ToolsService,
    private alertCtrl: AlertController,
    private customerSr: CustomerService,
    private notificationSrv: NotificationService,
    private ref: ChangeDetectorRef
  ) {
    this.theme = this.theme || {};
    this.formChange = new EventEmitter<FormGroup>();
  }

  /**
   * Display an alert box if postcode is empty and city field is clicked
   *
   * @param event click event
   */
  public checkPostcode(event: MouseEvent): void {
    this.translate.get(['SIGNUP.ERRORS.NO_POSTCODE']).subscribe(async (result) => {
      const alert = await this.alertCtrl.create({
        subHeader: result['SIGNUP.ERRORS.NO_POSTCODE'],
        buttons: ['OK'],
      });
      await alert.present();
    });
  }

  /**
   * Returns an array of cities based on the zip code
   */
  public getCitiesFromZipCode(event) {
    const zipCode = event && event.value;

    if (zipCode && zipCode.length > 3) {
      this.cities$ = this.customerSr.getCitiesFromZipCode(zipCode).pipe(
        tap((cities) => {
          this.isCities = !!cities.length;

          if (this.formGroup) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            this.isCities ? this.formGroup.get('city').enable() : this.formGroup.get('city').disable();
          }
        }),
        catchError((error) => {
          this.notificationSrv.showError({
            message: error,
          });
          this.isCities = false;
          return [];
        })
      );

      return _.cloneDeep(this.cities$);
    }

    return of(null);
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && changes.form.currentValue !== changes.form.previousValue) {
      if (!this.form.contains(this.name)) {
        this.validators = this.validators || [];
        // set validators
        this.postcodeValidatorsApplied = [...this.validators];
        this.cityValidatorsApplied = [...this.validators];
        if (this.required) {
          this.postcodeValidatorsApplied.push(Validators.required);
          this.cityValidatorsApplied.push(Validators.required);
        }
        // set local attributes
        const postcode = this.value && this.value.postcode;
        const cityId = this.value && this.value.cityId;
        let city: City = {};

        // generate formGroup
        this.formGroup = new FormGroup({
          postcode: new FormControl(cityId, this.postcodeValidatorsApplied),
          cityId: new FormControl(postcode, this.cityValidatorsApplied),
          city: new FormControl({}, this.cityValidatorsApplied),
        });
        // subscribe to change in order to refresh the cities list
        this.formGroup.get('postcode').valueChanges.subscribe((postcodeValue) => {
          this.getCitiesFromZipCode({ value: postcodeValue });
        });

        // update form locally
        this.form.registerControl(this.name, this.formGroup);
        // update form globally
        this.formChange.emit(this.form);
        // launch angular change detection
        this.ref.markForCheck();

        this.subscriptions.push(
          this.getCitiesFromZipCode({ value: postcode }).subscribe((cities) => {
            city = _.find(cities, { id: this.value && this.value.cityId });

            setTimeout(() => {
              this.formGroup.get('city').patchValue(city);
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              this.isEditMode === true ? this.formGroup.get('city').enable() : this.formGroup.get('city').disable();
              // launch angular change detection
              this.ref.markForCheck();
            }, 300);
          })
        );
      }
    }

    if (this.formGroup && changes.value && changes.value.currentValue !== changes.value.previousValue) {
      this.formGroup.get('postcode').patchValue(this.value.postcode);
      this.formGroup.get('cityId').patchValue(this.value.cityId);
    }

    if (this.formGroup && changes.disabled) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (changes.disabled.currentValue === true) {
        this.formGroup.disable();
      } else {
        this.formGroup.enable();
      }
    }

    if (changes.submitted && changes.submitted.currentValue !== changes.submitted.previousValue) {
      if (this.postcodeValidatorsApplied.length) {
        this.formGroup.get('postcode').markAsTouched();
        this.formGroup.get('postcode').markAsDirty();
      }

      if (this.cityValidatorsApplied.length) {
        this.formGroup.get('city').markAsTouched();
        this.formGroup.get('city').markAsDirty();
      }
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then();
  }

  /**
   * set city value in form
   *
   * @param event
   * TODO Check for ionSelect event in template
   */
  public setCity(event: Event) {
    if (this.formGroup) {
      // @ts-ignore
      this.formGroup.get('cityId').patchValue(event.detail.value.id);
      this.ref.markForCheck();
    }
  }

  /**
   * return true if both objects have the same id
   *
   * @param o1 first object
   * @param o2 second object
   */
  public compareById(o1: City, o2: City) {
    return o1.id === o2.id;
  }
}
