import { createReducer, MetaReducer, on } from '@ngrx/store';

import { PolicyFO } from '../models/policy.model';
import { PoliciesActions } from './action-types';
import { environment } from '../../../environments/environment';
import { InsuranceType } from '../models/insurance-type.model';
import { Mandate } from '../models/mandate.model';
import { Company } from '../../gfl-core/gfl-models/company.model';

export const policiesFeatureKey = 'policies';

export interface PoliciesState {
  policiesList: { [id: number]: { [lang: string]: Array<PolicyFO | Mandate> } };
  policiesItems: { [lang: string]: { [id: string]: PolicyFO } };
  insuranceTypes: {
    private: { [lang: string]: { [id: string]: InsuranceType } };
    corporate: { [lang: string]: { [id: string]: InsuranceType } };
  };
  companies: {
    items: { [id: string]: Company };
  };
  companiesByCustomerTypeByInsuranceType: { [id: string]: { [id: string]: number[] } };
}

const initialPoliciesState: PoliciesState = {
  policiesList: {},
  policiesItems: {},
  insuranceTypes: {
    private: {},
    corporate: {},
  },
  companies: {
    items: {},
  },
  companiesByCustomerTypeByInsuranceType: {},
};

export const policiesReducer = createReducer(
  initialPoliciesState,

  on(PoliciesActions.initialize, (state, action) => ({
    ...action.policiesState,
  })),

  on(PoliciesActions.reset, (state) => ({
    ...state,
    policiesList: {},
    policiesItems: {},
  })),

  on(PoliciesActions.setPoliciesAndMandates, (state, action) => ({
    ...state,
    policiesList: action.policiesList,
  })),

  on(PoliciesActions.setPolicy, (state, action) => {
    const policiesItems = { ...state.policiesItems };

    policiesItems[action.lang] = { ...policiesItems[action.lang] } || {};
    policiesItems[action.lang][action.policy.policy_id] = action.policy;

    return {
      policiesList: { ...state.policiesList },
      policiesItems,
      insuranceTypes: { ...state.insuranceTypes },
      companies: { ...state.companies },
      companiesByCustomerTypeByInsuranceType: { ...state.companiesByCustomerTypeByInsuranceType },
    };
  }),

  on(PoliciesActions.setInsuranceTypes, (state, action) => ({
    ...state,
    insuranceTypes: action.insuranceTypes,
  })),

  on(PoliciesActions.resetInsuranceTypes, (state) => ({
    ...state,
    insuranceTypes: {
      private: {},
      corporate: {},
    },
  })),

  on(PoliciesActions.setCompanies, (state, action) => {
    const companies = {
      items: action.companies,
    };

    return {
      ...state,
      companies,
    };
  }),

  on(PoliciesActions.setCompaniesByCustomerTypeByInsuranceType, (state, action) => ({
    ...state,
    companiesByCustomerTypeByInsuranceType: action.companiesByCustomerTypeByInsuranceType,
  }))
);

export const metaReducers: MetaReducer<PoliciesState>[] = !environment.production ? [] : [];
