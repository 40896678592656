import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocumentsState } from './documents.reducer';

export const selectDocumentsState = createFeatureSelector<DocumentsState>('documents');

export const documentCategories = createSelector(
  selectDocumentsState,
  documentsState => documentsState.categoriesByInsuranceType.items
);

export const documents = createSelector(
  selectDocumentsState,
  documentsState => documentsState.documents
);
