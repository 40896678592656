import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

/**
 * Apply this pipe on an array of objects
 * by giving hierarchical properties (separated by a coma) to follow
 * in order to find the date we are sorting by
 */
@Pipe({
  name: 'gflOrderByDate',
})
export class GflOrderByDatePipe implements PipeTransform {
  transform<T>(array: Array<T>, propertiesArg: string): Array<T> {
    const properties = propertiesArg.split(',');

    return _.sortBy(array, item => {
      let date: any = item;

      _.forEach(properties, prop => {
        date = date[prop];
      });

      // we use moment library because of safari bug on new date
      // https://stackoverflow.com/questions/26657353/date-on-ios-device-returns-nan/26671796
      const dateTemp: string = moment(date).format();
      const formattedDate: Date = new Date(dateTemp);

      return formattedDate.getTime();
    });
  }
}
