import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect } from '@ngrx/effects';

import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { StoreService } from '../gfl-core/gfl-services/store.service';
import { ToolsService } from '../gfl-core/gfl-services/tools.service';
import { AgencyActions, DocumentsActions, PermissionsActions, UiActions } from './action.types';
import { CustomerActions } from '../customer/reducers/action-types';
import { AuthActions } from '../authentication/reducers/action-types';
import { PoliciesActions } from '../policies/reducers/action-types';
import { ComparesActions } from '../compares/reducers/action-types';
import { SafeboxActions } from '../safebox/reducers/action-types';
import { ContactsActions } from '../contacts/reducers/action-types';

import { GflState } from './index';
import { environment } from '../../environments/environment';

@Injectable()
export class AppEffects {
  readonly appName: string;

  persistentStorageUpdateUi$ = createEffect(
    () =>
      this.actions$.pipe(
        withLatestFrom(this.ngrxStore),
        mergeMap(([action, stateData]: [Action, GflState]) => {
          const wantedActionTypes = [
            UiActions.setIsProSelected.type.toString(),
            UiActions.setIsProSelectorDisplayed.type.toString(),
            UiActions.setStyle.type.toString(),
            UiActions.updateLang.type.toString(),
            UiActions.setSelectedMember.type.toString(),
          ];
          // we wait for router to be defined in order to preserve persistent data on launch
          // ,but we don't want to lose stored data on initialization
          if (wantedActionTypes.includes(action.type)) {
            return this.store.setPersistentStoreUi(this.appName, stateData.ui).pipe(
              catchError((err) => {
                this.tools.error('persistentStorageUpdateUi$', err);
                return of(false);
              })
            );
          }

          return of(false);
        })
      ),
    { dispatch: false }
  );

  persistentStorageUpdateAgency$ = createEffect(
    () =>
      this.actions$.pipe(
        withLatestFrom(this.ngrxStore),
        mergeMap(([action, stateData]: [Action, GflState]) => {
          const wantedActionTypes = [
            AgencyActions.setAgency.type.toString(),
            AgencyActions.setContacts.type.toString(),
            AgencyActions.setAcls.type.toString(),
            AgencyActions.setDefaultAddress.type.toString(),
            AgencyActions.setPrivateSliders.type.toString(),
            AgencyActions.setPublicSliders.type.toString(),
          ];
          // we wait for router to be defined in order to preserve persistent data on launch
          // ,but we don't want to lose stored data on initialization
          if (wantedActionTypes.includes(action.type)) {
            return this.store.setPersistentStoreAgency(this.appName, stateData.agency).pipe(
              catchError((err) => {
                this.tools.error('persistentStorageUpdateAgency$', err);
                return of(false);
              })
            );
          }

          return of(false);
        })
      ),
    { dispatch: false }
  );

  persistentStorageUpdatePermissions$ = createEffect(
    () =>
      this.actions$.pipe(
        withLatestFrom(this.ngrxStore),
        mergeMap(([action, stateData]: [Action, GflState]) => {
          const wantedActionTypes = [PermissionsActions.setAcls.type.toString()];
          // we wait for router to be defined in order to preserve persistent data on launch
          // ,but we don't want to lose stored data on initialization
          if (wantedActionTypes.includes(action.type)) {
            return this.store.setPersistentStorePermissions(this.appName, stateData.permissions).pipe(
              catchError((err) => {
                this.tools.error('persistentStorageUpdatePermissions$', err);
                return of(false);
              })
            );
          }

          return of(false);
        })
      ),
    { dispatch: false }
  );

  persistentStorageUpdateDocuments$ = createEffect(
    () =>
      this.actions$.pipe(
        withLatestFrom(this.ngrxStore),
        mergeMap(([action, stateData]: [Action, GflState]) => {
          const wantedActionTypes = [
            DocumentsActions.setDocumentCategories.type.toString(),
            DocumentsActions.setDocuments.type.toString(),
          ];
          // we wait for router to be defined in order to preserve persistent data on launch
          // ,but we don't want to lose stored data on initialization
          if (wantedActionTypes.includes(action.type)) {
            this.store.setPersistentStoreDocuments(this.appName, stateData.documents).pipe(
              catchError((err) => {
                this.tools.error('persistentStorageUpdateDocuments$', err);
                return of(false);
              })
            );
          }
          return of(false);
        })
      ),
    { dispatch: false }
  );

  persistentStorageUpdateCompare$ = createEffect(
    () =>
      this.actions$.pipe(
        withLatestFrom(this.ngrxStore),
        mergeMap(([action, stateData]: [Action, GflState]) => {
          const wantedActionTypes = [
            ComparesActions.setCompares.type.toString(),
            ComparesActions.setCompare.type.toString(),
          ];
          // we wait for router to be defined in order to preserve persistent data on launch
          // ,but we don't want to lose stored data on initialization
          if (wantedActionTypes.includes(action.type)) {
            this.store.setPersistentStoreCompare(this.appName, stateData.compares).pipe(
              catchError((err) => {
                this.tools.error('persistentStorageUpdateCompare$', err);
                return of(false);
              })
            );
          }
          return of(false);
        })
      ),
    { dispatch: false }
  );

  persistentStorageUpdateCustomer$ = createEffect(
    () =>
      this.actions$.pipe(
        withLatestFrom(this.ngrxStore),
        mergeMap(([action, stateData]: [Action, GflState]) => {
          const wantedActionTypes = [
            CustomerActions.setCustomer.type.toString(),
            CustomerActions.setMembers.type.toString(),
            CustomerActions.setPairingCode.type.toString(),
          ];
          // we wait for router to be defined in order to preserve persistent data on launch
          // ,but we don't want to lose stored data on initialization
          if (wantedActionTypes.includes(action.type)) {
            this.store.setPersistentStoreCustomer(this.appName, stateData.customer).pipe(
              catchError((err) => {
                this.tools.error('persistentStorageUpdateCustomer$', err);
                return of(false);
              })
            );
          }
          return of(false);
        })
      ),
    { dispatch: false }
  );

  persistentStorageUpdateAuth$ = createEffect(
    () =>
      this.actions$.pipe(
        withLatestFrom(this.ngrxStore),
        mergeMap(([action, stateData]: [Action, GflState]) => {
          const wantedActionTypes = [
            AuthActions.login.type.toString(),
            AuthActions.loginOnSwitchMode.type.toString(),
            AuthActions.logout.type.toString(),
            AuthActions.setRememberMe.type.toString(),
          ];
          // we wait for router to be defined in order to preserve persistent data on launch
          // ,but we don't want to lose stored data on initialization
          if (wantedActionTypes.includes(action.type)) {
            this.store.setPersistentStoreAuth(this.appName, stateData.auth).pipe(
              catchError((err) => {
                this.tools.error('persistentStorageUpdateAuth$', err);
                return of(false);
              })
            );
          }
          return of(false);
        })
      ),
    { dispatch: false }
  );

  persistentStorageUpdatePolicies$ = createEffect(
    () =>
      this.actions$.pipe(
        withLatestFrom(this.ngrxStore),
        mergeMap(([action, stateData]: [Action, GflState]) => {
          const wantedActionTypes = [
            PoliciesActions.setPoliciesAndMandates.type.toString(),
            PoliciesActions.setPolicy.type.toString(),
            PoliciesActions.setInsuranceTypes.type.toString(),
            PoliciesActions.setCompanies.type.toString(),
          ];
          // we wait for router to be defined in order to preserve persistent data on launch
          // ,but we don't want to lose stored data on initialization
          if (wantedActionTypes.includes(action.type)) {
            return this.store.setPersistentStorePolicies(this.appName, stateData.policies).pipe(
              catchError((err) => {
                this.tools.error('persistentStorageUpdatePolicies$', err);
                return of(false);
              })
            );
          }
          return of(false);
        })
      ),
    { dispatch: false }
  );

  persistentStorageUpdateSafebox$ = createEffect(
    () =>
      this.actions$.pipe(
        withLatestFrom(this.ngrxStore),
        mergeMap(([action, stateData]: [Action, GflState]) => {
          const wantedActionTypes = [
            SafeboxActions.setSafeboxTypes.type.toString(),
            SafeboxActions.setSafeboxes.type.toString(),
            SafeboxActions.addSafebox.type.toString(),
            SafeboxActions.removeSafebox.type.toString(),
          ];
          // we wait for router to be defined in order to preserve persistent data on launch
          // ,but we don't want to lose stored data on initialization
          if (wantedActionTypes.includes(action.type)) {
            this.store.setPersistentStoreSafebox(this.appName, stateData.safebox).pipe(
              catchError((err) => {
                this.tools.error('persistentStorageUpdateSafebox$', err);
                return of(false);
              })
            );
          }
          return of(false);
        })
      ),
    { dispatch: false }
  );

  persistentStorageUpdateContacts$ = createEffect(
    () =>
      this.actions$.pipe(
        withLatestFrom(this.ngrxStore),
        mergeMap(([action, stateData]: [Action, GflState]) => {
          const wantedActionTypes = [
            ContactsActions.setChatItems.type.toString(),
            ContactsActions.setNotifications.type.toString(),
          ];
          // we wait for router to be defined in order to preserve persistent data on launch
          // ,but we don't want to lose stored data on initialization
          if (wantedActionTypes.includes(action.type)) {
            this.store.setPersistentStoreContacts(this.appName, stateData.contacts).pipe(
              catchError((err) => {
                this.tools.error('persistentStorageUpdateContacts$', err);
                return of(false);
              })
            );
          }
          return of(false);
        })
      ),
    { dispatch: false }
  );

  /**
   * @ignore
   */
  constructor(
    private actions$: Actions,
    private ngrxStore: Store<GflState>,
    private store: StoreService,
    private tools: ToolsService
  ) {
    this.appName = environment.APP_NAME;
  }
}
