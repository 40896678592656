import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GflBackBtnColorDirective } from './gfl-back-btn-color.directive';
import { GflFixTableHeadDirective } from './gfl-fix-table-head.directive';
import { GflThrottleClickDirective } from './gfl-throttle-click.directive';
import { GflWidthIndicatorDirective } from './gfl-width-indicator.directive';

@NgModule({
  declarations: [
    GflBackBtnColorDirective,
    GflFixTableHeadDirective,
    GflThrottleClickDirective,
    GflWidthIndicatorDirective,
  ],
  imports: [CommonModule],
  exports: [GflBackBtnColorDirective, GflFixTableHeadDirective, GflThrottleClickDirective, GflWidthIndicatorDirective],
})
export class GflDirectivesModule {}
