import { createAction, props } from '@ngrx/store';

import { Agency, DefaultAddress } from '../gfl-core/gfl-models/agency.model';
import { Contact } from '../gfl-core/gfl-models/contact.model';
import { Acls } from '../gfl-core/gfl-models/acls.model';
import { AgencyState } from './agency.reducer';
import { Slider, SliderType } from '../gfl-core/gfl-models/slider.model';

export const initialize = createAction(
  '[Store Service setNgrxStore] initialize AgencyState',
  props<{ agencyState: AgencyState }>()
);

export const setAgency = createAction('[Agency Service setAgency()] Set Agency', props<{ agency: Agency }>());

export const setContacts = createAction('[Agency Service setAgency()] Set Contacts', props<{ contacts: Contact[] }>());

export const setAcls = createAction('[Acls Service setPermissions()] Set Permissions', props<{ acls: Acls }>());

export const setDefaultAddress = createAction(
  '[Agency Service setAgency()] Set default address',
  props<{ defaultAddress: DefaultAddress }>()
);

export const setPrivateSliders = createAction(
  '[Agency Service setAgency()] Set private sliders',
  props<{ lang: string; sliders: Slider[] }>()
);

export const setPublicSliders = createAction(
  '[Agency Service setAgency()] Set public sliders',
  props<{ lang: string; sliders: Slider[] }>()
);

export const resetSliders = createAction(
  '[AppComponent checkAppVersion()] remove sliders from store',
  props<{ sliderType: SliderType }>()
);
