import { Injectable } from '@angular/core';
import { LoadingOptions } from '@ionic/core';
import { NavigationExtras, Router } from '@angular/router';
import { LoadingController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Capacitor } from '@capacitor/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import * as moment from 'moment';

import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { from, Observable, Observer, of, Subscription, throwError } from 'rxjs';

import { StoreService } from './store.service';
import { GflModeDisplayType } from '../../gfl-libraries/gfl-form-generator/models/gfl-form.model';

import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { format, parseISO } from 'date-fns';

export enum SelectInterfaceType {
  Alert = 'alert',
  ActionSheet = 'action-sheet',
  Popover = 'popover',
}

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  private readonly isProd: boolean;
  // tslint:disable-next-line:variable-name
  private MIMETypes = {
    aac: 'audio/aac',
    abw: 'application/x-abiword',
    arc: 'application/octet-stream',
    avi: 'video/x-msvideo',
    azw: 'application/vnd.amazon.ebook',
    bin: 'application/octet-stream',
    bz: 'application/x-bzip',
    bz2: 'application/x-bzip2',
    csh: 'application/x-csh',
    css: 'text/css',
    csv: 'text/csv',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    eot: 'application/vnd.ms-fontobject',
    epub: 'application/epub+zip',
    gif: 'image/gif',
    html: 'text/html',
    ico: 'image/x-icon',
    ics: 'text/calendar',
    jar: 'application/java-archive',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    js: 'application/javascript',
    json: 'application/json',
    mid: 'audio/midi',
    midi: 'audio/midi',
    mpeg: 'video/mpeg',
    mpkg: 'application/vnd.apple.installer+xml',
    odp: 'application/vnd.oasis.opendocument.presentation',
    ods: 'application/vnd.oasis.opendocument.spreadsheet',
    odt: 'application/vnd.oasis.opendocument.text',
    oga: 'audio/ogg',
    ogv: 'video/ogg',
    ogx: 'application/ogg',
    otf: 'font/otf',
    png: 'image/png',
    pdf: 'application/pdf',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    rar: 'application/x-rar-compressed',
    rtf: 'application/rtf',
    sh: 'application/x-sh',
    svg: 'image/svg+xml',
    swf: 'application/x-shockwave-flash',
    tar: 'application/x-tar',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    ts: 'application/typescript',
    ttf: 'font/ttf',
    vsd: 'application/vnd.visio',
    wav: 'audio/x-wav',
    weba: 'audio/webm',
    webm: 'video/webm',
    webp: 'image/webp',
    woff: 'font/woff',
    woff2: 'font/woff2',
    xhtml: 'application/xhtml+xml',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xml: 'application/xml',
    xul: 'application/vnd.mozilla.xul+xml',
    zip: 'application/zip',
    '3gp': 'video/3gpp',
    '3g2': 'video/3gpp2',
    '7z': 'application/x-7z-compressed',
  };
  private loader: HTMLIonLoadingElement;
  private refreshingLoader: HTMLIonLoadingElement;

  /**
   * @ignore
   */
  constructor(
    private translate: TranslateService,
    private router: Router,
    private platform: Platform,
    private store: StoreService,
    private loadingCtrl: LoadingController,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    this.isProd = environment.production;
  }

  /**
   * Generate an available cookie for one day and only for PWA
   * if the cookie expires then at app launch app.module will clear all localStorage
   */
  public setCookie() {
    if (!this.isNative()) {
      const expires = new Date();
      expires.setDate(expires.getDate() + 1);
      this.cookieService.set(environment.APP_NAME, '', expires, '/', null, true, 'Lax');
    }
  }

  /**
   * Display logs info according to current device type in dev mode
   *
   * @param title title of info to display
   * @param data info to display
   */
  public log(title: string, data: any, debug?: boolean): void {
    if (this.isProd) {
      return;
    }

    title = title + ' >>>';

    if (this.isNative()) {
      console.log('==========================================');
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      debug ? console.log(`DEBUG ${title}`) : console.log(title);
      console.log(JSON.stringify(data, null, 2));
    } else {
      console.log(title, data);
    }
  }

  /**
   * Display logs error according to current device type in dev mode
   *
   * @param title title of info to display
   * @param data info to display
   */
  public error(title: string, data: any): void {
    if (this.isProd) {
      return;
    }

    title = title + ' >>>';

    if (this.isNative()) {
      console.log('==========================================');
      console.log(title);
      console.error(JSON.stringify(data, null, 2));
    } else {
      console.error(title, data);
    }
  }

  /**
   * Transform given date to timestamp
   *
   * @param date a date string
   */
  public dateToTimeStamp(date: string): number {
    if (!date) {
      return;
    }

    const tmp = date.split(' ');
    date = tmp[0];
    const dateObject = new Date(date);

    return dateObject.getTime();
  }

  /**
   * Limit input keyboard to numeric characters
   *
   * @param event the keyboard event
   */
  public numericInputLimit(event: KeyboardEvent): boolean {
    let type;

    if (event.key !== undefined) {
      return /[0-9]/.test(event.key);
    }
    // @ts-ignore
    else if (event.keyIdentifier !== undefined) {
      type = 'keyIdentifier';
    } else if (event.keyCode !== undefined) {
      type = 'keyCode';
    } else {
      type = 'charCode';
    }
    return (event[type] <= 57 && event[type] >= 48) || [8, 26, 27].includes(event[type]);
  }

  /**
   * Return the extension of a file
   *
   * @param path file's extension
   */
  public getExtension(path: string): string {
    return path.substr(path.lastIndexOf('.') + 1);
  }

  /**
   * Return the MIME type corresponding to the extension file
   *
   * @param ext file's MIMEType
   */
  public getMIMEType(ext: string): string {
    return this.MIMETypes[ext] || '';
  }

  /**
   * Return true if the device is a native device
   */
  public isNative(): boolean {
    return Capacitor.isNative;
  }

  /**
   * Return true if platform is not a tablet or a desktop
   */
  public isMobile(): boolean {
    return !this.platform.is('tablet') && !this.platform.is('desktop');
  }

  /**
   * Return true if platform is a desktop
   */
  public isDesktop(): boolean {
    return this.platform.is('desktop');
  }

  /**
   * Return true if arg can be cast as integer
   *
   * @param arg argument to test
   */
  public isInt(arg: any): boolean {
    return !!parseInt(arg, 10);
  }

  /**
   * return true if file type is of one of types image
   *
   * @param fileType file extension or type
   */
  public isPicture(fileType: string) {
    const regExp = /(jpeg)|(jpg)|(png)|(tiff)/;
    return regExp.test(fileType);
  }

  /**
   * Compare deeply two arrays and return a boolean
   *
   * @param x an array
   * @param y an array
   */
  public isArrayEqual(x: Array<any>, y: Array<any>): boolean {
    return _(_.xorWith(x, y, _.isEqual)).isEmpty();
  }

  /**
   * Return a GflModeDisplayType mobile or tablet
   */
  public setModeDisplay(): GflModeDisplayType {
    return !this.isMobile() ? GflModeDisplayType.Tablet : GflModeDisplayType.Mobile;
  }

  /**
   * Extract sprite element corresponding to name param
   * and return it in svg tags
   *
   * @param svg the svg text
   * @param name id of sprite element to extract
   * @param flex add flex class if true
   */
  public extractSymbol(svg: string, name: string, flex: boolean): string {
    const option = flex ? ' class="gfl-flex-1"' : '';

    return svg
      .split('<symbol')
      .filter((def: string) => def.includes(name))
      .map((def) => def.split('</symbol>')[0])
      .map((def) => '<svg style="max-height: 100%; height: var(--height);"' + option + def + '</svg>')[0];
  }

  /**
   * fetch svg image from BO
   *
   * @param url url of image
   */
  public fetchSVGImage(url: string): Observable<string> {
    const headers = new HttpHeaders();

    headers.set('Accept', 'image/svg+xml');
    return this.http.get(url, { headers, responseType: 'text' }).pipe(
      map((svg) => this.filterSvgSource(svg)),
      catchError((err) => {
        this.error('fetchSVGImage ERROR', err);
        return of(null);
      })
    );
  }

  /**
   * fetch image from BO
   *
   * @param url url of image
   */
  public fetchImage(url: string): Observable<Blob> {
    const headers = new HttpHeaders();

    headers.set('Accept', 'image/png, image/jpeg');
    return this.http.get(url, { headers, responseType: 'blob' });
  }

  /**
   * Format source in order to display the svg in the right way
   */
  private filterSvgSource(svg: string): string {
    const regExp = /<svg(.|\s|\n|\t)*<\/svg>/;
    const result = svg.match(regExp);

    if (result && result.length) {
      const regExpReplacement = /<svg/;
      svg = result[0].replace(
        regExpReplacement,
        '<svg style="max-height: 100%; max-width: 100%; height: 100%; width: 100%"'
      );

      const classes = svg.match(/\.st[0-9]{1,2}/g);
      const styles = svg.match(/\.st[0-9]{1,2}\{fill:\#\w{3,6}/g);

      if (classes && styles) {
        // remove class definition from svg
        svg = svg.replace(/<style.*<\/style>/g, '');

        _.forEach(classes, (item, idx) => {
          const key = item.slice(1);
          const style = styles[idx].replace(item + '{', '');

          // replace class attribute by style
          svg = svg.replace(new RegExp('class="' + key + '"', 'g'), 'style="' + style + '"');
        });
      }
    }

    return svg;
  }

  /**
   * Set application's root page application
   *
   * @param apiToken the api token provided by BO
   * @returns the root page path
   */
  public setRootPage(apiToken): string {
    let rootPage: string;
    if (apiToken) {
      rootPage = this.platform.is('tablet') || this.platform.is('desktop') ? '/policies' : '/home';
    } else {
      rootPage = '/welcome';
    }
    return rootPage;
  }

  /**
   * Open broker website in a browser
   *
   * @param path the agency website path
   */
  public openWebSite(path: string): void {
    this.store.getAgency().subscribe((agency) => {
      window.open(agency[path], '_system', 'location=yes');
    });
  }

  /**
   * return an array of string with every day of week
   * in uppercase and beginning with MONDAY
   */
  public getWeekDays(): string[] {
    const week = [];

    _.forEach(moment.weekdays(), (day: string) => {
      week.push(day.toUpperCase());
    });

    // monday as first day
    week.push(week.shift());

    return week;
  }

  /**
   * Unsubscribe all subscription passed in the parameter array
   *
   * @param subscriptions ann array of subscriptions to remove
   */
  public async unsubscribeAll(subscriptions: Array<Subscription>): Promise<boolean> {
    _.forEach(subscriptions, (item) => {
      item.unsubscribe();
    });

    return Promise.resolve(true);
  }

  /**
   * This fix a bug with Ionic & Capacitor
   * Returns the correct FileReader embedding event listeners
   */
  public getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any).__zone_symbol__originalInstance;
    return zoneOriginalInstance || fileReader;
  }

  /**
   * Read the text content of a Blob using the FileReader interface handled by Rx.
   *
   * @param blob text content of blob
   */
  public readFileObs(blob: Blob): Observable<string> {
    return new Observable<string>((obs: Observer<string>) => {
      if (!(blob instanceof Blob)) {
        obs.error(new Error('`blob` must be an instance of File or Blob.'));
        return;
      }

      const reader = this.getFileReader();

      reader.onerror = (err) => obs.error(err);
      reader.onabort = (err) => obs.error(err);
      reader.onload = () => obs.next(reader.result as string);
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(blob);
    });
  }

  /**
   * Navigate to the page corresponding to the path parameter
   *
   * @param path data separated by /
   * @param navParam navigation extra params object (optional)
   */
  public openPage(path: string, navParam?: object): void {
    if (!navParam) {
      const data = path.split('/');

      let newPath = data[0];

      if (data[1]) {
        newPath += '?action=' + data[1];
      }
      this.router.navigateByUrl(newPath).catch((err) => this.error('navigateForward ' + path, err));
    } else {
      const navigationExtras: NavigationExtras = {
        state: {
          navParam,
        },
      };
      this.router.navigateByUrl(path, navigationExtras).catch((err) => this.error('navigateForward ' + path, err));
    }
  }

  /**
   * Display a global loader
   */
  public showLoader(wait?: boolean, keyTranslate?: string): any {
    // tslint:disable-next-line:no-shadowed-variable
    const delay = wait && 300;
    const text = keyTranslate || 'MODAL_LOADING';

    return setTimeout(() => {
      this.translate.get(text).subscribe(async (value) => {
        this.loader = await this.loadingCtrl.create({
          message: value,
        } as LoadingOptions);
        await this.loader.present();
        this.store.setIsLoading(true);
      });
    }, delay);
  }

  /**
   * Display an asynchronous global loader
   */
  public async showLoaderAsync(keyTranslate?: string): Promise<boolean> {
    const text = keyTranslate || 'MODAL_LOADING';

    const value = await this.translate.get(text).toPromise();
    this.loader = await this.loadingCtrl.create({
      message: value,
    } as LoadingOptions);

    await this.loader.present();
    this.store.setIsLoading(true);
    return true;
  }

  /**
   * Display an observable global loader
   */
  public showLoaderObs(keyTranslate?: string): Observable<void> {
    const text = keyTranslate || 'MODAL_LOADING';

    return this.translate.get(text).pipe(
      switchMap((val) =>
        from(
          this.loadingCtrl.create({
            message: val,
          } as LoadingOptions)
        )
      ),
      switchMap((loader) => {
        this.loader = loader;
        return from(loader.present());
      }),
      tap(() => this.store.setIsLoading(true))
    );
  }

  /**
   * Hide the global loader
   */
  public hideLoader(ref?: any): void {
    if (ref) {
      clearTimeout(ref);
    }
    const subscription = this.store
      .getIsLoading()
      .pipe(delay(500))
      .subscribe((isLoading) => {
        if (isLoading) {
          setTimeout(() => subscription.unsubscribe(), 500);
          this.store.setIsLoading(false);
          try {
            this.loader.dismiss().then(() => {
              this.loader = null;
            });
          } catch (e) {}
        } else {
          setTimeout(() => subscription.unsubscribe(), 500);
        }
      });
  }

  /**
   * display a loader if isRefreshing flag's store is true
   */
  public manageRefreshingLoader() {
    this.store.getIsRefreshingData().subscribe((flag) => {
      if (flag && !this.refreshingLoader) {
        this.translate.get('COMMON.REFRESHING_DATA').subscribe(
          async (value) => {
            try {
              this.refreshingLoader = await this.loadingCtrl.create({
                message: value,
                keyboardClose: true,
              } as LoadingOptions);
              await this.refreshingLoader.present();
            } catch (e) {
              this.error('manageRefreshingLoader error >>> ', e);
            }
          },
          (err) => this.error('manageRefreshingLoader', err)
        );
      } else {
        try {
          this.refreshingLoader.dismiss().then(() => (this.refreshingLoader = null));
        } catch (e) {}
      }
    });
  }

  /**
   * return error observable in case of action not available in offline mode
   */
  public notAvailableMsg(): Observable<never> {
    return throwError('NETWORK.NOT_AVAILABLE');
  }

  /**
   * return an array of entity's id to delete comparing origin BO and reference params
   *
   * @param origin a map of entities where to find entities to delete
   * @param reference a map of entities used as reference
   */
  public findEntitiesToRemove<T>(origin: { [id: string]: T }, reference: { [id: string]: T }): any[] {
    const originKeys = Object.keys(origin);
    const referenceKeys = Object.keys(reference);
    let keysToDelete = [...originKeys];

    _.forEach(referenceKeys, (key) => {
      const idx = _.indexOf(originKeys, key);
      if (idx > -1) {
        delete keysToDelete[idx];
      }
    });

    keysToDelete = _.compact(keysToDelete);

    return keysToDelete;
  }

  /**
   * return an array of new entities
   *
   * @param origin a map of entities where to find new entities
   * @param reference a map of entities used as reference
   * @param key entity attribute used to identify entities
   */
  public findNewEntities<T>(origin: { [id: string]: T }, reference: { [id: string]: T }, key: string): Array<T> {
    const keys = key.split('.');

    const newEntityIds = _.difference(Object.keys(reference), Object.keys(origin));

    if (keys.length === 1) {
      return _.filter(reference, (entity) => {
        if (entity && entity[keys[0]]) {
          return newEntityIds.includes(entity[keys[0]].toString());
        }
        return false;
      });
    } else {
      return _.filter(reference, (entity) => {
        if (entity && entity[keys[0]]) {
          return newEntityIds.includes(entity[keys[0]][keys[1]].toString());
        }
        return false;
      });
    }
  }

  /**
   * Return an array of an input split into len-character long pieces
   *
   * @param input a string to split
   * @param len length of pieces of input string
   */
  public split(input: string, len: number): string[] {
    const result = [];
    let content = _.cloneDeep(input);
    let piece;

    while (content.length > 0) {
      piece = content.slice(0, len);
      content = content.replace(piece, '');
      result.push(piece);
    }

    return result;
  }

  /**
   * Return true if str is find in the array
   *
   * @param arr an array of string
   * @param str string to look for by with RegExp
   */
  public IsInArrayOfString(arr: string[], str: string): boolean {
    const rexp = new RegExp(str);

    return !!_.find(arr, (o) => rexp.test(o));
  }

  /**
   * Format date value from ion-date-time component to value to be displayed in the linked input
   *
   * @param value date value from ion-date-time component
   */
  public formatDate(value: string): string {
    return value ? format(parseISO(value), 'dd/MM/yyyy') : '';
  }
}
