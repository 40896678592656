import { DefaultAddress } from '../../gfl-core/gfl-models/agency.model';
import { Country } from '../../gfl-core/gfl-models/country.model';
import { LinkRelationItem } from '../../gfl-core/gfl-models/api.model';
import { ItemTemplateFO } from '../../gfl-core/gfl-models/item-template.model';

export interface Customer {
  id?: number;
  customer_type_id?: any;
  currentStatus?: any;
  language?: any;
  email?: string;
  login?: string;
  password?: string;
  validationCode?: string;
  customerValidated?: boolean;
  civility?: string;
  firstname?: string;
  first_name?: string;
  lastname?: string;
  company_name?: string;
  other_agency?: string;
  dob?: string;
  nationalityCountry?: Country;
  marital_situation?: number;
  medical?: Array<any>;
  default_address?: DefaultAddress;
  phone?: string;
  items?: { [id: string]: ItemTemplateFO };
  advisor?: {
    lastname: string;
    firstname: string;
    phone: string;
    phone_prefix: string;
    email: string;
    agency_id: number;
  };
  agencyId?: number;
  agency_id?: number;
  active?: boolean;
  api_token?: string;
  phone_prefix?: string;
  agencies?: object;
  customer_type?: number;
  customer_link_type_id?: number;
  under18?: boolean;
  pairing_code?: string;
  bank_account?: CustomerBank;
  issued_only?: any;
}

export interface CustomerStatus {
  id: number;
  key: string;
  name: string;
  foreign_table_id: string;
  key_translate: string;
}

export interface CustomerFamilyMember {
  id: number;
  api_token?: string;
  civility?: string | number;
  customer_link_type_id?: number;
  customer_link_type_name?: string;
  customer_link_type_id_name?: string;
  customer_type_id?: any;
  customer_agency_id?: number;
  customer_dob?: string;
  first_name: string;
  firstname?: string;
  last_name: string;
  sprite: string;
  pairing_code?: string;
  permissions?: string[];
  read?: [0, 1];
  write?: [0, 1];
  delete?: [0, 1];
  issued_only?: boolean;
}

export interface CustomerBank {
  bank_name?: string;
  owner?: string;
  iban?: string;
  id?: number;
  swift?: string;
}

export interface StoredCustomer {
  customer: Customer;
  customerLinks: CustomerLinks;
  customerLinkReceived: LinkRelationItem[];
  contract: MandateContract;
}

export interface MandateContract {
  uri?: string;
  fileName?: string;
  id?: number | string;
  fileType?: string;
}

export interface CustomerLinks {
  available: LinkRelationItem[];
  issued_only: LinkRelationItem[];
  received_only: LinkRelationItem[];
}

export interface CustomerRole {
  id: number;
  name: string;
}

export enum CustomerFormType {
  Create = 1,
  Update = 2,
}

export enum AddCustomerTypes {
  Create = 1,
  Pair = 2,
}
