import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Contact } from '../../../gfl-core/gfl-models/contact.model';
import { AgencyService } from '../../../gfl-core/gfl-services/agency.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';

@Component({
  selector: 'gfl-signup-error',
  templateUrl: './signup-error.component.html',
  styleUrls: ['./signup-error.component.scss'],
})
export class SignupErrorComponent implements OnInit {
  public agencyName$: Observable<string>;
  public contact: Contact;
  public formattedPhone: string;

  /**
   * @ignore
   */
  constructor(private agencySrv: AgencyService, private tools: ToolsService, private modalCtrl: ModalController) {}

  /**
   * @ignore
   */
  ngOnInit() {
    this.agencyName$ = this.agencySrv.getAgencyName();
    this.agencySrv
      .getMyContact()
      .pipe(
        map((contact) => {
          if (contact) {
            let phone = contact.phone;
            let match;

            if (phone[0] !== '0' && phone[0] !== '+') {
              phone = '0' + phone;
            }

            if (phone[0] === '+') {
              match = phone.match(/^(\+\d{2})(\d{2})(\d{3})(\d{2})(\d{2})$/);
              this.formattedPhone = match
                ? match[1] + ' ' + match[2] + ' ' + match[3] + ' ' + match[4] + ' ' + match[4]
                : phone;
            } else {
              match = phone.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
              this.formattedPhone = match ? match[1] + ' ' + match[2] + ' ' + match[3] + ' ' + match[4] : phone;
            }
          }

          return contact;
        })
      )
      .subscribe(
        (contact) => (this.contact = contact),
        () => (this.contact = null)
      );
  }

  /**
   * Call the advisor
   */
  public async callAdvisor(phoneNumber: string): Promise<void> {
    window.open('tel:' + phoneNumber, '_system');
  }

  /**
   * Close Modal
   */
  public closeModal() {
    this.modalCtrl.dismiss().then();
  }
}
