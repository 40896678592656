import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { finalize, first } from 'rxjs/operators';

import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { GflModeDisplayType } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form.model';
import { GflThemeOptions } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form-options.model';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';

@Component({
  selector: 'gfl-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  @Input() login: string;
  @Input() agencyId: number;
  @Input() isOffline: boolean;
  @Input() logo$: Observable<string>;
  @Output() validated: EventEmitter<boolean> = new EventEmitter<boolean>();
  public style$: Observable<FrontTheme>;
  public validationForm: FormGroup;
  public validatorsForm: { [id: string]: Array<ValidatorFn> } = {};
  public modeDisplay: GflModeDisplayType;
  public optionTheme: GflThemeOptions;
  public formErrors = {
    password: undefined,
  };

  /**
   * @ignore
   */
  constructor(
    public tools: ToolsService,
    private customerSrv: CustomerService,
    private notificationSrv: NotificationService,
    private store: StoreService,
    private router: Router
  ) {
    this.initForm();
  }

  /**
   * @ignore
   */
  ngOnInit() {
    this.style$ = this.store.getStyle();
    this.setModeDisplay();

    this.optionTheme = {
      tabletBackgroundColor: '#edefef',
    };
  }

  /**
   * Reach next page on validate
   */
  public onValidateCode(): void {
    this.tools.showLoader();

    // Validate user
    this.customerSrv
      .resetCustomerPassword(
        this.validationForm.value.code,
        this.login,
        this.validationForm.value.password.password,
        this.validationForm.value.password.passwordConfirm,
        this.agencyId
      )
      .pipe(
        first(),
        finalize(() => this.tools.hideLoader())
      )
      .subscribe(
        () => {
          this.notificationSrv.showSuccess({ message: 'COMMON.PASSWORD_RESET_CONFIRMATION' });
          this.validated.emit(true);
        },
        (err) => {
          this.notificationSrv.showError({ message: err });
          this.validated.emit(false);
        }
      );
  }

  /**
   * Cancel password modification
   */
  public onCancel(): void {
    this.router.navigateByUrl('/authentication/login').then();
  }

  /**
   * Initialize the validation form
   */
  private initForm(): void {
    // form definition
    this.validationForm = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.maxLength(4), Validators.minLength(4)]),
    });

    this.validatorsForm = {
      password: [Validators.required, Validators.minLength(4)],
    };
  }

  /**
   * Set modeDisplay to mobile or tablet
   */
  private setModeDisplay(): void {
    this.modeDisplay = this.tools.setModeDisplay();
  }
}
