import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { ToolsService } from '../../gfl-core/gfl-services/tools.service';
import { StoreService } from '../../gfl-core/gfl-services/store.service';

@Injectable({
  providedIn: 'root',
})
export class AuthLoginAsCustomerGuard implements CanActivate {
  /**
   * @ignore
   */
  constructor(private tools: ToolsService, private store: StoreService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (route.queryParams && route.queryParams.user_token && route.queryParams.customer_id) {
      this.tools.setCookie(); // refresh cookie for PWA
      return true;
    } else {
      this.router.navigateByUrl('/welcome').then();
    }
  }
}
