import { Injectable } from '@angular/core';
import { Encoding } from '@capacitor/filesystem';
import { Device } from '@capacitor/device';
import * as Crypto from 'crypto-js';
import * as _ from 'lodash';

import { catchError, first, mergeMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

import { StoreService } from './store.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  private salt: string;
  readonly splitLength = 1000000;

  constructor(private store: StoreService, private tools: ToolsService) {
    Device.getId().then((info) => (this.salt = info.uuid));
  }

  /**
   * return an AES 256 encrypted content
   *
   * @param documentId Document Id
   * @param content Document's content
   */
  public encrypt(documentId: number | string, content: string): Observable<any> {
    return this.generatePassPhrase(documentId).pipe(
      mergeMap((passPhrase) => {
        try {
          if (content.length > this.splitLength) {
            const contentSplitted = this.tools.split(content, this.splitLength);
            const encryptedResult = [];

            _.forEach(contentSplitted, (piece) => {
              encryptedResult.push(Crypto.AES.encrypt(piece, passPhrase).toString());
            });

            return of({
              encryptContent: JSON.stringify(encryptedResult),
              enc: Encoding.UTF8,
            });
          } else {
            return of({
              encryptContent: Crypto.AES.encrypt(content, passPhrase).toString(),
              enc: null,
            });
          }
        } catch (e) {
          this.tools.error('ERROR encrypt', e);
          return of(null);
        }
      }),
      catchError((e) => {
        this.tools.error('ERROR encrypt', e);
        return of(null);
      })
    );
  }

  /**
   * Return document content decrypted
   *
   * @param documentId document id
   * @param encryptData encrypted content
   */
  public decrypt(documentId: number | string, encryptData: string): Observable<any> {
    return this.generatePassPhrase(documentId).pipe(
      mergeMap((passPhrase) => {
        let data;

        try {
          data = JSON.parse(encryptData);
        } catch (e) {
          this.tools.error('ERROR JSON.parse(encryptData)', e);
        }

        if (_.isArray(data)) {
          try {
            let decryptedResult = '';

            _.forEach(data, (item) => {
              decryptedResult += Crypto.AES.decrypt(item, passPhrase).toString(Crypto.enc.Utf8);
            });

            return of(decryptedResult);
          } catch (e) {
            let decryptedResult = '';

            _.forEach(data, (item) => {
              decryptedResult += Crypto.AES.decrypt(item, passPhrase).toString(Crypto.enc.Base64);
            });

            return of(decryptedResult);
          }
        } else {
          try {
            return of(Crypto.AES.decrypt(encryptData, passPhrase).toString(Crypto.enc.Utf8));
          } catch (e) {
            return of(Crypto.AES.decrypt(encryptData, passPhrase).toString(Crypto.enc.Base64));
          }
        }
      }),
      catchError((err) => {
        this.tools.error('decrypt error', err);
        return throwError(err);
      })
    );
  }

  /**
   *
   * @param documentId document id
   */
  private generatePassPhrase(documentId: number | string): Observable<string> {
    return this.store.getCustomerId().pipe(
      first(),
      mergeMap((customerId) => of(customerId + this.salt + documentId))
    );
  }
}
