import { createAction, props } from '@ngrx/store';

import { Acls } from '../gfl-core/gfl-models/acls.model';
import { PermissionsState } from './permissions.reducer';

export const initialize = createAction(
  '[Store Service setNgrxStore] initialize PermissionsState',
  props<{ permissionsState: PermissionsState }>()
);

export const setAcls = createAction(
  '[Acls Service setPermissions()] Set Permissions',
  props<{ acls: { [id: number]: Acls } }>()
);
