import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import * as fromAuth from './reducers';
import { AuthEffects } from './reducers/auth.effects';

@NgModule({
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.authReducer, { metaReducers: fromAuth.metaReducers }),
    EffectsModule.forFeature([AuthEffects]),
  ],
})
export class AuthenticationModule {}
