import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { CustomerRole } from '../../customer/models/customer.model';
import { StoreService } from './store.service';
import { WsService } from './ws.service';
import { ToolsService } from './tools.service';
import { DataMonitorService } from './data-monitor.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private roles: { [customerTypeId: string]: CustomerRole[] } = {};
  private readonly appName = environment.APP_NAME;

  constructor(
    private store: StoreService,
    private wsSrv: WsService,
    private tools: ToolsService,
    private dataMonitorSrv: DataMonitorService,
    private translate: TranslateService
  ) {
    const locksToMonitor = [
      {
        name: 'roles',
        lock: () => this.store.getRolesLock(),
        cb: () => this.setRoles(),
      },
    ];

    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * Fetch roles on app initialization
   */
  public loadRoles(): Promise<void> {
    return new Promise((resolve) => {
      this.manageRoles().subscribe(() => {
        resolve();
      });
    });
  }

  /**
   * update roles
   */
  public manageRoles(): Observable<any> {
    // first we fetch from localstorage
    return this.store.get(`${this.appName}_roles`).pipe(
      mergeMap((roles) => {
        if (roles) {
          this.roles = roles;
          return of(roles);
        } else {
          // if no local data then we fetch via http request
          return this.setRoles();
        }
      })
    );
  }

  /**
   * Set Statuses from BO
   *
   * @param reset if true empty data first
   */
  public setRoles(reset?: boolean): Observable<any> {
    return this.store.setRolesLock(Date.now()).pipe(
      mergeMap(() => this.wsSrv.requestCustomerRoles()),
      map((roles) => {
        this.roles = reset ? {} : roles;

        return this.store.set(`${this.appName}_roles`, this.roles);
      }),
      mergeMap(() => this.store.setRolesLock(null)),
      catchError((err) => {
        this.tools.error('RolesService setRoles()', err);
        return of(true);
      })
    );
  }

  /**
   * Get roles from BO
   */
  public getRoles(): { [customerTypeId: string]: CustomerRole[] } {
    return _.cloneDeep(this.roles);
  }

  /**
   * Get roles for a customerTypeId
   */
  public getRolesByCustomerTypeId(id: number): Observable<CustomerRole[] | undefined> {
    const roles = _.cloneDeep(this.roles[id]);
    const translationKeys = _.map(roles, (o) => o.name);

    return this.translate.get(translationKeys).pipe(
      map((result) =>
        _.map(roles, (role) => {
          role.name = result[role.name];
          return role;
        })
      )
    );
  }
}
