import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

import { Subscription } from 'rxjs';
import { filter, finalize, first, switchMap } from 'rxjs/operators';

import { CustomerService } from '../../services/customer.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { Customer, CustomerBank, CustomerFamilyMember } from '../../models/customer.model';
import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { GflModeDisplayType } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form.model';

import { IbanValidation } from '../../../gfl-libraries/gfl-form-generator/validators/iban.validator';

@Component({
  selector: 'gfl-member-bank',
  templateUrl: './member-bank.component.html',
  styleUrls: ['./member-bank.component.scss'],
})
export class MemberBankComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedMember: CustomerFamilyMember | Customer;
  @Input() style: FrontTheme;
  @Input() acls: Acls;
  @Input() isOffline: boolean;

  public customerBankData: CustomerBank;
  public isEditMode = false;
  public modeDisplay: GflModeDisplayType;
  public firstName: string;

  private subscriptions: Subscription[] = [];

  public bankForm: FormGroup = new FormGroup({});
  public validatorsForm: { [id: string]: Array<ValidatorFn> };

  /**
   * @ignore
   */
  constructor(
    private router: Router,
    private customerSrv: CustomerService,
    public notificationSrv: NotificationService,
    public tools: ToolsService,
    public translate: TranslateService,
    public platform: Platform,
    private activatedRoute: ActivatedRoute
  ) {}

  /**
   * @ignore
   */
  ngOnInit() {
    this.setModeDisplay();
    this.initForm();
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedMember && this.acls) {
      this.setCustomerBankData();

      if ('first_name' in this.selectedMember) {
        this.firstName = this.selectedMember.first_name;
      } else if ('firstname') {
        this.firstName = this.selectedMember.firstname;
      }
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then();
  }

  /**
   * Reset form to initial values
   */
  public onCancel() {
    this.bankForm.reset(this.customerBankData);

    this.isEditMode = false;
  }

  /**
   * set edit mode
   */
  public onEditMode() {
    this.isEditMode = true;
  }

  /**
   * Check data from form and save customer bank to BO
   */
  public onSubmit() {
    if (this.bankForm.invalid) {
      return;
    }

    this.tools.showLoader();

    // prepare data bank
    const customerBank: CustomerBank = {
      ...this.bankForm.value,
      id: this.customerBankData.id,
    };

    // Launch bank save process
    this.customerSrv
      .saveCustomerBank(customerBank, this.selectedMember.id)
      .pipe(
        switchMap(() => this.customerSrv.setCustomer({ customerIdLinked: this.selectedMember.id })),
        finalize(() => this.tools.hideLoader())
      )
      .subscribe(
        () => {
          this.notificationSrv.showSuccess({ message: 'PROFILE.SAVE' });
          this.isEditMode = false;

          if (this.modeDisplay === GflModeDisplayType.Mobile) {
            this.router.navigate(['../'], { relativeTo: this.activatedRoute }).then(() => this.bankForm.reset());
          }
        },
        (err) => {
          this.tools.error('MemberBankComponent onSubmit()', err);
          this.notificationSrv.showError({ message: 'API.ERROR_MESSAGE' });
        }
      );
  }

  /**
   * Set modeDisplay to mobile or tablet
   */
  private setModeDisplay(): void {
    this.modeDisplay = this.tools.setModeDisplay();
  }

  /**
   * Set customer's bank data
   */
  private setCustomerBankData(): void {
    this.customerSrv
      .getCustomerBank(this.selectedMember.id)
      .pipe(
        filter((val) => !_.isEmpty(val) || _.isArray(val)),
        first()
      )
      .subscribe(
        (customerBankData) => {
          if (!customerBankData.id) {
            customerBankData = {};
          }
          this.customerBankData = customerBankData;
        },
        (err) => {
          this.tools.error('MemberBankComponent setCustomerBankData()', err);
        }
      );
  }

  /**
   * Generate dynamic form
   */
  private initForm(): void {
    this.validatorsForm = {
      owner: [Validators.required],
      iban: [Validators.required, IbanValidation.isValidIBAN],
      swift: [IbanValidation.isValidBIC],
      bank_name: [Validators.required],
    };
  }
}
