import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, first, mergeMap, tap } from 'rxjs/operators';

import { AgencyService } from '../../gfl-core/gfl-services/agency.service';
import { CustomerService } from '../../customer/services/customer.service';
import { PolicyService } from '../../policies/services/policy.service';
import { InsuranceService } from '../../policies/services/insurance.service';
import { ItemService } from '../../gfl-core/gfl-services/item.service';
import { CompareService } from '../../compares/services/compare.service';
import { ApiService } from '../../gfl-core/gfl-services/api.service';
import { ConstantService } from '../../gfl-core/gfl-services/constant.service';
import { StatusService } from '../../gfl-core/gfl-services/status.service';
import { StoreService } from '../../gfl-core/gfl-services/store.service';
import { ToolsService } from '../../gfl-core/gfl-services/tools.service';
import { NotificationService } from '../../gfl-core/gfl-services/notification.service';
import { CompanyService } from '../../gfl-core/gfl-services/company.service';
import { SafeboxService } from '../../safebox/services/safebox.service';
import { DocumentService } from '../../gfl-core/gfl-services/document.service';
import { ContactsService } from '../../contacts/services/contacts.service';
import { SliderService } from '../../gfl-core/gfl-services/slider.service';
import { AclsService } from '../../gfl-core/gfl-services/acls.service';
import { Acls } from '../../gfl-core/gfl-models/acls.model';
import { SliderType } from '../../gfl-core/gfl-models/slider.model';

import { environment } from '../../../environments/environment';
import { AuthActions } from './action-types';

@Injectable()
export class AuthEffects {
  readonly appName: string;
  private lang: string;

  loginUserAsCustomer$ = createEffect(
    () => {
      let action;

      return this.actions$.pipe(
        ofType(AuthActions.loginUserAsCustomer),
        mergeMap((actionRep) => {
          action = actionRep;
          this.store.setLang(navigator.language.substring(0, 2));
          if (!environment.DEDICATED_APP) {
            this.store.resetSliders();
          }

          return this.removePersistentStore();
        }),
        mergeMap(() => this.setLockNull()),
        tap(() => this.apiSrv.cancelPendingRequests()),
        mergeMap(() => {
          environment.AGENCY_ID =
            (environment.DEDICATED_APP && environment.AGENCY_ID) ||
            (!environment.DEDICATED_APP && action.agencyId) ||
            environment.ADMIN_AGENCY_ID;

          return this.agencySrv.setAgency(environment.AGENCY_ID);
        }),
        mergeMap(() => this.initSliders()),
        mergeMap(() =>
          this.customerSrv.loginUserAsCustomer(action.userToken, action.customerId).pipe(
            catchError((err) => {
              this.store.logout();
              this.router.navigateByUrl('/welcome').then(() => this.notificationSrv.showError({ message: err }));
              return of(null);
            })
          )
        ),
        mergeMap((customer) => {
          if (customer) {
            this.router.navigateByUrl('/home').then(() =>
              this.notificationSrv.showSuccess({
                message: 'LOGIN.SUCCESS_USER_CONNEXION',
                interpolation: { login: customer.login },
                cssClass: 'ion-text-center',
              })
            );
            return this.setAcls();
          }

          return null;
        })
      );
    },
    { dispatch: false }
  );

  logout$ = createEffect(
    () => {
      let action;
      return this.actions$.pipe(
        ofType(AuthActions.logout),
        mergeMap((actionRep) => {
          action = actionRep;
          this.store.setLang(navigator.language.substring(0, 2));
          if (!environment.DEDICATED_APP) {
            this.store.resetSliders();
          }

          return this.removePersistentStore();
        }),
        mergeMap(() => {
          this.apiSrv.cancelPendingRequests();
          return this.setLockNull();
        }),
        mergeMap(() => this.setAcls()),
        mergeMap(() => {
          if (!environment.DEDICATED_APP) {
            environment.AGENCY_ID = environment.ADMIN_AGENCY_ID;
            return this.agencySrv.setAgency(environment.ADMIN_AGENCY_ID);
          }
          return of(true);
        }),
        tap(() => {
          if (!action.noRedirection) {
            this.router.navigateByUrl('/welcome').then(() => {
              this.store.setDisplayNomadLoader(false);
            });
          } else {
            this.store.setDisplayNomadLoader(false);
          }
        }),
        mergeMap(() => {
          if (!environment.DEDICATED_APP) {
            return this.initSliders();
          }
          return of(true);
        }),
        mergeMap(() => this.setLockNull()),
        tap(() => {
          // this.store.setIsRefreshingData(false);
        })
      );
    },
    { dispatch: false }
  );

  login$ = createEffect(
    () => {
      let action;
      let acls: Acls;

      return this.actions$.pipe(
        ofType(AuthActions.login),
        mergeMap((actionRep) => {
          action = actionRep;
          if (!environment.DEDICATED_APP) {
            environment.AGENCY_ID = actionRep.authData.agencyId;
          }
          return this.removePersistentStore();
        }),
        mergeMap(() => this.setLockNull()),
        tap(() => this.apiSrv.cancelPendingRequests()),
        mergeMap(() => {
          if (!environment.DEDICATED_APP) {
            return this.setAgency();
          }
          return of(true);
        }),
        mergeMap(() => this.getLang()),
        mergeMap((lang) => {
          this.lang = lang;

          return this.sliderSrv.setSliders(environment.AGENCY_ID, SliderType.Private, this.lang, true).pipe(
            catchError((err) => {
              this.tools.error('Auth Effect setSliders', err);
              return of(null);
            })
          );
        }),
        mergeMap(() => {
          this.store.resetCustomerData();
          return this.store.setCustomerLock(Date.now()).pipe(
            mergeMap(() =>
              this.customerSrv.setCustomer({
                customerToken: action.authData.customerToken,
                customerIdLinked: null,
                noUpdateMode: false,
                noAskForValidationCustomerLinks: false,
                fetchCustomerLinks: true,
                noContract: false,
              })
            ),
            mergeMap(() => {
              // in app.component.ts, if there's a customer link received
              // now we can display it just once
              this.store.setCustomerCompletedFlag(true);
              return this.store.setCustomerLock(null);
            }),
            catchError((err) => {
              this.tools.error('Auth Effect setCustomer', err);
              return of(null);
            })
          );
        }),
        mergeMap(() => this.setAcls()),
        tap((aclsResp) => {
          acls = aclsResp;
          this.store.setDisplayNomadLoader(false);
          this.store.setIsRefreshingData(true);
        }),
        mergeMap(() => {
          if (action.reloadAll) {
            return forkJoin([
              this.agencySrv.setContacts(action.authData.customerId),
              this.itemSrv.setItemTemplates(this.lang),
              this.documentSrv.setDocumentCategories(),
            ]).pipe(
              catchError((err) => {
                this.tools.error('Auth Effect setContacts,setItemTemplates,setDocumentCategories', err);
                return of(null);
              })
            );
          } else {
            return forkJoin([
              this.agencySrv.setContacts(action.authData.customerId),
              of(null),
              this.documentSrv.setDocumentCategories(),
            ]).pipe(
              catchError((err) => {
                this.tools.error('Auth Effect setContacts,setDocumentCategories', err);
                return of(null);
              })
            );
          }
        }),
        mergeMap(() => this.setInsuranceTypes(action.authData.reset)),
        mergeMap(() => this.setCompanies()),
        mergeMap(() => this.setPoliciesAndMandates(action)),
        mergeMap(() => this.setCompares(action)),
        mergeMap(() => this.setDocuments(action)),
        mergeMap(() => this.setChatItems()),
        mergeMap(() => {
          if (acls.LOAD_SAFEBOX) {
            return this.setSafeboxTypes();
          }
          return of(true);
        }),
        mergeMap(() => {
          if (acls.LOAD_SAFEBOX) {
            return this.setSafeboxes();
          }
          return of(true);
        })
      );
    },
    { dispatch: false }
  );

  reloadAll$ = createEffect(
    () => {
      let action;
      let acls;

      return this.actions$.pipe(
        ofType(AuthActions.reloadAll),
        tap((actionRep) => (action = actionRep)),
        mergeMap(() => this.getLang()),
        mergeMap((lang) => {
          this.lang = lang;
          return this.store.setTranslationsLock(Date.now()).pipe(
            mergeMap(() => this.http.get(environment.API_URL + '/translations?language_iso=' + lang)),
            catchError((err) => {
              this.tools.log('reloadAll$ translation HTTP ERROR', err);
              return of(null);
            })
          );
        }),
        mergeMap((translationObj) => {
          const translations = {};
          translations[this.lang] = translationObj;

          if (translationObj) {
            return this.store.set(`${this.appName}_translations`, translations);
          } else {
            return of(true);
          }
        }),
        mergeMap(() => this.store.setTranslationsLock(null)),
        mergeMap(() => this.setConstants()),
        mergeMap(() => this.setForeignTables()),
        mergeMap(() => this.setStatuses()),
        mergeMap(() => this.setAcls()),
        tap((aclsResp) => {
          acls = aclsResp;
          this.store.setDisplayNomadLoader(false);
        }),
        mergeMap(() => this.setAgency()),
        mergeMap(() => this.initSliders()),
        mergeMap(() => this.setInsuranceTypes(true)),
        mergeMap(() => this.setItemTemplates()),
        mergeMap(() => {
          if (acls.LOAD_SAFEBOX) {
            return this.setSafeboxTypes();
          }
          return of(true);
        }),
        mergeMap(() => this.setCompanies()),
        mergeMap(() => {
          this.store.setCustomerCompletedFlag(false);

          this.store.login({
            authData: {
              isLoggedIn: true,
              customerToken: action.authState.customerToken,
              customerLogin: action.authState.customerLogin,
              customerId: action.authState.customerId,
              agencyId: action.authState.agencyId,
              agencies: action.authState.agencies,
              customerTypeId: action.authState.customerTypeId,
              customerRefreshedTimeStamp: Date.now(),
              language: action.authState.language,
              rememberMe: action.authState.rememberMe,
            },
            reloadAll: true,
            noNotifications: true,
            reset: true,
          });
          return of(true);
        })
      );
    },
    { dispatch: false }
  );

  /**
   * @ignore
   */
  constructor(
    private actions$: Actions,
    private router: Router,
    private agencySrv: AgencyService,
    private customerSrv: CustomerService,
    private policySrv: PolicyService,
    private compareSrv: CompareService,
    private companySrv: CompanyService,
    private insuranceSrv: InsuranceService,
    private itemSrv: ItemService,
    private apiSrv: ApiService,
    private constantSrv: ConstantService,
    private statusSrv: StatusService,
    private store: StoreService,
    private tools: ToolsService,
    private http: HttpClient,
    private notificationSrv: NotificationService,
    private documentSrv: DocumentService,
    private safeboxSrv: SafeboxService,
    private contactsSrv: ContactsService,
    private sliderSrv: SliderService,
    private aclsSrv: AclsService
  ) {
    this.appName = environment.APP_NAME;
  }

  private setDocuments(action): Observable<any> {
    this.tools.log('AuthEffects', 'setDocuments');
    this.store.setIsRefreshingData(false);

    if (action.cb) {
      action.cb();
    }

    const CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
    const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
    const CUSTOMER_TYPE_ID_CORPORATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_CORPORATE');

    let except;

    switch (action.authData.customerTypeId) {
      case CUSTOMER_TYPE_ID_PRIVATE:
        except = [CUSTOMER_TYPE_ID_EMPLOYEE, CUSTOMER_TYPE_ID_CORPORATE];
        break;
      case CUSTOMER_TYPE_ID_EMPLOYEE:
        except = [CUSTOMER_TYPE_ID_PRIVATE];
    }

    return this.customerSrv.getCustomersIdList(except).pipe(
      filter((val) => !_.isEmpty(val)),
      first(),
      mergeMap((customersId) => this.documentSrv.setDocuments(customersId)),
      catchError((err) => {
        this.tools.log('$login setDocuments ERROR', err);
        return of(null);
      })
    );
  }

  private setCompanies(): Observable<any> {
    this.tools.log('AuthEffects', 'setCompanies');

    return this.companySrv.setCompanies().pipe(
      catchError((err) => {
        this.tools.error('Auth Effect setCompanies', err);
        return of(null);
      })
    );
  }

  private setAgency(): Observable<any> {
    this.tools.log('AuthEffects setAgency', environment.AGENCY_ID);

    return this.agencySrv.setAgency(environment.AGENCY_ID).pipe(
      catchError((err) => {
        this.tools.error('Auth Effect setAgency', err);
        return of(null);
      })
    );
  }

  private setSafeboxTypes(): Observable<any> {
    this.tools.log('AuthEffects', 'setSafeboxTypes');

    return this.safeboxSrv.setSafeboxTypes(this.lang, true).pipe(
      catchError((err) => {
        this.tools.error('Auth Effect setSafeboxTypes', err);
        return of(null);
      })
    );
  }

  private setItemTemplates(): Observable<any> {
    this.tools.log('AuthEffects', 'setItemTemplates');

    return this.itemSrv.setItemTemplates(this.lang, true).pipe(
      catchError((err) => {
        this.tools.error('Auth Effect setItemTemplates', err);
        return of(null);
      })
    );
  }

  private setInsuranceTypes(reset): Observable<any> {
    this.tools.log('AuthEffects', 'setInsuranceTypes');

    return this.insuranceSrv.setInsuranceTypes(this.lang, reset).pipe(
      catchError((err) => {
        this.tools.error('Auth Effect setInsuranceTypes', err);
        return of(null);
      })
    );
  }

  private initSliders(): Observable<any> {
    this.tools.log('AuthEffects', 'initSliders');

    return this.sliderSrv.initService(true).pipe(
      catchError((err) => {
        this.tools.error('Auth Effect initService', err);
        return of(null);
      })
    );
  }

  private setStatuses(): Observable<any> {
    this.tools.log('AuthEffects', 'setStatuses');

    return this.statusSrv.setStatuses(this.lang, true).pipe(
      catchError((err) => {
        this.tools.error('Auth Effect setStatuses', err);
        return of(null);
      })
    );
  }

  private setForeignTables(): Observable<any> {
    this.tools.log('AuthEffects', 'setForeignTables');

    return this.constantSrv.setForeignTables().pipe(
      catchError((err) => {
        this.tools.error('Auth Effect setForeignTables', err);
        return of(null);
      })
    );
  }

  private setConstants(): Observable<any> {
    this.tools.log('AuthEffects', 'setConstants');

    return this.constantSrv.setConstants(this.lang, true).pipe(
      catchError((err) => {
        this.tools.error('Auth Effect setConstants', err);
        return of(null);
      })
    );
  }

  private getLang(): Observable<any> {
    this.tools.log('AuthEffects', 'getLang');

    return this.store.getLang().pipe(
      filter((val) => !!val),
      first(),
      catchError((err) => {
        this.tools.error('Auth Effect getLang', err);
        return of(null);
      })
    );
  }

  private setAcls(): Observable<Acls> {
    this.tools.log('AuthEffects', 'setAcls');

    return this.aclsSrv.setAcls().pipe(
      first(),
      catchError((err) => {
        this.tools.error('Auth Effect setAcls', err);
        return of(null);
      })
    );
  }

  private setSafeboxes(): Observable<any> {
    this.tools.log('AuthEffects', 'setSafeboxes');

    return this.safeboxSrv.setSafeboxes().pipe(
      catchError((err) => {
        this.tools.error('Auth Effect setSafeboxes', err);
        return of(null);
      })
    );
  }

  private setChatItems(): Observable<any> {
    this.tools.log('AuthEffects', 'setChatItems');

    return this.contactsSrv.setChatItems().pipe(
      catchError((err) => {
        this.tools.error('Auth Effect setChatItems', err);
        return of(null);
      })
    );
  }

  private setCompares(action): Observable<any> {
    this.tools.log('AuthEffects', 'setCompares');

    return this.compareSrv.setCompares(action.authData.reset).pipe(
      catchError((err) => {
        this.tools.error('Auth Effect setCompares', err);
        return of(null);
      })
    );
  }

  private setPoliciesAndMandates(action): Observable<any> {
    this.tools.log('AuthEffects', 'setPoliciesAndMandates');

    return this.policySrv.setPoliciesAndMandates(action.authData.reset).pipe(
      catchError((err) => {
        this.tools.error('Auth Effect setPoliciesAndMandates', err);
        return of(null);
      })
    );
  }

  private removePersistentStore(): Observable<any> {
    this.tools.log('AuthEffects', 'removePersistentStore');
    const obs$ = [
      this.store.removePersistentStoreCustomer(this.appName),
      this.store.removePersistentStorePolicies(this.appName),
      this.store.removePersistentStoreCompare(this.appName),
      this.store.removePersistentStoreDocuments(this.appName),
      this.store.removePersistentStoreContacts(this.appName),
      this.store.removePersistentStoreSafebox(this.appName),
      this.store.removePersistentStorePermissions(this.appName),
    ];

    if (!environment.DEDICATED_APP) {
      obs$.push(this.store.removePersistentStoreSprite(this.appName));
    }

    return forkJoin(obs$).pipe(
      catchError((err) => {
        this.tools.error('Auth Effect removePersistentStore', err);
        return of(null);
      })
    );
  }

  private setLockNull(): Observable<any> {
    this.tools.log('AuthEffects', 'setLockNull');

    return forkJoin([
      this.store.setCustomerLock(null),
      this.store.setInsuranceTypesLock(null),
      this.store.setCompaniesLock(null),
      this.store.setPoliciesAndMandatesLock(null),
      this.store.setItemTemplatesLock(null),
      this.store.setDocumentCategoriesLock(null),
      this.store.setComparesLock(null),
      this.store.setDocumentsLock(null),
      this.store.setSafeboxesLock(null),
      this.store.setChatItemsLock(null),
      this.store.setPermissionsLock(null),
    ]).pipe(
      catchError((err) => {
        this.tools.error('Auth Effect setLock', err);
        return of(null);
      })
    );
  }
}
