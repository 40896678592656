import { Injectable } from '@angular/core';
import { ToastOptions } from '@ionic/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ToolsService } from './tools.service';

export interface NotificationConfig extends ToastOptions {
  interpolation?: Record<string, any>;
  callback?: any;
  returnObservable?: boolean;
  showCloseButton?: boolean;
  closeButtonText?: string;
  message?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  readonly isNative;
  private durationDefault = 3000;
  private positionDefault = 'bottom';
  private options: NotificationConfig = {};

  constructor(private toastCtrl: ToastController, private tools: ToolsService, private translate: TranslateService) {
    this.isNative = this.tools.isNative();
  }

  /**
   * Configure error style and call show method
   *
   * @param config the toast configuration object
   */
  public showError(config: NotificationConfig): Observable<Promise<HTMLIonToastElement>> {
    this.options.cssClass = 'gfl-toast-error';
    return this.show(config);
  }

  /**
   * Configure success style and call show method
   *
   * @param config the toast configuration object
   */
  public showSuccess(config: NotificationConfig): Observable<Promise<HTMLIonToastElement>> {
    this.options.cssClass = 'gfl-toast-success';
    return this.show(config);
  }

  /**
   * Configure warning style and call show method
   *
   * @param config the toast configuration object
   */
  public showWarning(config: NotificationConfig): Observable<Promise<HTMLIonToastElement>> {
    this.options.cssClass = 'gfl-toast-warning';
    return this.show(config);
  }

  public showOfflineNotAvailable(): void {
    this.showWarning({ message: 'NETWORK.ERROR_TITLE' });
  }

  /**
   * Show either a native notification or an ionic one depending on the sisNative flag
   *
   * @param config the toast configuration object
   */
  public show(config: NotificationConfig): Observable<Promise<HTMLIonToastElement>> {
    const obs$ = this.translate.get(config.message, config.interpolation).pipe(
      map(async (message) => {
        this.options.message = message;
        // @ts-ignore
        this.options.position = config.position || this.positionDefault;
        this.options.duration = config.showCloseButton ? null : config.duration || this.durationDefault;

        if (config.showCloseButton) {
          this.options.buttons = [
            {
              text: config.closeButtonText || 'X',
              role: 'cancel',
              handler: () => {},
            },
          ];
        }

        const toast = await this.toastCtrl.create(this.options);
        await toast.present();
        if (config.callback) {
          config.callback();
        }

        return toast;
      })
    );

    if (config.returnObservable) {
      return obs$;
    } else {
      obs$.subscribe(() => {}, console.error);
    }
  }
}
