import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

// tslint:disable-next-line:ban-types
declare let ga: (a: string, b: string, c?: any) => void;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsEventsService {
  constructor() {}

  /**
   * Initialize GA tracker
   */
  public startTrackerWithId(): void {
    ga('create', environment.GOOGLE_ANALYTICS_KEY, 'auto');
    ga('set', 'checkProtocolTask', null);
    ga('set', 'transportUrl', 'https://www.google-analytics.com/collect');
  }

  /**
   * Send page view to GA
   */
  public trackView(screenName: string): void {
    ga('set', 'page', screenName);
    ga('send', 'pageview');
  }

  /**
   * Send Event to GA
   */
  public trackEvent(category: string, action: string, label: string, customerId: number): void {
    ga('send', 'event', {
      eventCategory: category,
      eventLabel: label,
      eventAction: action,
      eventValue: customerId,
    });
  }
}
