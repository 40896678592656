import { NgModule } from '@angular/core';
import { GflCoreModule } from '../../gfl-core/gfl-core.module';
import { IonicModule } from '@ionic/angular';

import { GflFormGeneratorModule } from '../../gfl-libraries/gfl-form-generator/gfl-form-generator.module';

import { ContactComponent } from './contact/contact.component';
import { RequestCallComponent } from './request-call/request-call.component';
import { RouterModule } from '@angular/router';
import { ChatDisplayComponent } from './chat-display/chat-display.component';
import { ChatItemUserComponent } from './chat-item-user/chat-item-user.component';
import { ChatItemCustomerComponent } from './chat-item-customer/chat-item-customer.component';
import { NotificationsDisplayComponent } from './notifications-display/notifications-display.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';

@NgModule({
  declarations: [
    ContactComponent,
    RequestCallComponent,
    ChatDisplayComponent,
    ChatItemUserComponent,
    ChatItemCustomerComponent,
    NotificationsDisplayComponent,
    NotificationItemComponent,
  ],
  imports: [GflCoreModule, IonicModule, GflFormGeneratorModule.forRoot(), RouterModule],
  exports: [ContactComponent, RequestCallComponent, ChatDisplayComponent, NotificationsDisplayComponent],
})
export class ContactComponentsModule {}
