import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';

import { ToolsService } from './gfl-core/gfl-services/tools.service';

@Injectable({
  providedIn: 'root',
})
export class MobileGuard implements CanActivate {
  /**
   * @ignore
   */
  constructor(private tools: ToolsService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.tools.isMobile()) {
      return of(true);
    } else {
      this.router.navigateByUrl('/policies').then();
      return of(false);
    }
  }
}
