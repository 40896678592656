import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PoliciesState } from './index';

export const selectPoliciesState = createFeatureSelector<PoliciesState>('policies');

export const policiesAndMandates = createSelector(
  selectPoliciesState,
  policiesState => policiesState.policiesList
);

export const policiesItems = createSelector(
  selectPoliciesState,
  policiesState => policiesState.policiesItems
);

export const insuranceTypes = createSelector(
  selectPoliciesState,
  policiesState => policiesState.insuranceTypes
);

export const insuranceTypesPrivate = createSelector(
  selectPoliciesState,
  policiesState => policiesState.insuranceTypes.private
);

export const insuranceTypesCorporate = createSelector(
  selectPoliciesState,
  policiesState => policiesState.insuranceTypes.corporate
);

export const companies = createSelector(
  selectPoliciesState,
  policiesState => policiesState.companies.items
);

export const companiesByCustomerTypeByInsuranceType = createSelector(
  selectPoliciesState,
  policiesState => policiesState.companiesByCustomerTypeByInsuranceType
);
