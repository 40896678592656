import { createAction, props } from '@ngrx/store';
import { DocumentsState } from './documents.reducer';
import { DocumentCategoryItem, Document } from '../gfl-core/gfl-models/document.model';

export const initialize = createAction(
  '[Store Service setNgrxStore] initialize DocumentsState',
  props<{ documentsState: DocumentsState }>()
);

export const reset = createAction('[Store Service logout()] reset DocumentsState');

export const setDocumentCategories = createAction(
  '[Document Service setDocumentCategories()] load document categories from BO',
  props<{ categoriesByInsuranceType: DocumentCategoryItem[] }>()
);

export const setDocuments = createAction(
  '[Documents Service setDocuments()] Set documents',
  props<{ documents: { [id: string]: Document } }>()
);
