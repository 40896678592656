import { createReducer, on } from '@ngrx/store';
import { LocksActions } from './action.types';

export interface LocksState {
  documentCategoriesLock: number;
  documentsLock: number;
  safeboxTypesLock: number;
  safeboxesLock: number;
  agencyLock: number;
  sliderLock: number;
  companiesLock: number;
  insuranceTypesLock: number;
  policiesLock: number;
  customerLock: number;
  chatItemsLock: number;
  comparesLock: number;
  constantsLock: number;
  foreignTablesLock: number;
  spriteLock: number;
  statusesLock: number;
  rolesLock: number;
  itemTemplatesLock: number;
  translationsLock: number;
  permissionsLock: number;
}

export const initialLocksState: LocksState = {
  documentCategoriesLock: null,
  documentsLock: null,
  safeboxTypesLock: null,
  safeboxesLock: null,
  agencyLock: null,
  sliderLock: null,
  companiesLock: null,
  insuranceTypesLock: null,
  policiesLock: null,
  customerLock: null,
  chatItemsLock: null,
  comparesLock: null,
  constantsLock: null,
  foreignTablesLock: null,
  spriteLock: null,
  statusesLock: null,
  rolesLock: null,
  itemTemplatesLock: null,
  translationsLock: null,
  permissionsLock: null,
};

export const locksReducer = createReducer(
  initialLocksState,

  on(LocksActions.setDocumentCategoriesLock, (state, action) => ({
    ...state,
    documentCategoriesLock: action.lock,
  })),

  on(LocksActions.setDocumentsLock, (state, action) => ({
    ...state,
    documentsLock: action.lock,
  })),

  on(LocksActions.setSafeboxTypesLock, (state, action) => ({
    ...state,
    safeboxTypesLock: action.lock,
  })),

  on(LocksActions.setAgencyLock, (state, action) => ({
    ...state,
    agencyLock: action.lock,
  })),

  on(LocksActions.setSlidersLock, (state, action) => ({
    ...state,
    sliderLock: action.lock,
  })),

  on(LocksActions.setPoliciesAndMandatesLock, (state, action) => ({
    ...state,
    policiesLock: action.lock,
  })),

  on(LocksActions.setInsuranceTypesLock, (state, action) => ({
    ...state,
    insuranceTypesLock: action.lock,
  })),

  on(LocksActions.setCompaniesLock, (state, action) => ({
    ...state,
    companiesLock: action.lock,
  })),

  on(LocksActions.setCustomerLock, (state, action) => ({
    ...state,
    customerLock: action.lock,
  })),

  on(LocksActions.setChatItemsLock, (state, action) => ({
    ...state,
    chatItemsLock: action.lock,
  })),

  on(LocksActions.setComparesLock, (state, action) => ({
    ...state,
    comparesLock: action.lock,
  })),

  on(LocksActions.setConstantsLock, (state, action) => ({
    ...state,
    constantsLock: action.lock,
  })),

  on(LocksActions.setSpriteLock, (state, action) => ({
    ...state,
    spriteLock: action.lock,
  })),

  on(LocksActions.setStatusesLock, (state, action) => ({
    ...state,
    statusesLock: action.lock,
  })),

  on(LocksActions.setPermissionsLock, (state, action) => ({
    ...state,
    permissionsLock: action.lock,
  })),

  on(LocksActions.setRolesLock, (state, action) => ({
    ...state,
    rolesLock: action.lock,
  })),

  on(LocksActions.setItemTemplatesLock, (state, action) => ({
    ...state,
    itemTemplatesLock: action.lock,
  })),

  on(LocksActions.setTranslationLock, (state, action) => ({
    ...state,
    translationsLock: action.lock,
  }))
);
