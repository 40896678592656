import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PoliciesPage } from './pages/policies/policies.page';
import { AuthGuard } from '../authentication/guards/auth.guard';
import { OnlineGuard } from '../online.guard';
import { MobileGuard } from '../mobile.guard';

const routes: Routes = [
  {
    path: 'policies',
    component: PoliciesPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'policies/policy-detail/:policyId',
    loadChildren: () => import('./pages/policy-detail/policy-detail.module').then(m => m.PolicyDetailPageModule),
    canActivate: [AuthGuard, MobileGuard],
  },
  {
    path: 'policies/policy-cg/:policyId',
    loadChildren: () => import('./pages/policy-cg/policy-cg.module').then(m => m.PolicyCgPageModule),
    canActivate: [AuthGuard, MobileGuard],
  },
  {
    path: 'policies/policy-display/:policyId',
    loadChildren: () => import('./pages/policy-display/policy-display.module').then(m => m.PolicyDisplayPageModule),
    canActivate: [AuthGuard, MobileGuard],
  },
  {
    path: 'policies/budget',
    loadChildren: () => import('./pages/policies-budget/policies-budget.module').then(m => m.PolicyBudgetPageModule),
    canActivate: [AuthGuard, MobileGuard],
  },
  {
    path: 'policies/budget-global',
    loadChildren: () =>
      import('./pages/policies-budget-global/policies-budget-global.module').then(m => m.PolicyBudgetGlobalPageModule),
    canActivate: [AuthGuard, MobileGuard],
  },
  {
    path: 'policies/refund/:policyId',
    loadChildren: () =>
      import('./pages/policy-refund-item/policy-refund-item.module').then(m => m.PolicyRefundItemPageModule),
    canActivate: [AuthGuard, OnlineGuard],
  },
  {
    path: 'policies/refund',
    loadChildren: () => import('./pages/policy-refund/policy-refund.module').then(m => m.PolicyRefundPageModule),
    canActivate: [AuthGuard, OnlineGuard],
  },
  {
    path: 'policies/policy-documents/:id',
    loadChildren: () =>
      import('./pages/policy-documents/policy-documents.module').then(m => m.PolicyDocumentsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'policies/policy-invoices/:id',
    loadChildren: () => import('./pages/policy-invoices/policy-invoices.module').then(m => m.PolicyInvoicesPageModule),
    canActivate: [AuthGuard, MobileGuard],
  },
  {
    path: 'policies/policy-documents/:policyId/documents-category/:categoryId',
    loadChildren: () =>
      import('./pages/policy-documents-category/policy-documents-category.module').then(
        m => m.PolicyDocumentsCategoryPageModule
      ),
    canActivate: [AuthGuard, MobileGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PoliciesRoutingModule {}
