import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CustomerService } from '../services/customer.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileAddCustomerLinkGuard implements CanActivate {
  constructor(private customerSrv: CustomerService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.customerSrv.getSelectedMember().pipe(
      map((selectedMember) => {
        // selectedMember.id === 0 may correspond to family or all companies
        if (!selectedMember || selectedMember.id === 0) {
          this.router.navigateByUrl('/profile').then();
        }
        return selectedMember.id !== 0;
      })
    );
  }
}
