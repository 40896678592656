import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { concat, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, first, map, mergeMap, tap, toArray } from 'rxjs/operators';

import { SafeboxType } from '../models/safebox-type.model';
import { Safebox } from '../models/safebox.model';
import { ApiService } from '../../gfl-core/gfl-services/api.service';
import { StoreService } from '../../gfl-core/gfl-services/store.service';
import { WsService } from '../../gfl-core/gfl-services/ws.service';
import { ToolsService } from '../../gfl-core/gfl-services/tools.service';
import { DocumentService } from '../../gfl-core/gfl-services/document.service';

import { environment } from '../../../environments/environment';
import { DataMonitorService } from '../../gfl-core/gfl-services/data-monitor.service';

@Injectable({
  providedIn: 'root',
})
export class SafeboxService {
  constructor(
    private apiSrv: ApiService,
    private wsSrv: WsService,
    public store: StoreService,
    private tools: ToolsService,
    private documentSrv: DocumentService,
    private dataMonitorSrv: DataMonitorService
  ) {
    const lang$ = this.store.getLang().pipe(filter((val) => !!val));

    const locksToMonitor = [
      {
        name: 'safeboxTypes',
        lock: () => this.store.getSafeboxTypesLock(),
        cb: () => lang$.pipe(mergeMap((lang) => this.setSafeboxTypes(lang))),
      },
      {
        name: 'safebox',
        lock: () => this.store.getSafeboxesLock(),
        cb: () => this.setSafeboxes(),
      },
    ];

    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * Check if safebox types list need to be fetched from BO
   */
  public loadSafeboxTypes(): Observable<any> {
    let lang;

    return this.store.getIsLoggedIn().pipe(
      first(),
      mergeMap((isLoggedIn) => {
        if (isLoggedIn) {
          return this.store.getLang().pipe(
            mergeMap((language) => {
              lang = language;
              return this.store.getSafeboxTypes();
            }),
            mergeMap((safeboxTypesObj) => {
              if (_.isEmpty(safeboxTypesObj) || !safeboxTypesObj[lang]) {
                return this.setSafeboxTypes(lang);
              } else {
                return of(true);
              }
            }),
            first()
          );
        } else {
          return of(null);
        }
      })
    );
  }

  /**
   * store safebox type objects
   *
   * @param lang selected language
   * @param reset if true empty data first
   */
  public setSafeboxTypes(lang: string, reset?: boolean): Observable<any> {
    return this.store.setSafeboxTypesLock(Date.now()).pipe(
      mergeMap(() => this.wsSrv.requestSafeboxTypes()),
      map((result) => _.values(result)),
      mergeMap((requestedSafeboxTypes: SafeboxType[]) => {
        if (reset) {
          this.store.resetSafeboxTypes();
        }

        return this.setSafeboxTypesSvg(of(requestedSafeboxTypes));
      }),
      mergeMap((safeboxTypesResult) => {
        this.store.setSafeboxTypes(safeboxTypesResult, lang);
        return this.store.setSafeboxTypesLock(null);
      }),
      catchError((err) => {
        this.tools.error('setSafeboxTypes ERROR', err);
        return of(null);
      })
    );
  }

  /**
   * Return an observable of safeboxTypes array
   */
  public getSafeboxesTypes(): Observable<{ [lang: string]: SafeboxType[] }> {
    return this.store.getSafeboxTypes().pipe(filter((val) => !_.isEmpty(val) || Object.keys(val).length === 0));
  }

  /**
   * Return an observable of safeboxes array
   */
  public getSafeboxes(): Observable<Safebox[]> {
    return this.store.getSafeboxes().pipe(map((safeboxesMap) => _.values(safeboxesMap)));
  }

  /**
   * store safebox objects
   */
  public setSafeboxes(): Observable<any> {
    let safeboxesFromBO;
    let safeboxesFromStore;

    return this.store.setSafeboxesLock(Date.now()).pipe(
      mergeMap(() => forkJoin([this.wsSrv.requestSafeboxes(), this.store.getSafeboxes().pipe(first())])),
      mergeMap(([safeboxesBO, safeboxesStore]: [{ [id: string]: Safebox }, { [id: string]: Safebox }]) => {
        safeboxesFromBO = _.cloneDeep(safeboxesBO);
        safeboxesFromStore = _.cloneDeep(safeboxesStore);
        const deletedSafeboxeIds = this.tools.findEntitiesToRemove(safeboxesFromStore, safeboxesFromBO);
        return this.removeSafeboxesFromStore(safeboxesFromStore, deletedSafeboxeIds);
      }),
      mergeMap((result) => {
        safeboxesFromStore = result.shift();
        const obs$ = [];

        // check for safebox items documents updated
        _.forEach(safeboxesFromStore, (item, id) => {
          const storedDocuments = item.documents;
          const documentsFromBo = safeboxesFromBO[id].documents;
          const storeInFolder = documentsFromBo.length
            ? documentsFromBo[0].customer_id || documentsFromBo[0].document_customer_id
            : undefined;
          obs$.push(this.documentSrv.updateStoredSafeboxDocuments(storedDocuments, documentsFromBo, storeInFolder));
        });

        if (!obs$.length) {
          obs$.push(of(null));
        }

        return forkJoin(obs$);
      }),
      mergeMap((result) => {
        _.forEach(safeboxesFromStore, (safebox) => {
          safebox.documents = result.shift();
        });
        // get list of new safebox items => store files
        const newSafeboxes: Safebox[] = this.tools.findNewEntities(safeboxesFromStore, safeboxesFromBO, 'safebox_id');
        const newSafeboxesDocuments = _.concat([], ..._.map(newSafeboxes, (safebox) => safebox.documents));
        const storeInFolder = newSafeboxesDocuments.length
          ? newSafeboxesDocuments[0].customer_id || newSafeboxesDocuments[0].document_customer_id
          : undefined;
        return forkJoin([of(newSafeboxes), this.documentSrv.storeDocuments(newSafeboxesDocuments, storeInFolder)]);
      }),
      mergeMap((result) => {
        const safeboxesToAdd = _.keyBy(result.shift(), 'safebox_id');
        safeboxesFromStore = _.assign(safeboxesFromStore, safeboxesToAdd);
        this.store.setSafeboxes(safeboxesFromStore);

        return this.store.setSafeboxesLock(null);
      }),
      catchError((err) => {
        this.tools.error('setSafeboxes ERROR', err);
        return of(true);
      })
    );
  }

  public setSafebox(id: number): Observable<any> {
    let safebox;

    return this.wsSrv.requestSafeboxe(id).pipe(
      mergeMap((safeboxObj: Safebox) => {
        safebox = _.cloneDeep(safeboxObj);
        const folder = safebox.documents && safebox.documents[0] && safebox.documents[0].document_customer_id;
        return this.documentSrv.storeDocuments(safebox.documents, folder);
      }),
      mergeMap((result) => {
        safebox.documents = result;

        this.store.addSafebox(safebox);

        return of(safebox);
      }),
      catchError((err) => {
        this.tools.error('setSafebox ERROR', err);
        return throwError(err);
      })
    );
  }

  /**
   * Return an observable of safeboxes array filtered by safeboxTypeId
   *
   * @param safeboxTypeId safebox type id
   */
  public getSafeboxesByType(safeboxTypeId: number): Observable<Safebox[]> {
    return this.getSafeboxes().pipe(
      map((safeboxes) =>
        // keep only safeboxes of type safeboxTypeId
        _.filter(safeboxes, {
          safebox_type_id: safeboxTypeId,
        })
      )
    );
  }

  /**
   * Save new safebox
   *
   * @param data object with json and safebox_type_id properties
   */
  public addSafebox(data: { json: string; safebox_type_id: number }): Observable<object> {
    return this.wsSrv.postSafebox(data);
  }

  /**
   * Update the safebox corresponding to the safeboxId
   * Return an observable of ApiResponse
   *
   * @param data object with json, safebox_type_id and safebox_id properties
   */
  public updateSafebox(data: {
    json: string;
    safebox_type_id: number;
    safebox_id: number;
  }): Observable<object | number> {
    return this.wsSrv.putSafebox(data);
  }

  /**
   * Remove safebox
   *
   * @param safebox safebox object
   */
  public deleteSafebox(safebox: Safebox): Observable<boolean> {
    return this.wsSrv.deleteSafebox(safebox.safebox_id).pipe(
      map((result: { safebox: { id: number }; success: boolean }) => {
        if (result.success) {
          this.store.removeSafebox(result.safebox.id);
        }
        return result.success;
      }),
      tap(() => {
        _.forEach(safebox.documents, (document) => {
          this.documentSrv.deleteDocument(document);
        });
      })
    );
  }

  /**
   * Upload and replace svg url by its definition
   *
   * @param safeboxTypesObs$ safebox types observable
   */
  private setSafeboxTypesSvg(safeboxTypesObs$: Observable<SafeboxType[]>): Observable<SafeboxType[]> {
    let safeboxTypes;

    return safeboxTypesObs$.pipe(
      mergeMap((result: SafeboxType[]) => {
        safeboxTypes = result;
        const obs$: Observable<string>[] = [];

        _.forEach(safeboxTypes, (safeboxType) => {
          if (safeboxType.svg) {
            if (this.tools.isNative()) {
              obs$.push(
                this.tools
                  .fetchSVGImage(safeboxType.svg)
                  .pipe(
                    mergeMap((data) =>
                      this.documentSrv.storeSVGFile(
                        data,
                        safeboxType.name + '.svg',
                        environment.SVG_FOLDERS.SAFEBOX_TYPES
                      )
                    )
                  )
              );
            } else {
              obs$.push(of(safeboxType.svg));
            }
          }
        });

        if (!obs$.length) {
          obs$.push(of(null));
        }
        return forkJoin(obs$);
      }),
      mergeMap((result) => {
        _.forEach(safeboxTypes, (safeboxType) => {
          if (safeboxType.svg) {
            safeboxType.svg = result.shift() as string;
          }
        });

        return of(safeboxTypes);
      })
    );
  }

  /**
   * Remove safeboxes from store and delete corresponding files from device files system
   *
   * @param safeboxesFromStore safebox stored
   * @param safeboxeIdsToRemove an array of safebox id to remove from store
   */
  private removeSafeboxesFromStore(
    safeboxesFromStore: { [id: string]: Safebox },
    safeboxeIdsToRemove: string[]
  ): Observable<any[]> {
    const obs$ = [];
    const safeboxes = _.cloneDeep(safeboxesFromStore);

    _.forEach(safeboxeIdsToRemove, (id) => {
      if (this.tools.isNative()) {
        _.forEach(safeboxes[id].documents, (document) => {
          obs$.push(from(this.documentSrv.removeFile(document.device_uri)));
        });
      } else {
        obs$.push(of(null));
      }
      delete safeboxes[id];
    });

    return forkJoin([of(safeboxes), concat(...obs$).pipe(toArray())]);
  }
}
