import { createReducer, MetaReducer, on } from '@ngrx/store';

import { CompareFO, CompareListItem } from '../models/compare.model';
import { environment } from '../../../environments/environment';
import { ComparesActions } from './action-types';

export const comparesFeatureKey = 'compares';

export interface ComparesState {
  comparesList: { [id: number]: { [lang: string]: CompareListItem[] } };
  comparesItems: { [lang: string]: { [id: string]: CompareFO } };
}

const initialComparesState: ComparesState = {
  comparesList: {},
  comparesItems: {},
};

export const comparesReducer = createReducer(
  initialComparesState,

  on(ComparesActions.initialize, (state, action) => ({
    ...action.comparesState,
  })),

  on(ComparesActions.reset, () => initialComparesState),

  on(ComparesActions.setCompares, (state, action) => ({
    ...state,
    comparesList: action.comparesList,
  })),

  on(ComparesActions.setCompare, (state, action) => {
    const comparesItems = { ...state.comparesItems };

    comparesItems[action.lang] = { ...comparesItems[action.lang] } || {};
    comparesItems[action.lang][action.compare.id] = action.compare;

    return {
      comparesList: { ...state.comparesList },
      comparesItems,
    };
  })
);

export const metaReducers: MetaReducer<ComparesState>[] = !environment.production ? [] : [];
