import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as hash from 'object-hash';

import { catchError, filter, first, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { combineLatest, forkJoin, Observable, of, Subject, throwError } from 'rxjs';

import { InsuranceService, InsuranceTypesCategories } from './insurance.service';
import { ConstantService } from '../../gfl-core/gfl-services/constant.service';
import { StatusService } from '../../gfl-core/gfl-services/status.service';
import { ToolsService } from '../../gfl-core/gfl-services/tools.service';
import { CustomerService } from '../../customer/services/customer.service';
import { StoreService } from '../../gfl-core/gfl-services/store.service';
import { CompanyService } from '../../gfl-core/gfl-services/company.service';
import { WsService } from '../../gfl-core/gfl-services/ws.service';
import { PolicyBO, PolicyFO } from '../models/policy.model';
import { Mandate } from '../models/mandate.model';
import { InsuranceType } from '../models/insurance-type.model';
import { ItemsDisplayItemFO } from '../models/insured-object.model';
import { ItemTemplateFO } from '../../gfl-core/gfl-models/item-template.model';
import { Company } from '../../gfl-core/gfl-models/company.model';
import { NotificationItem, NotificationType } from '../../contacts/models/contacts.model';
import { DocumentService } from '../../gfl-core/gfl-services/document.service';
import { DataMonitorService } from '../../gfl-core/gfl-services/data-monitor.service';
import { AclsService } from '../../gfl-core/gfl-services/acls.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  public closeAllFabs$: Subject<any> = new Subject();
  private readonly appName = environment.APP_NAME;

  constructor(
    private wsSrv: WsService,
    private customerSrv: CustomerService,
    private insuranceSrv: InsuranceService,
    private companySrv: CompanyService,
    private statusSrv: StatusService,
    private documentSrv: DocumentService,
    private tools: ToolsService,
    private constantSrv: ConstantService,
    private store: StoreService,
    private dataMonitorSrv: DataMonitorService,
    private aclsSrv: AclsService
  ) {
    const locksToMonitor = [
      {
        name: 'policiesAndMandates',
        lock: () => this.store.getPoliciesAndMandatesLock(),
        cb: () => this.setPoliciesAndMandates(),
      },
    ];

    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * Return an observable of all policies and mandates stored
   */
  public getPolicies(): Observable<{ [id: number]: { [lang: string]: Array<PolicyFO | Mandate> } }> {
    return this.store.getPoliciesAndMandates().pipe(mergeMap((policies) => of(_.cloneDeep(policies))));
  }

  /**
   * update Policies and mandates
   */
  public managePoliciesAndMandates(): Observable<any> {
    // first we fetch from localstorage
    return forkJoin([this.store.getPoliciesAndMandates().pipe(first()), this.store.getLang().pipe(first())]).pipe(
      mergeMap(([policiesAndMandates, lang]) => {
        if (policiesAndMandates && policiesAndMandates[0] && policiesAndMandates[0][lang]) {
          return of(policiesAndMandates);
        } else {
          // if no local data then we fetch via http request
          return this.setPoliciesAndMandates();
        }
      })
    );
  }

  /**
   * Store Policies and mandates
   *
   * @param reset if true empty data first
   * @param apiToken current customer's apiToken
   * @param isSignupProcess if true we don't check for LOAD_POLICIES
   */
  public setPoliciesAndMandates(reset?: boolean, apiToken?: string, isSignupProcess?: boolean): Observable<any> {
    let ids: number[];
    let lang: string;

    const companiesObs$: Observable<{ [id: number]: Company }> = this.companySrv.getCompanies().pipe(first());

    return this.store.setPoliciesAndMandatesLock(Date.now()).pipe(
      mergeMap(() => this.store.getLang().pipe(first())),
      mergeMap((langRes) => {
        lang = langRes;
        return this.store.getAuthData().pipe(first());
      }),
      mergeMap((authData) => {
        const CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
        // tslint:disable-next-line:no-shadowed-variable
        const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
        const except = [CUSTOMER_TYPE_ID_EMPLOYEE];

        if (authData.customerTypeId === CUSTOMER_TYPE_ID_EMPLOYEE) {
          except.push(CUSTOMER_TYPE_ID_PRIVATE);
        }

        return this.customerSrv.getCustomersIdList(except);
      }),
      filter((val) => _.isArray(val) || !_.isEmpty(val)),
      first(),
      mergeMap((idsRep) => {
        ids = idsRep;
        return companiesObs$;
      }),
      mergeMap((companies) => {
        const obs$: Array<Observable<any>> = [];

        _.forEach(ids, (id) => {
          obs$.push(this.setPoliciesAndMandatesByCustomerId(id, companies, apiToken, isSignupProcess));
        });

        if (!obs$.length) {
          obs$.push(of(null));
        }

        return forkJoin(obs$);
      }),
      mergeMap((result) => {
        const policiesList: { [id: number]: { [lang: string]: Array<PolicyFO | Mandate> } } = {};
        // global policies list for family or all corporates display
        policiesList[0] = {};
        policiesList[0][lang] = [];

        _.forEach(result, (item) => {
          if (item) {
            policiesList[0][lang] = [...policiesList[0][lang], ...item.policies];
            policiesList[item.customerId] = {};
            policiesList[item.customerId][lang] = item.policies;
          }
        });

        return forkJoin([
          of(policiesList),
          this.getPolicies().pipe(first()),
          this.store.get(this.appName + '_setNotifications').pipe(first()),
        ]);
      }),
      mergeMap(([policiesList, policiesListStore, setNotifications]) => {
        const policiesListFromStore = _.cloneDeep(policiesListStore);

        const step1 = _.cloneDeep((policiesList[0] && policiesList[0][lang]) || []);
        const step2 = _.filter(step1, { is_mandate: false });
        const policiesFromBo = _.keyBy(step2, 'policy_id');

        // get all policyId already stored
        const policyIdListTemp = [];

        _.forEach(policiesListFromStore[0], (listByLang) => {
          _.forEach(listByLang, (policy) => {
            policyIdListTemp.push(policy.policy_id);
          });
        });

        const policyIdList = _.uniq(policyIdListTemp);

        // get a map of all policy stored for lang
        const policiesFromStore = _.keyBy(
          _.filter((policiesListFromStore[0] && policiesListFromStore[0][lang]) || [], { is_mandate: false }),
          'policy_id'
        );

        const policiesToAdd = this.tools.findNewEntities(policiesFromStore, policiesFromBo, 'policy_id');

        if (setNotifications) {
          const notifications: NotificationItem[] = [];

          _.forEach(policiesToAdd, (item) => {
            if (!item.is_mandate && !policyIdList.includes(item.policy_id)) {
              // we don't need to warn customer that he/she has sent a message :)
              notifications.push({
                done: false,
                id: hash(item),
                customerId: item.user_id,
                sprite: 'home_menu_icon_1',
                mobileLink: '/policies/policy-detail/' + item.policy_id,
                tabletLink: '/policies?policyId=' + item.policy_id,
                type: NotificationType.Policy,
                text: 'CONTACT.NOTIFICATIONS.NEW_POLICY',
              });
            }
          });

          this.store.addNotifications(notifications);
        }

        if (reset || _.isEmpty(policiesListStore)) {
          policiesListStore = policiesList;
          this.store.resetPoliciesData();
        } else {
          _.forEach(policiesList, (item, customerId) => {
            policiesListStore[customerId] = policiesListStore[customerId] || {};
            policiesListStore[customerId][lang] = item[lang];
          });
        }

        this.store.setPoliciesAndMandates(policiesListStore);

        const policiesArr$ = [];

        _.forEach(policiesList, (policies: { [lang: string]: PolicyFO[] }, key) => {
          const id = parseInt(key, 10);

          if (id !== 0) {
            if (policies[lang].length) {
              _.forEach(policies[lang], (policy) => {
                if (!policy.is_mandate) {
                  policiesArr$.push(this.setPolicyById(policy.policy_id, id, policy.user_customer_type_id, lang));
                }
              });
            }
          }
        });

        if (!policiesArr$.length) {
          policiesArr$.push(of(null));
        }

        return forkJoin([...policiesArr$, this.store.setPoliciesAndMandatesLock(null)]);
      }),
      catchError((err) => {
        this.tools.error('setPoliciesAndMandates ERROR', err);
        return of(null);
      })
    );
  }

  /**
   * Return an observable of medical policies only
   */
  public getMedicalPolicies(): Observable<Array<PolicyFO | Mandate>> {
    return combineLatest([this.getPolicies(), this.store.getLang()]).pipe(
      mergeMap(([response, lang]) => {
        // keep family part of the response
        const familyPolicies = response[0] && response[0][lang];

        // filter in the family part of the response
        const policies: Array<PolicyFO | Mandate> = _.filter(familyPolicies, (policy: PolicyFO) =>
          this.insuranceSrv.isSicknessType(policy.insurance_type_id)
        );

        return of(policies);
      })
    );
  }

  /**
   * Return an observable of policy of the given id
   *
   * @param  id policy's id
   */
  public getPolicyById(id: number): Observable<PolicyFO> {
    return this.store.getPolicy(id);
  }

  /**
   * Set policy of the given id in Store
   *
   * @param  id policy's id
   * @param  customerId customer id
   * @param  customerTypeId customer's type id
   * @param lang selected language
   */
  public setPolicyById(id: number, customerId: number, customerTypeId: number, lang?: string): Observable<any> {
    const params = { version: 'v2', customer_id_linked: customerId, language_iso: lang };
    const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');

    return this.wsSrv.requestPolicy(id, params).pipe(
      mergeMap((policyResponse) => {
        const policy: PolicyBO = policyResponse && policyResponse[id] ? policyResponse[id] : policyResponse;

        const category =
          customerTypeId === CUSTOMER_TYPE_ID_PRIVATE
            ? InsuranceTypesCategories.private
            : InsuranceTypesCategories.corporate;

        return forkJoin([
          of(policy),
          this.getPolicyById(policy.policy_id).pipe(first()),
          this.insuranceSrv.getInsuranceTypes(category, lang).pipe(
            filter((val) => !_.isEmpty(val)),
            first()
          ),
          this.companySrv.getCompanies().pipe(
            filter((val) => !_.isEmpty(val)),
            first()
          ),
          this.documentSrv.getDocumentCategoriesByInsuranceType(policy.insurance_type_id).pipe(first()),
          this.wsSrv.requestPolicyInvoices(id, {
            customer_id_linked: params.customer_id_linked,
            language_iso: params.language_iso,
          }),
          this.store.get(this.appName + '_setNotifications').pipe(first()),
        ]);
      }),
      mergeMap(
        ([policy, policyStore, insuranceTypesResponse, companies, documentCategories, invoices, setNotifications]) => {
          const DOCUMENT_CATEGORY_ID_POLICY = this.constantSrv.getValueFromKey('DOCUMENT_CATEGORY_ID_POLICY');
          const insuranceTypes = this.populateInsuranceTypes(insuranceTypesResponse);

          policy = this.populatePolicy(policy as PolicyFO, insuranceTypes, companies);
          policy = this.populateInsuredObject(policy as PolicyFO);
          // @ts-ignore
          policy.invoices = invoices;
          policy.user_id = customerId;

          const invoicesToAdd = this.tools.findNewEntities(
            _.keyBy(policyStore ? policyStore.invoices : {}, 'id'),
            _.keyBy(invoices, 'id'),
            'id'
          );

          const documentsToAdd = this.tools.findNewEntities(
            _.keyBy(policyStore ? policyStore.documents : {}, 'document_id'),
            _.keyBy(policy.documents, 'document_id'),
            'document_id'
          );

          // TODO - Why are those variables defined but not used ???
          // const cgaDocumentsToAdd = this.tools.findNewEntities(
          //   _.keyBy(policyStore ? policyStore.cga : {}, 'document_id'),
          //   _.keyBy(policy.cga || {}, 'document_id'),
          //   'document_id'
          // );
          //
          // const cgvDocumentsToAdd = this.tools.findNewEntities(
          //   _.keyBy(policyStore ? policyStore.cgv : {}, 'document_id'),
          //   _.keyBy(policy.cgv || {}, 'document_id'),
          //   'document_id'
          // );

          if (setNotifications) {
            const notifications: NotificationItem[] = [];

            _.forEach(invoicesToAdd, (item) => {
              notifications.push({
                done: false,
                id: hash(item),
                customerId: policy.user_id,
                sprite: 'action_mobile_invoices_icon',
                mobileLink: '/policies/policy-invoices/' + policy.policy_id,
                tabletLink: '/policies?invoicePolicyId=' + policy.policy_id,
                type: NotificationType.Invoice,
                text: 'CONTACT.NOTIFICATIONS.NEW_INVOICE',
              });
            });

            _.forEach(documentsToAdd, (item) => {
              const docCategory = _.find(documentCategories, { id: item.document_category_id });

              // display notifications for policy document and document categories selected by BO
              if (docCategory || item.document_category_id === DOCUMENT_CATEGORY_ID_POLICY) {
                const link =
                  '/policies/policy-documents/' + policy.policy_id + '/documents-category/' + item.document_category_id;

                notifications.push({
                  done: false,
                  id: hash(item),
                  customerId: policy.user_id,
                  sprite: 'insurance_detail_page_icon_5',
                  mobileLink:
                    item.document_category_id === DOCUMENT_CATEGORY_ID_POLICY
                      ? '/policies/policy-detail/' + policy.policy_id
                      : link,
                  tabletLink:
                    item.document_category_id === DOCUMENT_CATEGORY_ID_POLICY
                      ? '/policies?policyId=' + policy.policy_id
                      : link,
                  type: NotificationType.Invoice,
                  text: 'CONTACT.NOTIFICATIONS.NEW_POLICY_DOCUMENT',
                });
              }
            });

            this.store.addNotifications(notifications);
          }

          this.store.setPolicy(policy as PolicyFO, lang);

          return of(policy);
        }
      ),
      catchError((err) => {
        this.tools.error('setPolicyById', err);
        return throwError(err);
      })
    );
  }

  /**
   * Add children items to insuranceTypes
   *
   * @param insuranceTypesResponse insurance type response
   */
  public populateInsuranceTypes(insuranceTypesResponse: { [id: string]: InsuranceType }): Array<InsuranceType> {
    const insuranceTypes: Array<InsuranceType> = [];

    _.forEach(insuranceTypesResponse, (insuranceType: InsuranceType) => {
      if (_.size(insuranceType.children)) {
        _.forEach(insuranceType.children, (item: InsuranceType) => {
          insuranceTypes.push(item);
        });
      }
      insuranceTypes.push(insuranceType);
    });

    return _.uniqBy(insuranceTypes, 'id');
  }

  /**
   * Order policies by parent_insurance_type_id
   *
   * @param policies an array of policies
   */
  public orderPoliciesByParentInsuranceType(
    policies: Array<PolicyFO>
  ): Array<{ parentInsuranceTypeName: string; policies: Array<PolicyFO> }> {
    const result = [];

    const parentInsuranceTypes = _.compact(_.uniq(_.map(policies, 'parent_insurance_type_name')));

    _.forEach(parentInsuranceTypes, (parentInsuranceType) => {
      const policiesItem = _.filter(policies, { parent_insurance_type_name: parentInsuranceType });
      result.push({
        parentInsuranceTypeName: parentInsuranceType,
        policies: policiesItem,
      });
    });

    return result;
  }

  /**
   * Add information to be displayed
   *
   * @param policy a policy object
   * @param insuranceTypes array of insurance types
   * @param companies companies map
   */
  private populatePolicy(
    policy: PolicyFO,
    insuranceTypes: InsuranceType[],
    companies?: { [id: string]: Company }
  ): PolicyFO {
    if (policy.insurance_type_id) {
      const insuranceType = insuranceTypes.filter((value) => value.id === policy.insurance_type_id)[0];
      policy.insurance_type_name = insuranceType ? insuranceType.name_translate : '';
      policy.insurance_type_image = insuranceType ? insuranceType.svg : '';
    }

    if (companies) {
      policy.company_image = policy.company ? companies[policy.company_id].svg : '';
      policy.company_name = policy.company ? policy.company.name : '';
    }

    policy.isLifePolicy = this.insuranceSrv.isLifeType(policy.insurance_type_id);
    policy.isSicknessPolicy = this.insuranceSrv.isSicknessType(policy.insurance_type_id);

    return policy;
  }

  /**
   * Add information of the insured object displayed
   *
   * @param policy a policy object
   */
  private populateInsuredObject(policy: PolicyFO): PolicyFO {
    policy.insured_object_name = null;

    _.forEach(policy.insured_objects, (insuredObject, insuredObjectIdx) => {
      const itemsDisplay: Array<ItemsDisplayItemFO> = [];

      // set insured object name
      if (!policy.insured_object_name) {
        policy.insured_object_name = insuredObject.parent.name;
      } else {
        policy.insured_object_name += ', ' + insuredObject.parent.name;
      }

      // Go through all items type only of insured object
      _.forEach(insuredObject, (itemType, itemTypeIdx) => {
        // items type idx must be integer
        if (this.tools.isInt(itemTypeIdx)) {
          const typeDisplay = {
            insurance_type_name: itemType.insurance_type_name,
            items: [],
          };

          // Go through all items of the itemType
          _.forEach(itemType, (item: ItemTemplateFO, itemIdx) => {
            // item idx must be integer
            if (this.tools.isInt(itemIdx)) {
              item.child = [];

              // Go through all subitems if there are any
              if (item.children) {
                _.forEach(item.children, (subItem: ItemTemplateFO) => {
                  // If type_id === 1, meaning we need to fetch the constants
                  if (subItem.item_type_id === 1) {
                    const constant = this.constantSrv.getValueFromConstant(
                      subItem.item_template_key,
                      subItem.item_value_int
                    );

                    subItem.item_value_constant = constant.length === 0 ? subItem.value_reformat : constant;
                  }

                  // Push subitems in parent items
                  if (subItem.item_value_constant || subItem.value_reformat) {
                    item.child.push(subItem);
                  }
                });
              }

              // If type_id === 1, meaning we need to fetch the constants
              if (item.item_type_id === 1) {
                const constant = this.constantSrv.getValueFromConstant(item.item_template_key, item.item_value_int);
                item.value_reformat = constant.length === 0 ? item.value_reformat : constant;
              }

              // @ts-ignore
              if (
                Number(item.item_template_is_header) !== 1 &&
                ((item.child && Object.keys(item.child).length > 0) || item.item_value_constant || item.value_reformat)
              ) {
                typeDisplay.items.push(item);
              }
            }
          });

          itemsDisplay.push(typeDisplay);
        }

        policy.insured_objects[parseInt(insuredObjectIdx, 10)].itemsDisplay = itemsDisplay;
      });
    });

    return policy;
  }

  /**
   * Return an observable of policies of a given customer, customer's id and type id are also given
   *
   * @param customerId customer's id to retrieve policies and mandates
   * @param companies a companies map
   * @param apiToken current customer's apiToken
   * @param isSignupProcess true if signup process
   */
  private setPoliciesAndMandatesByCustomerId(
    customerId: number,
    companies: { [id: number]: Company },
    apiToken: string,
    isSignupProcess: boolean
  ): Observable<{ customerId: number; customerTypeId: number; policies: PolicyFO[] }> {
    return this.customerSrv.getCustomer(customerId).pipe(
      first(),
      withLatestFrom(this.aclsSrv.getAcls(customerId)),
      mergeMap(([customer, acls]) => {
        const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
        const params = {};
        if (apiToken) {
          // @ts-ignore
          params.api_token = apiToken;
        }
        if (!isSignupProcess) {
          // @ts-ignore
          params.customer_id_linked = customerId;
        }

        let insuranceTypeCategory;

        if (customer.customer_type_id === CUSTOMER_TYPE_ID_PRIVATE) {
          insuranceTypeCategory = InsuranceTypesCategories.private;
        } else {
          insuranceTypeCategory = InsuranceTypesCategories.corporate;
        }

        const insuranceTypes$: Observable<{ [id: string]: InsuranceType }> = this.insuranceSrv
          .getInsuranceTypes(insuranceTypeCategory)
          .pipe(
            filter((val) => !_.isEmpty(val)),
            take(1)
          );
        return forkJoin([
          acls && acls.LOAD_POLICIES && !isSignupProcess ? this.wsSrv.requestPolicies(params) : of(null),
          insuranceTypes$,
          (acls && acls.LOAD_MANDATES) || isSignupProcess ? this.wsSrv.requestMandates(params) : of(null),
          of(customer),
        ]);
      }),
      mergeMap(([policiesResponse, insuranceTypesResponse, mandatesResponse, customer]) => {
        let insuranceTypes = [];
        const policies: PolicyFO[] = [];

        // manage insurance types
        insuranceTypes = this.populateInsuranceTypes(insuranceTypesResponse);

        if (policiesResponse) {
          // manage policies of customer
          _.forEach(policiesResponse, (policy: PolicyFO) => {
            if (policy.policy_id || (policy.id && !policy.return_date)) {
              policy.user_name = customer.firstname || customer.lastname; // lastname for company;
              policy.user_token = customer.api_token;
              policy.user_id = customer.id;
              policy.user_customer_type_id = customer.customer_type_id;
              policy.is_mandate = false;

              if (policy.insurance_type_id) {
                const insuranceType = insuranceTypes.filter((value) => value.id === policy.insurance_type_id)[0];

                policy.insurance_type_name = insuranceType ? insuranceType.name_translate : '';
                policy.insurance_type_image = insuranceType ? insuranceType.svg : '';
                policy.parent_insurance_type_name = policy.parent_insurance_type_name || policy.insurance_type_name;
              }

              if (policy.company) {
                policy.company.svg = companies[policy.company_id].svg;
              }

              // add insured_object_name attribute
              const insuredObjectName = [];
              _.forEach(policy.insured_objects, (obj) => {
                insuredObjectName.push(obj.name);
              });
              policy.insured_object_name = insuredObjectName.join(', ');

              policies.push(policy);
            }
          });
        }

        if (mandatesResponse) {
          // manage mandates of customer
          _.forEach(mandatesResponse, (mandate: Mandate) => {
            mandate.is_mandate = true;

            if (mandate.policy_id || (mandate.id && !mandate.return_date)) {
              mandate.user_name = customer.firstname;

              if (mandate.insurance_type_id) {
                const insuranceType = insuranceTypes.filter((value) => value.id === mandate.insurance_type_id)[0];

                mandate.insurance_type_name = insuranceType ? insuranceType.name_translate : '';
                mandate.insurance_type_image = insuranceType ? insuranceType.svg : '';
              }

              if (mandate.company) {
                mandate.company.svg = companies[mandate.company_id].svg;
              }

              // Check status for push or not
              if (
                this.statusSrv.is(mandate.current_status_id, 'MANDATE_CREATED') ||
                this.statusSrv.is(mandate.current_status_id, 'MANDATE_SENT')
              ) {
                mandate.display_trash = this.statusSrv.is(mandate.current_status_id, 'MANDATE_CREATED');

                // For refund filter only
                // @ts-ignore
                policies.push(mandate);
              }
            }
          });
        }

        return of({
          customerId: customer.id,
          customerTypeId: customer.customer_type_id,
          policies,
        });
      })
    );
  }
}
