import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomerState } from './index';

export const selectCustomerState = createFeatureSelector<CustomerState>('customer');

export const customers = createSelector(
  selectCustomerState,
  customer => customer.customers
);

export const members = createSelector(
  selectCustomerState,
  customer => customer.members
);
