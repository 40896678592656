import { Injectable } from '@angular/core';
import { Network, Connection } from '@awesome-cordova-plugins/network/ngx';
import { Platform } from '@ionic/angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ToolsService } from './tools.service';
import { StoreService } from './store.service';
import { NotificationService } from './notification.service';

export enum NetWorkStatus {
  Online = 1,
  Offline = 2,
}

@Injectable({
  providedIn: 'root',
})
export class NetworkMonitorService {
  private toast: Promise<HTMLIonToastElement>;

  /**
   * @ignore
   */
  constructor(
    private tools: ToolsService,
    private store: StoreService,
    private platform: Platform,
    private network: Network,
    private notificationSrv: NotificationService
  ) {}

  public initialize() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.tools.isNative() ? this.initNative() : this.initBrowser();
  }

  /**
   * initialize network survey for native device
   */
  private async initNative(): Promise<void> {
    const connectTypesOffline: Array<any> = [Connection.NONE, Connection.UNKNOWN, Connection.CELL, Connection.CELL_2G];

    this.platform.resume.subscribe(() => {
      if (connectTypesOffline.indexOf(this.network.type) > -1 || this.network.type === null) {
        this.onNetworkDisconnect(false);
      } else {
        this.onNetworkConnect(false);
      }
    });

    // Check for internet connection on the user's device on start
    if (connectTypesOffline.includes(this.network.type)) {
      this.store.setNetworkStatus(NetWorkStatus.Offline);
    } else {
      this.store.setNetworkStatus(NetWorkStatus.Online);
    }

    this.network.onDisconnect().subscribe(() => {
      this.onNetworkDisconnect(true);
    });

    this.network.onConnect().subscribe(() => {
      this.onNetworkConnect(false);
    });
  }

  /**
   * initialize network survey for browser
   */
  private initBrowser(): void {
    // Check for internet connection on the user's device on start
    if (!navigator.onLine) {
      this.store.setNetworkStatus(NetWorkStatus.Offline);
    } else {
      this.store.setNetworkStatus(NetWorkStatus.Online);
    }

    // PWA version of watch network for a connection
    window.addEventListener('online', () => {
      this.onNetworkConnect(true);
    });

    window.addEventListener('offline', () => {
      this.onNetworkDisconnect(true);
    });
  }

  /**
   * Return an observable of network status
   */
  public getNetworkStatus(): Observable<NetWorkStatus> {
    return this.store.getNetworkStatus();
  }

  public getConnexionType(): { current: string; connexion: { [id: string]: string } } {
    return {
      current: this.network.type,
      connexion: {
        none: this.network.Connection.NONE,
        cell: this.network.Connection.CELL,
        cell2G: this.network.Connection.CELL_2G,
        cell3G: this.network.Connection.CELL_3G,
        cell4G: this.network.Connection.CELL_4G,
        ethernet: this.network.Connection.ETHERNET,
        wifi: this.network.Connection.WIFI,
      },
    };
  }

  /**
   * Return an observable of offline flag
   */
  public isOffline(): Observable<boolean> {
    return this.getNetworkStatus().pipe(map((status) => status === NetWorkStatus.Offline));
  }

  /**
   * Return an observable of online flag
   */
  public isOnline(): Observable<boolean> {
    return this.getNetworkStatus().pipe(map((status) => status === NetWorkStatus.Online));
  }

  /**
   * Network connection response
   *
   * @param display if true a notification is displayed
   */
  private onNetworkConnect(display: boolean) {
    if (this.toast && display) {
      this.toast.then((res) => res.dismiss());
    }

    this.store.setNetworkStatus(NetWorkStatus.Online);

    if (display) {
      this.notificationSrv.showSuccess({
        message: 'NETWORK.CONNECT_IS_BACK',
      });
    }
  }

  /**
   * Network disconnection response
   *
   * @param display if true a notification is displayed
   */
  private onNetworkDisconnect(display: boolean) {
    if (this.toast && display) {
      this.toast.then((res) => res.dismiss());
    }

    this.store.setNetworkStatus(NetWorkStatus.Offline);

    if (display) {
      this.notificationSrv
        .showWarning({
          message: 'NETWORK.ERROR_TITLE',
          showCloseButton: true,
          returnObservable: true,
        })
        .subscribe((toast) => (this.toast = toast));
    }
  }
}
