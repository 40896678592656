import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StoreService } from '../../gfl-services/store.service';

@Component({
  selector: 'gfl-nomad-loader',
  templateUrl: './gfl-nomad-loader.component.html',
  styleUrls: ['./gfl-nomad-loader.component.scss'],
})
export class GflNomadLoaderComponent implements OnInit {
  public logo$: Observable<string>;

  constructor(public store: StoreService) {}

  ngOnInit() {
    this.logo$ = this.store.getAdminLogo();
  }
}
