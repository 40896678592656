import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Router } from '@angular/router';

import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { DocumentService } from '../../../gfl-core/gfl-services/document.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { AgencyService } from '../../../gfl-core/gfl-services/agency.service';
import { AuthService } from '../../services/auth.service';
import { Customer } from '../../../customer/models/customer.model';

import { SignupBrokerComponent } from '../signup-broker/signup-broker.component';
import { SignatureFullScreenComponent } from '../signature-full-screen/signature-full-screen.component';

@Component({
  selector: 'gfl-signup-signature',
  templateUrl: './signup-signature.component.html',
  styleUrls: ['./signup-signature.component.scss'],
})
export class SignupSignatureComponent implements OnInit {
  @Input() isPro: boolean;
  @Input() customer: Customer;
  @Input() standAlone: boolean;
  @Input() signupProcess: boolean;
  @Output() validated: EventEmitter<boolean> = new EventEmitter<boolean>();
  public logo$: Observable<string>;
  public signatureImg = '';
  private hasAcceptedCg = false;
  private firstLoad = true;

  /**
   * @ignore
   */
  constructor(
    private modalCtrl: ModalController,
    private notificationSrv: NotificationService,
    public tools: ToolsService,
    private agencySrv: AgencyService,
    private documentSrv: DocumentService,
    private constantSrv: ConstantService,
    private screenOrientation: ScreenOrientation,
    private router: Router,
    private authSrv: AuthService
  ) {}

  /**
   * @ignore
   */
  ngOnInit() {
    if (!this.customer) {
      return this.router.navigateByUrl('/welcome').then();
    }

    this.logo$ = this.agencySrv.getPublicLogo();

    if (this.firstLoad) {
      this.openBrokerModal().then();
    }
    this.firstLoad = false;
  }

  /**
   * Open modal to accept broker contract
   */
  public async openBrokerModal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: SignupBrokerComponent,
      componentProps: {
        customer: this.customer,
        signupProcess: this.signupProcess,
      },
      backdropDismiss: false,
    });

    modal.onDidDismiss().then((rep) => {
      this.hasAcceptedCg = rep.data && rep.data.hasAcceptedCg;
    });
    await modal.present();
  }

  /**
   * Open Modal to add Signature
   */
  public async openSignatureModal(): Promise<void> {
    if (this.tools.isMobile() && this.tools.isNative()) {
      try {
        await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
      } catch (err) {
        this.tools.error('screenOrientation', err);
      }
    }

    const modal = await this.modalCtrl.create({
      component: SignatureFullScreenComponent,
    });

    await modal.present();

    const rep = await modal.onDidDismiss();
    this.signatureImg = rep.data && rep.data.signatureImg;

    if (this.tools.isMobile() && this.tools.isNative()) {
      try {
        await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      } catch (err) {
        this.tools.error('screenOrientation', err);
      }
    }
  }

  /**
   * Save Signature document to BO
   */
  public onSignatureValidation(): void {
    const DOCUMENT_CATEGORY_ID_SIGNATURE = this.constantSrv.getValueFromKey('DOCUMENT_CATEGORY_ID_SIGNATURE');

    if (!this.hasAcceptedCg) {
      this.notificationSrv.showError({ message: 'SIGNATURE.MANDATE_ACCEPT' });
    } else {
      let image = this.signatureImg;
      image = image.replace('data:image/png;base64,', '');

      this.tools.showLoader();

      const document = {
        path: image,
        tmp: image,
        name: this.customer.id + '_signature.png',
        ext: 'png',
      };

      this.documentSrv
        .saveDocument(
          document,
          this.signupProcess ? null : this.customer.id,
          DOCUMENT_CATEGORY_ID_SIGNATURE,
          this.customer.api_token
        )
        .pipe(finalize(() => this.tools.hideLoader()))
        .subscribe(
          () => {
            this.validated.emit(true);
          },
          () => {
            this.notificationSrv.showError({ message: 'SIGNATURE.MANDATE_ERROR' });
          }
        );
    }
  }

  /**
   * Stop signup process
   */
  public cancel(): void {
    this.authSrv.cancelSignup();
  }
}
