import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { GflModeDisplayType } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form.model';
import { GflThemeOptions } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form-options.model';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { CustomerService } from '../../services/customer.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';

@Component({
  selector: 'gfl-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @Input() customerId: number;
  public resetPasswordForm: FormGroup;
  public validatorsForm: { [id: string]: Array<ValidatorFn> } = {};

  public style$: Observable<FrontTheme>;
  public modeDisplay: GflModeDisplayType;
  public loader: HTMLIonLoadingElement;
  readonly optionTheme: GflThemeOptions = {
    tabletBackgroundColor: 'var(--gfl-form-item-background-color)',
  };

  /**
   * @ignore
   */
  constructor(
    private modalCtrl: ModalController,
    private store: StoreService,
    private translate: TranslateService,
    private customerSrv: CustomerService,
    private notificationSrv: NotificationService,
    public tools: ToolsService
  ) {
    this.initForm();
  }

  /**
   * @ignore
   */
  ngOnInit() {
    this.style$ = this.store.getStyle();

    this.setModeDisplay();
  }

  /**
   * close current modal
   */
  public closeModal() {
    this.modalCtrl.dismiss().then();
  }

  /**
   * Form submission
   */
  public onSubmit() {
    this.tools.showLoader();

    const oldPassword = this.resetPasswordForm.value.oldPassword;
    const password = this.resetPasswordForm.value.newPassword.password;
    const passwordConfirmation = this.resetPasswordForm.value.newPassword.passwordConfirm;

    this.customerSrv
      .changeCustomerPassword(oldPassword, password, passwordConfirmation, this.customerId)
      .pipe(finalize(() => this.tools.hideLoader()))
      .subscribe(
        () => {
          this.notificationSrv.showSuccess({ message: 'COMMON.PASSWORD_RESET_CONFIRMATION' });
          this.closeModal();
        },
        (err) => {
          this.resetPasswordForm.controls.oldPassword.setErrors({ invalidPassword: err });
          return this.notificationSrv.showError({ message: err });
        }
      );
  }

  /**
   * Initialize the register form
   */
  private initForm() {
    // form definition
    this.resetPasswordForm = new FormGroup({});

    this.validatorsForm = {
      oldPassword: [Validators.required, Validators.minLength(4)],
      newPassword: [Validators.required, Validators.minLength(4)],
    };
  }

  /**
   * Set modeDisplay to mobile or tablet
   */
  private setModeDisplay(): void {
    this.modeDisplay = this.tools.setModeDisplay();
  }
}
