import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActionSheetButton } from '@ionic/core';
import { ActionSheetController, AlertController, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import * as _ from 'lodash';

import { Camera, ImageOptions, Photo, CameraResultType, CameraSource } from '@capacitor/camera';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';

import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { first, mergeMap, switchMap } from 'rxjs/operators';

import {
  GflFormItem,
  GflModeDisplayType,
  GflSelectOption,
} from '../../../gfl-libraries/gfl-form-generator/models/gfl-form.model';
import { UploadDocument } from '../../../gfl-core/gfl-models/document.model';
import { DefaultAddress, FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { City } from '../../../gfl-core/gfl-models/city.model';
import { Constant } from '../../../gfl-core/gfl-models/constant.model';
import { Customer, CustomerFormType, CustomerRole } from '../../models/customer.model';
import { Address } from '../../../gfl-core/gfl-models/address.model';
import { GflThemeOptions } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form-options.model';
import { CustomerService } from '../../services/customer.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { GflFormGeneratorService } from '../../../gfl-libraries/gfl-form-generator/services/gfl-form-generator.service';
import { ImageService } from '../../../gfl-core/gfl-services/image.service';
import { ItemService } from '../../../gfl-core/gfl-services/item.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { DocumentService } from '../../../gfl-core/gfl-services/document.service';
import { AclsService } from '../../../gfl-core/gfl-services/acls.service';
import { RolesService } from '../../../gfl-core/gfl-services/roles.service';

import { SignupBrokerComponent } from '../../../authentication/components/signup-broker/signup-broker.component';
import { SignatureFullScreenComponent } from '../../../authentication/components/signature-full-screen/signature-full-screen.component';
import { DateValidation } from '../../../gfl-libraries/gfl-form-generator/validators/date.validator';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'gfl-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
})
export class CustomerFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() style: FrontTheme;
  /**
   * Private, pro or employee customer types
   */
  @Input() customerTypeId: number;
  /**
   * This is the customer that we are creating or updating in case of signup process
   */
  @Input() customer: Customer;

  /**
   * The customerLinked designates the original customer that is creating a customer link
   */
  @Input() customerLinked: Customer;
  /**
   * Two types are available... Create or Update customer
   */
  @Input() customerFormType: CustomerFormType;
  /**
   * The customerLinkTypeId may be passed as an input to the component
   * for an employee linked to a pro for example. But it can also be filled
   * in the current form component in case of a customer wanting to add
   * a member family for example
   */
  @Input() customerLinkTypeId: number;

  @Input() isSignUpProcess: boolean;

  @Input() agencyId: number;

  @Input() otherAgencyName: string;

  @Input() pairingPro: boolean;

  @Input() theme: GflThemeOptions;

  @Output() success: EventEmitter<Customer> = new EventEmitter<Customer>();
  @Output() error: EventEmitter<any> = new EventEmitter<any>();
  @Output() customerAlreadyExisting: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('inputFileCamera', { static: false }) inputFileCamera: ElementRef;
  public isPro: boolean;
  public isEmployee: boolean;
  public isPrivate: boolean;
  public isCustomerLinkedEmployee: boolean;
  public displayAddressButton: boolean;
  public roles: Array<GflSelectOption>;
  readonly ITEM_TEMPLATE_KEY_EMAIL: string;
  readonly ITEM_TEMPLATE_KEY_PHONE: string;
  readonly CUSTOMER_TYPE_ID_PRIVATE: number;
  readonly CUSTOMER_TYPE_ID_CORPORATE: number;
  readonly CUSTOMER_TYPE_ID_EMPLOYEE: number;
  readonly CUSTOMER_MARITAL_SITUATION_SINGLE: number;
  readonly DOCUMENT_CATEGORY_ID_ID_CARD: number;
  readonly CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT: number;
  readonly CUSTOMER_LINK_TYPE_EMPLOYEE: number;
  readonly CUSTOMER_LINK_TYPE_PARENT: number;
  public acls: {
    MANDATORY_ID_CARD: boolean;
  };
  public modeDisplay: GflModeDisplayType;
  public address: Address = {
    street: null,
    street2: null,
    country_id: null,
  };
  public customerLinkTypes: Array<GflSelectOption> = [];
  public formSubmitted: boolean;
  public isAdult: boolean;
  public idCardImg: string;
  public idPdfDoc: UploadDocument;
  public signatureImg: string;
  public isSignatureMandatory: boolean;
  public isCustomerAddress = false;
  public hasAcceptedCg: boolean;
  public country: string;
  public isEditMode = true;
  public customerForm: FormGroup;
  public customerFormData: Array<GflFormItem>;
  public phoneFormData: Array<GflFormItem>;
  public emailFormData: Array<GflFormItem>;
  public validatorsForm: { [id: string]: Array<ValidatorFn> } = {};
  public initialFormValues: { [id: string]: any } = {};
  public initialPhoneFormValues: { [id: string]: any };
  public initialEmailFormValues: { [id: string]: any };
  /**
   * private customer is already registered on signup process...
   */
  private isAlreadyRegistered: boolean;
  private apiToken: string;
  private subscriptions: Subscription[] = [];
  private FOREIGN_KEY_CUSTOMER: number;
  private FOREIGN_KEY_CUSTOMER_LINK: number;
  private ITEM_TEMPLATE_KEY_CUSTOMER: string;
  private city: City = {
    id: null,
    name: null,
    postcode: null,
  };
  private cameraOptions: ImageOptions;
  private isIOS: boolean;

  /**
   * @ignore
   */
  constructor(
    private customerSrv: CustomerService,
    private translate: TranslateService,
    private notificationSrv: NotificationService,
    private store: StoreService,
    private constantSrv: ConstantService,
    private itemSrv: ItemService,
    private gflFormSrv: GflFormGeneratorService,
    public tools: ToolsService,
    private documentSrv: DocumentService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private imageSrv: ImageService,
    private actionSheetCtrl: ActionSheetController,
    private platform: Platform,
    private chooser: Chooser,
    private aclsSrv: AclsService,
    private screenOrientation: ScreenOrientation,
    private rolesSrv: RolesService,
    private ngZone: NgZone
  ) {
    this.isAlreadyRegistered = !!this.customer;
    this.ITEM_TEMPLATE_KEY_EMAIL = 'email';
    this.ITEM_TEMPLATE_KEY_PHONE = 'phone';
    this.CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
    this.CUSTOMER_TYPE_ID_CORPORATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_CORPORATE');
    this.CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
    this.CUSTOMER_MARITAL_SITUATION_SINGLE = this.constantSrv.getValueFromKey('CUSTOMER_MARITAL_SITUATION_SINGLE');
    this.DOCUMENT_CATEGORY_ID_ID_CARD = this.constantSrv.getValueFromKey('DOCUMENT_CATEGORY_ID_ID_CARD');
    this.CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT = this.constantSrv.getValueFromKey(
      'CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT'
    );
    this.CUSTOMER_LINK_TYPE_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_LINK_TYPE_EMPLOYEE');
    this.CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
    this.CUSTOMER_LINK_TYPE_PARENT = this.constantSrv.getValueFromKey('CUSTOMER_LINK_TYPE_PARENT');
    this.cameraOptions = {
      quality: 50,
      resultType: CameraResultType.DataUrl,
      // encodingType: this.camera.EncodingType.JPEG,
      // mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      height: 1800,
      width: 1350,
    };
    this.FOREIGN_KEY_CUSTOMER = this.constantSrv.getForeignTableIdByName('CUSTOMER', true) as number;
    this.FOREIGN_KEY_CUSTOMER_LINK = this.constantSrv.getForeignTableIdByName('CUSTOMER_LINK', true) as number;
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.isIOS = this.platform.is('ios');
    this.apiToken =
      (this.customer && this.customer.api_token) || (this.customerLinked && this.customerLinked.api_token);

    this.setFlags();
    this.setCustomerLinkTypesOptions();

    switch (this.customerTypeId) {
      case this.CUSTOMER_TYPE_ID_CORPORATE:
        this.ITEM_TEMPLATE_KEY_CUSTOMER = 'item_templates_pro';
        break;
      case this.CUSTOMER_TYPE_ID_EMPLOYEE:
        this.setRolesOptions(this.CUSTOMER_TYPE_ID_EMPLOYEE);
        this.ITEM_TEMPLATE_KEY_CUSTOMER = 'item_templates_employee';
        break;
      default:
        this.ITEM_TEMPLATE_KEY_CUSTOMER = 'item_templates_private';
    }

    this.initialize();
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customer) {
      const customer: Customer = changes.customer.currentValue;
      this.isAlreadyRegistered = !!customer;
      this.apiToken = customer && customer.api_token;
    }
    if (this.customerLinked && this.customerFormType && this.customerTypeId) {
      this.setFlags();
      this.setCustomerLinkTypesOptions();
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then();
  }

  /**
   * Ask confirmation and then copy customer address data in member form
   */
  public useCustomerAddressAlert(): void {
    this.translate
      .get(['PROFILE.ADD_PRIVATE.COPY_ADDRESS', 'COMMON.BUTTON_NO', 'COMMON.BUTTON_YES'])
      .subscribe(async (result) => {
        const alert = await this.alertCtrl.create({
          message: result['PROFILE.ADD_PRIVATE.COPY_ADDRESS'],
          buttons: [
            {
              text: result['COMMON.BUTTON_YES'],
              cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
              handler: () => {
                this.isCustomerAddress = true;
                this.setCustomerAddress(this.customerLinked.default_address);
              },
            },
            {
              text: result['COMMON.BUTTON_NO'],
              cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
              handler: () => {
                this.isCustomerAddress = false;
                this.setCustomerAddress(null);
              },
            },
          ],
        });
        await alert.present();
      });
  }

  /**
   * Launch signature process
   */
  public addSignature(): void {
    if (this.signatureImg) {
      return;
    }

    this.openBrokerModal().then();
  }

  /**
   * Open modal to accept broker contract
   * if contract is accepted then launch signature process
   */
  public async openBrokerModal(): Promise<void> {
    // set customer data for SignupBrokerComponent
    const f = this.customerForm.value;

    if ((f.lastname || f.company_name) && f.address1 && f.zipCodeCity.city) {
      // we've got all needed data
      const customer = {
        lastname: f.lastname,
        firstname: f.firstname,
        company_name: f.company_name,
        dob: f.dob,
        default_address: {
          address: {
            street: f.address1,
          },
          city: f.zipCodeCity.city,
          country: {
            name: this.customerForm.controls.country.value,
          },
        },
      };

      const modal = await this.modalCtrl.create({
        component: SignupBrokerComponent,
        componentProps: {
          customer,
        },
        backdropDismiss: false,
      });

      modal.onDidDismiss().then((rep) => {
        // @ts-ignore
        this.hasAcceptedCg = rep && rep.data && rep.data.hasAcceptedCg;
        if (!this.hasAcceptedCg) {
          this.notificationSrv.showError({ message: 'SIGNATURE.MANDATE_ACCEPT' });
        } else {
          this.openSignatureModal();
        }
      });
      await modal.present();
    } else {
      this.notificationSrv.showError({
        message: 'PROFILE.BROKER_DATA',
      });
    }
  }

  /**
   * Open Modal to add Signature
   */
  public async openSignatureModal(): Promise<void> {
    if (this.tools.isMobile() && this.tools.isNative()) {
      try {
        await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
      } catch (err) {
        this.tools.error('screenOrientation', err);
      }
    }

    const modal = await this.modalCtrl.create({
      component: SignatureFullScreenComponent,
    });

    await modal.present();

    const rep = await modal.onDidDismiss();
    this.signatureImg = rep.data && rep.data.signatureImg;
    if (this.tools.isMobile() && this.tools.isNative()) {
      try {
        await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      } catch (err) {
        this.tools.error('screenOrientation', err);
      }
      await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    }
  }

  /**
   * Add a picture to pictures array
   *
   * @param event DOM Event
   */
  public addPicture(event: Event): void {
    this.imageSrv.handleSelectedImage(event).subscribe(
      (picture: string) => {
        const regExp = /application\/pdf/;

        if (regExp.test(picture)) {
          this.idPdfDoc = {
            filename: 'idCard',
            title: 'idCard',
            thumbnail: null,
            spriteItem: 'pdf-icon',
            data: picture,
            ext: 'pdf',
          };
        } else {
          this.idCardImg = picture;
        }
      },
      (err) => {
        this.tools.error('CustomerFormComponent addPicture', err);
        this.notificationSrv.showError({
          message: 'API.ERROR_MESSAGE',
        });
      }
    );
  }

  /**
   * Remove picture from pictures array
   */
  public removePicture(): void {
    this.idCardImg = null;
    this.idPdfDoc = null;
  }

  /**
   * Get picture
   */
  public takePicture(): void {
    if (this.tools.isNative()) {
      this.takeNativePicture();
    } else {
      this.inputFileCamera.nativeElement.click();
    }
  }

  /**
   * launch validators, check and update customer
   */
  public onSubmit(): void {
    // We check if all is right for the customer type chosen
    // We add validation on DOB for adult
    if ((this.isSignUpProcess && this.isPrivate) || this.isEmployee) {
      // this is an employee or a private on sign up  process that has to be adult
      if (this.customerForm.controls.dob) {
        this.customerForm.controls.dob.markAsTouched();
        this.customerForm.controls.dob.setValidators(DateValidation.isMajor);
        this.customerForm.controls.dob.updateValueAndValidity();
        if (this.customerForm.controls.dob.invalid) {
          this.notificationSrv.showError({ message: 'FORM.ERRORS.MAJOR' });
        }
      }
    }

    if (this.customerForm.invalid) {
      this.formSubmitted = true;
      return;
    }

    // Add signature as mandatory for adults
    if (this.isSignatureMandatory && !this.signatureImg) {
      this.notificationSrv.showError({ message: 'SIGNATURE.MANDATORY' });
      return;
    }

    // Add idCard as mandatory for adult
    if (!this.isPro && this.isAdult && !this.idCardImg && !this.idPdfDoc) {
      this.notificationSrv.showError({ message: 'COMMON.IDCARD_MANDATORY' });
      return;
    }

    if (!this.isSignUpProcess && this.isPrivate && !this.isCustomerLinkedEmployee) {
      // We add customerLinkTypeId from form's customerLinkTypeId for a private if not on sign up process
      this.customerLinkTypeId = this.customerForm.value.customerLinkTypeId;
    } else if (!this.isSignUpProcess && this.isPrivate && this.isCustomerLinkedEmployee) {
      this.customerLinkTypeId = this.isCustomerLinkedEmployee && this.CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT;
    }

    this.tools.showLoader();

    const customerId =
      !this.isSignUpProcess && this.customerLinked
        ? this.customerLinked.id
        : !this.isSignUpProcess && this.customer && this.customer.id;

    // Check if customer already exists if he's not registered
    // For a pro customer we use only the lastname
    if (this.customerForm.controls.lastname && !this.isAlreadyRegistered && customerId) {
      this.customerSrv
        .customerExists(
          this.customerForm.value.lastname || this.customerForm.value.company_name,
          this.customerForm.value.firstname || '',
          this.customerForm.value.dob || '',
          this.customerTypeId,
          customerId,
          this.apiToken
        )
        .subscribe(
          (customerExists) => {
            if (customerExists) {
              this.tools.hideLoader();
              this.customerAlreadyExisting.next();
              // also remove any signature
              this.signatureImg = null;
            } else {
              // if not, then we save the new customer to the BO
              this.saveToBO();
            }
          },
          (err) => {
            this.tools.hideLoader();
            this.tools.error('CustomerFormComponent customerExists()', err);
            this.notificationSrv.showError({ message: err });
          }
        );
    } else {
      // if api_token is undefined then we skip this check
      this.saveToBO();
    }
  }

  private setFlags() {
    // Set which type of customer linked is being created
    this.isPro = this.customerTypeId === this.CUSTOMER_TYPE_ID_CORPORATE;
    this.isEmployee = this.customerTypeId === this.CUSTOMER_TYPE_ID_EMPLOYEE;
    this.isPrivate = this.customerTypeId === this.CUSTOMER_TYPE_ID_PRIVATE;
    this.isCustomerLinkedEmployee =
      this.customerLinked && this.customerLinked.customer_type_id === this.CUSTOMER_TYPE_ID_EMPLOYEE;
    this.displayAddressButton = !!(
      this.customerLinked &&
      this.customerLinked.default_address &&
      this.customerLinked.default_address.address &&
      this.customerLinked.default_address.address.id &&
      this.customerLinked.default_address.city &&
      this.customerLinked.default_address.city.id &&
      this.customerLinked.default_address.country &&
      this.customerLinked.default_address.country.id
    );
  }

  /**
   * Initialize properties
   */
  private initialize(): void {
    this.setModeDisplay();

    if (this.customerFormType === CustomerFormType.Update && this.apiToken) {
      this.setDataUpdate();
    } else {
      this.setDataCreate();
    }
  }

  /**
   * set customerLinkTypes options
   */
  private setCustomerLinkTypesOptions(): void {
    this.constantSrv.getConstantByForeignTable(this.FOREIGN_KEY_CUSTOMER_LINK).subscribe((result) => {
      result = _.filter(result, (item: Constant) => this.isCustomerLinkTypeAuthorized(item));
      this.customerLinkTypes = _.map(
        result,
        (item: Constant) =>
          ({
            value: item.value,
            text: item.key_translate,
            selected: false,
          } as GflSelectOption)
      );
    });
  }

  /**
   * Set customer address as the same as the customerLinked
   *
   * @param defaultAddress Customer Address
   */
  private setCustomerAddress(defaultAddress: DefaultAddress | null) {
    this.address = {
      street: defaultAddress && defaultAddress.address && defaultAddress.address.street,
      street2: defaultAddress && defaultAddress.address && defaultAddress.address.street2,
      country_id: defaultAddress && defaultAddress.country && defaultAddress.country.id,
    };
    this.city = defaultAddress && defaultAddress.city;

    this.customerForm.patchValue({
      address1: this.address.street,
      address2: this.address.street2,
      zipCodeCity: {
        postcode: this.city && this.city.postcode,
        city: this.city,
        cityId: this.city && this.city.id,
      },
      country: 'Suisse',
    });
  }

  /**
   * Set modeDisplay to mobile or tablet
   */
  private setModeDisplay(): void {
    this.modeDisplay = this.tools.setModeDisplay();
  }

  /**
   * Set formData attributes for items template with customer data
   */
  private setDataUpdate(): void {
    forkJoin([
      this.customerSrv.getCustomerItemsByItemTemplateKey(this.customer.id, this.ITEM_TEMPLATE_KEY_CUSTOMER),
      this.customerSrv.getCustomerItemsByItemTemplateKey(this.customer.id, this.ITEM_TEMPLATE_KEY_PHONE),
      this.customerSrv.getCustomerItemsByItemTemplateKey(this.customer.id, this.ITEM_TEMPLATE_KEY_EMAIL),
      this.itemSrv
        .getItemTemplateByItemTemplateKey(
          this.FOREIGN_KEY_CUSTOMER,
          true,
          this.ITEM_TEMPLATE_KEY_CUSTOMER,
          this.apiToken
        )
        .pipe(first()),
      this.itemSrv
        .getItemTemplateByItemTemplateKey(this.FOREIGN_KEY_CUSTOMER, true, this.ITEM_TEMPLATE_KEY_PHONE, this.apiToken)
        .pipe(first()),
      this.itemSrv
        .getItemTemplateByItemTemplateKey(this.FOREIGN_KEY_CUSTOMER, true, this.ITEM_TEMPLATE_KEY_EMAIL, this.apiToken)
        .pipe(first()),
    ]).subscribe(
      ([customerItems, phoneItems, emailItems, itemsTemplateCustomer, itemsTemplatePhone, itemsTemplateEmail]) => {
        this.address = {
          street:
            this.customer &&
            this.customer.default_address &&
            this.customer.default_address.address &&
            this.customer.default_address.address.street,
          street2:
            this.customer &&
            this.customer.default_address &&
            this.customer.default_address.address &&
            this.customer.default_address.address.street2,
          country_id:
            this.customer &&
            this.customer.default_address &&
            this.customer.default_address.country &&
            this.customer.default_address.country.id,
        };
        if (this.customer && this.customer.default_address && this.customer.default_address.city) {
          this.city = this.customer.default_address.city;
        }

        const itemsData = this.gflFormSrv.setFormData(customerItems, itemsTemplateCustomer, true);
        const itemsPhoneData = this.gflFormSrv.setFormData(phoneItems, itemsTemplatePhone, true);
        const itemsEmailData = this.gflFormSrv.setFormData(emailItems, itemsTemplateEmail, true);

        // this step is needed because we won't use the GflFormGenerator but directly GflFieldGenerator...
        this.customerFormData = this.gflFormSrv.formatItemsTemplate(itemsData);
        this.phoneFormData = this.gflFormSrv.formatItemsTemplate(itemsPhoneData);
        this.emailFormData = this.gflFormSrv.formatItemsTemplate(itemsEmailData);
        // we store initial values to be used in case of cancellation
        this.initialFormValues = this.gflFormSrv.getFormInitialValues(this.customerFormData);
        this.initialPhoneFormValues = this.gflFormSrv.getFormInitialValues(this.phoneFormData);
        this.initialEmailFormValues = this.gflFormSrv.getFormInitialValues(this.emailFormData);

        this.initForm();
      },
      (err) => {
        this.tools.error('CustomerFormComponent setData()', err);
      }
    );
  }

  /**
   * Set formData attributes for items template
   */
  private setDataCreate(): void {
    forkJoin([
      this.itemSrv
        .getItemTemplateByItemTemplateKey(
          this.FOREIGN_KEY_CUSTOMER,
          true,
          this.ITEM_TEMPLATE_KEY_CUSTOMER,
          this.apiToken
        )
        .pipe(first()),
      this.itemSrv
        .getItemTemplateByItemTemplateKey(this.FOREIGN_KEY_CUSTOMER, true, this.ITEM_TEMPLATE_KEY_PHONE, this.apiToken)
        .pipe(first()),
      this.itemSrv
        .getItemTemplateByItemTemplateKey(this.FOREIGN_KEY_CUSTOMER, true, this.ITEM_TEMPLATE_KEY_EMAIL, this.apiToken)
        .pipe(first()),
    ]).subscribe(
      ([itemsTemplateCustomer, itemsTemplatePhone, itemsTemplateEmail]) => {
        // this step is needed because we won't use the GflFormGenerator but directly GflFieldGenerator...
        // @ts-ignore
        this.customerFormData = this.gflFormSrv.formatItemsTemplate(_.cloneDeep(itemsTemplateCustomer));
        // @ts-ignore
        this.phoneFormData = this.gflFormSrv.formatItemsTemplate(_.cloneDeep(itemsTemplatePhone));
        // @ts-ignore
        this.emailFormData = this.gflFormSrv.formatItemsTemplate(_.cloneDeep(itemsTemplateEmail));

        this.initialFormValues = this.gflFormSrv.getFormInitialValues(this.customerFormData);
        this.initialPhoneFormValues = this.gflFormSrv.getFormInitialValues(this.phoneFormData);
        this.initialEmailFormValues = this.gflFormSrv.getFormInitialValues(this.emailFormData);

        this.initForm();
      },
      (err) => {
        this.tools.error('CustomerFormComponent setDataCreate()', err);
      }
    );
  }

  /**
   * Initialize form
   */
  private initForm(): void {
    if (this.isEmployee) {
      this.initFormEmployee();
    } else if (this.isPro) {
      this.initFormPro();
    } else {
      this.initFormPrivate();
    }
  }

  /**
   * Generate dynamic form for private
   */
  private initFormPrivate(): void {
    this.customerForm = new FormGroup({});

    this.validatorsForm = {
      login: [Validators.required],
      address1: [Validators.required],
      address2: [],
      zipCodeCity: [Validators.required],
      country: [],
      dob: [Validators.required],
      password: [Validators.required, Validators.minLength(4)],
    };

    this.initialFormValues = {
      ...this.initialFormValues,
      login: '',
      address1: this.address.street,
      address2: this.address.street2,
      zipCodeCity: this.city,
      country: 'Suisse',
    };

    setTimeout(() => {
      this.onPrivateFormChanges();
    }, 300);
  }

  /**
   * Generate dynamic form for pro
   */
  private initFormPro(): void {
    this.customerForm = new FormGroup({});

    this.validatorsForm = {
      address1: [Validators.required],
      address2: [],
      zipCodeCity: [Validators.required],
      country: [],
    };

    this.initialFormValues = {
      ...this.initialFormValues,
      address1: this.address.street,
      address2: this.address.street2,
      zipCodeCity: this.city,
    };

    this.onProFormChanges();
  }

  /**
   * Generate dynamic form for employee
   */
  private initFormEmployee(): void {
    this.customerForm = new FormGroup({});

    this.validatorsForm = {
      login: [Validators.required],
      password: [Validators.required, Validators.minLength(4)],
      phone: [Validators.required],
    };

    this.initialFormValues = {
      ...this.initialFormValues,
      login: '',
      password: '',
      phone: { prefix: null, phone: null },
    };

    setTimeout(() => {
      this.onEmployeeFormChanges();
    }, 300);
  }

  /**
   *
   */
  private onPrivateFormChanges(): void {
    const dobControl: AbstractControl = this.customerForm.get('dob');

    if (dobControl) {
      dobControl.valueChanges.subscribe((val) => this.setIsAdult(val));
    }
  }

  /**
   *
   */
  private onProFormChanges(): void {}

  /**
   *
   */
  private onEmployeeFormChanges(): void {
    const dobControl: AbstractControl = this.customerForm.get('dob');

    // Update isAdult flag according to dob value
    if (dobControl) {
      dobControl.valueChanges.subscribe((val) => {
        this.setIsAdult(val);
        this.isSignatureMandatory = !this.isSignUpProcess && ((this.isPrivate && this.isAdult) || this.isPro);
      });
    }
  }

  /**
   * set isAdult flag to true if age >= 18 years
   */
  private setIsAdult(birthDate: string) {
    const majorAge = 18;

    if (birthDate) {
      const temp = birthDate.split('-');
      const majorDate = new Date(parseInt(temp[0], 10) + majorAge + '-' + temp[1] + '-' + temp[2]);
      const today = new Date();

      return (this.isAdult = !(majorDate > today));
    }

    this.isAdult = false;
  }

  /**
   * Launch record to BO process
   */
  private saveToBO(): void {
    let action$: Observable<any>;

    switch (this.customerFormType) {
      case CustomerFormType.Create:
        action$ = this.register().pipe(
          switchMap((customer) => {
            this.customer = customer;
            environment.AGENCY_ID = customer.agency_id;
            this.apiToken = this.isSignUpProcess && customer.api_token;
            return this.getCustomerLinkRegistrationObs();
          }),
          mergeMap(() => forkJoin(this.update(this.customerForm)))
        );
        break;

      case CustomerFormType.Update:
        action$ = this.getCustomerLinkRegistrationObs().pipe(switchMap(() => forkJoin(this.update(this.customerForm))));
        break;
    }

    action$
      .pipe(
        switchMap(() =>
          this.customerSrv.setCustomer({
            customerToken: this.apiToken,
            customerIdLinked: null,
            noUpdateMode: false,
            noAskForValidationCustomerLinks: false,
            fetchCustomerLinks: false,
            noContract: true,
          })
        )
      )
      .subscribe(
        () => {
          if (this.customerForm.value.password) {
            this.customer.password = this.customerForm.value.password.password;
          }
          this.success.next(this.customer);
        },
        (err) => {
          this.tools.error('CustomerFormComponent registerPro()', err);
          this.error.next(err);
        }
      );
  }

  /**
   * Launch registration process
   */
  private register(): Observable<any> {
    const f = this.customerForm.value;

    if (this.isAlreadyRegistered) {
      return of(null);
    }

    if (!this.customerForm.controls.dob) {
      // we force isAdult if there's not dob field
      this.isAdult = true;
    }

    return this.store.getLang().pipe(
      first(),
      mergeMap((language: string) => {
        let newCustomer: Customer = {
          customer_type: this.customerTypeId,
          language,
        };

        // create and register new customer
        if (!this.isPro) {
          const phone = _.find(f.phone_mobile, (phoneTmp) => !phoneTmp.deleted && phoneTmp.prefix && phoneTmp.phone);

          newCustomer = {
            ...newCustomer,
            password: this.isAdult && f.password.password,
            login: this.isAdult && f.login,
            email: this.isAdult && f.login,
            phone_prefix: this.isAdult && phone && phone.prefix,
            phone: this.isAdult && phone && phone.phone,
            language,
            under18: !this.isAdult,
            other_agency: this.otherAgencyName,
          };
        }

        return this.customerSrv.registerCustomer(newCustomer, environment.AGENCY_ID);
      })
    );
  }

  /**
   * Return an array of observables of these actions:
   * - Save customer's items template
   * - Save customer's address to BO
   * - Save the customer link if any
   *
   * @param form the customer form object
   */
  private update(form: FormGroup): Array<Observable<any>> {
    // update customerFormData
    this.customerFormData = this.gflFormSrv.updateFormData(this.customerFormData, form.value);
    const customerApiToken = this.isSignUpProcess && this.customer && this.customer.api_token;
    // get observables generated by form's item templates submission
    const formObservables = this.itemSrv.generateItemsSubmissionObservables(
      this.customerFormData,
      this.isSignUpProcess ? undefined : this.customer && this.customer.id,
      customerApiToken
    );

    return [
      ...formObservables,
      this.getIdCardObs(),
      this.getSignatureObs(),
      this.getAddressObs(form, customerApiToken),
    ];
  }

  /**
   * Generate putDocument Observable for ID Card
   */
  private getIdCardObs(): Observable<any> {
    let obs: Observable<any> = of(null);
    let idCard: string;
    let document;
    let isPicture: boolean;

    if (this.idCardImg) {
      const regExp = /data:image/;
      isPicture = regExp.test(this.idCardImg);
    }

    if (isPicture) {
      idCard = this.idCardImg.replace(/data:image\/.{3,4};base64,/, '');

      document = {
        path: idCard,
        tmp: idCard,
        name: this.customer.api_token + '_id_card.jpg',
        ext: 'jpg',
      };
    } else if (this.idCardImg || (this.idPdfDoc && this.idPdfDoc.data)) {
      idCard = (this.idCardImg || this.idPdfDoc.data).replace(/data:application\/pdf;base64,/, '');

      document = {
        path: idCard,
        tmp: idCard,
        name: this.customer.api_token + '_id_card.pdf',
        ext: 'pdf',
      };
    }

    if (document) {
      const customerApiToken = this.isSignUpProcess && this.customer.api_token;
      const customerLinkedId = !this.isSignUpProcess && this.customer.id;

      obs = this.documentSrv.saveDocument(
        document,
        customerLinkedId,
        this.DOCUMENT_CATEGORY_ID_ID_CARD,
        customerApiToken
      );
    }

    return obs;
  }

  /**
   * Generate putDocument Observable for signature
   */
  private getSignatureObs(): Observable<any> {
    let obs: Observable<any> = of(null);

    if (this.signatureImg) {
      const DOCUMENT_CATEGORY_ID_SIGNATURE = this.constantSrv.getValueFromKey('DOCUMENT_CATEGORY_ID_SIGNATURE');

      // TODO - generate a signature service !

      const image = this.signatureImg.replace('data:image/png;base64,', '');
      const customerApiToken = this.isSignUpProcess && this.customer.api_token;

      const document = {
        path: image,
        tmp: image,
        name: this.customer.id + '_signature.png',
        ext: 'png',
      };

      obs = this.documentSrv.saveDocument(document, this.customer.id, DOCUMENT_CATEGORY_ID_SIGNATURE, customerApiToken);
    }

    return obs;
  }

  /**
   * Generate putCustomerAddress Observable
   *
   * @param form the customer form object
   * @param apiToken current customer's apiToken
   */
  private getAddressObs(form: FormGroup, apiToken: string): Observable<any> {
    let obs: Observable<any> = of(null);

    if (form.value.address1) {
      // Build the address entity
      const address: Address = {
        street: form.value.address1,
        street2: form.value.address2,
        postcode: form.value.zipCodeCity.city.postcode,
        city_id: form.value.zipCodeCity.city.id,
      };

      obs = this.customerSrv.putCustomerAddress(address, this.isSignUpProcess ? null : this.customer.id, apiToken);
    }

    return obs;
  }

  /**
   * Generate customer link between current customer and customerLinked
   */
  private getCustomerLinkRegistrationObs(): Observable<any> {
    // Check if we need to link the current customer
    if (this.customerLinked) {
      return this.customerSrv.registerCustomerLink(
        this.customerLinkTypeId || Number(this.customerForm.value.customerLinkTypeId),
        this.customer.id,
        !this.isSignUpProcess && this.customerLinked.id,
        this.customerLinked.api_token,
        this.customerForm.value.roleId
      );
    } else {
      // if not ...
      return of(null);
    }
  }

  /**
   * Select Source type in order to deliver the picture
   */
  private takeNativePicture(): void {
    this.translate
      .get(['COMMON.PHOTO_FROM_CAMERA', 'COMMON.PHOTO_FROM_LIBRARY', 'COMMON.SELECT_PDF', 'COMMON.BUTTON_CANCEL'])
      .subscribe(async (result) => {
        const buttons: ActionSheetButton[] = [
          {
            text: result['COMMON.PHOTO_FROM_CAMERA'],
            cssClass: 'gfl-alert-validate-btn',
            icon: !this.isIOS ? 'camera' : null,
            handler: () => {
              this.cameraOptions.source = CameraSource.Camera;
              this.getPicture();
            },
          },
          {
            text: result['COMMON.PHOTO_FROM_LIBRARY'],
            cssClass: 'gfl-alert-info-btn',
            icon: !this.isIOS ? 'images' : null,
            handler: () => {
              this.cameraOptions.source = CameraSource.Photos;
              this.getPicture();
            },
          },
        ];

        if (!this.isIOS) {
          buttons.push({
            text: result['COMMON.SELECT_PDF'],
            cssClass: 'gfl-alert-validate-btn',
            icon: 'document',
            handler: () => {
              this.cameraOptions.source = CameraSource.Camera;
              this.getPDF();
            },
          });
        }

        buttons.push({
          text: result['COMMON.BUTTON_CANCEL'],
          role: 'cancel', // will always sort to be on the bottom
          icon: !this.isIOS ? 'close' : null,
          handler: () => {},
        });

        const actionSheet = await this.actionSheetCtrl.create({ buttons });

        await actionSheet.present();
      });
  }

  /**
   * Use Native Camera or Photo Library to get the picture
   */
  private async getPicture(): Promise<void> {
    try {
      const image: Photo = await Camera.getPhoto(this.cameraOptions);
      this.tools.log('image getPicture', image);

      this.ngZone.run(() => {
        this.idCardImg = image.dataUrl;
      });
    } catch (err) {
      this.tools.error('CustomerFormComponent getPicture', err);
      if (typeof err !== 'string') {
        this.notificationSrv.showError({
          message: 'API.ERROR_MESSAGE',
        });
      }
    }
  }

  /**
   * Use chooser to get a pdf file for idCard
   */
  private getPDF(): void {
    this.chooser
      .getFile(this.tools.getMIMEType('pdf'))
      .then((file) => {
        this.idPdfDoc = {
          filename: 'idCard',
          title: 'idCard',
          thumbnail: null,
          spriteItem: 'pdf-icon',
          data: file.dataURI,
          ext: 'pdf',
        };
      })
      .catch((error: any) => {
        this.tools.error('getPDF', error);
        this.notificationSrv.showError({ message: 'API.ERROR_MESSAGE' });
      });
  }

  /**
   * Set roles select options
   *
   * @param customerTypeId customerType id
   */
  private setRolesOptions(customerTypeId: number) {
    this.rolesSrv.getRolesByCustomerTypeId(customerTypeId).subscribe((roles) => {
      this.roles = _.map(
        roles,
        (role: CustomerRole) =>
          ({
            value: role.id,
            text: role.name,
            selected: false,
          } as GflSelectOption)
      );
    });
  }

  /**
   * Check if this item is authorized for the customer's type id and the customer's link type id
   *
   * @param item an item from constant service
   */
  private isCustomerLinkTypeAuthorized(item: Constant): boolean {
    const val = parseInt(item.value, 10);
    const customerTypeId =
      (this.customer && this.customer.customer_type_id) ||
      (this.customerLinked && this.customerLinked.customer_type_id);
    switch (customerTypeId) {
      case this.CUSTOMER_TYPE_ID_EMPLOYEE:
        if (this.customerTypeId === this.CUSTOMER_TYPE_ID_PRIVATE) {
          // an employee can link to a private
          // with CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT only
          return val === this.CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT;
        }
        if (this.customerTypeId === this.CUSTOMER_TYPE_ID_CORPORATE) {
          return val === this.CUSTOMER_LINK_TYPE_EMPLOYEE;
        }
        return false;
      case this.CUSTOMER_TYPE_ID_PRIVATE:
        // Private case
        if (this.customerTypeId === this.CUSTOMER_TYPE_ID_EMPLOYEE) {
          // a private can link to employee
          // with CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT only
          return val === this.CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT;
        } else if (this.customerTypeId === this.CUSTOMER_TYPE_ID_PRIVATE) {
          // a private can link to a private
          // with all customerTypeId < or = to CUSTOMER_LINK_TYPE_PARENT
          return val <= this.CUSTOMER_LINK_TYPE_PARENT;
        }
        return false;
      case this.CUSTOMER_TYPE_ID_CORPORATE:
        // corporate case
        if (this.customerTypeId === this.CUSTOMER_TYPE_ID_EMPLOYEE) {
          return val === this.CUSTOMER_LINK_TYPE_EMPLOYEE;
        }
        return false;
    }
  }
}
