import { createAction, props } from '@ngrx/store';
import { LocksState } from './locks.reducer';

export const initialize = createAction(
  '[Store Service setNgrxStore] initialize DocumentsState',
  props<{ locksState: LocksState }>()
);

export const setDocumentCategoriesLock = createAction(
  '[Document Service setDocumentCategories()] set documentCategories lock',
  props<{ lock: number }>()
);

export const setDocumentsLock = createAction(
  '[Documents Service setDocuments()] Set documents lock',
  props<{ lock: number }>()
);

export const setSafeboxTypesLock = createAction(
  '[Safebox Service setSafeboxTypes()] set safebox types lock',
  props<{ lock: number }>()
);

export const setSafeboxesLock = createAction(
  '[Store Service setSafeboxes()] set safebox lock',
  props<{ lock: number }>()
);

export const setAgencyLock = createAction('[Agency Service setAgency()] Set Agency lock', props<{ lock: number }>());

export const setSlidersLock = createAction(
  '[Slider Service initService()] Set sliders lock',
  props<{ lock: number }>()
);

export const setPoliciesAndMandatesLock = createAction(
  '[Policy service setPoliciesAndMandates()] set policies lock',
  props<{ lock: number }>()
);

export const setInsuranceTypesLock = createAction(
  '[InsuranceType service setInsuranceTypes()] set lock for insurance types',
  props<{ lock: number }>()
);

export const setCompaniesLock = createAction(
  '[Company Service setCompanies()] set companies lock',
  props<{ lock: number }>()
);

export const setCustomerLock = createAction(
  '[Customer Service setCustomer()] set customer lock',
  props<{ lock: number }>()
);

export const setChatItemsLock = createAction(
  '[Store Service setChatItems()] set chatItems lock',
  props<{ lock: number }>()
);

export const setComparesLock = createAction(
  '[Compare service setCompares()] set compares lock',
  props<{ lock: number }>()
);

export const setConstantsLock = createAction(
  '[constants service setConstants()] set constants lock',
  props<{ lock: number }>()
);

export const setPermissionsLock = createAction(
  '[acls service setAcls()] set permission lock',
  props<{ lock: number }>()
);

export const setForeignTablesLock = createAction(
  '[constants service setForeignTables()] set foreignTables lock',
  props<{ lock: number }>()
);

export const setSpriteLock = createAction('[sprite service setSprite()] set sprite lock', props<{ lock: number }>());

export const setItemTemplatesLock = createAction(
  '[item service setItemTemplates()] set itemTemplates lock',
  props<{ lock: number }>()
);

export const setStatusesLock = createAction(
  '[status service setStatuses()] set status lock',
  props<{ lock: number }>()
);

export const setRolesLock = createAction('[roles service setRoles()] set status lock', props<{ lock: number }>());

export const setTranslationLock = createAction(
  '[App Module getTranslation()] set translation lock',
  props<{ lock: number }>()
);
