import { Component, Input } from '@angular/core';

export interface ProgressSignupConfig {
  step1: number;
  step2: number;
  step3: number;
  step4: number;
  time: number;
}

@Component({
  selector: 'gfl-progress-signup',
  templateUrl: './progress-signup.component.html',
  styleUrls: ['./progress-signup.component.scss'],
})
export class ProgressSignupComponent {
  @Input() config: ProgressSignupConfig;
}
